import React from 'react'
import style from "../../../assets/css/components/assessment.module.css"

export const SurveyCompleteMsg = ({ userName }) => {
    return (
        <div className={style.survey_complete_msg_con}>
            <div className={style.msg_con}>
                <div className='flex center'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
                        <path d="M35.3333 45.9993L28.1666 38.8327C27.5555 38.2216 26.7777 37.916 25.8333 37.916C24.8888 37.916 24.1111 38.2216 23.5 38.8327C22.8888 39.4438 22.5833 40.2216 22.5833 41.166C22.5833 42.1105 22.8888 42.8882 23.5 43.4993L33 52.9993C33.6666 53.666 34.4444 53.9993 35.3333 53.9993C36.2222 53.9993 37 53.666 37.6666 52.9993L56.5 34.166C57.1111 33.5549 57.4166 32.7771 57.4166 31.8327C57.4166 30.8882 57.1111 30.1105 56.5 29.4993C55.8888 28.8882 55.1111 28.5827 54.1666 28.5827C53.2222 28.5827 52.4444 28.8882 51.8333 29.4993L35.3333 45.9993ZM40 73.3327C35.3888 73.3327 31.0555 72.4577 27 70.7077C22.9444 68.9577 19.4166 66.5827 16.4166 63.5827C13.4166 60.5827 11.0416 57.0549 9.29163 52.9993C7.54163 48.9438 6.66663 44.6105 6.66663 39.9993C6.66663 35.3882 7.54163 31.0549 9.29163 26.9993C11.0416 22.9438 13.4166 19.416 16.4166 16.416C19.4166 13.416 22.9444 11.041 27 9.29102C31.0555 7.54102 35.3888 6.66602 40 6.66602C44.6111 6.66602 48.9444 7.54102 53 9.29102C57.0555 11.041 60.5833 13.416 63.5833 16.416C66.5833 19.416 68.9583 22.9438 70.7083 26.9993C72.4583 31.0549 73.3333 35.3882 73.3333 39.9993C73.3333 44.6105 72.4583 48.9438 70.7083 52.9993C68.9583 57.0549 66.5833 60.5827 63.5833 63.5827C60.5833 66.5827 57.0555 68.9577 53 70.7077C48.9444 72.4577 44.6111 73.3327 40 73.3327ZM40 66.666C47.4444 66.666 53.75 64.0827 58.9166 58.916C64.0833 53.7493 66.6666 47.4438 66.6666 39.9993C66.6666 32.5549 64.0833 26.2493 58.9166 21.0827C53.75 15.916 47.4444 13.3327 40 13.3327C32.5555 13.3327 26.25 15.916 21.0833 21.0827C15.9166 26.2493 13.3333 32.5549 13.3333 39.9993C13.3333 47.4438 15.9166 53.7493 21.0833 58.916C26.25 64.0827 32.5555 66.666 40 66.666Z" fill="#5BA400" />
                    </svg>
                </div>
                <div className={style.msg}>
                    {userName}, thank you for
                    completing the survey
                </div>
            </div>
        </div>
    )
}
