import React from 'react'
import { CircularProgressBar } from '../CircularProgressBar'
import style from "../../../assets/css/components/reports.module.css"
import { DoubleCircularProgressBar } from '../DoubleCircularProgressBar'

export const ProgressCard = ({ value1, name, value2, type, active, showChart, ...props }) => {

    return (
        <div {...props} className={`${active ? style.active_card : ""} ${!showChart ? style.show_only_name : style.progress_card_con} pointer`}>
            {showChart &&
                <div>
                    {!value2 ?
                        <div>
                            <CircularProgressBar value={value1} />
                        </div>
                        :
                        <div>
                            <DoubleCircularProgressBar value1={value1} value2={value2} />
                        </div>}
                </div>}

            <div className={style.content}>{name}</div>
        </div>

    )
}
