import React, { useEffect } from 'react'
import { KPISIHomeTable } from "../../components/Common/KPI&SICommonComp/KPISHomeTable"
import { currentUser, currentUserPrimaryGroupId, currentUserRole } from '../../utils'
import { useNavigate } from 'react-router'

export const KPIList = () => {
    let role = currentUserRole()
    let primary_group = currentUserPrimaryGroupId()
    let navigate = useNavigate()

    useEffect(() => {
        const checkUserRoleAndNavigate = () => {
            let user = currentUser()
            if (user.role.item_name === "groupAdmin") {
                navigate(`preview/${user.user.primary_group}`)
            }
        }
        checkUserRoleAndNavigate()
    }, [])

    return (
        <KPISIHomeTable type={"KPI"} primary_group={primary_group} role={role} />
    )
}