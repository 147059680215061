// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .footer_main_con {
    height: 40px;
    color: #4A4A4A;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0px 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & span {
        margin-left: 40px;
    }
}

*/
.TRO1pqXlcV4IDU2BLrds {
    padding: 10px 44px;
    font-size: 14px;
    color: #4A4A4A;
    font-weight: 400;
    display: flex;
    align-items: center;
    background-color: #F9F9FD;

    .kQXmilObjzxov__CUqDK {
        display: flex;
        gap: 20px;
        text-wrap: nowrap;
    }

}

@media (max-width:850px) {
    .TRO1pqXlcV4IDU2BLrds {
        font-size: 10px;

        .kQXmilObjzxov__CUqDK {
            gap: 10px;
        }
    }
}

/* @media (max-width:677px) {
    .footer_main_con {
        font-size: 5px;


    }
} */`, "",{"version":3,"sources":["webpack://./src/assets/css/components/footer.module.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;CAiBC;AACD;IACI,kBAAkB;IAClB,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,yBAAyB;;IAEzB;QACI,aAAa;QACb,SAAS;QACT,iBAAiB;IACrB;;AAEJ;;AAEA;IACI;QACI,eAAe;;QAEf;YACI,SAAS;QACb;IACJ;AACJ;;AAEA;;;;;;GAMG","sourcesContent":["/* .footer_main_con {\n    height: 40px;\n    color: #4A4A4A;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n    padding: 0px 44px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n\n    & span {\n        margin-left: 40px;\n    }\n}\n\n*/\n.footer_main_con {\n    padding: 10px 44px;\n    font-size: 14px;\n    color: #4A4A4A;\n    font-weight: 400;\n    display: flex;\n    align-items: center;\n    background-color: #F9F9FD;\n\n    .container_2 {\n        display: flex;\n        gap: 20px;\n        text-wrap: nowrap;\n    }\n\n}\n\n@media (max-width:850px) {\n    .footer_main_con {\n        font-size: 10px;\n\n        .container_2 {\n            gap: 10px;\n        }\n    }\n}\n\n/* @media (max-width:677px) {\n    .footer_main_con {\n        font-size: 5px;\n\n\n    }\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer_main_con": `TRO1pqXlcV4IDU2BLrds`,
	"container_2": `kQXmilObjzxov__CUqDK`
};
export default ___CSS_LOADER_EXPORT___;
