import axios from "../config/axiosInterceptor";
import { API, BASE_URL} from "./api";


export const getAllGroups = async () => {
    return await axios.post(BASE_URL + API.fetchGroups)
}

export const createSubGroup = async (data) => {
    return await axios.post(BASE_URL + API.createSubGroup, data)
}
export const getSubGroupByGroupId = async (id) => {
    return await axios.get(BASE_URL + API.fetchSubGroupsByGroupId + id)
}
export const getSubGroupById = async (id) => {
    return await axios.get(BASE_URL + API.fetchSubGroupById + id)
}
export const editSubGroup = async (data) => {
    return await axios.put(BASE_URL + API.editSubGroup, data)
}
export const deleteSubGroup = async (id) => {
    return await axios.delete(BASE_URL + API.deleteSubGroup + id)
}
export const getAllSubGroups = async () => {
    return await axios.get(BASE_URL + API.getAllSubGroups)
}