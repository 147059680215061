import React, { useEffect, useRef, useState } from "react"
import { SubGroupForm } from "../../components/Common/Group/SubGroupForm"
import { editSubGroup, getSubGroupById } from "../../api/Group"
import { useNavigate, useParams } from "react-router"
import { loader, toast } from "../../utils"
import { BreadCrum } from "../../components/Common/BreadCrum"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
export const EditSubGroup = () => {
    const tokenStatus = useSelector((state) => state.tokenStatus);
    let params = useParams()
    let [updatedFields, setUpdatedFields] = useState({})
    let [subGroupData, setSubGroupData] = useState({})
    let { t } = useTranslation()
    let navigate = useNavigate()
    let getsubgroupbygroupidRef = useRef(getsubgroupbygroupid)

    async function getsubgroupbygroupid() {
        try {
            loader.start()
            let res = await getSubGroupById(params.id)
            loader.stop()
            setSubGroupData(res.data.response)
        }
        catch (err) {
            loader.stop()
            toast.error(err?.response?.data?.error)
        }
    }
    async function editsubgroup() {
        try {
            loader.start()
            let res = await editSubGroup({ ...updatedFields, _id: params.id })
            toast.success(res.data.message)
            loader.stop()
            navigate(`/group/viewSubgroup/${subGroupData.groupId}`)
        }
        catch (err) {
            loader.stop()
            toast.error(err?.response?.data?.error)
        }
    }
    useEffect(() => {
        getsubgroupbygroupidRef.current()
    }, [getsubgroupbygroupidRef, tokenStatus])

    let breadCrumData = [
        {
            text: t("groupManagement.Group Management"),
            path: "/group"
        },
        {
            text: t("groupManagement.Edit Sub Group"),
        },


    ]

    return (

        <div className={"padding_22_44"}>
            <BreadCrum dataArr={breadCrumData} />
            <br />
            {subGroupData.name !== undefined ?
                <SubGroupForm groupDisable={false} mode="edit" setUpdatedFields={setUpdatedFields} onSubmit={editsubgroup} data={subGroupData} /> : null}
        </div>
    )
}