import React, { useRef } from "react"
import { KPISIForm } from "../../components/Common/KPI&SICommonComp/KPISIForm"
import { createKPI } from "../../api/KPI"
import { loader, toast } from "../../utils"
import { useLocation, useNavigate } from "react-router"
import { BreadCrum } from "../../components/Common/BreadCrum"
import { useTranslation } from "react-i18next"

export const CreateKPIs = () => {
    const id = new URLSearchParams(useLocation().search).get('id');
    const groupName = new URLSearchParams(useLocation().search).get('groupName');
    let { t } = useTranslation()
    let KPIData = useRef({
        name: '',
        groupId: Number(id),
        siId: null,
        groupName,
        type: 'Organizational'
    })
    let navigate = useNavigate()

    async function formSubmit(data) {
        delete data.groupName

        try {
            loader.start()
            let res = await createKPI(data)
            loader.stop()
            toast.success(res.data.message)
            navigate(`/KPI/preview/${data.groupId}?addMore=true`)
        }
        catch (err) {
            loader.stop()
            toast.error(err?.response?.data?.error)
        }
    }

    let breadCrumData = [
        {
            text: t("KPI.Key Performance Indicators"),
            path: "/KPI"
        },
        {
            text: t("Create New"),
        },


    ]
    return (
        <>
            <div className="padding_22_44">
                <BreadCrum dataArr={breadCrumData} />
                <br />
                <KPISIForm mode={"Create"} groupDisable={id && groupName ? true : false} onClose={() => navigate("/KPI")} onSubmit={formSubmit} type={"KPI"} data={KPIData.current} />
            </div>
        </>
    )
}
