import React, { Fragment } from 'react'
import style from "../../../assets/css/components/assessment.module.css"
import { ExpandMoreButton } from '../../Buttons/ExpandMoreButton'
import { ExpandLessButton } from '../../Buttons/ExpandLessButton'

import CircleIcon from '@mui/icons-material/Circle';
import { useTranslation } from 'react-i18next'
export const AssessmentDropDownMenu = ({ data, addRemoveButtons, questionData, openIndex, currentIndex, setOpenIndex }) => {
    let { t } = useTranslation();
    console.log(openIndex, "============================")

    return (
        <div>
            <div className='flex align_center'>
                <div>
                    {openIndex === currentIndex ? <ExpandLessButton onClick={() => {
                        setOpenIndex && setOpenIndex(0)
                    }} /> :
                        <ExpandMoreButton onClick={() => setOpenIndex(currentIndex)} />}
                </div>
                <div className={style.heading}>
                    {data?.name}
                </div>
            </div>
            {openIndex === currentIndex ?
                <div className={`${style.question_con}`}>
                    {questionData?.questionData?.length > 0 ?
                        <div className='flex' style={{ gap: "30px" }}>
                            <div className={`w-70 ${style.label}`}>{t("questionBank.Question")}</div>
                            {/* <div className={`w-30 ${style.label} noWrap`}>{t("Question Type")}</div> */}
                        </div> :
                        <div className='body_1'>{t("No Question Selected")}...</div>
                    }
                    {questionData && questionData.questionData.map((res) => {
                        return (
                            <Fragment key={res._id}>
                                <div className={`flex align_center ${style.content}`} style={{ gap: "30px" }} >
                                    <div className='w-70 flex'>
                                        <div><CircleIcon sx={{ fontSize: "6px", pb: 0.2, mr: 1 }} /></div>  <div>{res.question}</div>
                                    </div>
                                    {/* <div className='w-30'>{res.questionType}</div> */}
                                </div>
                            </Fragment>
                        )
                    })}

                    <div>{addRemoveButtons && addRemoveButtons()}</div>
                </div> : null}
        </div>
    )
}
