import React, { useEffect, useState } from 'react'
import { BreadCrum } from '../../components/Common/BreadCrum'
import style from "../../assets/css/views/reports.module.css"
import { ASSESSMENT_TYPES } from '../../constants/constants'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { generateCombinedReport } from '../../api/Reports'
import { formatChartdata, loader } from '../../utils'
import { getSubGroupByGroupId } from '../../api/Group'
import { DownloadButton } from '../../components/Buttons/DownloadButton'
import { DownloadPdf } from '../../components/Common/DownloadPdf'
import { ButtonTabGroup } from '../../components/Common/ButtonTabGroup'
import { MultipleCircularProgressCard } from '../../components/Common/Reports/MultipleCircularProgressCard'
import { MultipleProgressLineTable } from '../../components/Common/Reports/MultipleProgressLineTable'
import { TabButton } from '../../components/Common/TabButton'
import { fetchJobRolesByGroupId } from '../../api/jobRoles'
import { NoTableData } from '../../components/Common/NoTableData'
import _ from "underscore";


export const ReportsDetail = () => {

  const { assessmentType } = useParams()
  const isOrg = assessmentType === ASSESSMENT_TYPES.organizational
  let { t } = useTranslation()

  let [filterInputValues, setFilterInputValues] = useState({
    assessmentCycle: null
  })
  let [error, setError] = useState({
    error: false,
    errorMsg: ""
  })

  let [chartData, setChartData] = useState({
    circularChartData: [],
    tableChartData: []
  })
  let [filteredChartData, setFilteredChartData] = useState({
    circularChartData: [],
    tableChartData: [],
  })

  let params = useParams()
  const downloadClickHandler = () => {
    return
    const input = document.getElementById('pdf-content');
    return input;
  };

  let breadCrumData = [
    {
      text: t("Reports"),
      path: `/reports/${assessmentType}`
    },
    {
      text: t(assessmentType),
      // path: `/reports/${assessmentType}`
    },
    {
      text: t(assessmentType),
    },

  ]


  let [subTabData, setSubTabData] = useState([])
  let [activeTab, setActiveTab] = useState("group")
  const [selectedSubTab, setSelectedSubTab] = useState(null)

  function TabBtnClick(res) {
    setActiveTab(res.value)
  }

  let tabs = [
    { label: t(isOrg ? "Group" : "All"), value: "group" },
    { label: t(isOrg ? "Sub Group" : "jobRole.jobRole"), value: "subGroup", disabled: subTabData?.length === 0 },
  ]



  async function getSubGroupsOfGroup() {
    try {
      let res = await getSubGroupByGroupId(params.id)
      let data = res?.data?.response || []
      // set subGroup data into sub tabs state
      setSubTabData(data.map((e, ind) => ({ name: e.name, value: e._id, active: ind === 0 })))
    }
    catch (err) {
      console.log(err)
    }
  }

  async function getAllJobRolesOfGroup() {
    try {
      let res = await fetchJobRolesByGroupId(params.id)
      let data = res?.data?.response || []
      // set job role data into sub tabs state 
      setSubTabData(data.map((e, ind) => ({ name: e?.jobRoleObj?.name, value: e.jobRoleObj?.roleId, active: ind === 0 })))
    }

    catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    // fetch subgroup of group if assessment type is organizational   
    if (assessmentType === ASSESSMENT_TYPES.organizational) {
      getSubGroupsOfGroup()
    }
    // fetch job role of group if assessment type is job role 
    else {
      getAllJobRolesOfGroup()
    }
  }, [])

  useEffect(() => {
    // if user click on first tab 
    if (activeTab === "group") {
      getReportData({ groupId: params.id })
    }
    else {
      //  user click on second tab (for sub tabs)
      setSelectedSubTab(subTabData?.[0]?.value)
    }
  }, [activeTab])

  // fetch report data 
  async function getReportData(reqBody) {
    try {
      let body = {
        ...reqBody,
        assessmentType: assessmentType
      }
      loader.start()
      let res = await generateCombinedReport(body)
      setError({
        error: false,
        errorMsg: ""
      })
      let response = res?.data?.response || []
      if (_.size(response.reports)) {
        let data = {
          assessmentType: assessmentType,
          reports: response.reports
        }
        // this function is also used to format report date in single assesment report page
        // format response data as per chart compatiblity 
        let formattedData = formatChartdata(data, "multipleAssessmentReport")
        setChartData(formattedData)
        setFilteredChartData(formattedData)
      }
      else {
        setError({
          error: true,
          errorMsg: "No Reports Found"
        })
      }
    }
    catch (err) {
      setError({
        error: true,
        errorMsg: "No Reports Found"
      })
      console.log(err)
    }
    finally {
      loader.stop()
    }
  }

  useEffect(() => {
    if (selectedSubTab) {
      // fetch report data if user click on sub group tab
      if (assessmentType === ASSESSMENT_TYPES.organizational) {
        getReportData({ subGroupId: selectedSubTab })
      }
      // fetch report data if user click on job roles tab
      if (assessmentType === ASSESSMENT_TYPES.jobRole) {
        getReportData({ jobRoleId: selectedSubTab })
      }

    }
  }, [selectedSubTab])

  // filter (kpi or competency) table data as per card clicked
  function onCircularCardClick(data, chart1Index) {
    setFilteredChartData((pre) => {
      let tableChartData = pre.circularChartData[chart1Index].active ? chartData.tableChartData : chartData.tableChartData.filter(e => e.parentId == data.itemId)
      return {
        circularChartData: pre.circularChartData.map((item, index) => ({
          ...item,
          active: index === chart1Index ? !item.active : false
        })),
        tableChartData,
      };
    });
  }

  return (
    <div className={`padding_22_44 ${style.reports_detail_con}`}>
      <BreadCrum dataArr={breadCrumData} />
      <br />

      <section className={style.report_filter_con}>
        <div className={style.header}>
          <div className="between">
            <div className="header_4">Group 1</div>
            <DownloadPdf
              onSubmit={downloadClickHandler}
              fileName={`${filterInputValues.assessmentCycle?.name}`}
            //   onBeforeGenerate={removePdfContentSection}
            >
              <DownloadButton />
            </DownloadPdf>
          </div>
        </div>

        <div className={style.report_filter} id="pdf-content-section">
          <ButtonTabGroup
            onClick={TabBtnClick}
            activeIndex={activeTab === "group" ? 0 : 1}
            tabData={tabs} />
          <br />


          {(activeTab === "subGroup") && <div className={style.tabs_btn_con}>
            {subTabData.map((res, key) => {
              return <TabButton key={key} onClick={() => setSelectedSubTab(res.value)} label={res.name} active={selectedSubTab == res.value} />
            })}
          </div>}
        </div>

      </section>

      <section className={style.progress_bar_con}>
        {error.error ?
          <NoTableData text={error.errorMsg} />
          :

          <>
            <div className={style.heading}>
              {assessmentType === ASSESSMENT_TYPES.organizational
                ? t("Strategic Imperatives")
                : t("category.categories")}
            </div>

            <div className={style.card_section_con}>
              <div className="flex">
                {filteredChartData?.circularChartData?.map((res, ind) => {
                  return (
                    <MultipleCircularProgressCard
                      key={res.itemId}
                      active={res.active}
                      title={res.name}
                      onClick={(e) => onCircularCardClick(res, ind)}
                      values={[res?.value1, res?.value2, res?.value3].filter(e => e)}
                    />
                  )
                })}
              </div>


            </div>
            <br />

            <div className={`subtitle_2`}>
              {t(assessmentType == ASSESSMENT_TYPES.organizational ? "Key performance indicators (KPI)" : "competency.competency")}
            </div>
            <br />
            <div className={style.kpi_table_con}>

              <MultipleProgressLineTable
                data={filteredChartData?.tableChartData || []}
              />

            </div>
          </>
        }


      </section>


    </div>
  );
}
