import React, { useState } from 'react'
import { DndContext, useSensor, useSensors, PointerSensor, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { TableContainer } from './TableContainer';
import { AppsSharp } from '@mui/icons-material';


const DraggableRow = ({ row, columns }) => {
    const { id } = row;
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: 'move',
    };

    return (
        <tr ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <td style={{ width: "50px" }}><div><AppsSharp /></div></td>
            {columns.map((column) => (
                <td key={column}><div>{row[column?.key]}</div></td>
            ))}
        </tr>
    );
};


export const DraggableTable = ({ rows, columns, onRowsUpdate }) => {
    const sensors = useSensors(
        useSensor(PointerSensor)
    );

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            const oldIndex = rows.findIndex((row) => row.id === active.id);
            const newIndex = rows.findIndex((row) => row.id === over.id);
            const updatedRows = arrayMove(rows, oldIndex, newIndex);

            // Call the callback with the updated rows
            onRowsUpdate(updatedRows);
        }
    };

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
        >
            <SortableContext items={rows} strategy={verticalListSortingStrategy}>
                <TableContainer>
                    <table>
                        {/* <thead>
                            <tr>
                                <th><div></div></th>
                                    {columns.map((column) => (
                                    <th key={column}><div>{column?.label}</div></th>
                                ))}
                            </tr>
                        </thead> */}
                        <tbody>
                            {rows.map((row) => (
                                <DraggableRow key={row.id} row={row} columns={columns} />
                            ))}
                        </tbody>
                    </table>
                </TableContainer>
            </SortableContext>
        </DndContext>
    );
};




