import React, { useEffect, useState } from 'react'
import { CustomSelect } from '../../Inputs/CustomSelect'
import { fetchAssessments } from '../../../api/Assessment'
import { toast } from '../../../utils'
import { useTranslation } from 'react-i18next'
import { ASSESSMENT_TYPES } from '../../../constants/constants'

export const PreviousSurveySelect = ({ onChange, disabled, formData, error }) => {

    let [allPreviousSurvey, setAllPreviousSurvey] = useState([])
    let [previousSurvey, setPreviousSurvey] = useState(null)
    let { t } = useTranslation()

    async function fetchPreviousSurvey() {
        setPreviousSurvey({})
        try {
            let query = {
                "$and": [
                    { type: "Baseline" },
                    { status: "Completed" },
                    { groupId: formData.groupId },
                    { assessmentType: ASSESSMENT_TYPES.organizational },
                ]
            }

            let res = await fetchAssessments(query)

            let currentData = res.data.response.find(e => e._id == formData.previousSurveyId)

            // onChange(currentData)

            setAllPreviousSurvey(res.data.response)



        }
        catch (err) {
            onChange(null)

            setAllPreviousSurvey([])
            toast.error(err?.response?.data?.error)
        }
    }

    useEffect(() => {
        fetchPreviousSurvey()
    }, [formData.groupId])

    return (
        <div>
            <CustomSelect
                disabled={disabled}
                value={formData.previousSurveyId ? allPreviousSurvey?.length !== 0 ? allPreviousSurvey.find(e => e._id === formData.previousSurveyId) : null : null}
                options={allPreviousSurvey}
                optionLabel={"name"}
                className="bg-white"
                error={error}
                keyLabel={"_id"}
                onChange={(e, val) => {
                    onChange(val)
                    setPreviousSurvey(val || {})

                }}
                label={t("Assessment.Previous survey")}
                renderOption={"name"}
            />
        </div>
    )
}

