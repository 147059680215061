import axios from "../config/axiosInterceptor"
import { API, BASE_URL } from "./api"

export const login = async (data) => {
    return await axios.post(BASE_URL + API.login, data)
}

export const autologin = async (data) => {
    return await axios.post(BASE_URL + API.autologin, data)
}

export const fetchTPGroup = async (groupId) => {
    return await axios.get(`${BASE_URL}${API.fetchTPGroup}${groupId}`)
}
export const getUsersByGroupId = async (groupId) => {
    return await axios.get(`${BASE_URL}${API.fetchTPUsersByGroup}${groupId}`)
}
