import React, { Fragment, useEffect, useState } from "react";
import { EditButton } from "../../Buttons/EditButton";
import { Box, Dialog, Divider, LinearProgress, useForkRef } from "@mui/material";
import style from "../../../assets/css/components/questionBank.module.css";
import { CrossButton } from "../../Buttons/CrossButton";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCompetency,
} from "../../../store/Slices/QuestionCompetencySlice";
import { loader, toast } from "../../../utils";
import { SearchInput } from "../../Inputs/SearchInput";
import { DeleteButon } from "../../Buttons/DeleteButton";
import { CustomCheckbox } from "../../Inputs/CustomCheckbox";
import { LoadingButton } from "../../Buttons/LoadingButton";
import _ from "underscore";
import { useTranslation } from "react-i18next";
import { deleteTags, editTags, getAllTags } from "../../../api/tag";
import { deleteCompetencies, editCompetency, getAllCompetencies } from "../../../api/competency";

export const EditCompetencyOrTag = ({ onsubmit, usedFor }) => {

  // usedFor can be "Tag" of "Competency"
  let isTag = usedFor === "Tag"


  let [dialog, setDialog] = useState(false);
  let [loader, setLoader] = useState(false)

  let [allCompetenciesOrTag, setAllCompetenciesOrTag] = useState([])

  let dispatch = useDispatch();

  let [selectedCompetencyOrTags, setSelectedCompetencyOrTags] = useState([]);

  let [btnLoader, setBtnLoader] = useState(false);

  // let [allCompetencyOrTagData, setAllCompetencyOrTagData] = useState([]);
  let [allCompetencyOrTagData, setAllCompetencyOrTagData] = useState([]);
  let { t } = useTranslation()

  useEffect(() => {
    if (dialog) {
      getAllCompetenciesOrTag()
    }
  }, [dialog])


  async function getAllCompetenciesOrTag() {
    try {
      setLoader(true)
      if (usedFor === "Tag") {
        // if assesment type is organizational then get all tags for seaarching dropdown 
        let res = await getAllTags()
        let tagData = res?.data?.response || []
        setAllCompetenciesOrTag(tagData)
        setAllCompetencyOrTagData(
          tagData.map((e) => ({ ...e, type: "", visible: true }))
        );
      }
      else {
        // else get all competencies for searching dropdown 
        let res = await getAllCompetencies()
        let competencyData = res?.data?.response || []
        setAllCompetenciesOrTag(competencyData)
        setAllCompetencyOrTagData(
          competencyData.map((e) => ({ ...e, type: "", visible: true }))
        );

      }
    }
    catch (err) {
      console.error(err);
      toast.error(err.response?.data?.error || "Some Error Occured!");
    }
    finally {
      setLoader(false)
    }
  }

  async function formSubmit(e) {
    e.preventDefault();

    let obj = { edit: [], delete: [] };

    allCompetencyOrTagData.forEach((res) => {
      if (res.type !== "") {
        obj[res.type].push({ name: res.name, _id: res._id });
      }
    });

    if (_.size(obj["edit"]) === 0 && _.size(obj["delete"]) === 0) {
      toast.error("Please Delete or Edit any Competency !");
      return;
    }

    try {
      setBtnLoader(true);
      let toastData = "";

      if (_.size(obj.edit) > 0) {
        // edit data 

        if (usedFor === "Tag") {
          // edit Tag if used for is Tag
          let res = await editTags(obj.edit)
          console.log(res)
          console.log(obj.edit)
          onsubmit({ editData: obj.edit });
          if (!res?.payload?.error) {
            let catLength = obj.edit.length;
            toastData = `${catLength} ${catLength > 1 ? "Tags" : "Tag"
              } Updated `;
            toast.success(toastData);
          }
        }
        else {
          // edit competency if usedFor is competency 
          let res = await editCompetency(obj.edit)
          console.log(obj.edit)
          console.log(res)
          if (!res.payload.error) {
            let catLength = obj.edit.length;
            toastData = `${catLength} ${catLength > 1 ? "Competencies" : "Competency"
              } Updated `;
            toast.success(toastData);
          }
        }


      }
      if (_.size(obj.delete) > 0) {
        let deletedData = { [usedFor === "Tag" ? "tagIds" : "competencyIds"]: obj.delete.map((e) => e._id) };


        let res;

        if (usedFor === "Tag") {
          let response = await deleteTags(deletedData)
          res = { data: _.difference(deletedData[usedFor === "Tag" ? "tagIds" : "competencyIds"], response.data.skippedIds), response: response.data }
        }
        else {
          let response = await deleteCompetencies(deletedData)
          res = { data: _.difference(deletedData[usedFor === "Tag" ? "tagIds" : "competencyIds"], response.data.skippedIds), response: response.data }
        }
        if (!res?.error) {
          let deletedCount = res?.response?.deletedCount;
          // remove deleted category from question (new creation in progress), if we delete category which is present on new question
          onsubmit(res.data);
          onsubmit({ deleteData: res.data });
          toastData += ` ${deletedCount === 0 ? "No" : deletedCount}
                           ${deletedCount > 1 ? usedFor === "Tag" ? "Tags" : "Competencies" : usedFor === "Tag" ? "Tag" : "Competency"} Deleted`;

          toast.success(toastData);
        }
        else {
          setAllCompetencyOrTagData((pr) =>
            pr.map((ee) => {
              return { ...ee, type: "" };
            })
          );
        }

      }

      setDialog(false);
      setSelectedCompetencyOrTags([]);
      setBtnLoader(false);
    } catch (err) {
      // loader.stop();
      toast.error(err.response?.data?.error || "Some Error Occured!");
      setBtnLoader(false);
      setAllCompetencyOrTagData((pr) =>
        pr.map((ee) => {
          return { ...ee, type: "" };
        })
      );
    }
  }
  function editClick() {
    setDialog(true);
  }

  function search(e) {
    setAllCompetencyOrTagData((pre) => {
      return pre.map((res) => {
        if (res.name.includes(e.target.value)) {
          return { ...res, visible: true };
        } else {
          return { ...res, visible: false };
        }
      });
    });
  }

  function checkBoxChange(checked, data) {
    checked
      ? setSelectedCompetencyOrTags([...selectedCompetencyOrTags, data])
      : setSelectedCompetencyOrTags((prev) =>
        prev.filter((val) => val._id !== data._id)
      );
  }

  function editCategoryIconClick(e, index, id) {
    setAllCompetencyOrTagData((pre) => {
      pre[index].type = "edit";
      return [...pre];
    });
  }

  function categoryChange(e, index) {
    setAllCompetencyOrTagData((prev) => {
      prev[index].name = e.target.value;
      return [...prev];
    });
  }

  async function deleteCategories() {
    setAllCompetencyOrTagData((pr) =>
      pr.map((ee) => {
        if (selectedCompetencyOrTags.findIndex((s) => ee._id === s._id) !== -1) {
          return { ...ee, type: "delete" };
        } else {
          return { ...ee };
        }
      })
    );
    setSelectedCompetencyOrTags([]);
  }


  function resetData() {
    setAllCompetencyOrTagData(
      allCompetenciesOrTag.map((e) => ({ ...e, type: "", visible: true }))
    );
  }

  const handleCloseDialog = () => {
    // Add any additional actions you want to perform on dialog close
    setDialog(false);
    resetData()
  };
  return (
    <>
      <EditButton type="button" onClick={editClick} />

      <Dialog
        maxWidth={"sm"}
        fullWidth
        open={dialog}
        onClose={handleCloseDialog}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        className={style.editCategory_dialog}
      >

        <Box>
          <div className={`dialog_header between`}>
            <div>{t(isTag ? "tag.editTag" : "competency.editCompetency")}</div>
            <div
              onClick={handleCloseDialog}
              className={`pointer ${style.heading} `}
            >
              <CrossButton />
            </div>
          </div>
          <div className={style.subheading}>
            {t(isTag ? "tag.editTagLabel" : "competency.editCompetencyLabel")}
          </div>
          <Divider color="#E0E8F9" sx={{ borderBottomWidth: 1 }} />

          <Box p={4}>
            <div className={style.action_container}>
              <div className="between align_center">
                <div>
                  <SearchInput placeholder={"Search"} onChange={search} />
                </div>
                <div>
                  <DeleteButon
                    deletedItem={"Selected Competency"}
                    disabled={selectedCompetencyOrTags.length === 0}
                    onDelete={deleteCategories}
                  />
                </div>
              </div>
            </div>

            <div className={style.allCategory_listing_con}>
              {loader ? <LinearProgress /> :
                <div>

                  {allCompetencyOrTagData
                    .filter((r) => r.visible)
                    .map((res, key) => {
                      if (res.type !== "delete") {
                        return (
                          <div
                            key={key}
                            className={`between align_center ${style.single_category} `}
                          >
                            <div className="flex align_center">
                              <CustomCheckbox

                                checked={
                                  selectedCompetencyOrTags.findIndex(
                                    (e) => e.name === res.name
                                  ) !== -1
                                }
                                onChange={(e) =>
                                  checkBoxChange(e.target.checked, res)
                                }
                                className="me-6"
                              />
                              {res.type === "edit" ? (
                                <div>
                                  <input
                                    onChange={(e) => categoryChange(e, key)}
                                    type="text"
                                    value={res.name}
                                  />
                                </div>
                              ) : (
                                <span>{res.name}</span>
                              )}
                            </div>

                            <div
                              onClick={(e) =>
                                editCategoryIconClick(e, key, res._id)
                              }
                              className="pointer"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M19.9996 10.2855V16.7862C19.9987 17.9027 19.5543 18.9743 18.764 19.7646C17.9737 20.5549 16.9031 20.9993 15.7856 21.0002H7.21402C6.09746 20.9993 5.02589 20.5549 4.23562 19.7646C3.4453 18.9743 3.00094 17.9027 3 16.7862V8.21457C3.00094 7.09709 3.44532 6.02645 4.23562 5.23617C5.02594 4.44585 6.09749 4.00149 7.21402 4.00056H13.7147C14.2668 4.00056 14.714 4.44775 14.714 4.99994C14.714 5.55213 14.2668 6.00026 13.7147 6.00026H7.21402C5.9915 6.0012 5.0015 6.99213 4.99963 8.21465V16.7862C5.00151 18.0088 5.9915 18.9988 7.21402 19.0006H15.7856C17.0081 18.9988 17.9991 18.0088 18 16.7862V10.2856C18 9.73341 18.4481 9.28622 19.0003 9.28622C19.5525 9.28622 19.9996 9.73334 19.9996 10.2855ZM20.6568 3.34329C20.4693 3.15579 20.2153 3.05078 19.95 3.05078C19.6846 3.05078 19.4306 3.15578 19.2431 3.34329L10.2928 12.2936C10.1024 12.4802 9.99463 12.7352 9.9937 13.0024C9.99276 13.2686 10.0978 13.5246 10.2862 13.7139C10.4756 13.9023 10.7315 14.0074 10.9978 14.0064C11.265 14.0055 11.52 13.8977 11.7065 13.7074L20.6568 4.75704C20.8443 4.56954 20.9493 4.31549 20.9493 4.05017C20.9493 3.78485 20.8443 3.53078 20.6568 3.34329Z"
                                  fill="#4C63B6"
                                />
                              </svg>
                            </div>
                          </div>
                        );
                      } else {
                        return <Fragment key={key}></Fragment>;
                      }
                    })}
                  {allCompetencyOrTagData.every(
                    (e) => !e.visible || e.type === "delete"
                  ) ? (
                    <div className={style.noData_text}>
                      {t(isTag ? "tag.noTag" : "competency.noCompetency")}...
                    </div>
                  ) : null}
                </div>
              }

            </div>

            <div className={`end`}>

              &nbsp;&nbsp;&nbsp;
              <LoadingButton
                disabled={
                  !allCompetencyOrTagData.some(
                    (e) => e.type === "edit" || e.type === "delete"
                  )
                }
                loader={btnLoader}
                onClick={formSubmit}
                label={"Update"}
              />
              &nbsp;&nbsp;&nbsp;
              <button
                type="button"
                className="btn-secondary"
                onClick={handleCloseDialog}
              >
                Cancel
              </button>
            </div>
          </Box>
        </Box>



      </Dialog >
    </>
  );
};
