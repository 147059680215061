import React, { useRef, useState } from 'react'
import { CreateAssessmentSurvey } from '../../components/Common/Assessment/CreateAssessmentSurvey'
import { CreateJobRoleIndividualAssSurvey } from '../../components/Common/Assessment/CreateJobRoleIndividualAssSurvey'
import { createAssessment } from '../../api/Assessment'
import { loader, toast } from '../../utils'
import { useNavigate, useParams } from 'react-router'
import { ASSESSMENT_TYPES } from "../../constants/constants";
import { ConfirmationDialog } from '../../components/Common/ConfirmationDialog'

export const CreateAssessment = () => {

    const { assessmentType } = useParams()
    const [confirmationDialog, setConfirmationDialog] = useState(false)
    const [confirmationMsg, setConfirmationMsg] = useState("")
    let assessmentData = useRef(null)

    let assmentData = {
        name: "",
        groupId: null,
        type: "Baseline",
        previousSurveyId: null,
        assessmentType: assessmentType,
        description: "",
        categoryIds: [],


        surveyAssignments: {
            subGroupIds: [],
            user: {},
            jobRoleId: null
        },
        reviewers: {
            subGroupIds: [],
            jobRoleIds: [],
            individuals: []
        },

        completionDate: null,
        siIds: [],
        kpiQuestions: [],
        status: "Drafts"
    }

    let navigate = useNavigate()
    async function formSubmit(data, validationCheck) {
        assessmentData.current = data;
        let kpiQuestions = data.kpiQuestions.map((res) => {
            return (
                {
                    [assessmentType === ASSESSMENT_TYPES.organizational ? "kpiId" : "competencyId"]: res.kpiData._id,
                    questionIds: res.questionData.map(s => s._id)
                }
            )
        })

        try {

            let body = { ...data, questions: kpiQuestions }
            if (assessmentType !== assessmentType.organizational) {
                delete data.siIds
                delete data.subGroupIds
                // delete data.previousSurveyId
            }

            delete body.kpiQuestions
            body.validationCheck = validationCheck ? true : false


            loader.start()
            let res = await createAssessment(body)
            loader.stop()
            navigate(`/assessment/${assessmentType}/preview/${res.data.assessmentId}`)
            toast.success(res.data.message)

        }
        catch (err) {
            loader.stop()
            if (err?.response?.data?.error == `Oops ! Aseessment for Job Role ${data.surveyAssignments.jobRoleId
                } already exist`) {
                setConfirmationDialog(true)
                setConfirmationMsg(err?.response?.data?.error)
            }
            else {
                toast.error(err?.response?.data?.error)
            }
        }

    }

    return (
        <div className='padding_22_44'>
            {
                assessmentType == ASSESSMENT_TYPES.organizational ? (
                    <CreateAssessmentSurvey onSubmit={formSubmit} mode="Create" assessmentData={assmentData} />
                ) : (
                    <CreateJobRoleIndividualAssSurvey onSubmit={formSubmit} mode="Create" assessmentData={assmentData} />
                )
            }
            <>
            </>
            <ConfirmationDialog
                onSubmit={() => {
                    setConfirmationDialog(false);

                    formSubmit(assessmentData.current, "resubmit")
                }}
                onCancel={() => setConfirmationDialog(false)}
                heading={"Warning"}
                text={confirmationMsg}
                open={confirmationDialog}
            />

        </div>
    )
}
