import axios from "axios";
import { store } from "../store/store";
import { setTokenStatus } from "../store/Slices/TokenExpireSlice";
import { currentUser, loader, logout } from "../utils";
import { autologin } from "../api/user";
import { useNavigate } from "react-router";
const instance = axios.create({
  headers: {
    "Content-Type": "application/json",
    // Add any other default headers here
  },
  withCredentials: true, // Add this line
});

instance.interceptors.response.use(
  (res) => res,
  async (err) => {
    if (
      err?.response?.status === 401 &&
      err?.response?.data?.error ===
      "Oops! Session Expired. Refreshing your session now. Please wait a moment"
    ) {
      store.dispatch(setTokenStatus(false));
      await refreshSession();
      loader.stop();
    }
    return Promise.reject(err);
  },
);

const refreshSession = async () => {
  try {
    const params = {
      email: currentUser().user.email,
      auto_login_token: currentUser().user.auto_login_token,
    };
    const res = await autologin(params);
    localStorage.setItem("userData", JSON.stringify(res.data.response));
    store.dispatch(setTokenStatus(true));
  } catch (err) {
    window.location.href = '/'
    store.dispatch(setTokenStatus(false));
    console.error(err);
  }
};

export default instance;
