// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DjQT5Jop_Ma_d9YhAyQG {
    border-radius: 4px;
    border: 1px solid var(--LIght-2, #E0E8F9);
    background: var(--White, #FFF);
    padding: 20px;

    .V7_GjIAtD46i5ZcsO4mA {
        max-width: 300px;
    }

    .Zj6WR9fmO7IKl05pqTjg {
        padding: 40px;
        margin-top: 20px;
        background-color: rgba(239, 243, 252, 1);

        .dt5gGHVM9nH0DU0V9B0A {
            display: grid;
            grid-template-columns: 50% 50%;
            column-gap: 30px;
        }

    }
}


.UDbmpLUCMZ1eDRGsqelf {
    border-radius: 4px;
    background: var(--Light-1, #EFF3FC);
    padding: 15px 20px;
    margin-bottom: 8px;

    .Er5IPq52tEYlHv2pLRve {
        margin-top: 10px;
    }

    .tpSvltxc8dwnyLfSRxXO {
        color: var(--Grey-1, #3E4C59);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-top: 5px;
        letter-spacing: 0.5px;
    }
}`, "",{"version":3,"sources":["webpack://./src/assets/css/components/competency.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,yCAAyC;IACzC,8BAA8B;IAC9B,aAAa;;IAEb;QACI,gBAAgB;IACpB;;IAEA;QACI,aAAa;QACb,gBAAgB;QAChB,wCAAwC;;QAExC;YACI,aAAa;YACb,8BAA8B;YAC9B,gBAAgB;QACpB;;IAEJ;AACJ;;;AAGA;IACI,kBAAkB;IAClB,mCAAmC;IACnC,kBAAkB;IAClB,kBAAkB;;IAElB;QACI,gBAAgB;IACpB;;IAEA;QACI,6BAA6B;QAC7B,eAAe;QACf,kBAAkB;QAClB,gBAAgB;QAChB,iBAAiB;QACjB,eAAe;QACf,qBAAqB;IACzB;AACJ","sourcesContent":[".assignCompentency_form_con {\n    border-radius: 4px;\n    border: 1px solid var(--LIght-2, #E0E8F9);\n    background: var(--White, #FFF);\n    padding: 20px;\n\n    .assign_comp_group_input {\n        max-width: 300px;\n    }\n\n    .competency_dard_bg {\n        padding: 40px;\n        margin-top: 20px;\n        background-color: rgba(239, 243, 252, 1);\n\n        .competency_dard_bg_grid_con {\n            display: grid;\n            grid-template-columns: 50% 50%;\n            column-gap: 30px;\n        }\n\n    }\n}\n\n\n.competency_preview_collapse_row {\n    border-radius: 4px;\n    background: var(--Light-1, #EFF3FC);\n    padding: 15px 20px;\n    margin-bottom: 8px;\n\n    .category_label {\n        margin-top: 10px;\n    }\n\n    .categories_list {\n        color: var(--Grey-1, #3E4C59);\n        font-size: 16px;\n        font-style: normal;\n        font-weight: 400;\n        line-height: 24px;\n        margin-top: 5px;\n        letter-spacing: 0.5px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"assignCompentency_form_con": `DjQT5Jop_Ma_d9YhAyQG`,
	"assign_comp_group_input": `V7_GjIAtD46i5ZcsO4mA`,
	"competency_dard_bg": `Zj6WR9fmO7IKl05pqTjg`,
	"competency_dard_bg_grid_con": `dt5gGHVM9nH0DU0V9B0A`,
	"competency_preview_collapse_row": `UDbmpLUCMZ1eDRGsqelf`,
	"category_label": `Er5IPq52tEYlHv2pLRve`,
	"categories_list": `tpSvltxc8dwnyLfSRxXO`
};
export default ___CSS_LOADER_EXPORT___;
