import React, { useEffect, useState } from 'react'
import { BreadCrum } from '../BreadCrum'
import { TableContainer, TableHeader } from '../TableContainer'
import { SearchInput } from '../../Inputs/SearchInput'
import { CustomCheckbox } from '../../Inputs/CustomCheckbox'
import { CustomPagination } from '../CustomPagination'
import { loader, toast } from '../../../utils'
import { GetAllQuestions, fetchQuestionsByCategories, fetchQuestionsByCompetencies, fetchQuestionsByTags } from '../../../api/Question'
import { Autocomplete, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { searchDataWithKey } from '../../../utils/search'
import { PreviewQuestion } from './PreviewQuestion'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { ASSESSMENT_TYPES } from '../../../constants/constants'
import { getAllTags } from '../../../api/tag'
import { getAllCompetencies } from '../../../api/competency'

export const QuestionTable = ({ currentKPI, onContinue, onCancel, assessmentName, usedFor }) => {
    let item_per_page = 20;

    const { assessmentType } = useParams()
    let isOrganizational = assessmentType === ASSESSMENT_TYPES.organizational

    let [page, setPage] = useState(1);
    let [totalQuestions, setTotalQuestions] = useState(0);
    let [tableData, setTableData] = useState([]);
    let [selectedCategories, setSelectedCategories] = useState([]);
    let [allData, setAllData] = useState([])
    let { t } = useTranslation()
    let [KPIData, setKPIData] = useState({ ...currentKPI })


    let [searchValue, setSearchValue] = useState("")


    const [allCompetenciesOrTag, setAllCompetencyOrTagData] = useState([])
    useEffect(() => {
        getAllCompetenciesOrTags()
    }, [])


    async function getAllCompetenciesOrTags() {
        try {

            if (isOrganizational) {
                // if assesment type is organizational then get all tags for seaarching dropdown 
                let res = await getAllTags()
                setAllCompetencyOrTagData(res.data.response)
            }
            else {
                // else get all competencies for searching dropdown 
                let res = await getAllCompetencies()
                setAllCompetencyOrTagData(res.data.response)

            }
        }
        catch (err) {
            toast.error(err?.response?.data?.error)
        }
    }



    async function fetchData() {
        setSearchValue("")
        try {
            loader.start();
            let res;
            if (selectedCategories.length === 0) {

                if (usedFor === "KPI") {
                    res = await GetAllQuestions(page, item_per_page, assessmentType);
                }
                else {
                    let body = {
                        competencyIds: [currentKPI?.kpiData?._id],
                        page,
                        records_per_page: item_per_page,
                        assessmentType: assessmentType
                    }
                    res = await fetchQuestionsByCompetencies(body)
                }


            } else {
                const data = {
                    [isOrganizational ? "tagIds" : "competencyIds"]: selectedCategories.map(e => e._id),
                    page,
                    records_per_page: item_per_page,
                    assessmentType: assessmentType
                };

                if (isOrganizational) {
                    res = await fetchQuestionsByTags(data)
                }
            }
            loader.stop();
            setTotalQuestions(res.data.totalCount);
            setAllData(res.data.response.sort((a, b) => KPIData.questionData.some(f => f._id == b._id) - KPIData.questionData.some(f => f._id == a._id)))
            setTableData(res.data.response.sort((a, b) => KPIData.questionData.some(f => f._id == b._id) - KPIData.questionData.some(f => f._id == a._id)));
        } catch (err) {
            console.log(err)

            setTotalQuestions(0)
            setAllData([])
            setTableData([])
            loader.stop();
            toast.error(err?.response?.data?.error);
        }
    }

    useEffect(() => {
        fetchData();
    }, [page, selectedCategories]);

    function handleCategoryChange(e, val) {
        setPage(1);
        setSelectedCategories(val);
    }

    function searchQuestion(e) {
        setSearchValue(e.target.value)
        setTableData(searchDataWithKey("question", allData, e.target.value))
    }
    function checkboxChange(ee) {
        setKPIData(pre => {
            let ind = pre.questionData.findIndex(e => e._id == ee._id)
            if (ind === -1) {
                return { ...pre, questionData: [...pre.questionData, ee] }
            }
            else {
                pre.questionData.splice(ind, 1)
                return { ...pre }
            }

        })
    }
    let breadCrumData = [
        {
            text: t("Assessment.Assessment"),
            path: "/assessment"
        },
        {
            text: assessmentName?.trim() || t("Create New"),
        },
        {
            text: KPIData?.kpiData?.name,
        },

    ]

    return (
        <div>
            <BreadCrum dataArr={breadCrumData} />
            <br />
            <TableHeader>
                <div className='flex'>
                    <div>
                        <SearchInput value={searchValue} onChange={searchQuestion} placeholder="Search" />
                    </div>&nbsp;&nbsp;
                    {isOrganizational && <div>
                        {/* <FormControl fullWidth>
                            {selectedCategories.length === 0 ?
                                <InputLabel shrink={false} sx={{ marginTop: '-8px' }}>{t("tag.tag")}</InputLabel> : null}
                            <Select
                                sx={{ height: '38px', width: '300px' }}
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                className='bg-white'
                                value={selectedCategories}
                                onChange={handleCategoryChange}
                                input={<OutlinedInput label="" />}
                                renderValue={(selected) => selected.map(e => e.name).join(", ")}

                            >
                                {allCompetenciesOrTag?.map((res) => (
                                    <MenuItem key={res.name} value={res}>
                                        <CustomCheckbox className="me-6" checked={selectedCategories.findIndex(e => e.name === res.name) > -1} />
                                        <ListItemText primary={res.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl> */}


                        <Autocomplete
                            multiple
                            id="competency-select"
                            className="bg-white w-100"
                            options={allCompetenciesOrTag}
                            sx={{
                                width: "300px",
                                '.MuiAutocomplete-inputRoot': {
                                    minHeight: '30px',
                                    alignItems: 'flex-start',

                                },
                                '.MuiAutocomplete-tag': {
                                    lineHeight: '37px',

                                },
                            }}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <CustomCheckbox
                                        checked={selected}
                                        className="me-6"
                                    />
                                    {option.name}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                        sx: {
                                            height: "38px",
                                            padding: '0 10px',
                                        },
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        sx: {
                                            height: '100%',
                                            padding: '0 !important',
                                            display: 'flex',
                                            alignItems: 'center',

                                        },
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            position: 'absolute',
                                            top: '-8px',
                                            left: '0px',
                                            '&.MuiInputLabel-shrink': {
                                                top: '-10px',
                                                left: '15px',
                                                fontSize: '0.75rem',
                                                transform: 'translate(0, 0)',
                                            },
                                        },
                                    }}
                                    label={t("tag.tags")}
                                />
                            )}
                            value={selectedCategories}
                            onChange={handleCategoryChange}
                            renderTags={(tagValue, getTagProps) => (
                                <span className={`autocomplete_wrapper_300`}>
                                    {tagValue.map((option) => option.name).join(' / ')}
                                </span>
                            )}

                        />
                    </div>}
                </div>
            </TableHeader>

            <TableContainer>
                <table>
                    <thead>
                        <tr>
                            <th><CustomCheckbox
                                checked={tableData.every(e => KPIData.questionData.some(d => d._id == e._id))}
                                onClick={(e) => {
                                    setKPIData(pre => {
                                        if (e.target.checked) {
                                            return ({ ...pre, questionData: tableData })
                                        }
                                        else {
                                            return ({ ...pre, questionData: [] })
                                        }

                                    })
                                }} />
                            </th>
                            <th>{t("questionBank.Question")}</th>

                            <th>  {assessmentType === ASSESSMENT_TYPES.organizational && t("tag.tags")}</th>
                            {/* <th>{t("Question Type")}</th> */}

                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData?.map((question, key) => {
                            return (
                                <tr key={key}>
                                    <td><div>
                                        <CustomCheckbox checked={KPIData.questionData.some(e => e._id === question._id)} onChange={() => checkboxChange(question)} />
                                    </div></td>
                                    <td><div>{question.question}</div></td>
                                    {assessmentType === ASSESSMENT_TYPES.organizational && <td><div>{question?.tagData?.map((e) => e.name)?.join(" / ")}</div></td>}
                                    {/* <td><div>{question.questionType}</div></td> */}
                                    <td>

                                        <PreviewQuestion parent={"assessment"} data={question} />
                                    </td>
                                </tr>

                            )
                        })}
                    </tbody>
                </table>
            </TableContainer>
            <CustomPagination page={page - 1} count={totalQuestions} pageChange={setPage} perPage={item_per_page} />
            <br />
            <div className='end'>
                <button className='btn-primary' type='button' onClick={() => onContinue(KPIData)}>{t("Continue")}</button>&nbsp;&nbsp;
                <button className='btn-secondary' type='button' onClick={onCancel}>{t("Cancel")}</button>
            </div>
        </div>
    )
}
