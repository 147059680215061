import axios from "../config/axiosInterceptor";
import { API, BASE_URL } from "./api"

export const fetchKPIByGroupId = async (groupId) => {
    return await axios.get(`${BASE_URL}${API.fetchKPIByGroupId}${groupId}`)
}
export const fetchKPIById = async (id) => {
    return await axios.get(`${BASE_URL}${API.fetchKPIById}${id}`)
}
export const deleteKPI = async (id) => {
    return await axios.delete(`${BASE_URL}${API.deleteKPI}${id}`)
}
export const createKPI = async (data) => {
    return await axios.post(`${BASE_URL}${API.createKPI}`, data)
}
export const fetchKPI = async (data) => {
    return await axios.post(`${BASE_URL}${API.fetchKPI}`, data)
}
export const editKPI = async (data) => {
    return await axios.put(`${BASE_URL}${API.editKPI}`, data)
}