import React, { useState } from "react"
import { AddButton } from "../../Buttons/AddButton"
import { Box, Dialog, Divider, TextField } from "@mui/material"
import style from "../../../assets/css/components/questionBank.module.css"
import { CrossButton } from "../../Buttons/CrossButton"
import { useDispatch } from "react-redux"
import { loader, toast } from "../../../utils"
import { useTranslation } from "react-i18next"

export const AddCompetencyOrTag = ({ disabled, onAddCompetency, type, allCompetencies }) => {
    let [dialog, setDialog] = useState(false)
    let isTag = type === "Tag"

    let [name, setName] = useState("")

    let dispatch = useDispatch()
    let { t } = useTranslation()

    async function competencySubmit(e) {
        e.preventDefault()
        if (allCompetencies.some(e => e?.name?.trim()?.toLowerCase() == name?.trim()?.toLowerCase())) {
            toast.error(t(isTag ? "tag.tagAlreadyExist" : "competency.Competency already exists"))
            return
        }

        if (name.trim() !== "") {
            // loader.start()
            // setDialog(false)
            let data = {
                name,
                // status: true,
            }
            onAddCompetency(data)
            // dispatch(addCategory(data))
            setName("")
            setDialog(false)

        }
        else {
            toast.error(`Please fill ${type}`)
        }
    }

    return (
        <>
            <AddButton disabled={disabled} type='button' onClick={() => setDialog(true)} />
            <Dialog
                maxWidth={"sm"}
                fullWidth
                open={dialog}
                onClose={() => setDialog(false)}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box className={style.addCategory_con}>
                    <div className={`dialog_header between`}>
                        <div>{t(isTag ? "tag.addNewTag" : "competency.addNewCompetency")}</div>
                        <div onClick={() => setDialog(false)} className={`pointer ${style.heading}`}>
                            <CrossButton />
                        </div>
                    </div>
                    <Divider color="#E0E8F9" sx={{ borderBottomWidth: 1 }} />

                    <Box p={4}>
                        <div className="flex align_center">
                            <TextField value={name} sx={{ mr: 3 }} onChange={(e) => setName(e.target.value)} required label={t(isTag ? "tag.tag" : "competency.competency")} fullWidth />
                        </div>
                        <div className={`${style.dialog_actions} end`}>
                            <button type="button" onClick={competencySubmit} className=" btn-primary">{t("Create")}</button>&nbsp;&nbsp;&nbsp;
                            <button type="button" className="btn-secondary" onClick={() => {
                                setName("")
                                setDialog(false)
                            }}>{t("Cancel")}</button>
                        </div>
                    </Box>

                </Box>
            </Dialog >
        </>
    )
}