import React, { useState } from "react"
import { ConfirmationDialog } from "../Common/ConfirmationDialog"
import { Tooltip } from "@mui/material"
import { useTranslation } from "react-i18next"

export const DeleteButon = ({ deletedItem, onDelete, id, confirmation, heading, text, ...props }) => {
    let [dialog, setDialog] = useState(false)
    let { t } = useTranslation()
    return (
        <>
            <Tooltip title={t("Delete")} arrow>
                <span>

                    <button onClick={() => {
                        confirmation ? setDialog(true) : onDelete(id ? id : null)
                    }} {...props} className="icon-btn" >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M11.7787 2.99996C11.3887 2.99996 11.009 3.12746 10.695 3.36558C10.2384 3.71245 9.96652 4.25903 9.96652 4.84403V4.8984H14.0333V4.84403C14.0333 4.25903 13.7614 3.71248 13.3049 3.36558C12.9908 3.12745 12.6111 2.99996 12.2211 2.99996H11.7787ZM15.7135 4.89841V4.84404C15.7135 3.73872 15.1998 2.69436 14.3204 2.02788C13.7157 1.56849 12.9788 1.32007 12.2213 1.32007H11.7788C11.0213 1.32007 10.2844 1.56852 9.67974 2.02788C8.80036 2.69443 8.28661 3.73881 8.28661 4.84404V4.89841H3.60013C3.13607 4.89841 2.76013 5.27435 2.76013 5.73841C2.76013 6.20248 3.13607 6.57841 3.60013 6.57841H4.52826V14.2603C4.52826 17.1768 4.9042 19.3575 6.14826 20.7797C7.4242 22.2375 9.40986 22.68 12.0002 22.68C14.5905 22.68 16.576 22.2375 17.8521 20.7797C19.0962 19.3575 19.4721 17.1768 19.4721 14.2603V6.57841H20.4002C20.8643 6.57841 21.2402 6.20248 21.2402 5.73841C21.2402 5.27435 20.8643 4.89841 20.4002 4.89841H15.7135ZM17.792 6.57841H6.20812V14.2603C6.20812 17.1468 6.60937 18.7555 7.4128 19.6735C8.18341 20.5548 9.5128 21.0001 11.9999 21.0001C14.487 21.0001 15.8164 20.5548 16.587 19.6735C17.3905 18.7557 17.7917 17.1471 17.7917 14.2603L17.792 6.57841ZM9.56812 9.93001C10.0322 9.93001 10.4081 10.3059 10.4081 10.77V16.3613C10.4081 16.8244 10.0322 17.2013 9.56812 17.2013C9.10405 17.2013 8.72812 16.8244 8.72812 16.3613V10.77C8.72812 10.3059 9.10405 9.93001 9.56812 9.93001ZM14.432 9.93001C14.896 9.93001 15.272 10.3059 15.272 10.77V16.3613C15.272 16.8244 14.896 17.2013 14.432 17.2013C13.9679 17.2013 13.592 16.8244 13.592 16.3613V10.77C13.592 10.3059 13.9679 9.93001 14.432 9.93001Z" fill="white" />
                        </svg>

                    </button>
                </span>
            </Tooltip>

            <ConfirmationDialog onSubmit={() => {
                onDelete(id ? id : null)
                setDialog(false)
            }} onCancel={() => setDialog(false)} heading={heading} text={text} open={dialog} />

        </>
    )
}