import { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getallgroups } from "../store/Slices/GroupSlice";


const useGroupData = () => {
    const allGroups = useSelector((state) => state.groupData.allGroups);
    const initialized = useSelector((state) => state.groupData.initialized);
    const dispatch = useDispatch();
    const fetched = useRef(false);

    useEffect(() => {
        // Fetch only if not already fetched
        if (!fetched.current && !initialized) {
            fetched.current = true;
            dispatch(getallgroups());
        }
    }, [dispatch, initialized]);

    return allGroups;
};

export default useGroupData;
