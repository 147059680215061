// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.awnUejKuq3zfV0BrekVm {

    .U8KWeJ3RIoWYDPFTuFsw {
        margin-bottom: 50px;
    }

    .y90hT4bTKyYNZ59wbHcF {
        max-width: 800px;
        background-color: white;
        padding: 20px;
        border-radius: 4px;
    }
}`, "",{"version":3,"sources":["webpack://./src/assets/css/views/profile.module.css"],"names":[],"mappings":"AAAA;;IAEI;QACI,mBAAmB;IACvB;;IAEA;QACI,gBAAgB;QAChB,uBAAuB;QACvB,aAAa;QACb,kBAAkB;IACtB;AACJ","sourcesContent":[".profile_main_con {\r\n\r\n    .heading {\r\n        margin-bottom: 50px;\r\n    }\r\n\r\n    .container {\r\n        max-width: 800px;\r\n        background-color: white;\r\n        padding: 20px;\r\n        border-radius: 4px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profile_main_con": `awnUejKuq3zfV0BrekVm`,
	"heading": `U8KWeJ3RIoWYDPFTuFsw`,
	"container": `y90hT4bTKyYNZ59wbHcF`
};
export default ___CSS_LOADER_EXPORT___;
