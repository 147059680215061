import { Button, ButtonGroup } from '@mui/material';
import React from 'react';
import style from "../../assets/css/components/common.module.css"

export const ButtonTabGroup = ({ tabData, activeIndex, disabled, onClick }) => {
    return (
        <ButtonGroup disableElevation variant="contained" aria-label="Button group">
            {tabData.map((res, ind) => (
                <Button
                    disabled={res.disabled}
                    key={ind}
                    onClick={() => onClick(res)}
                    className={`${style.buttonTabButton} ${activeIndex == ind ? style.buttonTabActiveButton : ""}`}

                >
                    {res.label}
                </Button>
            ))}
        </ButtonGroup>
    );
};
