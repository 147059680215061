// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wMggT_rnQESoL8p8vywr {


    .lz2yQ8hG4eLss49AZ0jJ {
        height: calc(100vh - 334px);
        overflow-y: auto;
        overflow-x: hidden;
    }

    .E0iS3awj8OHzs1jaAFg3 {
        max-width: 260px;
    }
}`, "",{"version":3,"sources":["webpack://./src/assets/css/views/group.module.css"],"names":[],"mappings":"AAAA;;;IAGI;QACI,2BAA2B;QAC3B,gBAAgB;QAChB,kBAAkB;IACtB;;IAEA;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".group_con {\r\n\r\n\r\n    .scroll_part {\r\n        height: calc(100vh - 334px);\r\n        overflow-y: auto;\r\n        overflow-x: hidden;\r\n    }\r\n\r\n    .search_inp {\r\n        max-width: 260px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"group_con": `wMggT_rnQESoL8p8vywr`,
	"scroll_part": `lz2yQ8hG4eLss49AZ0jJ`,
	"search_inp": `E0iS3awj8OHzs1jaAFg3`
};
export default ___CSS_LOADER_EXPORT___;
