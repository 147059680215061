import React from 'react'
import style from "../../assets/css/components/common.module.css"
import { AddCircle } from '@mui/icons-material'

export const AddMoreItem = ({ onClick, text, className, ...props }) => {
    return (
        <div className={`flex ${className}`} {...props}>
            <div className={`flex pointer ${style.add_more}`} onClick={onClick}>
                <div>
                    <AddCircle />&nbsp;&nbsp;
                </div>
                <div>
                    {text}
                </div>
            </div>
        </div>
    )
}
