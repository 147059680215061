import React from 'react'
import { FormControlLabel, Radio } from "@mui/material"

export const CustomRadio = (props) => {
    return (
        <FormControlLabel {...props} control={<Radio sx={{
            '&, &.Mui-checked': {
                color: 'rgba(82, 96, 109, 1)',
            }
        }} />} />
    )
}