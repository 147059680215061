import { Tooltip } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
export const ViewButton = (props) => {
    let { t } = useTranslation()
    return (
        <Tooltip title={t("Preview")} arrow>
            <span>
                <button className='icon-primary pointer' {...props}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM5 19H19V7H5V19ZM12 17C10.6333 17 9.4125 16.6292 8.3375 15.8875C7.2625 15.1458 6.48333 14.1833 6 13C6.48333 11.8167 7.2625 10.8542 8.3375 10.1125C9.4125 9.37083 10.6333 9 12 9C13.3667 9 14.5875 9.37083 15.6625 10.1125C16.7375 10.8542 17.5167 11.8167 18 13C17.5167 14.1833 16.7375 15.1458 15.6625 15.8875C14.5875 16.6292 13.3667 17 12 17ZM12 15.5C12.9333 15.5 13.7833 15.2792 14.55 14.8375C15.3167 14.3958 15.9167 13.7833 16.35 13C15.9167 12.2167 15.3167 11.6042 14.55 11.1625C13.7833 10.7208 12.9333 10.5 12 10.5C11.0667 10.5 10.2167 10.7208 9.45 11.1625C8.68333 11.6042 8.08333 12.2167 7.65 13C8.08333 13.7833 8.68333 14.3958 9.45 14.8375C10.2167 15.2792 11.0667 15.5 12 15.5ZM12 14.5C12.4167 14.5 12.7708 14.3542 13.0625 14.0625C13.3542 13.7708 13.5 13.4167 13.5 13C13.5 12.5833 13.3542 12.2292 13.0625 11.9375C12.7708 11.6458 12.4167 11.5 12 11.5C11.5833 11.5 11.2292 11.6458 10.9375 11.9375C10.6458 12.2292 10.5 12.5833 10.5 13C10.5 13.4167 10.6458 13.7708 10.9375 14.0625C11.2292 14.3542 11.5833 14.5 12 14.5Z" fill="#7B8794" />
                    </svg>
                </button>
            </span>
        </Tooltip>
    )
}