import React from 'react'
import Chart from 'react-apexcharts';


export const DoubleCircularProgressBar = ({ value1, value2 }) => {

    let state = {
        series: [value1, value2],
        options: {

            colors: ['#2D3A8C', '#4C63B6'],
            labels: [`${value1} %`, `${value2} %`],
            plotOptions: {
                radialBar: {
                    startAngle: -90,
                    endAngle: 270,
                    // strokeWidth: '1%',
                    hollow: {
                        // size: ['0.2%', "0.2%"],
                        show: false,


                        background: "transparent",
                    },
                    dataLabels: {
                        name: {
                            show: false
                        },
                        value: {
                            show: false
                        }
                    },
                    track: {
                        show: true,
                        strokeWidth: '100%',
                    }
                },
            },

            legend: {
                show: true,
                floating: true,
                fontSize: '25px',
                fontWeight: 700,
                position: 'left',
                offsetX: 65,
                offsetY: 40,
                labels: {
                    useSeriesColors: false,
                    colors: ['#2D3A8C', '#4C63B6'],
                },
                markers: {
                    show: false,
                    width: 0,
                    height: 0,
                },
                itemMargin: {
                    vertical: 5,
                },
            },



        },
    };


    return (
        <div style={{ display: 'flex', overflow: "hidden", justifyContent: 'space-around' }} >
            <Chart options={state.options} series={state.series} height={"240   px"} type="radialBar" />
        </div >
    )
}
