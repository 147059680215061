import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en.json"
import es from "./es.json"
import id from "./id.json"
import ja from "./ja.json"
import zh from "./zh.json"

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: { ...en }
            },
            es: {
                translation: { ...es }
            },
            id: {
                translation: { ...id }
            },
            ja: {
                translation: { ...ja }
            },
            zh: {
                translation: { ...zh }
            }
        },
        lng: localStorage.getItem("language") || "en",
        fallbackLng: "en",
        debug: true,
        interpolation: {
            escapeValue: false
        }
    });