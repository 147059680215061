import axios from "../config/axiosInterceptor";
import { BASE_URL, API } from "./api"

export const CreateQuestionApi = async (data) => {
    return await axios.post(`${BASE_URL}${API.createQuestion}`, data)
}

export const CreateBulkQuestionApi = async (data) => {
    return await axios.post(`${BASE_URL}${API.createBulkQuestions}`, data)
}


export const GetAllQuestions = async (page, records_per_page, assessmentType) => {
    return await axios.get(`${BASE_URL}${API.allQuestions}?page=${page}&records_per_page=${records_per_page}&assessmentType=${assessmentType}`)
}

export const DeleteQuestion = async (data) => {
    return await axios.post(`${BASE_URL}${API.deleteQuestions}`, data)
}
export const getQuestionById = async (id) => {
    return await axios.get(`${BASE_URL}${API.fetchQuestionById}` + id)
}
export const editQuestion = async (data) => {
    return await axios.put(`${BASE_URL}${API.editQuestion}`, data)
}
export const filterQuestionsByCategory = async (data) => {
    return await axios.put(`${BASE_URL}${API.filterQuestionsByCategory}`, data)
}
export const copyQuestions = async (data) => {
    return await axios.post(`${BASE_URL}${API.copyQuestions}`, data)
}
export const fetchQuestionsByCategories = async (data) => {
    return await axios.post(`${BASE_URL}${API.fetchQuestionsByCategories}`, data)
}
export const fetchQuestionsByCompetencies = async (data) => {
    return await axios.post(`${BASE_URL}${API.fetchQuestionsByCompetencies}`, data)
}
export const fetchQuestionsByTags = async (data) => {
    return await axios.post(`${BASE_URL}${API.fetchQuestionsByTags}`, data)
}
