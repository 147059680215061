import React from 'react'
import { CircularProgress } from "@mui/material"

export const LoadingButton = ({ loader, label, disabled, onClick }) => {
    return (
        <div>
            <button disabled={disabled || loader} onClick={onClick} className="btn-primary flex align_center justify_center" >
                {loader ? <>Loading<CircularProgress disableShrink sx={{ color: 'white', ml: 1 }} size={15} /></> : label}
            </button>
        </div>
    )
}