import React, { useEffect, useRef, useState } from "react";
import style from "../../assets/css/views/reports.module.css";
import { DownloadButton } from "../../components/Buttons/DownloadButton";
import { TableContainer } from "../../components/Common/TableContainer";
import { changeDateFormat, loader, toast } from "../../utils";
import { getAssessmentReport } from "../../api/Reports";
import { useParams } from "react-router";
import { FormClosed } from "../../components/Common/FormClosed";
import { DownloadCsv } from "../../components/Common/DownloadCsv";
import { useSelector } from "react-redux";
import { ASSESSMENT_TYPES } from "../../constants/constants";
import { useTranslation } from "react-i18next";
export const AssessmentReport = () => {

  const [assessmentType, setAssessmentType] = useState(null)

  const tokenStatus = useSelector((state) => state.tokenStatus);
  let { t } = useTranslation()
  let params = useParams();
  let [tableData, setTableData] = useState([]);
  let [dataLoad, setDataLoad] = useState(true);
  let [noData, setNoData] = useState({
    error: false,
    errorMessage: "",
  });

  let [reportData, setReportData] = useState({});

  useEffect(() => {
    const getassessmentdata = async () => {
      try {
        loader.start();
        setDataLoad(true);

        let res = await getAssessmentReport(params.id);
        setDataLoad(false);
        let response = res?.data?.response;

        let data = fixTableData(response, response.assessmentType);

        setAssessmentType(response.assessmentType)
        setTableData(data);
        setReportData(response);

        loader.stop();
      } catch (err) {
        loader.stop();

        setNoData({
          error: true,
          errorMessage: err?.response?.data?.error,
        });
        toast.error(err?.response?.data?.error);
      }
    };
    getassessmentdata();
  }, [tokenStatus]);

  function fixTableData(data, assessmentType) {
    let obj = {};

    if (assessmentType == ASSESSMENT_TYPES.organizational) {
      data.questions.forEach((res) => {
        res.questionData.forEach((res2) => {
          if (obj[res2._id]) {
            obj[res2._id].kpi.push(res.kpiData.name);
          } else {
            obj[res2._id] = {
              ...res2,
              kpi: [res.kpiData.name],
            };
          }
        });
      });

      return Object.values(obj);
    }
    else {

      data.questions.forEach((res) => {
        res.questionData.forEach((res2) => {
          if (obj[res2._id]) {
            obj[res2._id].kpi.push(res.competencyData.name);
          } else {
            obj[res2._id] = {
              ...res2,
              kpi: [res.competencyData.name],
            };
          }
        });
      });

      return Object.values(obj);
    }
  }

  async function downloadCsv() {

    let arr = [
      {
        "Assessment Name": reportData?.name || "",
        "Completion Date": changeDateFormat(reportData?.completionDate) || "",
        "Description": reportData?.description || "",
        "Strategic Imperatives": reportData?.strategicImperativesData?.map(e => e.name)?.join(", ") || ""
      },
      {
        "Assessment Name": "",
        "Completion Date": "",
        "Description": "",
        "Strategic Imperatives": ""
      },
      {
        "Assessment Name": "Multiple Choice Questions",
        "Completion Date": "Key Performance Indicators",
        "Description": "Percentage",
        "Strategic Imperatives": ""
      },
    ]
    let datafinal = tableData.map((res) => {
      return {
        ["Assessment Name"]: res.question,
        ["Completion Date"]: res.kpi.join("/"),
        Description: res.percentage,
        "Strategic Imperatives": ""
      };
    });


    return [...arr, ...datafinal]

  }

  return (
    <div className={`padding_22_44`}>
      {!noData.error ? (
        <>
          {dataLoad ? (
            <></>
          ) : (
            <div className={`${style.assessmentReport_main_con}`}>
              <div className="flex between">
                <div className={style.heading}>
                  {reportData?.name}
                </div>
                <div>
                  <DownloadCsv
                    fileName={"AssessmentReport.csv"}
                    onSubmit={downloadCsv}
                  >
                    <DownloadButton />
                  </DownloadCsv>
                </div>
              </div>
              <div className={style.content_part}>
                <div className="flex">
                  <div className="w-50">
                    <div className={style.label}>{t("Assessment.Type of Assessment")}:</div>
                    <div>{reportData?.type || "----"}</div>
                  </div>
                  <div className="w-50">
                    <div className={style.label}>{t("Completion Date")}</div>
                    <div>
                      {(reportData?.completionDate &&
                        changeDateFormat(reportData.completionDate)) ||
                        "----"}
                    </div>
                  </div>
                </div>

                <br />
                <div>
                  <div>
                    <div className={style.label}>{t("Description")}</div>
                    <div>{reportData?.description || "----"}</div>
                  </div>
                  <br />
                  {assessmentType === ASSESSMENT_TYPES.organizational &&
                    <div>
                      <div className={style.label}>{t("Strategic Imperatives")}:</div>
                      <ul>
                        {reportData?.strategicImperativesData?.map((res) => {
                          return <li key={res._id}>{res?.name}</li>;
                        })}
                      </ul>
                    </div>}
                  {assessmentType !== ASSESSMENT_TYPES.organizational &&
                    <div>
                      <div className={style.label}>{t("category.categories")}:</div>
                      <ul>
                        {reportData?.categoriesData?.map((res) => {
                          return <li key={res._id}>{res?.name}</li>;
                        })}
                      </ul>
                    </div>}

                </div>
              </div>
              <br />
              <div>
                <TableContainer>
                  <table>
                    <thead>
                      <tr>
                        <th>{t("Multiple Choice Questions")}</th>
                        <th>&nbsp;&nbsp;</th>
                        <th>
                          {assessmentType === ASSESSMENT_TYPES.organizational ?
                            t("Key performance indicators (KPI)") :
                            t("competency.competencies")
                          }

                        </th>
                        <th>{t("Percentage")}</th>
                      </tr>
                    </thead>

                    <tbody>
                      {tableData?.map((res) => (
                        <tr key={res._id}>
                          <td className="w-60">
                            <div>{res.question || "----"}</div>
                          </td>
                          <td>
                            <div>&nbsp;&nbsp;</div>
                          </td>
                          <td>
                            <div>{res.kpi.join("/") || "----"}</div>
                          </td>
                          <td>
                            <div>{res?.percentage || "---"}</div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </TableContainer>
              </div>
            </div>
          )}
        </>
      ) : (
        <FormClosed text={noData.errorMessage} />
      )}
    </div>
  );
};
