import React from 'react'
import style from "../../assets/css/components/common.module.css"
import { Button } from '@mui/material'

export const TabButton = ({ active, label, ...props }) => {
    return (
        <Button
            className={`${style.buttonTabButton} ${active ? style.buttonTabActiveButton : ""}`}
            {...props}
        >
            {label}
        </Button>
    )
}
