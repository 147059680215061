import { AddCircle, ExpandLess, ExpandMore } from '@mui/icons-material'
import React, { useState } from 'react'
import { EditButton } from '../../Buttons/EditButton'
import { DeleteButon } from '../../Buttons/DeleteButton'
import style from "../../../assets/css/components/category.module.css"
import { useNavigate } from 'react-router'
import { deleteCategory } from '../../../api/category'
import { Collapse } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { loader, toast } from '../../../utils'

export const CategoryPreviewCollapseTab = ({ data, onDeleteCategory }) => {
    const [collapse, setCollapse] = useState(false)
    let navigate = useNavigate();
    const toggleExpand = () => setCollapse(!collapse)
    const { t } = useTranslation()

    function editData() {
        navigate(`/category/edit/${data._id}`)
    }
    async function deleteData(categoryData) {
        try {
            loader.start()
            let res = await deleteCategory(categoryData._id)

            toast.success(res?.data?.message)
            onDeleteCategory(categoryData._id)
        }
        catch (err) {
            toast.error(err?.response?.data?.error)

        } finally {
            loader.stop()
        }
    }

    return (
        <div className={style.category_preview_collapse_row}>
            <div className="between align_center">

                <div className="flex align_center">
                    <div className="expand_btn">
                        {collapse ? <ExpandMore onClick={toggleExpand} /> : <ExpandLess onClick={toggleExpand} />}
                    </div>
                    <div>
                        {data.name}
                    </div>
                </div>


                {collapse &&
                    <div className='flex'>
                        <EditButton
                            confirmation={"Edit"}
                            heading={"Edit"}
                            text={"Are you sure you want to Edit ?"}
                            data={data}
                            onClick={editData}
                        />&nbsp;&nbsp;
                        <DeleteButon
                            confirmation
                            heading={"Delete"}
                            text={"Are your sure your want to delete Category ?"}
                            id={data}
                            onDelete={deleteData}

                        />
                    </div>
                }
            </div>

            <Collapse in={collapse} timeout="auto" unmountOnExit>
                <div className={`${style.description_label} text-secondary`}>{t("Description")}:</div>
                <div className={style.description}>
                    {data?.description}
                </div>
                {data?.Competencies && data?.Competencies.length > 0 &&
                    <div className={style.expand_Competency_data}>
                        <br />
                        <div className={style.label}>{t("competency.competencies")} : </div>
                        <ul>
                            {data?.Competencies?.map((res, key) => {
                                return (
                                    <li className={style.expand_content} key={key}>{res.name}</li>
                                )
                            })}
                        </ul>
                    </div>}
            </Collapse>




        </div>
    )
}
