import { ExpandLess } from '@mui/icons-material'
import React from 'react'

export const ExpandLessButton = (props) => {
    return (
        <div className='expand_btn' {...props}>
            <ExpandLess />
        </div>
    )
}
