import React from 'react'
import { Box, LinearProgress, Tooltip, linearProgressClasses } from "@mui/material";
import styled from '@emotion/styled';

export const CustomProgressLine = ({ value, variant }) => {


    const positionRef = React.useRef({
        x: 0,
        y: 0,
    });
    const popperRef = React.useRef(null);
    const areaRef = React.useRef(null);

    const handleMouseMove = (event) => {
        positionRef.current = { x: event.clientX, y: event.clientY };

        if (popperRef.current != null) {
            popperRef.current.update();
        }
    };
    return (
        <>
            <Tooltip
                placement="top"
                arrow
                PopperProps={{
                    popperRef,
                    anchorEl: {
                        getBoundingClientRect: () => {
                            return new DOMRect(
                                positionRef.current.x,
                                areaRef.current.getBoundingClientRect().y,
                                0,
                                0,
                            );
                        },
                    },
                }}
                title={`${value} %`}>
                <ProgressLine2 ref={areaRef} onMouseMove={handleMouseMove} variant="determinate" type={variant} value={value || 0} />
            </Tooltip>

        </>
    )
}
const ProgressLine2 = styled(LinearProgress)(({ theme, type }) => ({

    height: '14px',
    borderRadius: '8px',
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: getprogresslinecolor(type)?.bgColor,
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: '8px',
        backgroundColor: getprogresslinecolor(type)?.color,
    },
}));

function getprogresslinecolor(type) {
    switch (type) {
        case "secondary":
            return ({
                bgColor: "rgba(228, 231, 235, 1)",
                color: "rgba(123, 135, 148, 1)"
            })
        case "light_secondary":
            return ({
                bgColor: "rgba(228, 231, 235, 1)",
                color: "#9ba4ae"
            })


        default:
            break;
    }
}