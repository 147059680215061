
import axios from "../config/axiosInterceptor";
import { API, BASE_URL } from "./api";



export const fetchJobRolesByGroupId = async (id) => {
    return await axios.get(BASE_URL + API.fetchJobRolesByGroupId + id)
}
export const createJobRole = async (data) => {
    return await axios.post(BASE_URL + API.createJobRole, data)
}
export const fetchJobRoleById = async (id) => {
    return await axios.get(BASE_URL + API.fetchJobRoleById + id)
}
export const editJobRole = async (body) => {
    return await axios.put(BASE_URL + API.editJobRole, body)
}
export const deleteJobRole = async (id) => {
    return await axios.delete(BASE_URL + API.deleteJobRole + id)
}
export const fetchTPJobRoles = async (groupId) => {
    return await axios.get(BASE_URL + API.fetchTPJobRoles + groupId)
}

