import React, { useEffect } from 'react'
import { BreadCrum } from '../../components/Common/BreadCrum'
import { useNavigate, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { getallgroups } from '../../store/Slices/GroupSlice'
import { Autocomplete, Grid, TextField } from '@mui/material'
import style from "../../assets/css/views/developmentPlan.module.css"
import { CustomDatePicker } from '../../components/Inputs/CustomDatePicker'
import dayjs from 'dayjs'
import { createDPD, editDPD, getDPDById } from '../../api/developmentPlan'
import { loader, toast } from '../../utils'
import { Link } from 'react-router-dom';
import { useQueryParams } from '../../hooks/useQueryParams'

export const CreateUpdateDevelopmentPlan = () => {
    const { id } = useParams();
    const groupId = useQueryParams().get("groupId")



    const isCreateMode = !(!!id);
    const { t } = useTranslation();
    const navigate = useNavigate();
    let dispatch = useDispatch();
    let allGroups = useSelector((e) => e.groupData.allGroups);

    useEffect(() => {
        if (allGroups.length === 0) {
            dispatch(getallgroups());
        }
    }, [dispatch, allGroups.length]);

    let breadCrumData = [
        {
            text: t("developmentPlan.developmentPlans"),
            path: `/developmentPlan`
        },
        {
            text: t("Create")
        },
    ]

    const { control, handleSubmit, setValue, watch, reset, formState: { dirtyFields, errors } } = useForm({
        defaultValues: {
            groupId: groupId && Number(groupId) || null,
            name: "",
            description: "",
            startDate: null,
            endDate: null,
        }
    })
    const startDate = watch("startDate")

    useEffect(() => {
        if (id) {
            fetchDPById()
        }
    }, [])
    async function fetchDPById() {
        try {
            loader.start()
            let res = await getDPDById(id)
            let response = res?.data?.response


            let { name, description, startDate, groupId, endDate } = response
            reset({ name, description, startDate, endDate, groupId })
        } catch (error) {

        }
        finally {
            loader.stop()
        }
    }

    async function createUpdateDPD(data) {
        try {
            loader.start()
            // edit development plan 
            if (id) {
                // delete non dirty fields
                Object.keys(data).forEach((e) => {
                    if (!dirtyFields.hasOwnProperty(e)) {
                        delete data[e]
                    }
                })
                let body = { ...data, _id: id }
                let res = await editDPD(body)
                toast.success(res?.data?.message)
            }
            else {
                // create development plan 
                let res = await createDPD(data)

                toast.success(res?.data?.message)
            }
            navigate("/developmentPlan")
        } catch (error) {

            toast.error(error?.response?.data?.error)
        }
        finally {
            loader.stop()
        }
    }

    return (
        <div className={`padding_22_44 ${style.createUpdateDP_form_con}`}>
            <BreadCrum
                dataArr={breadCrumData}
            />
            <br />

            <form onSubmit={handleSubmit(createUpdateDPD)}>

                <div>
                    <Controller
                        name="groupId"
                        control={control}
                        defaultValue={null}
                        rules={{ required: true }}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <Autocomplete
                                    disabled={!!groupId}
                                    value={allGroups.find(d => d.id === value) || null}
                                    options={allGroups}
                                    className='bg-white'
                                    rules={{ required: true }}
                                    sx={{ maxWidth: "400px" }}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t("Group Name")}
                                            variant="outlined"
                                            error={!!errors.groupId}

                                        />
                                    )}
                                    onChange={(e, val) => {
                                        onChange(val ? val.id : null);
                                    }}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.id}>
                                            {option.name}
                                        </li>
                                    )}
                                />
                            );
                        }}
                    />

                </div>

                <div className={style.createUpdateDP_dark_bg}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="name"
                                control={control}
                                defaultValue={null}
                                rules={{ required: true }}
                                render={({ field: { value, onChange } }) => {
                                    return (
                                        <TextField
                                            error={!!errors?.name}
                                            className='bg-white'
                                            label={t("developmentPlan.name")}
                                            value={value} onChange={onChange} fullWidth />
                                    )
                                }} />
                        </Grid>
                        <Grid item md={6}></Grid>

                        <Grid item xs={12} md={6}>
                            <Controller
                                name="startDate"
                                control={control}
                                defaultValue={null}
                                rules={{ required: true }}
                                render={({ field: { value, onChange } }) => {
                                    return (
                                        <CustomDatePicker sx={{ width: "100%" }}
                                            error={!!errors?.startDate}
                                            value={value}
                                            onChange={(e) => {
                                                setValue("endDate", null)
                                                onChange(e ? new Date(e.toString()).toISOString() : null)
                                            }}

                                            label={t("developmentPlan.startDate")} />
                                    )
                                }} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Controller
                                name="endDate"
                                control={control}
                                defaultValue={null}
                                rules={{ required: true }}
                                render={({ field: { value, onChange } }) => {
                                    return (
                                        <CustomDatePicker sx={{ width: "100%" }}
                                            error={!!errors?.endDate}
                                            value={value} onChange={(e) => onChange(e ? new Date(e.toString()).toISOString() : null)}
                                            disablePast
                                            minDate={startDate ? dayjs(startDate) : dayjs()}
                                            label={t("developmentPlan.endDate")} />
                                    )
                                }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="description"
                                control={control}
                                defaultValue={null}
                                render={({ field: { value, onChange } }) => {
                                    return (
                                        <TextField multiline className='bg-white'
                                            value={value} onChange={onChange} fullWidth
                                            label={t("Description")}
                                        />
                                    )
                                }} />
                        </Grid>
                    </Grid>
                </div>
                <br />
                <div className="end">
                    <Link to="/developmentPlan">
                        <button type='button' className='btn-secondary'>{t("Cancel")}</button>
                    </Link>
                    &nbsp;&nbsp;
                    <button type='submit' className='btn-primary'>{t(isCreateMode ? "Submit" : "Update")}</button>
                </div>


            </form>




        </div>
    )
}
