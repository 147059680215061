import React, { useState } from 'react'
import { ShareIcon } from '../../Icons/Icon'
import { Box, Dialog } from '@mui/material'
import { CopyButton } from '../../Buttons/CopyButton';
import style from "../../../assets/css/components/jobRole.module.css"
import { CrossButton } from '../../Buttons/CrossButton';
import { toast } from '../../../utils';

export const ShareJobRole = ({ data }) => {

    const [open, setOpen] = useState(false);
    const currentDomain = window.location.origin;

    const shareLink = `${currentDomain}/job-Role/sortcompetencies/${data._id}`

    function copyLink() {

        navigator.clipboard
            .writeText(shareLink)
            .then(() => {
                setTimeout(() => toast.success("Link copied to clipboard !"), 200); // Reset after 2 seconds
            })
            .catch((err) => console.error("Failed to copy text:", err));


    }

    return (
        <div>
            <button className='icon-secondary align_center' onClick={() => setOpen(true)}>
                <ShareIcon />
            </button>

            <Dialog
                maxWidth={"sm"}
                fullWidth
                open={open}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <div className={`between dialog_header align_center`}>
                    <div className='dialog_heading'>Share Link</div>
                    <CrossButton onClick={() => setOpen(false)} />
                </div>
                <Box p={2} pb={4} className={style.shareJobRole_dialog_con}>
                    <div className={style.share_link_text}>{shareLink}</div>
                    <div className="end">

                        <button className='btn-primary center' onClick={copyLink}>
                            Copy
                        </button>
                    </div>

                </Box>
            </Dialog >



        </div>
    )
}
