import { useLocation, useParams } from "react-router"
import { BreadCrum } from "../../components/Common/BreadCrum"
import { KPISIPreview } from "../../components/Common/KPI&SICommonComp/KPISIPreview"
import { fetchSIByGroupId } from "../../api/StrategicImperatives"
import React, { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import { loader, toast, currentUser } from "../../utils"
import { useTranslation } from "react-i18next"
import { ADMIN_ROLE } from "../../constants/constants";
import { useSelector } from "react-redux"
export const PreviewStratImperative = () => {

    let params = useParams()
    let [SIData, setSIData] = useState({})
    let { t } = useTranslation()
    let [dataFetched, setDataFetched] = useState(false)
    let [groupName, setGroupName] = useState("")
    let getId = useRef(getSIByGroupId)

    useEffect(() => {
        getId.current()
    }, [getId])

    async function getSIByGroupId() {
        try {
            loader.start()
            let res = await fetchSIByGroupId(params.id)
            loader.stop()
            setDataFetched(true)
            setSIData({ groupName: res.data.groupName, data: res.data.response })
        } catch (err) {
            loader.stop()
            toast.error(err?.response?.data?.error)
        }
    } function removeDataAfterDelete(deletedId) {
        setSIData(pre => {
            return ({ ...pre, data: pre.data.filter(e => e._id !== deletedId) })
        })
    }
    let breadCrumData = [
        {
            text: t("Strategic Imperatives"),
            path: "/strategicImperatives"
        },
        {
            text: t("Preview"),
        },


    ]
    return (
        <div className="padding_22_44">
            <BreadCrum dataArr={breadCrumData} />
            <br />
            {
                dataFetched ?
                    <KPISIPreview removeDataAfterDelete={removeDataAfterDelete} type={"SI"} data={SIData} />
                    : null
            }
            {
                currentUser().role.item_name == ADMIN_ROLE ?
                    <div className="end">
                        <Link to={"/strategicImperatives"}>
                            <button className="btn-secondary">{t("Cancel")}</button>
                        </Link>
                    </div> : null
            }
        </div>
    )
}