import React from 'react'

import style from "../../assets/css/components/topbar.module.css"
import { changeDateFormat } from '../../utils'
import { useTranslation } from 'react-i18next'
import { ASSESSMENT_TYPES } from '../../constants/constants'

export const UserHeader = ({ assessmentData }) => {
    let { t } = useTranslation();

    let assessmentType = assessmentData?.assessment?.assessmentType

    return (
        <div className={style.userHeader_main_con}>

            <div className={style.content_main_con}>
                <div className={style.left_con}>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="95" height="60" viewBox="0 0 95 60" fill="none">
                            <path d="M62.3856 8.33908V35.9884C62.3856 40.5947 58.6527 44.3275 54.0465 44.3275H8.33908C3.73284 44.3275 0 40.5947 0 35.9884V8.33908C0 3.73284 3.73284 0 8.33908 0H54.0425C58.6487 0 62.3816 3.73284 62.3816 8.33908H62.3856ZM52.9817 33.6435V10.6841C52.9817 9.48366 52.0086 8.51057 50.8082 8.51057H11.5774C10.377 8.51057 9.40788 9.48765 9.40788 10.6841V33.6474C9.40788 34.8479 10.381 35.817 11.5774 35.817H16.742V42.3654L27.2585 35.817H50.8082C52.0126 35.817 52.9817 34.8439 52.9817 33.6474V33.6435Z" fill="white" />
                            <path d="M17.0374 43.3437L27.538 36.8033H50.8045C52.5473 36.8033 53.967 35.3875 53.967 33.6447V10.6853C53.967 8.94255 52.5473 7.52279 50.8045 7.52279H11.5777C9.83889 7.52279 8.41914 8.94255 8.41914 10.6853V33.6487C8.41914 35.3875 9.8349 36.8073 11.5777 36.8073H15.7532V43.3477H8.33937C4.28349 43.3477 0.985352 40.0496 0.985352 35.9937V8.34035C0.985352 4.28447 4.28349 0.986328 8.33937 0.986328H54.0428C58.0987 0.986328 61.3968 4.28447 61.3968 8.34035V35.9897C61.3968 40.0456 58.0987 43.3437 54.0428 43.3437H17.0374Z" fill="white" />
                            <path d="M94.2092 24.011V51.6643C94.2092 56.2705 90.4764 60.0034 85.8701 60.0034H40.1667C35.5605 60.0034 31.8276 56.2705 31.8276 51.6643V24.011C31.8276 19.4047 35.5605 15.6719 40.1667 15.6719H85.8701C90.4764 15.6719 94.2092 19.4047 94.2092 24.011ZM84.8053 49.3233V26.3559C84.8053 25.1555 83.8322 24.1864 82.6358 24.1864H43.405C42.2046 24.1864 41.2315 25.1595 41.2315 26.3559V49.3233C41.2315 50.5237 42.2086 51.4928 43.405 51.4928H66.9547L77.4712 58.0412V51.4928H82.6358C83.8362 51.4928 84.8053 50.5197 84.8053 49.3233Z" fill="white" />
                            <path d="M78.4567 59.0196V52.4792H82.6323C84.3711 52.4792 85.7908 51.0634 85.7908 49.3206V26.3532C85.7908 24.6144 84.3751 23.1987 82.6323 23.1987H43.4015C41.6587 23.1987 40.239 24.6144 40.239 26.3532V49.3206C40.239 51.0594 41.6587 52.4792 43.4015 52.4792H66.668L77.1686 59.0196H40.1592C36.1033 59.0196 32.8052 55.7215 32.8052 51.6656V24.0122C32.8052 19.9563 36.1033 16.6582 40.1592 16.6582H85.8626C89.9185 16.6582 93.2166 19.9563 93.2166 24.0122V51.6656C93.2166 55.7215 89.9185 59.0196 85.8626 59.0196H78.4488H78.4567Z" fill="white" />
                        </svg>
                    </div>

                    <div>
                        <div className={style.header}>{assessmentData?.assessment?.name}</div>
                        <div className={style.content}>{assessmentData?.assessment?.description}</div>

                        {assessmentType === ASSESSMENT_TYPES.organizational &&
                            <>
                                {assessmentData?.assessment?.surveyAssignments?.subGroupsData[0]?.name &&
                                    <div>
                                        <span className={style.label}>{t("groupManagement.Sub Group")} :</span>&nbsp;&nbsp;
                                        <span className={style.content}>{assessmentData?.assessment?.surveyAssignments?.subGroupsData[0]?.name}</span>
                                    </div>
                                }
                            </>
                        }

                        {assessmentType === ASSESSMENT_TYPES.jobRole &&
                            <>
                                {assessmentData?.assessment?.surveyAssignments?.jobRole?.jobRoleObj?.name &&
                                    <div>
                                        <span className={style.label}>{t("Job Role Name")} :</span>&nbsp;&nbsp;
                                        <span className={style.content}>{assessmentData?.assessment?.surveyAssignments?.jobRole?.jobRoleObj?.name}</span>
                                    </div>
                                }
                                {/* {assessmentData?.assessment?.surveyAssignments?.jobRole?.jobRoleObj?.roleId &&
                                    <div>
                                        <span className={style.label}>{t("Job Role ID")} :</span>&nbsp;&nbsp;
                                        <span className={style.content}>{assessmentData?.assessment?.surveyAssignments?.jobRole?.jobRoleObj?.roleId}</span>
                                    </div>} */}
                            </>
                        }

                        {assessmentType === ASSESSMENT_TYPES.individual &&
                            <>
                                {assessmentData?.assessment?.surveyAssignments?.user?.first_name &&
                                    <div>
                                        <span className={style.label}>{t("Name")} :</span>&nbsp;&nbsp;
                                        <span className={style.content}>{assessmentData?.assessment?.surveyAssignments?.user?.first_name}</span>
                                    </div>}
                                {assessmentData?.assessment?.surveyAssignments?.user?.email &&
                                    <div>
                                        <span className={style.label}>{t("Email ID")} :</span>&nbsp;&nbsp;
                                        <span className={style.content}>{assessmentData?.assessment?.surveyAssignments?.user?.email}</span>
                                    </div>}
                            </>
                        }

                    </div>

                </div>
                <div className={style.right_con}>
                    <div>
                        <div>{assessmentData?.assessment?.type && t("Assessment.Assessment Type")}:</div>
                        <div>{assessmentData?.assessment?.type}</div>
                    </div>
                    <div>
                        <div>{assessmentData?.assessment?.completionDate && t("Completion Date")}:</div>
                        <div>{assessmentData?.assessment?.completionDate && changeDateFormat(assessmentData?.assessment?.completionDate)}</div>
                    </div>
                </div>
            </div>

        </div >
    )
}
