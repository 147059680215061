import React, { useEffect, useState } from 'react'
import { BreadCrum } from '../../components/Common/BreadCrum'
import { CategoryForm } from '../../components/Common/Category/CategoryForm'
import { useNavigate, useParams } from 'react-router'
import { editCategory, fetchCategoryById } from '../../api/category'
import { useTranslation } from 'react-i18next'
import { loader, toast } from '../../utils'

export const EditCategory = () => {

    const [categoryData, setCategoryData] = useState({})
    let navigate = useNavigate()
    const { id } = useParams();
    const { t } = useTranslation()
    async function updateCategory(data) {
        try {
            loader.start()
            let res = await editCategory(data)
            toast.success(res?.data?.message)
            navigate("/category")
        } catch (error) {
            toast.error(error?.response?.data?.error)
        }
        finally {
            loader.stop()
        }
    }
    const breadCrumData = [
        {
            text: t("category.category"),
            path: "/category"
        },
        {
            text: t("Update"),
        },
    ]

    useEffect(() => {
        getCategoryById()
    }, [])
    async function getCategoryById() {
        try {
            loader.start()
            let res = await fetchCategoryById(id)
            setCategoryData(res?.data?.response || {})
        } catch (error) {
            toast.error(error?.response?.data?.error)
        }
        finally {
            loader.stop()
        }
    }


    return (
        <div className="padding_22_44">
            <BreadCrum dataArr={breadCrumData} />
            <br />
            {categoryData?.name &&
                <CategoryForm mode={"edit"} selectedCompetency={[]} data={categoryData} onSubmit={updateCategory} />}
        </div>
    )
}
