import React, { useEffect, useState } from 'react'
import style from "../../../assets/css/components/jobRole.module.css"
import { Autocomplete, CircularProgress, FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextareaAutosize, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getallgroups } from '../../../store/Slices/GroupSlice';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { fetchJobRolesByGroupId, fetchTPJobRoles } from '../../../api/jobRoles';
import { CustomCheckbox } from '../../Inputs/CustomCheckbox';
import { fetchCategoriesByGroupId } from '../../../api/category';
import { fetchCompetenciesByCategories } from '../../../api/competency';
import { toast } from '../../../utils';
import { CustomDatePicker } from '../../Inputs/CustomDatePicker';
import dayjs from 'dayjs';
import { getAllDPDByGroup } from '../../../api/developmentPlan';
import { useQueryParams } from '../../../hooks/useQueryParams';

export const JobRoleForm = ({ data, mode, onSubmit }) => {
    const { t } = useTranslation()
    const groupIdP = new URLSearchParams(useLocation().search).get("groupId");


    const [jobRoles, setJobRoles] = useState([])
    const [allCategories, setAllCategories] = useState([])
    const [allCompetecies, setAllCompetecies] = useState([]);
    const [fetchJobRoleLoader, setFetchJobRoleLoader] = useState(false)
    const [fetchCompetencyLoader, setFetchCompetencyLoader] = useState(false)
    const [fetchCategoryLoader, setFetchCategoryLoader] = useState(false)
    const [developmentPlanLoader, setDevelopmentPlanLoader] = useState(false)
    const [allDps, setAllDps] = useState([])

    const { control, handleSubmit, watch, setValue, formState: { errors } } = useForm({
        defaultValues: { ...data }
    })

    let dispatch = useDispatch();
    let allGroups = useSelector((e) => e.groupData.allGroups);

    useEffect(() => {
        if (allGroups.length === 0) {
            dispatch(getallgroups());
        }
    }, [dispatch, allGroups.length]);


    const groupId = watch("groupId")
    const categoryIds = watch("categoryIds")
    const competencyIds = watch("competencyIds")
    const dbStartDate = watch("dbStartDate")

    function formSubmit(formData) {
        let isAllCategoryHaveCompetencies = categoryIds.every(e => competencyIds.some(d => d.categoryId === e))
        if (!isAllCategoryHaveCompetencies) {
            toast.error("Please select competencies for all chosen categories")
        }
        else {
            onSubmit(formData)
        }
    }

    useEffect(() => {
        if (groupId) {
            fetchJobRole(groupId)
            fetchCategoryData(groupId)
            fetchDps(groupId)
        }
    }, [groupId])

    useEffect(() => {
        if (categoryIds?.length > 0) {
            fetchCompetencyData(categoryIds)
        }
    }, [categoryIds])


    async function fetchDps(groupId) {
        try {
            setDevelopmentPlanLoader(true)
            let res = await getAllDPDByGroup(groupId)
            setAllDps(res?.data?.response || [])
        } catch (error) {
            setAllDps([])
        }
        finally {
            setDevelopmentPlanLoader(false)

        }
    }

    async function fetchJobRole(groupId) {
        try {
            setFetchJobRoleLoader(true)
            let res = await fetchTPJobRoles(groupId)
            setJobRoles(res?.data?.response || [])
        } catch (error) {
            setJobRoles([])
            setAllCategories([])
            setAllCompetecies([])
        }
        finally {
            setFetchJobRoleLoader(false)
        }
    }

    async function fetchCategoryData(groupId) {
        try {
            setFetchCategoryLoader(true)
            let res = await fetchCategoriesByGroupId(groupId)
            setAllCategories(res.data.response)
        } catch (error) {
            setAllCategories([])
            setAllCompetecies([])
        }
        finally {
            setFetchCategoryLoader(false)
        }
    }
    async function fetchCompetencyData(categoryIds) {
        try {
            let body = {
                categoryIds: categoryIds
            }
            setFetchCompetencyLoader(true)
            let res = await fetchCompetenciesByCategories(body)
            setAllCompetecies(res?.data?.response?.map(extractCompetencyData))
        } catch (error) {
            setAllCompetecies([])
        }
        finally {
            setFetchCompetencyLoader(false)
        }
    }

    function extractCompetencyData(data) {
        return ({
            name: data.name,
            _id: data._id,
            categoryId: data.categoryId
        })
    }

    return (
        <form onSubmit={handleSubmit(formSubmit)}>


            <div className={style.jobRole_form_con}>

                <div>
                    <div>
                        <Controller
                            name="groupId"
                            control={control}
                            defaultValue={null} // Set the default value for the form field
                            rules={{ required: true }}
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <Autocomplete
                                        value={allGroups.find(d => d.id === value) || null}
                                        options={allGroups}
                                        disabled={groupIdP}
                                        className='bg-white'
                                        sx={{ maxWidth: "400px" }}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={t("Group Name")}
                                                variant="outlined"
                                                error={!!errors.groupId} // Display error state if the field is invalid
                                            // helperText={errors.groupId ? t("Group is required") : null} // Show error message if required
                                            />
                                        )}
                                        onChange={(e, val) => {
                                            setValue("jobRoleObj.roleId", null)
                                            setValue("jobRoleObj.name", null)
                                            setJobRoles([])
                                            onChange(val ? val.id : null);
                                        }}
                                        renderOption={(props, option) => (
                                            <li {...props} key={option.id}>
                                                {option.name}
                                            </li>
                                        )}
                                    />
                                );
                            }}
                        />

                    </div>
                    <div className={style.jobrole_dard_bg}>

                        <div className={style.jobrole_dard_bg_grid_con}>


                            <div>
                                <Controller
                                    name="jobRoleObj.roleId"
                                    control={control}
                                    defaultValue={null}
                                    rules={{ required: true }}
                                    render={({ field: { value, onChange } }) => {
                                        return (
                                            <Autocomplete
                                                value={jobRoles.find(d => d.id === value) || null}
                                                options={jobRoles}
                                                loading={fetchJobRoleLoader}
                                                // sx={{ maxWidth: "400px" }}
                                                className='bg-white'
                                                getOptionLabel={(option) => option.name}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={t("jobRole.jobRole")}
                                                        variant="outlined"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {fetchJobRoleLoader ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                        error={!!errors?.jobRoleObj?.roleId} // Display error state if the field is invalid
                                                    // helperText={errors.groupId ? t("Group is required") : null} // Show error message if required
                                                    />
                                                )}
                                                onChange={(e, val) => {
                                                    setValue("jobRoleObj.name", val ? val.name : null)
                                                    onChange(val ? val.id : null);
                                                }}
                                                renderOption={(props, option) => (
                                                    <li {...props} key={option.id}>
                                                        {option.name}
                                                    </li>
                                                )}
                                            />

                                        );
                                    }}
                                />
                            </div>

                            <div>
                                <Controller
                                    name="dpdId"
                                    control={control}
                                    defaultValue={null}
                                    rules={{ required: true }}
                                    render={({ field: { value, onChange } }) => {
                                        return (
                                            <Autocomplete
                                                value={allDps.find(d => d._id === value) || null}
                                                options={allDps}
                                                className='bg-white'
                                                getOptionLabel={(option) => option.name}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={t("developmentPlan.developmentPlans")}
                                                        variant="outlined"
                                                        error={!!errors.dpdId}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {developmentPlanLoader ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                                onChange={(e, val) => {
                                                    onChange(val ? val._id : null);
                                                }}
                                                renderOption={(props, option) => (
                                                    <li {...props} key={option.id}>
                                                        {option.name}
                                                    </li>
                                                )}
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </div>
                        <br />
                        <div className={style.jobrole_dard_bg_grid_con}>
                            {/* job role  */}
                            <div>
                                <Controller
                                    name="categoryIds"
                                    control={control}
                                    defaultValue={null}
                                    rules={{ required: true }}
                                    render={({ field: { value, onChange } }) => {
                                        return (
                                            <Autocomplete
                                                multiple
                                                id="competency-select"
                                                className="bg-white"
                                                options={allCategories}
                                                disableCloseOnSelect
                                                getOptionLabel={(option) => option.name}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <CustomCheckbox
                                                            className="me-6"
                                                            checked={selected}
                                                            style={{ marginRight: 8 }}
                                                        />
                                                        {option.name}
                                                    </li>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        label={t("Categories")}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {fetchCategoryLoader ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                        error={errors?.categoryIds}
                                                    />
                                                )}
                                                value={allCategories.filter(d => value?.includes(d._id))}
                                                onChange={(event, newValue) => {
                                                    // setValue("competencyIds", [])
                                                    onChange(newValue.map(e => e._id))
                                                }}

                                                renderTags={(tagValue, getTagProps) => (
                                                    tagValue.map((option) => option.name).join('/')
                                                )}

                                            />

                                        );
                                    }}
                                />
                            </div>

                            <div>
                                <Controller
                                    name="competencyIds"
                                    control={control}
                                    defaultValue={null}
                                    rules={{ required: true }}
                                    render={({ field: { value, onChange } }) => {

                                        return (
                                            <Autocomplete
                                                multiple
                                                id="competency-select"
                                                className="bg-white"
                                                options={allCompetecies}
                                                disableCloseOnSelect
                                                getOptionLabel={(option) => option.name}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <CustomCheckbox
                                                            className="me-6"
                                                            checked={selected}
                                                            style={{ marginRight: 8 }}
                                                        />
                                                        {option.name}
                                                    </li>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        label={t("competency.competencies")}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {fetchCompetencyLoader ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                        error={errors?.categoryIds}
                                                    />
                                                )}
                                                value={allCompetecies.filter(d => value.some(e => e._id === d._id))}

                                                onChange={(event, newValue) => {
                                                    onChange(newValue)
                                                }}

                                                renderTags={(tagValue, getTagProps) => (
                                                    tagValue.map((option) => option.name).join('/')
                                                )}

                                            />
                                        );
                                    }}
                                />
                            </div>





                        </div>
                    </div>


                </div>


            </div><br />
            <div className="end">
                <button className="btn-primary" type='submit'>
                    {t(mode === "edit" ? "Update" : "Assign")}
                </button>
                &nbsp;&nbsp;
                <Link to="/jobRole">
                    <button className="btn-secondary" type='button'>
                        {t("Cancel")}
                    </button>
                </Link>
            </div>
        </form >
    )
}
