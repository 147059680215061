import React, { useEffect, useState } from 'react'
import style from "../../../assets/css/components/competency.module.css"
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getallgroups } from '../../../store/Slices/GroupSlice';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { fetchCategoriesByGroupId } from '../../../api/category';
import { CustomCheckbox } from '../../Inputs/CustomCheckbox';
import { getAllCompetencies, getCompetenciesByCategory } from "../../../api/competency";
import { toast } from "../../../utils";


export const AssignCompetencyForm = ({ data, mode, onSubmit }) => {

  const { t } = useTranslation()
  const [categories, setCategories] = useState([])
  const [selectedCompetencies, setSelectedCompetencies] = useState([])

  let [fetchCompetencyLoader, setFetchCompetencyLoader] = useState(false);
  let [fetchCategoryLoader, setFetchCategoryLoader] = useState(false);
  let [allCompetecies, setAllCompetecies] = useState([])

  const { control, handleSubmit, watch, setValue, formState: { errors } } = useForm({
    defaultValues: { ...data }
  })

  let dispatch = useDispatch();
  let allGroups = useSelector((e) => e.groupData.allGroups);
  useEffect(() => {
    if (allGroups.length === 0) {
      dispatch(getallgroups());
    }
  }, [dispatch, allGroups.length]);

  function formSubmit(formData) {
    onSubmit(formData)
  }

  useEffect(() => {
    getAllCompetency()
  }, [])

  async function getAllCompetency() {
    try {
      let res = await getAllCompetencies()
      setAllCompetecies(res?.data?.response || [])
    }
    catch (err) {
      toast.error(err?.response?.data?.error)
    }
  }


  const groupId = watch("groupId")
  const categoryId = watch("categoryId")

  useEffect(() => {
    if (groupId) {
      fetchCategories(groupId)
    }
  }, [groupId])

  async function fetchCategories(groupId) {
    try {
      setFetchCategoryLoader(true)
      let res = await fetchCategoriesByGroupId(groupId)
      setCategories(res?.data?.response || [])
      setValue("competencyIds", [])
    }
    catch (err) {
      setValue("competencyIds", [])
    }
    finally {
      setFetchCategoryLoader(false)
      // set loader
    }
  }

  // fetch competencies on change of category 
  useEffect(() => {
    if (categoryId) {
      fetchCompetenciesByCategory(categoryId);
    }
  }, [categoryId]);


  async function fetchCompetenciesByCategory(categoryId) {
    try {
      setFetchCompetencyLoader(true)
      let body = {
        categoryId: categoryId,
      };
      let res = await getCompetenciesByCategory(body);
      let data = res?.data?.response || [];
      setSelectedCompetencies(data)
      const competenceyIds = data.map((item) => item._id);
      setValue("competencyIds", competenceyIds)

    } catch (err) {
      setValue("competencyIds", [])
      // toast.error(err?.response?.data?.error);
    }
    finally {
      setFetchCompetencyLoader(false)
    }
  }

  const handleCompetencyChange = (newValue) => {
    const newSelectedCompetencies = newValue.map((e) => e._id);

    // Extracting _id from previous selected competencies
    const previousIds = selectedCompetencies.map((comp) => comp._id);

    // Finding unselected competency IDs
    const unselectedIds = previousIds.filter(
      (id) => !newSelectedCompetencies.includes(id)
    );

    setValue("unsetCompetencyIds", unselectedIds)

  };


  return (
    <form onSubmit={handleSubmit(formSubmit)}>
      <div className={style.assignCompentency_form_con}>
        <div>
          <div className={style.assign_comp_group_input}>
            <Controller
              name="groupId"
              control={control}
              defaultValue={null}
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => {
                return (
                  <Autocomplete
                    value={allGroups.find((d) => d.id === value) || null}
                    options={allGroups}

                    className="bg_white"
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("Group Name")}
                        variant="outlined"
                        error={errors?.groupId}
                      />
                    )}
                    onChange={(e, val) => {
                      setCategories([]);
                      onChange(val ? val.id : null);
                    }}
                    renderOption={(props, option) => (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    )}
                  />
                );
              }}
            />
          </div>
        </div>

        <div className={style.competency_dard_bg}>
          <div className={style.competency_dard_bg_grid_con}>
            <div>
              <Controller
                name="categoryId"
                control={control}
                defaultValue={null}
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => {
                  return (
                    <Autocomplete
                      fullWidth
                      value={categories.find((d) => d._id === value) || null}
                      options={categories}
                      className="bg_white"
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("category.category")}
                          variant="outlined"
                          error={errors.categoryId}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {fetchCategoryLoader ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        // helperText={errors.groupId ? t("Competency is required") : null}
                        />
                      )}
                      onChange={(e, val) => {
                        onChange(val ? val._id : null);
                      }}
                      renderOption={(props, option) => (
                        <li {...props} key={option._id}>
                          {option.name}
                        </li>
                      )}
                    />
                  );
                }}
              />
            </div>
            <div>
              <Controller
                name="competencyIds"
                control={control}
                defaultValue={null}
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => {

                  // Filter competencies that do not have a categoryId set
                  const filteredCompetencies = allCompetecies.filter(competency => !competency.categoryId);

                  // Combine both arrays and remove duplicates (if needed)
                  const updatedFilteredCompetencies = [...filteredCompetencies, ...selectedCompetencies];

                  return (
                    <Autocomplete
                      multiple
                      id="competency-select"
                      className="bg-white"
                      options={updatedFilteredCompetencies}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <CustomCheckbox
                            className="me-6"
                            checked={selected}
                            style={{ marginRight: 4 }}
                          />
                          {option.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={t("competency.competency")}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {fetchCompetencyLoader ? (
                                  <CircularProgress
                                    color="inherit"
                                    size={20}
                                  />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                          error={errors?.competencyIds}
                        />
                      )}
                      value={updatedFilteredCompetencies.filter((d) => value.includes(d._id)) || []}
                      onChange={(event, newValue) => {
                        handleCompetencyChange(newValue);
                        onChange(newValue.map((e) => e._id))
                      }}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option) => option.name).join("/")
                      }
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="end">
        <button className="btn-primary" type="submit">
          {t(mode === "edit" ? "Update" : "Assign")}
        </button>
        &nbsp;&nbsp;
        <Link to="/competency">
          <button className="btn-secondary" type="button">
            {t("Cancel")}
          </button>
        </Link>
      </div>
    </form>
  );
}
