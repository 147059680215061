import React from 'react'
import { Box, Dialog, Divider } from "@mui/material"
import { CrossButton } from "../Buttons/CrossButton"
import { useTranslation } from 'react-i18next'

export const ConfirmationDialog = ({ heading, open = false, text, size, text2, onSubmit, onCancel, noClosable, btnText }) => {
    let { t } = useTranslation()
    return (
        <>
            <Dialog
                maxWidth={size}
                fullWidth
                open={open}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box>
                    <div className={`between dialog_header`}>
                        <div className='dialog_heading'>{heading}</div>
                        {!noClosable ?
                            <div onClick={onCancel} className={`pointer`}>
                                <CrossButton />
                            </div> : null}
                    </div>
                    <Divider color="#E0E8F9" sx={{ borderBottomWidth: 1 }} />
                    <Box pb={4} px={4}>
                        <Box sx={{ maxWidth: '550px', mx: 'auto', my: 5 }} className={'text-big-secondary'}>
                            {/* <div>
                                {text}
                            </div> */}

                            <div dangerouslySetInnerHTML={{ __html: text }} />

                            {text2 && <div>{text2}</div>}
                        </Box>
                        <div className="end">
                            <button className="btn-primary" onClick={onSubmit}>{btnText?.submit ? btnText.submit : t("Continue")}</button>&nbsp;&nbsp;
                            {!noClosable ?
                                <button className="btn-secondary" onClick={onCancel}>{btnText?.cancel ? btnText.cancel : t("Cancel")}</button> : null}
                        </div>
                    </Box>
                </Box>
            </Dialog >
        </>
    )
}