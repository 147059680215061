import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from "../../../assets/css/components/category.module.css"
import { ContentCopy, ExpandLess, ExpandMore, IosShare, Pageview, PageviewOutlined } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { ViewButton } from '../../Buttons/ViewButton'
import { Collapse } from '@mui/material'
import { ShareIcon } from '../../Icons/Icon'
import { ShareJobRole } from '../JobRole/ShareJobRole'
import { STATUS } from '../../../constants/constants'

export const CategoryTableDropdown = ({ data, usedFor, open }) => {
    let [expand, setExpand] = useState(false)
    const toggleExpant = () => setExpand(!expand)
    let { t } = useTranslation()

    useEffect(() => {
        setExpand(open)
    }, [open])
    return (
        <div className={`${style.home_table_dropDown} `}>
            <div className={style.inner_grid_con}>
                <div className={`flex align_center`}>
                    <div className={style.expand_btn}>
                        {!expand ? <ExpandMore onClick={toggleExpant} /> : <ExpandLess onClick={() => {
                            toggleExpant()
                        }} />}
                    </div>
                    <div className={`${style.heading}`} >
                        {data.groupName}
                    </div>
                </div>
                <div className={`between  ${style.viewContainer}`}>
                    {usedFor !== "jobrole" ? <div>&nbsp;&nbsp;{data.data.length}</div> : <div />}
                    <div>
                        <Link to={`preview/${data?.groupId}`}>
                            <ViewButton />
                        </Link>
                    </div>
                </div>
            </div>

            <Collapse in={expand} timeout="auto" unmountOnExit>
                <br />
                <div>
                    {data?.data?.map((res, key) => {
                        return (
                            <div key={key}>
                                <div className={`${style.content_name} ${usedFor === "jobrole" ? style.jobrole_table_grid_con : style.category_table_grid_con}`}>

                                    <div>
                                        {res?.name || res?.jobRoleObj?.name}
                                    </div>
                                    {usedFor === "jobrole" &&
                                        <div>
                                            {res?.dpdData?.name || "--"}
                                        </div>}

                                    {usedFor === "jobrole" && <div>
                                        {res?.status || STATUS.DRAFTS}
                                    </div>}
                                    {usedFor === "jobrole" &&
                                        <div>
                                            <div className="flex align_center end">

                                                {res?.status == STATUS.IN_PROGRESS ?
                                                    <ShareJobRole data={res} /> : <div />}


                                                <Link className='unstyled' to={`sortcompetencies/${res?._id}`}>
                                                    <button className='icon-primary'>
                                                        <PageviewOutlined sx={{ fontSize: "20px" }} />
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    }
                                </div>

                            </div>
                        )
                    })}
                </div>
            </Collapse>
        </div >
    )
}
