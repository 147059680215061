import React from 'react'
import style from "../../../assets/css/components/home.module.css"

export const AssessmentsCard = ({ heading, value }) => {
    return (
        <div className={style.assessment_card}>
            <div className={style.heading}>{heading}</div>
            <div className={`flex end ${style.content}`}>{value || 0}</div>
        </div>
    )
}
