import React from 'react';
import { Backdrop, CircularProgress, } from "@mui/material"
import { useSelector } from "react-redux"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { Login } from "../views/Login/Login"
import { CheckAuth } from "../config/CheckAuth"
import { Page404 } from "../views/Page404/Page404"
import { CustomSnackbar } from "../components/Common/CustomSnackbar"
import { ADMIN_ROLES, ASSESSMENT_TYPES, DATA_CREATE_UPDATE_ACCESS_ROLES, USER_ROLES } from '../constants/constants';
import { Assessments } from '../views/Assessment/Assessments';
import { UserAuth } from '../config/UserAuth';
import { QuestionBank } from "../views/QuestionBank/QuestionBank"
import { CreateQuestion } from "../views/QuestionBank/CreateQuestion"
import { EditQuestion } from "../views/QuestionBank/EditQuestion"
import { Groups } from "../views/Group/Groups"
import { CreateSubGroup } from "../views/Group/CreateSubGroup"
import { UserListing } from "../components/Common/Group/UsersListing"
import { EditSubGroup } from "../views/Group/EditSubGroup"
import { ViewSubGroup } from "../views/Group/ViewSubgroup"
import { StratImperatives } from "../views/StrategicImperatives/StratImperatives"
import { CreateStratImperative } from "../views/StrategicImperatives/CrateStratImperative"
import { EditStratImperative } from "../views/StrategicImperatives/EditStratImperative"
import { PreviewStratImperative } from "../views/StrategicImperatives/PreviewStratImperatives"
import { KPIList } from "../views/KPI/KPIList"
import { CreateKPIs } from "../views/KPI/CreateKPI"
import { PreviewKPI } from "../views/KPI/PreviewKPI"
import { EditKPI } from "../views/KPI/EditKPI"
import { Profile } from "../views/Profie/Profile"
import { Home } from "../views/Home/Home"
import { Assessment } from '../views/Assessment/Assessment';
import { PreviewAssessment } from '../views/Assessment/PreviewAssessment';
import { CreateAssessment } from '../views/Assessment/CreateAssessment';
import { EditAssessment } from '../views/Assessment/EditAssessment';
import { Reports } from '../views/Reports/Reports';
import { ReportsDetail } from '../views/Reports/ReportsDetail';
import { ReportsDetailIndividual } from '../views/Reports/ReportsDetailIndividual';
import { ProtectedRoute } from './ProtectedRoute';
import { AssessmentReport } from '../views/Reports/AssessmentReport';
import { SidebarWrapper } from './SidebarWrapper';
import { UserAssessment } from '../views/Assessment/UserAssessment';
import { SessionExpiredModal } from '../components/Common/SessionExpiredModal';
import { Category } from '../views/Category/Category';
import { Competency } from '../views/Competency/Competency';
import { JobRole } from '../views/JobRole/JobRole';
import { CreateCategory } from '../views/Category/CreateCategory';
import { AssignCompetency } from '../views/Competency/AssignCompetency';
import { JobRoleQuestionBank } from '../views/QuestionBank/JobRoleQuestionBank';
import { IndividualQuestionBank } from '../views/QuestionBank/IndividualQuestionBank';
import { EditCategory } from '../views/Category/EditCategory';
import { CreateJobRole } from '../views/JobRole/CreateJobRole';
import { PreviewCategory } from '../views/Category/PreviewCategory';
import { PreviewCompetency } from '../views/Competency/PreviewCompetency';
import { PreviewJobRole } from '../views/JobRole/PreviewJobRole';
import { EditJobRole } from '../views/JobRole/EditJobRole';
import { EditCompetency } from '../views/Competency/EditCompetency';
import { ValidateRouteParams } from './ValidateRouteParams';


export const Router = () => {
    let loader = useSelector(e => e.loader)



    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loader} >
                <CircularProgress color="inherit" />
            </Backdrop>
            <CustomSnackbar />



            <BrowserRouter>
                <SessionExpiredModal />
                <Routes>
                    <Route path='/' element={<Login />} />
                    <Route path='/' element={<UserAuth authenticatedRoles={[...USER_ROLES, ...ADMIN_ROLES]} />}>
                        <Route path='survey-form/:id' element={<Assessments />} />

                        <Route element={<SidebarWrapper />}>

                            <Route path='userAssessment/:assessmentType' element={<ValidateRouteParams
                                paramsKey={"assessmentType"}
                                allowedParams={[ASSESSMENT_TYPES.individual, ASSESSMENT_TYPES.jobRole, ASSESSMENT_TYPES.organizational]}
                                redirectTo={`/userAssessment/${ASSESSMENT_TYPES.organizational}`}
                            />}>
                                <Route index element={<UserAssessment />} />
                                <Route path='preview/:id' element={<PreviewAssessment />} />
                            </Route>


                        </Route>
                    </Route>

                    <Route path='/' element={<CheckAuth authenticatedRoles={[...ADMIN_ROLES, ...USER_ROLES]} />}>
                        <Route path="/profile" element={<Profile />} />
                    </Route>

                    <Route path='/' element={<CheckAuth authenticatedRoles={ADMIN_ROLES} />}>

                        <Route path="/home" element={<Home />} />

                        <Route path="/jobrolequestionBank/" element={<JobRoleQuestionBank />} />
                        <Route path="/individualquestionBank/" element={<IndividualQuestionBank />} />


                        <Route path='questionBank/:assessmentType' element={<ValidateRouteParams
                            paramsKey={"assessmentType"}
                            allowedParams={[ASSESSMENT_TYPES.individual, ASSESSMENT_TYPES.jobRole, ASSESSMENT_TYPES.organizational]}
                            redirectTo={`/questionBank/${ASSESSMENT_TYPES.organizational}`}
                        />}>
                            <Route index element={<QuestionBank />} />
                            <Route element={<ProtectedRoute authUsers={DATA_CREATE_UPDATE_ACCESS_ROLES} navigateUrl={"/home"} />}>
                                <Route path="create" element={<CreateQuestion />} />
                                <Route path="edit/:id" element={<EditQuestion />} />
                            </Route>
                        </Route>


                        <Route path="/group" element={<Groups />} />
                        <Route path="/group/viewSubgroup/:id" element={<ViewSubGroup />} />
                        <Route path="/group/userListing" element={<UserListing />} />

                        <Route path="/strategicImperatives" element={<StratImperatives />} />
                        <Route path="/strategicImperatives/preview/:id" element={<PreviewStratImperative />} />

                        <Route path="/KPI" element={<KPIList />} />
                        <Route path="/KPI/preview/:id" element={<PreviewKPI />} />


                        <Route path='assessment/:assessmentType' element={<ValidateRouteParams
                            paramsKey={"assessmentType"}
                            allowedParams={[ASSESSMENT_TYPES.individual, ASSESSMENT_TYPES.jobRole, ASSESSMENT_TYPES.organizational]}
                            redirectTo={`/assessment/${ASSESSMENT_TYPES.organizational}`}
                        />}>
                            <Route index element={<Assessment />} />
                            <Route element={<ProtectedRoute authUsers={DATA_CREATE_UPDATE_ACCESS_ROLES} navigateUrl={"/home"} />}>
                                <Route path="create" element={<CreateAssessment />} />
                                <Route path="edit/:id" element={<EditAssessment />} />
                            </Route>

                            <Route path="preview/:id" element={<PreviewAssessment />} />
                        </Route>

                        <Route path='reports/:assessmentType'
                            element={<ValidateRouteParams
                                paramsKey={"assessmentType"}
                                allowedParams={[ASSESSMENT_TYPES.individual, ASSESSMENT_TYPES.jobRole, ASSESSMENT_TYPES.organizational]}
                                redirectTo={`/reports/${ASSESSMENT_TYPES.individual}`}
                            />}>
                            <Route index element={<Reports />} />
                            <Route path="detail/:id" element={<ReportsDetail />} />
                            <Route path="detailIndividual/:id" element={<ReportsDetailIndividual />} />

                        </Route>


                        <Route path='/' element={<ProtectedRoute authUsers={DATA_CREATE_UPDATE_ACCESS_ROLES} navigateUrl={"/home"} />}>


                            <Route path="/group/createSubGroup" element={<CreateSubGroup />} />
                            <Route path="/group/editSubgroup/:id" element={<EditSubGroup />} />
                            <Route path="/strategicImperatives/create" element={<CreateStratImperative />} />
                            <Route path="/strategicImperatives/edit/:id" element={<EditStratImperative />} />
                            <Route path="/KPI/create" element={<CreateKPIs />} />
                            <Route path="/KPI/edit/:id" element={<EditKPI />} />

                        </Route>
                        <Route path="/assessment/report/:id" element={<AssessmentReport />} />



                        <Route path='category'>
                            <Route index element={<Category />} />
                            <Route path='create' element={<CreateCategory />} />
                            <Route path='edit/:id' element={<EditCategory />} />
                            <Route path='preview/:id' element={<PreviewCategory />} />
                        </Route>
                        <Route path='competency'>
                            <Route index element={<Competency />} />
                            <Route path='assign' element={<AssignCompetency />} />
                            <Route path='preview/:id' element={<PreviewCompetency />} />
                            <Route path='edit/:id/:groupId' element={<EditCompetency />} />
                        </Route>
                        <Route path='jobRole'>
                            <Route index element={<JobRole />} />
                            <Route path='create' element={<CreateJobRole />} />
                            <Route path='preview/:id' element={<PreviewJobRole />} />
                            <Route path='edit/:id' element={<EditJobRole />} />
                        </Route>

                    </Route>

                    <Route path="*" element={<Page404 />} />
                </Routes>
            </BrowserRouter>
        </>
    )
}