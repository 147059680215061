import React, { useEffect, useState } from 'react'
import { BreadCrum } from '../../components/Common/BreadCrum'
import { CompetencyPreviewCollapseTab } from '../../components/Common/Competency/CompetencyPreviewCollapseTab'
import { useTranslation } from 'react-i18next'
import { fetchCompetenciesByGroupId } from '../../api/competency'
import { useParams } from 'react-router'
import style from "../../assets/css/views/competency.module.css"
import { loader, toast } from '../../utils'
import { AddCircle } from '@mui/icons-material'
import { Link } from 'react-router-dom'


export const PreviewCompetency = () => {

    const { t } = useTranslation()
    const { id } = useParams()
    const [competencyeData, setCompetencyData] = useState([])
    const [groupName, setGroupName] = useState("")
    let breadCrumData = [
        {
            text: t("competency.competency"),
            path: "/competency"
        },
        {
            text: t("Preview"),
        },
    ]

    useEffect(() => {
        fetchData()
    }, [])
    async function fetchData() {
        try {
            loader.start()
            let res = await fetchCompetenciesByGroupId(id)
            setCompetencyData(res.data.response)
            setGroupName(res.data.groupName)
        }
        catch (error) {
            toast.error(error?.response?.data?.error)
        }
        finally {
            loader.stop()
        }
    }
    function onDeleteCompetency(id) {
        setCompetencyData(pre => pre.filter(c => c._id !== id))
    }


    return (
        <div className={` padding_22_44`}>

            <BreadCrum dataArr={breadCrumData} />
            <br />
            <div className={`${style.preview_competency_con}`}>
                <div className={style.heading}>
                    {groupName}
                </div>
                {competencyeData?.map((res, key) => {
                    return (
                        <CompetencyPreviewCollapseTab onDeleteCompetency={onDeleteCompetency} data={res} key={key} />
                    )
                })}

            </div>

        </div>
    )
}
