import React, { useState } from "react"
import { ConfirmationDialog } from "../Common/ConfirmationDialog"
import { Tooltip } from "@mui/material"
import { useTranslation } from "react-i18next"

export const EditButton = ({ text, onClick, confirmation, noClosableDialog, heading, data, ...props }) => {

    let [dialog, setDialog] = useState(false)
    let { t } = useTranslation()
    return (
        <>
            <Tooltip title={t("Edit")} arrow>
                <span>
                    <button {...props} type="button" onClick={() => confirmation ? setDialog(true) : onClick(data ? data : null)} className="icon-btn">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.9996 10.2855V16.7862C19.9987 17.9027 19.5543 18.9743 18.764 19.7646C17.9737 20.5549 16.9031 20.9993 15.7856 21.0002H7.21402C6.09746 20.9993 5.02589 20.5549 4.23562 19.7646C3.4453 18.9743 3.00094 17.9027 3 16.7862V8.21457C3.00094 7.09709 3.44532 6.02645 4.23562 5.23617C5.02594 4.44585 6.09749 4.00149 7.21402 4.00056H13.7147C14.2668 4.00056 14.714 4.44775 14.714 4.99994C14.714 5.55213 14.2668 6.00026 13.7147 6.00026H7.21402C5.9915 6.0012 5.0015 6.99213 4.99963 8.21465V16.7862C5.00151 18.0088 5.9915 18.9988 7.21402 19.0006H15.7856C17.0081 18.9988 17.9991 18.0088 18 16.7862V10.2856C18 9.73341 18.4481 9.28622 19.0003 9.28622C19.5525 9.28622 19.9996 9.73334 19.9996 10.2855ZM20.6568 3.34329C20.4693 3.15579 20.2153 3.05078 19.95 3.05078C19.6846 3.05078 19.4306 3.15578 19.2431 3.34329L10.2928 12.2936C10.1024 12.4802 9.99463 12.7352 9.9937 13.0024C9.99276 13.2686 10.0978 13.5246 10.2862 13.7139C10.4756 13.9023 10.7315 14.0074 10.9978 14.0064C11.265 14.0055 11.52 13.8977 11.7065 13.7074L20.6568 4.75704C20.8443 4.56954 20.9493 4.31549 20.9493 4.05017C20.9493 3.78485 20.8443 3.53078 20.6568 3.34329Z" fill="white" />
                        </svg>
                    </button>
                </span>
            </Tooltip>


            <ConfirmationDialog onSubmit={() => {
                onClick(data ? data : null)
                setDialog(false)
            }}
                noClosable={noClosableDialog}
                onCancel={() => setDialog(false)} heading={heading} text={text} open={dialog} />




        </>
    )
}