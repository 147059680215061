import React from 'react'
import { useNavigate } from "react-router"
import { ConfirmationDialog } from "./ConfirmationDialog"
import { useDispatch, useSelector } from "react-redux"
import { setTokenStatus } from "../../store/Slices/TokenExpireSlice"
import {logout} from "../../utils";

export const SessionExpiredModal = () => {

    let navigate = useNavigate()
    let dispatch = useDispatch()

    // let validToken = useSelector(e => e.tokenStatus)
    return (
        <ConfirmationDialog  onSubmit={() =>logout(navigate)} noClosable heading={"Session Expired"} text={"Oops! Session Expired"} />
    )
}