import React from 'react';
import Chart from 'react-apexcharts';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export const MultiCircularProgressBar = ({ values }) => {

    function chartCircleSize() {
        switch (values.length) {
            case 1:
                return "50%"
                break;
            case 2:
                return "50%"
                break;
            case 3:
                return "20%"
                break;

            default:
                break;
        }
    }

    let state = {
        series: [...values],
        options: {
            chart: {
                type: 'radialBar',
            },
            colors: ['#2D3A8C', '#4C63B6', '#7B8FC1'],
            plotOptions: {
                radialBar: {
                    startAngle: -90,
                    endAngle: 270,
                    hollow: {
                        // size: '20%',
                        size: chartCircleSize(),
                        background: "transparent",
                        padding: 0,
                        margin: 1,
                    },
                    dataLabels: {
                        name: {
                            show: false,
                        },
                        value: {
                            show: false,
                        },
                    },
                    track: {
                        show: true,
                        strokeWidth: '100%',
                    },
                },
            },
            tooltip: {
                enabled: true,
                theme: 'dark', // You can change the tooltip theme to 'light' if you prefer
                y: {
                    formatter: function (val) {
                        return `${val}%`; // Display the value with a percentage sign
                    },
                    title: {
                        formatter: (seriesName) => "", // Customize the tooltip title
                    },
                },
                style: {
                    backgroundColor: "#E0E8F9",
                    color: "#52606D",
                    fontFamily: "Poppins-Medium",
                    fontSize: "12px",
                    fontWeight: 400,
                    padding: "4px 8px"


                },
            },
            labels: [values?.[0] && `${values[0]} %`, values[1] && `${values[1]} %`].filter(e => e),
            legend: {
                show: values.length <= 2,//show labels only for max two progress line
                floating: true,
                fontSize: '20px',
                fontWeight: 700,
                position: 'left',
                fontFamily: "Poppins-Medium",
                offsetX: 60,
                offsetY: values.length === 1 ? 75 : 60,
                labels: {
                    useSeriesColors: false,
                    colors: ['#2D3A8C', '#4C63B6'],
                },
                markers: {
                    show: false,
                    width: 0,
                    height: 0,
                },
                itemMargin: {
                    vertical: 0,
                },
            },
        },
    };

    return (
        <div>
            <Chart
                options={state.options}
                height="260px"
                series={state.series}
                type="radialBar"
            />
        </div>
    );
};
