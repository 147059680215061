import React, { useEffect, useRef, useState } from "react"
import { getSubGroupByGroupId } from "../../api/Group"
import { useParams } from "react-router"
import { ViewSubGroupComp } from "../../components/Common/Group/ViewSubGroupComp"
import { loader, toast } from "../../utils"
import { BreadCrum } from "../../components/Common/BreadCrum"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

export const ViewSubGroup = () => {
    const tokenStatus = useSelector((state) => state.tokenStatus);
    let params = useParams()
    let { t } = useTranslation()
    let [subGroupData, setSubGroupData] = useState([])
    let [groupName, setGroupName] = useState("")

    async function getAllSubGroupsByGroupId() {
        try {
            loader.start()
            let res = await getSubGroupByGroupId(params.id)
            setGroupName(res.data.groupName)
            loader.stop()
            setSubGroupData(res.data.response)
        }
        catch (err) {
            toast.error(err?.response?.data?.error)
            loader.stop()
        }
    }
    let getallSub = useRef(getAllSubGroupsByGroupId)
    useEffect(() => {
        getallSub.current()
    }, [getallSub,tokenStatus])

    let breadCrumData = [
        {
            text: t("groupManagement.Group Management"),
            path: "/group"
        },
        {
            text: t("Preview"),
        },
    ]

    return (

        <div className={"padding_22_44"}>
            <BreadCrum dataArr={breadCrumData} />
            <br />
            <ViewSubGroupComp groupName={groupName} data={subGroupData} setData={setSubGroupData} />
        </div >
    )
}