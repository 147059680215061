import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { fetchJobRolesByGroupId } from '../../api/jobRoles'
import { BreadCrum } from '../../components/Common/BreadCrum'
import style from "../../assets/css/components/jobRole.module.css"
import { CategoryPreviewCollapseTab } from '../../components/Common/Category/CategoryPreviewCollapseTab'
import { useTranslation } from 'react-i18next'
import { JobRolePreviewCollapseTab } from '../../components/Common/JobRole/JobRolePreviewCollapseTab'
import { loader, toast } from '../../utils'
import { Link } from 'react-router-dom'
import { AddCircle } from '@mui/icons-material'
import { isUserCreateUpdateData } from '../../utils'

export const PreviewJobRole = () => {

    const { id } = useParams()
    const { t } = useTranslation()
    const [jobRoleData, setJobRoleData] = useState([])
    const [groupName, setGroupName] = useState("")

    useEffect(() => {
        fetchData()
    }, [])


    async function fetchData() {
        try {
            loader.start()
            let res = await fetchJobRolesByGroupId(id)
            setJobRoleData(res.data.response)
            setGroupName(res.data.groupName)
        } catch (error) {
            toast.error(error?.response?.data?.error)
        }
        finally {
            loader.stop()
        }
    }
    let breadCrumData = [
        {
            text: t("jobRole.jobRole"),
            path: "/jobRole"
        },
        {
            text: t("Preview"),
        },


    ]
    function onDeleteJobrole(id) {
        setJobRoleData(pre => pre.filter(c => c._id !== id))
    }

    return (
        <div className={` padding_22_44`}>

            <BreadCrum dataArr={breadCrumData} />
            <br />
            <div className={`${style.preview_jobrole_con}`}>
                <div className={style.heading}>
                    {groupName}
                </div>
                {jobRoleData?.map((res, key) => {
                    return (
                        <JobRolePreviewCollapseTab onDeleteJobrole={onDeleteJobrole} data={res} key={key} />
                    )
                })}
                {isUserCreateUpdateData() ?
                    <div className="add_more" >
                        <br />
                        <Link className='unstyled' to={`/jobRole/create?groupId=${id}`}>
                            <span className="pointer flex align_centers">
                                <AddCircle />&nbsp;
                                <span>
                                    {t("jobRole.Add Job Role")}
                                </span>
                            </span>
                        </Link>
                    </div>
                    : null}
            </div>
            {isUserCreateUpdateData() ?
                <div className="end">
                    <Link to={"/jobRole"}>
                        <button className="btn-secondary">{t("Cancel")}</button>
                    </Link>
                </div>
                : null}
        </div>
    )
}
