import React from 'react'
import style from "../../assets/css/components/footer.module.css"
import { useTranslation } from 'react-i18next';

export const Footer = () => {
    const currentYear = new Date().getFullYear();
    let { t } = useTranslation();
    return (
        <div className={`flex between ${style.footer_main_con}`}>
            <div>{currentYear} © {t("footer.rightsText")}.</div>
            <div className={style.container_2}>
                <div>{t("footer.siteMap")}</div>
                <div>{t("footer.yourPrivacy")}</div>
                <div>{t("footer.conditionOfUse")} </div>
            </div>
        </div>
    )
}