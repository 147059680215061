import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from "../../../assets/css/components/category.module.css"
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { ViewButton } from '../../Buttons/ViewButton'
import { Collapse } from '@mui/material'

export const CategoryTableDropdown = ({ data, open }) => {
    let [expand, setExpand] = useState(false)
    const toggleExpant = () => setExpand(!expand)
    let { t } = useTranslation()

    useEffect(() => {
        setExpand(open)
    }, [open])
    return (
        <div className={`${style.home_table_dropDown} `}>
            <div className={style.inner_grid_con}>
                <div className={`flex align_center`}>
                    <div className={style.expand_btn}>
                        {!expand ? <ExpandMore onClick={toggleExpant} /> : <ExpandLess onClick={() => {
                            toggleExpant()
                        }} />}
                    </div>
                    <div className={`${style.heading}`} >
                        {data.groupName}
                    </div>
                </div>
                <div className={`between ${style.viewContainer}`}>
                    <div>&nbsp;&nbsp;{data.data.length}</div>
                    <div>
                        <Link to={`preview/${data?.groupId}`}>
                            <ViewButton />
                        </Link>
                    </div>
                </div>
            </div>

            <Collapse in={expand} timeout="auto" unmountOnExit>
                <br />
                <div>
                    {data.data.map((res, key) => {
                        return (
                            <div key={key}>
                                <div className={style.content_name}>
                                    <div>
                                        {res?.name || res?.jobRoleObj?.name}
                                    </div>
                                </div>

                            </div>
                        )
                    })}
                </div>
            </Collapse>
        </div >
    )
}
