import { ExpandMore } from '@mui/icons-material'
import React from 'react'

export const ExpandMoreButton = (props) => {
    return (
        <div className='expand_btn' {...props}>
            <ExpandMore />
        </div>
    )
}
