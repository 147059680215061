import React from 'react'
import { Checkbox } from "@mui/material"

export const CustomCheckbox = (props) => {
    return (
        <Checkbox
            {...props}
            sx={{
                '&.Mui-checked': {
                    color: 'rgba(76, 99, 182, 1)',
                    borderColor: 'red',

                },
                '&.Mui-checked.Mui-disabled': {
                    color: 'lightgray',
                    borderColor: 'red'
                },
                '&.Mui-disabled': {
                    color: 'gray',
                    borderColor: 'green'
                },
                '&.MuiCheckbox-root': {
                    padding: "0px !important",
                },
                '&.MuiCheckbox-root:hover': {
                    backgroundColor: "transparent",
                }

            }} />
    )
}