import React, { useRef } from "react"
import { SubGroupForm } from "../../components/Common/Group/SubGroupForm"
import { createSubGroup } from "../../api/Group"
import { useLocation, useNavigate } from "react-router"
import { loader, toast } from "../../utils"
import { BreadCrum } from "../../components/Common/BreadCrum"
import { useTranslation } from "react-i18next"

export const CreateSubGroup = () => {


    const type = new URLSearchParams(useLocation().search).get('type');
    const paramsGroupId = new URLSearchParams(useLocation().search).get('groupId');

    let subGroupData = useRef({
        groupId: paramsGroupId,
        name: '',
        description: '',
        members: []

    })
    let { t } = useTranslation()
    let navigate = useNavigate()
    async function formSubmit(e) {
        try {
            loader.start()

            let res = await createSubGroup(e)
            loader.stop()
            toast.success(res?.data?.message)
            navigate(`/group/viewSubgroup/${e.groupId}`)
        }
        catch (err) {
            loader.stop()
            toast.error(err?.response?.data?.error)
        }
    }
    let breadCrumData = [
        {
            text: t("groupManagement.Group Management"),
            path: "/group"
        },
        {
            text: t("groupManagement.Create Sub Group"),
        },


    ]
    return (
        <div className={"padding_22_44"}>
            <BreadCrum dataArr={breadCrumData} />
            <br />
            <SubGroupForm mode={type ? "recreate" : "create"} data={subGroupData.current} groupDisable={type ? true : false} onSubmit={formSubmit} />
        </div>
    )
}