// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.end {
  display: flex;
  justify-content: flex-end;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.w-100 {
  width: 100%;
}

.border {
  border: 1px solid red;
}

.wrap {
  flex-wrap: wrap;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".end {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.center {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.w-100 {\n  width: 100%;\n}\n\n.border {\n  border: 1px solid red;\n}\n\n.wrap {\n  flex-wrap: wrap;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
