import { Autocomplete, Chip, CircularProgress, FormControl, FormControlLabel, InputAdornment, InputLabel, ListItemText, MenuItem, OutlinedInput, Radio, RadioGroup, Select, TextField, Tooltip } from "@mui/material"
import style from "../../../assets/css/components/questionBank.module.css"
import { DeleteButon } from "../../Buttons/DeleteButton"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { CustomCheckbox } from "../../Inputs/CustomCheckbox"
import { EditCompetencyOrTag } from "../Competency/EditCompetencyOrTag"
import { toast } from "../../../utils"
import { QUESTION_TYPE, ASSESSMENT_TYPES, QUESTION_TAGS, OPTIONS_TAGS } from "../../../constants/constants"
import { AddCompetencyOrTag } from "../Competency/AddCompetencyOrTag"
import { AddCircle } from "@mui/icons-material"
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { getAllCompetencies } from "../../../api/competency"
import { getAllTags } from "../../../api/tag"

export const QuestionForm = ({ data, onSubmit, mode, disabled, onCancel }) => {

  const { assessmentType } = useParams()
  const isOrganizational = assessmentType === ASSESSMENT_TYPES.organizational

  let TagOrCompetencyIdsKey = assessmentType === ASSESSMENT_TYPES.organizational ? "tagIds" : "competencyIds"

  const [allNewCompetencies, setAllNewCompetencies] = useState([])
  const [newSelectedCompetencies, setNewSelectedCompetencies] = useState([])
  const [fetchCompetencyLoader, setFetchCompetencyLoader] = useState(false)

  // these states store all competency data or all tags data as per assessment type
  const [allCompetencyOrTagData, setAllCompetencyOrTagData] = useState([])
  const [allNewCompetencyOrTagData, setAllNewCompetencyOrTagData] = useState([])
  const [allNewSelectedCompetencyOrTagData, setAllNewSelectedCompetencyOrTagData] = useState([])

  const [fetchCompetencyOrTagLoader, setFetchCompetencyOrTagLoader] = useState(false)


  let { t } = useTranslation()
  let [questionData, setQuestionData] = useState({ ...data })
  let [invalidFields, setInvalidFields] = useState({
    name: false,
    category: false,
    options: [
      { value: false, weightage: false }
    ]

  })

  function addOption() {
    setQuestionData({ ...questionData, options: [...questionData.options, { name: '', weightage: 0 }] })
  }

  function addQuestionSubmit(e) {
    e.preventDefault()

    let isFormValid = validateForm()
    if (!isFormValid) { return }
    let isWeightageValid = checkWeightageTotal()

    let r = checkOptionsDuplication(questionData.options)
    if (r.isError) {
      setInvalidFields(pre => ({ ...pre, options: r.data }))
      toast.error("All options should be unique !")
      return
    }
    if (isFormValid && isWeightageValid) {
      console.log(questionData)

      let competencysOrTags = allNewCompetencyOrTagData.filter(comp => allNewSelectedCompetencyOrTagData.some(k => k === comp._id)).map(({ name }) => ({ name }))

      console.log(competencysOrTags)

      onSubmit({
        ...questionData,
        assessmentType,
        // remove this condition after tags api integrateino 
        [assessmentType === ASSESSMENT_TYPES.organizational ? "newTags" : "newCompetencies"]: competencysOrTags,
        // newTags: assessmentType === ASSESSMENT_TYPES.organizational ? competencysOrTags : []
      })
    }

  }

  function checkOptionsDuplication(optionArr) {
    let error = optionArr.map((res, ind) => {
      let newArr = [...optionArr]
      newArr.splice(ind, 1)
      let isValueAlign = newArr.some(e => e?.name?.toLowerCase()?.trim() == res?.name?.toLowerCase()?.trim())
      return ({ name: isValueAlign, weightage: isValueAlign })
    })
    return ({ isError: error.some(e => e.name), data: error })
  }

  function checkWeightageTotal() {
    let errors = { options: [] }
    if (questionData.questionType === "Weightage") {
      let count = 0;
      questionData.options.forEach(res => count += res.weightage * 1)
      if (count === 100) {
        return true
      }
      else {
        toast.error('Total of weightage should be "100 %"')
        questionData.options.forEach((e, index) => {
          errors.options[index] = { ...errors.options[index], weightage: true }

        })
        setInvalidFields(errors)
        return false
      }
    }
    else {
      return true
    }

  }
  let inputFieldStyle = {
    '& label.Mui-focused': {
      color: 'rgba(45, 58, 140, 1)',
    },


  }
  let selectInputStyle = {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(45, 58, 140, 1)',
    },
  }

  function validateForm() {
    let errors = { options: [] }
    let error = []
    if (!questionData.question || questionData?.question?.trim() === "") {
      errors.question = true
      error.push("error")
    }
    else {
      errors.question = false
    }

    // if (assessmentType !== ASSESSMENT_TYPES.organizational) {
    if (questionData[TagOrCompetencyIdsKey].length === 0 && allNewSelectedCompetencyOrTagData.length === 0) {
      errors.category = true
      error.push("error")
    }
    else {
      errors.category = false
    }
    // }

    questionData.options.forEach((element, index) => {
      if (!element.name) {
        errors.options[index] = { ...errors.options[index], name: true }
        error.push("error")
      }
      else {
        errors.options[index] = { ...errors.options[index], name: false }

      }
      if (!element.weightage && questionData.questionType === "Weightage") {
        errors.options[index] = { ...errors.options[index], weightage: true }
        error.push("error")
      }
      else {
        errors.options[index] = { ...errors.options[index], weightage: false }

      }
    })
    setInvalidFields(errors)
    if (error.length === 0) {
      return true
    }
    else {
      toast.error("All Fields Are Required !")
      return false
    }
  }

  async function getAllCompetencyOrTag() {
    try {
      if (assessmentType !== ASSESSMENT_TYPES.organizational) {
        let res = await getAllCompetencies()
        setAllCompetencyOrTagData(res.data.response)
      }
      else {
        // get all tags 
        let res = await getAllTags()
        setAllCompetencyOrTagData(res.data.response)
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getAllCompetencyOrTag()
  }, [])


  function editcompetencySubmit(data) {
    if (data?.deleteData) {
      // delete data from all competencies or tag state 
      setAllCompetencyOrTagData(e => e.filter(da => !data?.deleteData.includes(da._id)))
      setQuestionData(prev => ({ ...prev, [TagOrCompetencyIdsKey]: questionData[TagOrCompetencyIdsKey].filter(e => !e.includes(data?.deleteData)) }));
    }
    else if (data?.editData) {
      // update all competencies or tag state after update data 
      setAllCompetencyOrTagData(pre => {
        return pre.map(e => {
          const editItem = data.editData.find(item => item._id === e._id);
          if (editItem) {
            return { ...e, name: editItem.name };
          }
          return e;
        });
      });
    }
  }


  function removeOption(index) {
    resetErrorFields()
    let arr = [...questionData.options]
    arr.splice(index, 1)
    setQuestionData({ ...questionData, options: arr })
  }

  function resetErrorFields() {
    setInvalidFields({
      name: false,
      category: false,
      options: [
        { value: false, weightage: false }
      ]
    })
  }

  function addNewCompetencyOrTag(data) {
    let _id = allNewCompetencyOrTagData.length + 1
    setAllNewCompetencyOrTagData(pre => [...pre, { ...data, _id }])
    setAllNewSelectedCompetencyOrTagData(pre => [...pre, _id])
  }

  function questionTabClick(tag) {
    setQuestionData(pre => {
      pre.question += tag.value;
      return ({ ...pre })
    })
  }

  function optionTagClick(tag) {
    setQuestionData(pre => {
      pre.options = tag.value;
      return ({ ...pre })
    })
  }


  return (
    <>
      <div className={`${style.question_form}`}>
        <form onSubmit={addQuestionSubmit}>
          <div
            className={`${style.form_con} `}
          >
            <div className={style.max_width_70}>
              <TextField
                disabled={disabled}
                fullWidth
                error={invalidFields.question}
                label={t("questionBank.Question")}
                value={questionData.question}
                onChange={(e) => {
                  setQuestionData({
                    ...questionData,
                    question: e.target.value,
                  });
                  resetErrorFields();
                }}
              />
            </div>

            {QUESTION_TAGS.filter((e) =>
              e.assessmentType.includes(assessmentType)
            ).map((res, key) => (
              <Chip
                key={key}
                className={style.question_tag}
                onClick={() => questionTabClick(res)}
                label={res.label}
              ></Chip>
            ))}
            <br />
            <div className="flex align_center">


              <Autocomplete
                multiple
                id="competency-select"
                className="bg-white w-100"
                sx={{ width: "70%" }}
                options={[...allCompetencyOrTagData, ...allNewCompetencyOrTagData]}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <CustomCheckbox
                      disabled={disabled}
                      checked={selected}
                      className="me-6"
                    />
                    {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={t(assessmentType === ASSESSMENT_TYPES.organizational ? "tag.tags" : "competency.competencies")}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {fetchCompetencyOrTagLoader ? (
                            <CircularProgress
                              color="inherit"
                              size={20}
                            />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                    error={invalidFields.category}
                  />
                )}
                value={
                  [...allCompetencyOrTagData, ...allNewCompetencyOrTagData].filter(
                    (d) =>
                      [
                        ...questionData[TagOrCompetencyIdsKey],
                        ...allNewSelectedCompetencyOrTagData,
                      ].includes(d._id)
                  ) || []
                }
                onChange={(e, value) => {
                  // let value = e.target.value;


                  // resetErrorFields();
                  setQuestionData({
                    ...questionData,
                    [TagOrCompetencyIdsKey]: value
                      .filter((e) =>
                        allCompetencyOrTagData.some((c) => c._id === e._id)
                      )
                      .map((e) => e._id),
                  });

                  setAllNewSelectedCompetencyOrTagData(
                    value
                      .filter(
                        (e) =>
                          !allCompetencyOrTagData.some((c) => c._id === e._id)
                      )
                      .map((dd) => dd._id)
                  );
                }}



                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option) => option.name).join("/")
                }
              />



              <div className={style.addCatBtn}>
                <AddCompetencyOrTag
                  type={assessmentType === ASSESSMENT_TYPES.organizational ? "Tag" : "Competency"}
                  disabled={disabled}
                  onAddCompetency={addNewCompetencyOrTag}
                  allCompetencies={[
                    ...allCompetencyOrTagData,
                    ...allNewCompetencyOrTagData,
                  ]}
                />
                &nbsp;&nbsp;

                <EditCompetencyOrTag
                  type="button"
                  usedFor={isOrganizational ? "Tag" : "Competency"}
                  onsubmit={editcompetencySubmit}
                />

              </div>
            </div>
            <br />

            <div >
              {t("questionBank.Question Type")}
            </div>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={questionData.questionType}
                row
                className="flex align_center"
                name="questionType"
                onChange={(e) => {
                  setQuestionData({
                    ...questionData,
                    questionType: e.target.value,
                  });
                  resetErrorFields();
                }}
              >
                {QUESTION_TYPE.map((res, key) => {
                  return (
                    <FormControlLabel
                      key={key}
                      value={res}
                      control={
                        <Radio
                          disabled={disabled}
                          sx={{
                            "&, &.Mui-checked": {
                              color: "rgba(82, 96, 109, 1)",
                            },
                          }}
                        />
                      }
                      label={res}
                    />
                  );
                })}
                <Tooltip
                  arrow
                  componentsProps={{
                    tooltip: {
                      sx: {
                        padding: "10px",
                        lineHeight: "15px",
                        fontSize: "13px",
                        boxShadow: "0px 0px 5px black",
                        minWidth: "30rem",
                      },
                    },
                  }}
                  title="First Option - Most Preferred | Last Option - Least Preferred"
                  placement="right"
                >
                  <InfoIcon sx={{ color: "grey" }} className="pointer" />
                </Tooltip>
              </RadioGroup>
            </FormControl>

            <div>
              {OPTIONS_TAGS.map((res, key) => {
                return (
                  <Chip
                    onClick={() => optionTagClick(res)}
                    key={key}
                    className={style.option_tag}
                    label={res.label}
                  />
                );
              })}
            </div>

            {questionData.options.map((opt, key) => {
              return (
                <div
                  key={key}
                  className={`${style.options_con} ${style.max_width_70}`}
                >
                  <div className={style.items_con}>
                    <div className={style.left}>
                      <TextField
                        error={invalidFields.options[key]?.name}
                        fullWidth
                        disabled={disabled}
                        sx={{
                          ...inputFieldStyle,
                        }}
                        label={`${t("questionBank.Option")} ${key + 1}`}
                        value={questionData.options[key].name}
                        onChange={(e) => {
                          let arr = { ...questionData };
                          let options = arr.options.map((option, index) => ({
                            ...option,
                            name:
                              index === key
                                ? e.target.value === ""
                                  ? ""
                                  : e.target.value
                                : option.name,
                          }));
                          setQuestionData({ ...arr, options });
                          resetErrorFields();
                        }}
                      />
                    </div>
                    {/* {questionData.questionType === "Weightage" ?
                                            <div className={style.center}>
                                                <FormControl>
                                                    <OutlinedInput
                                                        id="outlined-adornment-weight"
                                                        endAdornment={<InputAdornment position="end">%  </InputAdornment>}
                                                        aria-describedby="outlined-weight-helper-text"
                                                        inputProps={{
                                                            'aria-label': 'weight',
                                                        }}
                                                        error={invalidFields?.options[key]?.weightage}
                                                        disabled={disabled}
                                                        fullWidth
                                                        type="number"
                                                        value={questionData?.options[key]?.weightage}
                                                        sx={{ ...inputFieldStyle }}
                                                        onChange={(e) => {
                                                            if (Number(e.target.value) <= 100) {
                                                                setQuestionData(pre => {
                                                                    let arr = { ...pre }
                                                                    arr.options[key].weightage = Number(e.target.value)
                                                                    return arr
                                                                })
                                                            }
                                                            resetErrorFields()
                                                        }}
                                                    />
                                                </FormControl>
                                            </div> : null} */}

                    <div className={style.right}>
                      {questionData.options.length > 2 ? (
                        <DeleteButon
                          disabled={disabled}
                          type="button"
                          onDelete={() => removeOption(key)}
                        />
                      ) : null}
                    </div>

                  </div>
                </div>
              );
            })}
            <br />

            {questionData.options.length < 5 && !disabled ? (
              <>
                <div className="flex">
                  <div
                    className={`flex pointer ${style.add_more}`}
                    onClick={addOption}
                  >
                    <div>
                      <AddCircle />
                      &nbsp;&nbsp;
                    </div>
                    <div>{t("questionBank.Add More")}</div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
          <br />
          <div className="end">
            <div>
              <button type="submit" className="btn-primary">
                {disabled
                  ? t("Edit")
                  : mode === "edit"
                    ? t("Update")
                    : t("Submit")}
              </button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <button
                type="button"
                className="btn-secondary"
                onClick={onCancel}
              >
                {" "}
                {t("Cancel")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}