import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit"
import axios from "../../config/axiosInterceptor"
import { toastaction } from "./ToastSlice"
import { stopLoader } from "./LoaderSlice"
import { BASE_URL, API } from "../../api/api"
import _ from "underscore"

let initialState = {
    allCompetencies: [],
    loading: false
}

export const getAllCompetencies = createAsyncThunk("getAllCompetencies", async (d, { rejectWithValue, ...thunkapi }) => {
    try {
        let res = await axios.get(`${BASE_URL}${API.getAllCompetencies}`)
        return res?.data?.response || []
    }
    catch (err) {
        thunkapi.dispatch(toastaction({ type: "error", msg: 'Some Error Occured While Getting Competencies' }))
        return rejectWithValue({ msg: err })
    }
})
export const createCompetency = createAsyncThunk("createCompetency", async (data, { rejectWithValue, ...thunkapi }) => {

    try {
        let res = await axios.post(`${BASE_URL}${API.createCompetency}`, data)
        thunkapi.dispatch(toastaction({ type: "success", msg: 'Competency Created Sucessfully !' }))
        thunkapi.dispatch(stopLoader())
        return ({ ...data, _id: res.data.competencyId })
    }
    catch (err) {
        thunkapi.dispatch(toastaction({ type: "error", msg: err.response?.data?.error }))
        thunkapi.dispatch(stopLoader())
        return rejectWithValue(err)
    }
})
export const editCompetency = createAsyncThunk("editCompetency", async (data, { rejectWithValue, ...thunkapi }) => {

    try {
        let response = await axios.put(`${BASE_URL}${API.editCompetencies}`, data)
        return { data, response: response.data }
    }
    catch (err) {
        thunkapi.dispatch(toastaction({ type: "error", msg: 'Some Error Occured While Editing Competency' }))
        thunkapi.dispatch(stopLoader())
        return rejectWithValue(err)
    }
})
export const deleteCompetency = createAsyncThunk("deleteCompetency", async (data, { rejectWithValue, ...thunkapi }) => {
    try {
        let response = await axios.post(`${BASE_URL}${API.deleteCompetencies}`, data)
        return { data: _.difference(data.competencyIds, response.data.skippedIds), response: response.data }

    }
    catch (err) {
        // console.error(err)
        thunkapi.dispatch(toastaction({ type: "error", msg: err.response?.data?.error }))
        thunkapi.dispatch(stopLoader())
        return rejectWithValue(err.response?.data)
    }
})



export const questionCompetencySlice = createSlice({
    name: 'questionCompetency',
    initialState,
    reducers: {
        emptyCompetencyState: (state, action) => {
            return initialState
        },
        addCompetencies: (state, { payload }) => {
            return ({ allCompetencies: [...state.allCompetencies, ...payload], loading: false })
        }
    },
    extraReducers: (builder) => {
        // {{{ ==========  get all competencies events ==============}}}
        builder.addCase(getAllCompetencies.pending, (state) => {
            return ({ ...state, loading: true })
        })
        builder.addCase(getAllCompetencies.fulfilled, (state, action) => {
            return ({ allCompetencies: action.payload, loading: false })
        })
        builder.addCase(getAllCompetencies.rejected, (state) => {
            return initialState
        })
        // {{{ ==========  Add competencies events ==============}}}
        builder.addCase(createCompetency.fulfilled, (state, action) => {
            return ({ loading: false, error: false, allCompetencies: [...state.allCompetencies, action.payload] })
        })
        builder.addCase(createCompetency.pending, (state, action) => {
            return ({ ...state, loading: true, error: false })
        })
        builder.addCase(createCompetency.rejected, (state, action) => {
            return ({ ...state, loading: false, error: true })
        })
        // {{{ ==========  Edit competencies events ==============}}}
        builder.addCase(editCompetency.fulfilled, (state, action) => {

            let allCompetencies = [...current(state).allCompetencies]

            let data = allCompetencies.map((res) => {
                let matchinData = action.payload.data.find(res2 => res2._id === res._id)
                if (matchinData) {
                    return { ...res, name: matchinData.name }
                }
                else {
                    return res
                }
            })
            return ({ loading: false, error: false, allCompetencies: data })
        })
        builder.addCase(editCompetency.pending, (state, action) => {
            return ({ ...state, loading: true, error: false })
        })
        builder.addCase(editCompetency.rejected, (state, action) => {
            return ({ ...state, loading: false, error: true })
        })
        // {{{ ==========  Delete competencies events ==============}}}

        builder.addCase(deleteCompetency.fulfilled, (state, action) => {
            let alldata = [...current(state).allCompetencies]
            return ({ loading: false, error: false, allCompetencies: alldata.filter(e => !action.payload.data.includes(e._id)) })
        })
        builder.addCase(deleteCompetency.pending, (state, action) => {
            return ({ ...state, loading: true, error: false })
        })
        builder.addCase(deleteCompetency.rejected, (state, action) => {
            return ({ ...state, loading: false, error: true })
        })

    }
})

export let { emptyCompetencyState, addCompetencies } = questionCompetencySlice.actions