import { ExpandLess, ExpandMore } from '@mui/icons-material';
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import { EditButton } from '../../Buttons/EditButton';
import { DeleteButon } from '../../Buttons/DeleteButton';
import style from "../../../assets/css/components/competency.module.css"
import { deleteCompetency } from '../../../api/competency';
import { loader, toast } from '../../../utils';
import { Collapse } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const CompetencyPreviewCollapseTab = ({ data, onDeleteCompetency }) => {
    const { id } = useParams()
    const [collapse, setCollapse] = useState(false)
    let navigate = useNavigate();
    const toggleExpand = () => setCollapse(!collapse)
    const { t } = useTranslation()

    function editData() {
        navigate(`/competency/edit/${data._id}/${id}`)
    }

    async function deleteData(data) {
        try {
            loader.start()
            let res = await deleteCompetency(data._id)
            toast.success(res?.data?.message)
            onDeleteCompetency(data._id)
        }
        catch (err) {
            toast.error(err?.response?.data?.error)
        }
        finally {
            loader.stop()
        }
    }


    return (
        <div className={style.competency_preview_collapse_row}>
            <div className="between align_center">

                <div className="flex align_center">
                    <div className="expand_btn">
                        {collapse ? <ExpandMore onClick={toggleExpand} /> : <ExpandLess onClick={toggleExpand} />}
                    </div>
                    <div>
                        {data.name}
                    </div>
                </div>

                {/* {collapse &&
                    <div>
                        <EditButton
                            confirmation={"Edit"}
                            heading={"Edit"}
                            text={"Are you sure you want to Edit ?"}
                            data={data}
                            onClick={editData}
                        />&nbsp;&nbsp;
                        <DeleteButon
                            confirmation
                            heading={"Delete"}
                            text={"Are your sure your want to delete Competency ?"}
                            id={data}
                            onDelete={deleteData}

                        />
                    </div>
                } */}
            </div>
            <Collapse in={collapse} timeout="auto" unmountOnExit>
                <div className={`${style.category_label} text-secondary`}>{t("category.category")}:</div>
                <ul className={style.categories_list}>
                    <li>{data?.categoriesData?.name}</li>
                </ul>
            </Collapse>
        </div>
    )
}
