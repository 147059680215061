import React, { useEffect, useState } from 'react'
import style from "../../../assets/css/components/category.module.css"
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getallgroups } from '../../../store/Slices/GroupSlice';

import { Link, useLocation } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { getAllCompetencies } from '../../../api/competency';
import { CustomCheckbox } from '../../Inputs/CustomCheckbox';
import { toast } from '../../../utils';

export const CategoryForm = ({ data, mode, selectedCompetency, onSubmit }) => {
    const { t } = useTranslation();
    const groupId = new URLSearchParams(useLocation().search).get("groupId");

    const { control, handleSubmit, setValue, formState: { errors } } = useForm({
        defaultValues: { ...data }
    })

    let [allCompetecies, setAllCompetecies] = useState([])

    const [selectedCompetencies, setSelectedCompetencies] = useState([...selectedCompetency])

    let allGroups = useSelector(e => e.groupData.allGroups)
    const tokenStatus = useSelector((state) => state.tokenStatus);
    let [fetchCompetencyLoader, setFetchCompetencyLoader] = useState(false)

    let dispatch = useDispatch()
    useEffect(() => {
        if (allGroups.length === 0) {
            dispatch(getallgroups())
        }
    }, [dispatch, allGroups.length, tokenStatus])

    function formSubmit(e) {
        console.log(e)
        onSubmit(e)
    }
    useEffect(() => {
          // If mode is 'edit', append default competencies to the state
        if (mode === 'edit' && data.Competencies) {
            console.log(data.Competencies);
            setSelectedCompetencies(data.Competencies);
            const competenceyIds = data.Competencies.map((item) => item._id);
            setValue("competencyIds", competenceyIds)
        }

        getAllCompetency()
    }, [])
    async function getAllCompetency() {
        try {
            let res = await getAllCompetencies()
            setAllCompetecies(res?.data?.response || [])
        }
        catch (err) {
            toast.error(err?.response?.data?.error)
        }
    }

    const handleCompetencyChange = (newValue) => {
        const newSelectedCompetencies = newValue.map((e) => e._id);
    
        // Extracting _id from previous selected competencies
        const previousIds = selectedCompetencies.map((comp) => comp._id);
    
        // Finding unselected competency IDs
        const unselectedIds = previousIds.filter(
          (id) => !newSelectedCompetencies.includes(id)
        );
    
        setValue("unsetCompetencyIds", unselectedIds)
    
      };
    

    return (

        <form onSubmit={handleSubmit(formSubmit)}>
            <div className={style.category_form_con}>

                <div className={style.category_form_grids}>
                    <div className={style.category_form_group_input}>
                        <Controller
                            name="groupId"
                            control={control}
                            defaultValue={null}
                            rules={{ required: true }}
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <Autocomplete
                                        value={allGroups.find(d => d.id === value) || null}
                                        options={allGroups}
                                        disabled={groupId}
                                        fullWidth
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={t("Group Name")}
                                                variant="outlined"
                                                error={!!errors.groupId}
                                            // helperText={errors.groupId ? t("Group is required") : null}
                                            />
                                        )}
                                        onChange={(e, val) => {
                                            onChange(val ? val.id : null);
                                        }}
                                        renderOption={(props, option) => (
                                            <li {...props} key={option.id}>
                                                {option.name}
                                            </li>
                                        )}
                                    />
                                );
                            }}
                        />
                    </div>
                </div>

                <div className={style.category_dark_bg}>


                    <div className={style.category_name_field}>
                        <Controller
                            name='name'
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <TextField
                                        fullWidth
                                        className='bg_white w-100'
                                        error={errors?.name}
                                        label={t("category.categoryName")}
                                        value={value}
                                        onChange={onChange}
                                    />
                                )
                            }}
                        />
                    </div>

                    <div>
                        <Controller
                            name='description'
                            control={control}
                            className="w-100"
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <TextField
                                        fullWidth
                                        className='bg_white'
                                        onChange={onChange}
                                        label={t("Description")}
                                        value={value}
                                    />
                                )
                            }}
                        />
                    </div>

                    <div>
                        <Controller
                            name="competencyIds"
                            control={control}
                            defaultValue={null}
                            rules={{ required: false }}
                            render={({ field: { value, onChange } }) => {
                                // Filter competencies that do not have a categoryId set
                                const filteredCompetencies = allCompetecies.filter(competency => !competency.categoryId);

                                // Combine both arrays and remove duplicates (if needed)
                                const updatedFilteredCompetencies = [...filteredCompetencies, ...selectedCompetencies];
                                return (
                                    <Autocomplete
                                        multiple
                                        id="competency-select"
                                        className="bg-white"
                                        options={updatedFilteredCompetencies}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.name}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <CustomCheckbox
                                                    className="me-6"
                                                    checked={selected}
                                                    style={{ marginRight: 4 }}
                                                />
                                                {option.name}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label={t("competency.competency")}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <>
                                                            {fetchCompetencyLoader ? (
                                                                <CircularProgress
                                                                    color="inherit"
                                                                    size={20}
                                                                />
                                                            ) : null}
                                                            {params.InputProps.endAdornment}
                                                        </>
                                                    ),
                                                }}
                                                error={errors?.competencyIds}
                                            />
                                        )}
                                        value={updatedFilteredCompetencies.filter((d) => value.includes(d._id)) || []}
                                        onChange={(event, newValue) => {
                                            handleCompetencyChange(newValue);
                                            onChange(newValue.map((e) => e._id))
                                        }}
                                        renderTags={(tagValue, getTagProps) =>
                                            tagValue.map((option) => option.name).join("/")
                                        }
                                    />
                                );
                            }}
                        />
                    </div>




                </div>


                <br />
                <div className="end">
                    <button className="btn-primary" type='submit'>
                        {t(mode === "edit" ? "Update" : "Submit")}
                    </button>
                    &nbsp;&nbsp;
                    <Link to="/category">
                        <button className="btn-secondary" type='button'>
                            {t("Cancel")}
                        </button>
                    </Link>
                </div>
            </div>
        </form >


    )
}
