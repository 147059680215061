
import axios from "../config/axiosInterceptor";
import { API, BASE_URL } from "./api";



export const createCategory = async (data) => {
    return await axios.post(BASE_URL + API.createCategory, data)
}
export const getAllCategory = async () => {
    return await axios.get(BASE_URL + API.getAllCategories)
}
export const fetchCategoryById = async (id) => {
    return await axios.get(BASE_URL + API.fetchCategoryById + id)
}
export const fetchCategoriesByGroupId = async (groupId) => {
    return await axios.get(BASE_URL + API.fetchCategoriesByGroupId + groupId)
}
export const editCategories = async (body) => {
    return await axios.put(BASE_URL + API.editCategories, body)
}
export const editCategory = async (body) => {
    return await axios.put(BASE_URL + API.editCategory, body)
}
export const deleteCategory = async (id) => {
    return await axios.delete(BASE_URL + API.deleteCategory + id)
}