import style from "../../assets/css/views/questionBank.module.css"
import React, { useRef } from "react";
import { CreateQuestionApi } from "../../api/Question";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { loader, toast } from "../../utils";
import { QuestionForm } from "../../components/Common/QuestionBank/QuestionForm";
import { BreadCrum } from "../../components/Common/BreadCrum";
import { useTranslation } from "react-i18next";
import { ASSESSMENT_TYPES } from "../../constants/constants";
import { useDispatch } from "react-redux";
import { addCompetencies } from "../../store/Slices/QuestionCompetencySlice";

export const CreateQuestion = () => {
    let { t } = useTranslation()
    const { assessmentType } = useParams()
    let TagOrCompetencyIdsKey = assessmentType === ASSESSMENT_TYPES.organizational ? "tagIds" : "competencyIds"
    const dispatch = useDispatch()
    let questionData = useRef({
        question: '',
        questionType: 'Rating',
        assessmentType: '',
        status: true,
        options: [
            { name: '', weightage: 0 },
            { name: '', weightage: 0 }
        ],
        [TagOrCompetencyIdsKey]: [],
    })
    let navigate = useNavigate()



    async function addQuestionSubmit(data) {
        try {

            // console.log(data);

            if (assessmentType === ASSESSMENT_TYPES.organizational) {
                delete data.competencyIds
                delete data.newCompetencies
            }
            else {
                delete data.tagIds
                delete data.newTags
            }

            let options = addIndexIngToOptions(data.options)

            data.options = options;

            console.log(data)


            loader.start()


            let res = await CreateQuestionApi(data)

            // let newCompetencies = res?.data?.response?.newCompetencies || []
            // store new created competencies to store after question creation
            // if (newCompetencies.length > 0) {
            //     dispatch(addCompetencies(newCompetencies))
            // }
            loader.stop()
            toast.success(res?.data?.message)
            navigate(`/questionBank/${assessmentType}`);

        }
        catch (err) {
            toast.error(err?.response?.data?.error)
            loader.stop()
        }
    }
    let breadCrumData = [
        {
            text: t("questionBank.Question Bank"),
            path: `/questionBank/${assessmentType}`
        },
        {
            text: t(assessmentType)
        },
        {
            text: t("questionBank.Create Question"),
        },

    ]
    function addIndexIngToOptions(options) {
        return options.map((option, index) => {
            return { ...option, index: index + 1 }
        })
    }

    function onCancelClick() {
        navigate(`/questionBank/${assessmentType}`);
    }
    return (
        <>
            <div className={`${style.create_question_con} padding_22_44`}>
                <BreadCrum dataArr={breadCrumData} />
                <br />
                <QuestionForm mode="add" onCancel={onCancelClick} data={questionData.current} onSubmit={addQuestionSubmit} />
                <br />
            </div >
        </>
    )
}


