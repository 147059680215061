// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZRB5Qp0LF6i4XAGl8msl {
    background-color: white;
    margin-bottom: 8px;
    padding: 20px;

    .Fj35xLtH7CWNjCo6zbkp {
        color: rgba(62, 76, 89, 1);
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        margin-bottom: 20px;
    }
}`, "",{"version":3,"sources":["webpack://./src/assets/css/views/competency.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,aAAa;;IAEb;QACI,0BAA0B;QAC1B,eAAe;QACf,kBAAkB;QAClB,gBAAgB;QAChB,iBAAiB;QACjB,mBAAmB;IACvB;AACJ","sourcesContent":[".preview_competency_con {\r\n    background-color: white;\r\n    margin-bottom: 8px;\r\n    padding: 20px;\r\n\r\n    .heading {\r\n        color: rgba(62, 76, 89, 1);\r\n        font-size: 20px;\r\n        font-style: normal;\r\n        font-weight: 500;\r\n        line-height: 28px;\r\n        margin-bottom: 20px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"preview_competency_con": `ZRB5Qp0LF6i4XAGl8msl`,
	"heading": `Fj35xLtH7CWNjCo6zbkp`
};
export default ___CSS_LOADER_EXPORT___;
