import React from 'react';
import Chart from 'react-apexcharts';

export const CircularProgressBar = ({ value }) => {
    let state = {
        series: [value,],
        options: {
            grid: {
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                }
            },
            colors: ['#2D3A8C'],
            labels: [`${value} %`],
            plotOptions: {
                radialBar: {
                    startAngle: -90,
                    endAngle: 270,
                    // strokeWidth: '1%',
                    hollow: {
                        show: false,
                        background: "transparent",
                    },
                    dataLabels: {
                        name: {
                            show: false
                        },
                        value: {
                            show: false
                        }
                    },
                    track: {
                        show: true,
                        strokeWidth: '100%',
                    }
                },
            },

            legend: {
                show: true,
                floating: true,
                fontSize: '30px',
                fontWeight: 700,
                position: 'left',
                offsetX: 60,
                offsetY: 75,
                labels: {
                    useSeriesColors: false,
                    colors: ['#2D3A8C'],
                },
                markers: {
                    show: false,
                    width: 0,
                    height: 0,
                },
                itemMargin: {
                    vertical: 0,
                },
            },

        },
    };
    return (
        <div >
            <Chart options={state.options}
                series={state.series}
                type="radialBar" />
        </div>

    )
}
