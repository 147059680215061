import { Autocomplete, TextField } from "@mui/material";
import React from "react";

export const SearchAutoComplete = (props) => {
    return (
        <>

            <Autocomplete

                id="competency-select"
                className="bg-white w-100"
                // options={allCompetencyOrTagData}
                {...props}
                sx={{
                    '.MuiAutocomplete-inputRoot': {
                        minHeight: '30px',
                        alignItems: 'flex-start',

                    },
                    '.MuiAutocomplete-tag': {
                        lineHeight: '37px',

                    },
                    ...props.sx
                }}
                // disableCloseOnSelect
                // getOptionLabel={(option) => option.name}
                // renderOption={(props, option, { selected }) => (
                //     <li {...props}>
                //         <CustomCheckbox
                //             checked={selected}
                //             className="me-6"
                //         />
                //         {option.name}
                //     </li>
                // )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                            sx: {
                                height: "38px",
                                padding: '0 10px',
                            },
                        }}
                        inputProps={{
                            ...params.inputProps,
                            sx: {
                                height: '100%',
                                padding: '0 !important',
                                display: 'flex',
                                alignItems: 'center',

                            },
                        }}
                        InputLabelProps={{
                            sx: {
                                position: 'absolute',
                                top: '-8px',
                                left: '0px',
                                '&.MuiInputLabel-shrink': {
                                    top: '-10px',
                                    left: '15px',
                                    fontSize: '0.75rem',
                                    transform: 'translate(0, 0)',
                                },
                            },
                        }}

                        // label={t(isOrganizational ? "tag.tags" : "competency.competencies")}
                        label={props.label}
                    />
                )}
            // value={selectedCategories}
            // onChange={handleCompetencyOrTagChange}
            // renderTags={(tagValue, getTagProps) => (
            //     <span className={`${style.autocomplete_wrapper}`}>
            //         {tagValue.map((option) => option.name).join(' / ')}
            //     </span>
            // )}

            />



        </>
    )
}