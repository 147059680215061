import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import style from "../../../assets/css/components/group.module.css";
import { CustomInput } from "../../Inputs/CustomInput";
import { UserListing } from "./UsersListing";
import { useDispatch, useSelector } from "react-redux";
import { getallgroups } from "../../../store/Slices/GroupSlice";
import { useLocation, useNavigate } from "react-router";
import { toast } from "../../../utils";
import { validateForm } from "../../../utils/formValidationCheck";
import { useTranslation } from "react-i18next";
import { TableContainer, TableHeader } from "../TableContainer";
import { SearchInput } from "../../Inputs/SearchInput";

export const SubGroupForm = ({
  data,
  onSubmit,
  setUpdatedFields,
  groupDisable,
  mode,
}) => {
  let { t } = useTranslation();
  let [formData, setFormData] = useState({ ...data });
  let dispatch = useDispatch();
  let allGroups = useSelector((e) => e.groupData.allGroups);
  let navigate = useNavigate();
  let [validation, setValidation] = useState({
    groupId: false,
    name: false,
    members: false,
  });
  const groupId = new URLSearchParams(useLocation().search).get("groupId");
  const type = new URLSearchParams(useLocation().search).get("type");
  useEffect(() => {
    if (allGroups.length === 0) {
      dispatch(getallgroups());
    }
  }, [dispatch, allGroups.length]);

  function cancelClick() {
    switch (mode) {
      case "recreate":
        navigate(`/group/viewSubgroup/${groupId}`);
        break;
      case "create":
        navigate(`/group`);
        break;
      case "edit":
        let group = allGroups.find((e) => e.id === formData.groupId);
        navigate(`/group/viewSubgroup/${group.id}`);
        break;
      default:
        break;
    }
  }
  function toastErrorMessages(field) {
    switch (field) {
      case "groupId":
        return "Please select a group !";
        break;
      case "name":
        return "Please fill Sub-Group name !";
        break;
      case "members":
        return "Please select atleast one member !";
        break;

      default:
        return "Please fill all fields !";
    }
  }

  function formSubmit() {
    let formValidation = validateForm(["groupId", "name", "members"], formData);
    setValidation(formValidation.data);
    if (formValidation.isFormValid) {
      onSubmit(formData);
    } else {
      toast.error(toastErrorMessages(formValidation.errorFields[0]));
    }
  }
  function resetErrorFields() {
    setValidation({
      groupId: false,
      name: false,
      members: false,
    });
  }

  return (
    <>
      <div className={style.subGroupForm_con}>
        <div>
          <Autocomplete
            value={
              formData.groupId
                ? allGroups.length !== 0
                  ? allGroups.find((e) => e.id === parseInt(formData.groupId))
                  : null
                : null
            }
            options={allGroups}
            sx={{ maxWidth: "400px" }}
            disabled={groupDisable || type}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("Group Name")}
                variant="outlined"
                error={validation.groupId}
              />
            )}
            onChange={(e, val) => {
              resetErrorFields();
              setFormData({ ...formData, groupId: val && val.id, members: [] });
              //used for update subgroup
              setUpdatedFields &&
                setUpdatedFields((pre) => ({ ...pre, groupId: val.id }));
            }}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              );
            }}
          />
        </div>
        <br />
        <div>
          <div className={`${style.sub_group_fields}`}>
            <div className={style.topFields}>
              <div>
                <TextField
                  error={validation.name}
                  className="w-100"
                  label={t("Sub Group")}
                  value={formData.name}
                  disabled={type === "preview"}
                  onChange={(e) => {
                    resetErrorFields();
                    //used for update subgroup
                    setUpdatedFields &&
                      setUpdatedFields((pre) => ({
                        ...pre,
                        name: e.target.value,
                      }));
                    setFormData((pre) => {
                      pre.name = e.target.value;
                      return { ...pre };
                    });
                  }}
                />
              </div>
              <div>
                <TextField
                  error={validation.members}
                  value={formData.members.length}
                  onChange={() => { }}
                  disabled
                  sx={{ width: 90 }}
                  label={t("Members")}
                />
              </div>
            </div>
            <div>
              <br />

              <TextField
                multiline
                fullWidth
                disabled={type === "preview"}
                onChange={(e) => {
                  //used for update subgroup
                  setUpdatedFields &&
                    setUpdatedFields((pre) => ({
                      ...pre,
                      description: e.target.value,
                    }));
                  setFormData((pre) => {
                    pre.description = e.target.value;
                    return { ...pre };
                  });
                  resetErrorFields();
                }}
                label={t("Description")}
                value={formData.description}
              />
            </div>
          </div>
          <br />

          <UserListing
            type={type}
            value={formData.members}
            onChange={(e) => {
              setFormData({ ...formData, members: e });
              setUpdatedFields &&
                setUpdatedFields((pre) => ({ ...pre, members: e }));
              resetErrorFields();
            }}
            groupId={formData.groupId}
          />
        </div>
      </div>
      <br />
      <div className="end">
        {type !== "preview" && (
          <>
            <button className="btn-primary" onClick={formSubmit}>
              {t(mode === "edit" ? "Update" : "Submit")}
            </button>
            &nbsp;&nbsp;
          </>
        )}
        <button className="btn-secondary" onClick={cancelClick}>
          {t("Cancel")}
        </button>
      </div>
    </>
  );
};
