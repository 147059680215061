import axios from "../config/axiosInterceptor";
import { API, BASE_URL } from "./api";




export const createDPD = async (body) => {
    return await axios.post(BASE_URL + API.createDPD, body)
}
export const editDPD = async (body) => {
    return await axios.put(BASE_URL + API.editDPD, body)
}
export const getDPDById = async (dpId) => {
    return await axios.get(BASE_URL + API.getDPDById + dpId)
}
export const getAllDPDByGroup = async (groupId) => {
    return await axios.get(BASE_URL + API.getAllDPDByGroup + groupId)
}
export const deleteDPD = async (dpId) => {
    return await axios.delete(BASE_URL + API.deleteDPD + dpId)
}
export const getAllDPDByJobRole = async (jobroleId) => {
    return await axios.get(BASE_URL + API.getAllDPDByJobRole + jobroleId)
}

