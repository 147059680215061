import { Controller } from "react-hook-form";
import { DeleteButon } from "../../Buttons/DeleteButton";
import React, { Fragment } from "react";
import style from "../../../assets/css/components/kpi&sicommon.module.css"
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

export const KPIFields = ({ control, fields, remove, allUsers, onDeleteKpi, fetchUsersLoader, errors, prefix }) => {

    let { t } = useTranslation()
    let { id } = useParams()

    // console.log(errors)
    return (
        <Fragment>
            
            {fields.map((field, index) => {
                console.log(errors)
                return (

                    <Fragment key={field.id}> {/* Use `field.id` as the key */}
                        {/* KPI Name Field */}
                        <Controller
                            control={control}
                            rules={{ required: true }}
                            name={`${prefix}.${index}.name`}
                            defaultValue={field.name}
                            render={({ field }) => {
                                return (
                                    <TextField
                                        className="bg_white"
                                        {...field}
                                        label={t("KPI.KPI")}
                                        error={!!errors?.[prefix]?.[index]?.name}
                                        variant="outlined"
                                        fullWidth
                                    />
                                )
                            }}
                        />

                        {/* Owner Field */}
                        <Controller
                            control={control}
                            name={`${prefix}.${index}.owner`}
                            defaultValue={field.owner}
                            rules={{ required: true }}
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <Autocomplete
                                        value={allUsers.find(d => d?.userId == value?.userId) || null}
                                        options={allUsers}
                                        className="bg_white"
                                        getOptionLabel={(option) => `${option.email}`}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <>
                                                            {fetchUsersLoader ? <CircularProgress color="inherit" size={20} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </>
                                                    ),
                                                }}
                                                label={t("SI.Owner")}
                                                variant="outlined"
                                                error={!!errors?.[prefix]?.[index]?.owner}
                                            />
                                        )}
                                        onChange={(e, val) => {
                                            console.log(val);
                                            onChange(val);
                                        }}
                                        renderOption={(props, option) => (
                                            <li {...props} key={option.userId}>
                                                {`${option.email}`}
                                            </li>
                                        )}
                                    />
                                )
                            }
                            }
                        />

                        {/* Supervisor Field */}
                        <Controller
                            control={control}
                            name={`${prefix}.${index}.supervisor`}
                            defaultValue={field.supervisor}
                            rules={{ required: true }}
                            render={({ field: { value, onChange } }) => (
                                <Autocomplete
                                    value={allUsers.find(d => d?.userId === value?.userId) || null}
                                    options={allUsers}
                                    className="bg_white"
                                    getOptionLabel={(option) => `${option.email}`}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {fetchUsersLoader ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                ),
                                            }}
                                            label={t("SI.supervisor")}
                                            variant="outlined"
                                            error={!!errors?.[prefix]?.[index]?.supervisor}
                                        />
                                    )}
                                    onChange={(e, val) => {
                                        onChange(val);
                                    }}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.userId}>
                                            {`${option.email}`}
                                        </li>
                                    )}
                                />
                            )}
                        />
                        <DeleteButon type="button" onClick={() => {
                            // store _id of deleted kpi only for old values which are store in keyPerformanceIndicators array
                            if (prefix === "keyPerformanceIndicators") {
                                onDeleteKpi(field)
                            }
                            remove(index)
                        }} />
                    </Fragment>
                )
            })}
        </Fragment>
    );
};