import React, { useEffect, useRef, useState } from "react";
import { CustomRadio } from "../../components/Inputs/CustomRadio";
import style from "../../assets/css/views/assessment.module.css";
import { Footer } from "../../components/Footer/Footer";
import { FormControl, RadioGroup } from "@mui/material";
import { UserHeader } from "../../components/UserHeader/UserHeader";
import { SurveyCompleteMsg } from "../../components/Common/Assessment/SurveyCompleteMsg";
import { FormClosed } from "../../components/Common/FormClosed";
import { getSurveyFormById, saveSurveyResponse } from "../../api/Assessment";
import { currentUser, loader, logout, toast } from "../../utils";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next";

export const Assessments = () => {
  const tokenStatus = useSelector((state) => state.tokenStatus);
  let params = useParams();
  let { t } = useTranslation()
  let [assessmentData, setAssessmentData] = useState({});
  let [isLoading, setIsLoading] = useState(true);
  let surveyQuestionsData = useRef({});
  let [formSubmitted, setFormSubmitted] = useState(false);
  let navigate = useNavigate()

  let [assessmentFetchErrors, setAssessmentFetchErrors] = useState({
    error: false,
    errorMessage: "",
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchAssessment = async () => {
    loader.start();
    try {
      let data = {
        userId: currentUser().user.id,
        assessmentId: params.id,
      };
      let res = await getSurveyFormById(data);
      setAssessmentData(res.data.response);
      setIsLoading(false);
      if (!res.data.response.questions) {
        setAssessmentFetchErrors({
          error: true,
          errorMessage: res.data.message,
        });
      } else {
        setAssessmentFetchErrors({
          error: false,
          errorMessage: '',
        });
      }
      if (
        res.data.response.userResponse &&
        res.data.response.userResponse !== null &&
        res.data.response.userResponse.status === "In Progress"
      ) {
        res.data.response.userResponse.assessmentResponse.forEach((res) => {
          fillOption(res.questionId, res.selectedOptionId);
        });
      }
    } catch (err) {
      loader.stop();
      setAssessmentFetchErrors({
        error: true,
        errorMessage: err?.response?.data?.error,
      });
    } finally {
      loader.stop();
    }
  };

  useEffect(() => {
    fetchAssessment();
  }, [tokenStatus]);

  function fillOption(questionId, optionId) {
    surveyQuestionsData.current[questionId] = optionId;
  }

  async function formSubmit(status) {
    // e.preventDefault()
    loader.start();
    try {
      const assessmentResponse = Object.entries(
        surveyQuestionsData.current
      ).map(([questionId, selectedOptionId]) => ({
        questionId,
        selectedOptionId,
      }));
      if (
        assessmentData.questions.length !== assessmentResponse.length &&
        status === "Completed"
      ) {
        toast.error("Please answer all questions");
        loader.stop();
        return;
      }


      let body = {
        responseId: assessmentData.userResponse._id,
        assessmentResponse,
        submittedDate: new Date().toISOString(),
        status: status,
      };


      let res = await saveSurveyResponse(body);
      if (status === "Completed") {
        setFormSubmitted(true);
      } else {
        toast.success(res.data.message);
      }
      loader.stop();
    } catch (err) {
      loader.stop();
      toast.error(err?.response?.data?.error);
    }
  }

  return (
    <>
      <div className={`${style.fillAssessment_con}`}>
        <UserHeader assessmentData={assessmentData} />
        <div className={style.assessment_middle_con}>
          {!assessmentFetchErrors.error ? (
            <>
              {!formSubmitted ? (
                <div className={style.question_main_con}>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      formSubmit("Completed");
                    }}
                  >
                    <div className={style.question_scroll_part}>
                      {assessmentData?.questions &&
                        assessmentData?.questions?.map((res, ind) => {
                          return (
                            <div key={res._id} className={style.question_con}>
                              <div className={style.header}>
                                {" "}
                                {ind + 1}. {res.question}
                              </div>
                              <div>
                                <FormControl>
                                  <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="type"
                                    value={surveyQuestionsData.current[res._id]}
                                  >
                                    {res.options.map((option, key) => {
                                      return (
                                        <div
                                          key={option._id}
                                          className={style.option}
                                        >
                                          <CustomRadio
                                            onChange={() =>
                                              fillOption(res._id, option._id)
                                            }
                                            key={option._id}
                                            value={option._id}
                                          />
                                          {option.name}
                                        </div>
                                      );
                                    })}
                                  </RadioGroup>
                                </FormControl>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    <br />
                    <div className="end">
                      {!isLoading && (
                        <>
                          <button
                            className="btn-primary"
                            type="button"
                            onClick={() => formSubmit("In Progress")}
                          >
                            {t("Save to Drafts")}
                          </button>
                          &nbsp;&nbsp;&nbsp;
                          <button className="btn-primary" type="submit">
                            {t("Submit")}
                          </button>
                          &nbsp;&nbsp;&nbsp;
                        </>
                      )}
                    </div>
                  </form>
                </div>
              ) : (
                <div className={style.complete_msg}>
                  <SurveyCompleteMsg userName={currentUser().user?.username} />
                </div>
              )}
            </>
          ) : (
            <div className={style.complete_msg}>
              <FormClosed text={assessmentFetchErrors.errorMessage} />
              <div className="center">
                <div className="body_1" style={{ zIndex: "999" }}>
                  {t("Assessment.loginAgainMsg")}. <Link onClick={() => logout(navigate)} to={"/"}>{t("Click here")}</Link>
                </div>
              </div>
            </div>
          )}
        </div>

        <Footer />
      </div>
    </>
  );
};
