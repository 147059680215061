import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { loader, toast } from '../../utils'
import { BreadCrum } from '../../components/Common/BreadCrum'
import { useTranslation } from 'react-i18next'
import { fetchCompetencyById } from '../../api/competency'
import { assignCompetency } from '../../api/competency'
import { AssignCompetencyForm } from '../../components/Common/Competency/AssignCompetencyForm'

export const EditCompetency = () => {

    const { id, groupId } = useParams()
    const [competencyData, setCompetenyData] = useState({})
    const { t } = useTranslation();
    const navigate = useNavigate()
    useEffect(() => {
        fetchData()
    }, [])

    async function fetchData() {
        try {
            loader.start()
            let res = await fetchCompetencyById(id, groupId)
            let data = res.data.response
            const categoryIds = data?.categoriesData.map(category => category._id)

            const formData = {
                competencyId: data._id,
                groupId: data.groupId,
                categoryIds: categoryIds
            }
            setCompetenyData(formData)

        } catch (error) {
            toast.error(error?.response?.data?.error)
        }
        finally {
            loader.stop()
        }
    }

    const breadCrumData = [
        {
            text: t("competency.competency"),
            path: "/competency"
        },
        {
            text: t("Edit"),
        },
    ]
    async function handleEditCompetency(data) {
        try {
            loader.start()

            let body = {
                competencyId: data.competencyId,
                groupId: data.groupId,
                categoryIds: data.categoryIds
            }
            let res = await assignCompetency(body)
            toast.success(res?.data?.message)
            navigate("/competency")

        } catch (error) {
            toast.error(error?.response?.data?.error)
        }
        finally {
            loader.stop()

        }
    }


    return (
        <div className="padding_22_44">
            <BreadCrum dataArr={breadCrumData} />
            <br />
            {competencyData?.groupId &&
                <AssignCompetencyForm mode={"edit"} data={competencyData} onSubmit={handleEditCompetency} />}
        </div>
    )
}
