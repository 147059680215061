import React, { Fragment, useEffect, useState } from "react";
import { EditButton } from "../../Buttons/EditButton";
import { Box, Dialog, Divider, LinearProgress, useForkRef } from "@mui/material";
import style from "../../../assets/css/components/questionBank.module.css";
import { CrossButton } from "../../Buttons/CrossButton";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCompetency,
} from "../../../store/Slices/QuestionCompetencySlice";
import { loader, toast } from "../../../utils";
import { SearchInput } from "../../Inputs/SearchInput";
import { DeleteButon } from "../../Buttons/DeleteButton";
import { CustomCheckbox } from "../../Inputs/CustomCheckbox";
import { LoadingButton } from "../../Buttons/LoadingButton";
import _ from "underscore";
import { useTranslation } from "react-i18next";
import { deleteTags, editTag, getAllTags } from "../../../api/tag";
import { deleteCompetencies, editCompetency, getAllCompetencies } from "../../../api/competency";
import { AddCompetencyOrTag } from "./AddCompetencyOrTag";

export const EditCompetencyOrTag = ({ onsubmit, usedFor }) => {

  // usedFor can be "Tag" of "Competency"
  let isTag = usedFor === "Tag"
  const [formData, setFormData] = useState({})

  let [dialog, setDialog] = useState(false);
  let [comptencyFormDialog, setCompetencyFormDialog] = useState(false)

  useEffect(() => {
  }, [comptencyFormDialog])

  let [loader, setLoader] = useState(false)

  let [allCompetenciesOrTag, setAllCompetenciesOrTag] = useState([])

  let dispatch = useDispatch();

  let [selectedCompetencyOrTags, setSelectedCompetencyOrTags] = useState([]);

  let [btnLoader, setBtnLoader] = useState(false);

  // let [allCompetencyOrTagData, setAllCompetencyOrTagData] = useState([]);
  let [allCompetencyOrTagData, setAllCompetencyOrTagData] = useState([]);
  let { t } = useTranslation()

  useEffect(() => {
    if (dialog) {
      if (allCompetencyOrTagData.length === 0) {
        getAllCompetenciesOrTag()
      }
    }
  }, [dialog])


  async function getAllCompetenciesOrTag() {
    try {
      setLoader(true)
      if (usedFor === "Tag") {
        // if assesment type is organizational then get all tags for seaarching dropdown 
        let res = await getAllTags()
        let tagData = res?.data?.response || []
        setAllCompetenciesOrTag(tagData)
        setAllCompetencyOrTagData(
          tagData.map((e) => ({ ...e, type: "", visible: true }))
        );
      }
      else {
        // else get all competencies for searching dropdown 
        let res = await getAllCompetencies()
        let competencyData = res?.data?.response || []
        setAllCompetenciesOrTag(competencyData)
        setAllCompetencyOrTagData(
          competencyData.map((e) => ({ ...e, type: "", visible: true }))
        );

      }
    }
    catch (err) {
      console.error(err);
      toast.error(err.response?.data?.error || "Some Error Occured!");
    }
    finally {
      setLoader(false)
    }
  }

  // async function formSubmit(e) {
  //   e.preventDefault();

  //   let obj = { edit: [], delete: [] };

  //   allCompetencyOrTagData.forEach((res) => {
  //     if (res.type !== "") {
  //       obj[res.type].push({ name: res.name, _id: res._id });
  //     }
  //   });



  //   if (_.size(obj["edit"]) === 0 && _.size(obj["delete"]) === 0) {
  //     toast.error("Please Delete or Edit any Competency !");
  //     return;
  //   }

  //   try {
  //     setBtnLoader(true);
  //     let toastData = "";

  //     if (_.size(obj.edit) > 0) {
  //       // edit data 

  //       if (usedFor === "Tag") {
  //         // edit Tag if used for is Tag
  //         let res = await editTags(obj.edit)
  //         onsubmit({ editData: obj.edit });
  //         if (!res?.payload?.error) {
  //           let catLength = obj.edit.length;
  //           toastData = `${catLength} ${catLength > 1 ? "Tags" : "Tag"
  //             } Updated `;
  //           toast.success(toastData);
  //         }
  //       }
  //       else {
  //         // edit competency if usedFor is competency 

  //         let res = await editCompetency(obj.edit)
  //         console.log(obj.edit)
  //         console.log(res)
  //         if (!res.payload.error) {
  //           let catLength = obj.edit.length;
  //           toastData = `${catLength} ${catLength > 1 ? "Competencies" : "Competency"
  //             } Updated `;
  //           toast.success(toastData);
  //         }
  //       }


  //     }
  //     if (_.size(obj.delete) > 0) {
  //       let deletedData = { [usedFor === "Tag" ? "tagIds" : "competencyIds"]: obj.delete.map((e) => e._id) };


  //       let res;

  //       if (usedFor === "Tag") {
  //         let response = await deleteTags(deletedData)
  //         res = { data: _.difference(deletedData[usedFor === "Tag" ? "tagIds" : "competencyIds"], response.data.skippedIds), response: response.data }
  //       }
  //       else {
  //         let response = await deleteCompetencies(deletedData)
  //         res = { data: _.difference(deletedData[usedFor === "Tag" ? "tagIds" : "competencyIds"], response.data.skippedIds), response: response.data }
  //       }
  //       if (!res?.error) {
  //         let deletedCount = res?.response?.deletedCount;
  //         // remove deleted category from question (new creation in progress), if we delete category which is present on new question
  //         onsubmit(res.data);
  //         onsubmit({ deleteData: res.data });
  //         toastData += ` ${deletedCount === 0 ? "No" : deletedCount}
  //                          ${deletedCount > 1 ? usedFor === "Tag" ? "Tags" : "Competencies" : usedFor === "Tag" ? "Tag" : "Competency"} Deleted`;

  //         toast.success(toastData);
  //       }
  //       else {
  //         setAllCompetencyOrTagData((pr) =>
  //           pr.map((ee) => {
  //             return { ...ee, type: "" };
  //           })
  //         );
  //       }

  //     }

  //     setDialog(false);
  //     setSelectedCompetencyOrTags([]);
  //     setBtnLoader(false);
  //   } catch (err) {
  //     // loader.stop();
  //     toast.error(err.response?.data?.error || "Some Error Occured!");
  //     setBtnLoader(false);
  //     setAllCompetencyOrTagData((pr) =>
  //       pr.map((ee) => {
  //         return { ...ee, type: "" };
  //       })
  //     );
  //   }
  // }
  function editClick() {
    setDialog(true);
  }

  function search(e) {

    setAllCompetencyOrTagData((pre) => {
      return pre.map((res) => {
        if (res?.name?.toLowerCase().includes(e?.trim()?.toLowerCase())) {
          return { ...res, visible: true };
        } else {
          return { ...res, visible: false };
        }
      });
    });
  }


  // function checkBoxChange(checked, data) {
  //   checked
  //     ? setSelectedCompetencyOrTags([...selectedCompetencyOrTags, data])
  //     : setSelectedCompetencyOrTags((prev) =>
  //       prev.filter((val) => val._id !== data._id)
  //     );
  // }

  // function editCategoryIconClick(e, index, id) {
  //   setAllCompetencyOrTagData((pre) => {
  //     pre[index].type = "edit";
  //     return [...pre];
  //   });
  // }

  function categoryChange(e, index) {
    setAllCompetencyOrTagData((prev) => {
      prev[index].name = e.target.value;
      return [...prev];
    });
  }

  // async function deleteCategories() {
  //   setAllCompetencyOrTagData((pr) =>
  //     pr.map((ee) => {
  //       if (selectedCompetencyOrTags.findIndex((s) => ee._id === s._id) !== -1) {
  //         return { ...ee, type: "delete" };
  //       } else {
  //         return { ...ee };
  //       }
  //     })
  //   );
  //   setSelectedCompetencyOrTags([]);
  // }


  function resetData() {
    setAllCompetencyOrTagData(
      allCompetenciesOrTag.map((e) => ({ ...e, type: "", visible: true }))
    );
  }

  const handleCloseDialog = () => {
    // Add any additional actions you want to perform on dialog close
    setDialog(false);
    resetData()
  };

  async function onEditSubmit(data) {
    try {

      if (isTag) {
        // update tag  
        let res = await editTag(data)


      }
      else {
        // update competency 

        let res = await editCompetency(data)
      }
    }
    catch (err) {
      console.log(err)
    }
    // move this onSubmit inside try block after fix from backend because now backend gives error on updation also
    onsubmit(data)

    setAllCompetencyOrTagData(pre => pre.map(res => res._id == data._id ? { ...res, ...data } : res))
    setAllCompetenciesOrTag(pre => pre.map(res => res._id == data._id ? { ...res, ...data } : res))

    // handle edit competency or tag 

  }
  return (
    <>
      <EditButton type="button" onClick={editClick} />

      <AddCompetencyOrTag
        mode="edit"
        onClose={() => {
          search("")
          setCompetencyFormDialog(false)
          setDialog(true)
        }}
        dialogOpen={comptencyFormDialog}
        setDialogOpen={setCompetencyFormDialog}
        data={formData}
        onAddCompetency={onEditSubmit}
      />


      <Dialog
        maxWidth={"sm"}
        fullWidth
        open={dialog}
        onClose={handleCloseDialog}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        className={style.editCategory_dialog}
      >

        <Box>
          <div className={`dialog_header between`}>
            <div>{t(isTag ? "tag.editTag" : "competency.editCompetency")}</div>
            <div
              onClick={handleCloseDialog}
              className={`pointer ${style.heading} `}
            >
              <CrossButton />
            </div>
          </div>
          <div className={style.subheading}>
            {t(isTag ? "tag.editTagLabel" : "competency.editCompetencyLabel")}
          </div>
          <Divider color="#E0E8F9" sx={{ borderBottomWidth: 1 }} />

          <Box p={4}>
            <div className={style.action_container}>
              <div className="between align_center">
                <div>
                  <SearchInput placeholder={"Search"} onChange={(e) => search(e.target.value)} />
                </div>
                <div>
                  {/* <DeleteButon
                    deletedItem={"Selected Competency"}
                    disabled={selectedCompetencyOrTags.length === 0}
                    onDelete={deleteCategories}
                  /> */}
                </div>
              </div>
            </div>

            <div className={style.allCategory_listing_con}>
              {loader ? <LinearProgress /> :
                <div>

                  {allCompetencyOrTagData
                    .filter((r) => r.visible)
                    .map((res, key) => {
                      if (res.type !== "delete") {
                        return (
                          <div
                            key={key}
                            className={`between align_center ${style.single_category} `}
                          >
                            <div className="flex align_center">
                              {/* <CustomCheckbox

                                checked={
                                  selectedCompetencyOrTags.findIndex(
                                    (e) => e.name === res.name
                                  ) !== -1
                                }
                                onChange={(e) =>
                                  checkBoxChange(e.target.checked, res)
                                }
                                className="me-6"
                              /> */}
                              {res.type === "edit" ? (
                                <div>
                                  <input
                                    onChange={(e) => categoryChange(e, key)}
                                    type="text"
                                    value={res.name}
                                  />
                                </div>
                              ) : (
                                <span>{res.name}</span>
                              )}
                            </div>
                            <EditButton onClick={() => {
                              setDialog(false)

                              setCompetencyFormDialog(true)
                              setFormData(res)
                            }} />


                          </div>
                        );
                      } else {
                        return <Fragment key={key}></Fragment>;
                      }
                    })}
                  {allCompetencyOrTagData.every(
                    (e) => !e.visible || e.type === "delete"
                  ) ? (
                    <div className={style.noData_text}>
                      {t(isTag ? "tag.noTag" : "competency.noCompetency")}...
                    </div>
                  ) : null}
                </div>
              }

            </div>

            <div className={`end`}>

              &nbsp;&nbsp;&nbsp;
              {/* <LoadingButton
                disabled={
                  !allCompetencyOrTagData.some(
                    (e) => e.type === "edit" || e.type === "delete"
                  )
                }
                loader={btnLoader}
                onClick={formSubmit}
                label={"Update"}
              /> */}
              &nbsp;&nbsp;&nbsp;
              <button
                type="button"
                className="btn-secondary"
                onClick={handleCloseDialog}
              >
                Cancel
              </button>
            </div>
          </Box>
        </Box>



      </Dialog >
    </>
  );
};
