import React, { useEffect, useState } from "react"
import { BreadCrum } from "../../components/Common/BreadCrum"
import { PreviewAssessmentSurvey } from "../../components/Common/Assessment/PreviewAssessmentSurvey"
import { useParams } from "react-router"
import { fetchAssessmentById } from "../../api/Assessment"
import { loader, toast } from "../../utils"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
export const PreviewAssessment = () => {
    const tokenStatus = useSelector((state) => state.tokenStatus);
    const { assessmentType } = useParams()
    let params = useParams()
    let [assessmentData, setAssessmentData] = useState({})
    let [noData, setNoData] = useState(false)

    let { t } = useTranslation()

    useEffect(() => {
        loader.start()
        getAssessmentData()
    }, [tokenStatus])

    async function getAssessmentData() {
        try {
            loader.start()
            setNoData(false)
            let res = await fetchAssessmentById(params.id)
            setAssessmentData(res.data.response)
            loader.stop()
        }
        catch (err) {
            loader.stop()
            toast.error(err?.response?.data?.error)
        }
    }

    let breadCrumData = [
        {
            text: t("Assessment.Assessment"),
            path: `/userAssessment/${assessmentType}`
        },
        {
            text: t(assessmentType),
            // path: `/userAssessment/${assessmentType}`
        },
        {
            text: t("Preview")
        },

    ]


    return (
        <div className="padding_22_44">
            <BreadCrum dataArr={breadCrumData} />
            <br />
            {!noData ?
                <PreviewAssessmentSurvey previewData={assessmentData} /> :
                <div className="body_1">No Data Available..</div>}
        </div>

    )
}