import React, { useEffect } from 'react';
import './App.css';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { store } from './store/store';
import { Router } from './routes/routes';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { SessionExpiredModal } from './components/Common/SessionExpiredModal';
import { refreshSession } from './config/axiosInterceptor';
function App() {


  return (
    <Provider store={store}>
      <Router />
    </Provider>
  );
}

export default App;
