// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.w6ul76k3LS_WyG6LMsKh {
    position: relative;

    & input {

        width: 100% !important;
        box-sizing: border-box;
        padding: 0px 30px 0px 10px;
        border-radius: 4px;
        border: 1px solid var(--Grey-3, #7B8794);
        background: #FFF;
        height: 38px !important;
        /* font-size: 20px; */
        font-family: Poppins-Regular;
        border: 1px solid var(--Grey-3, #7f8a9674) !important;
        border-radius: 4px;

        &:hover {
            border: 1px solid black !important;
        }

        &:focus {
            border: none !important;
            outline: 2px solid rgba(45, 58, 140, 1) !important;
        }
    }


    .sY11kM6Ns0j4kCTELcdF {
        position: absolute;
        top: 6px;
        right: 4px;
    }
}`, "",{"version":3,"sources":["webpack://./src/assets/css/components/inputs.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;;IAElB;;QAEI,sBAAsB;QACtB,sBAAsB;QACtB,0BAA0B;QAC1B,kBAAkB;QAClB,wCAAwC;QACxC,gBAAgB;QAChB,uBAAuB;QACvB,qBAAqB;QACrB,4BAA4B;QAC5B,qDAAqD;QACrD,kBAAkB;;QAElB;YACI,kCAAkC;QACtC;;QAEA;YACI,uBAAuB;YACvB,kDAAkD;QACtD;IACJ;;;IAGA;QACI,kBAAkB;QAClB,QAAQ;QACR,UAAU;IACd;AACJ","sourcesContent":[".search_input_con {\r\n    position: relative;\r\n\r\n    & input {\r\n\r\n        width: 100% !important;\r\n        box-sizing: border-box;\r\n        padding: 0px 30px 0px 10px;\r\n        border-radius: 4px;\r\n        border: 1px solid var(--Grey-3, #7B8794);\r\n        background: #FFF;\r\n        height: 38px !important;\r\n        /* font-size: 20px; */\r\n        font-family: Poppins-Regular;\r\n        border: 1px solid var(--Grey-3, #7f8a9674) !important;\r\n        border-radius: 4px;\r\n\r\n        &:hover {\r\n            border: 1px solid black !important;\r\n        }\r\n\r\n        &:focus {\r\n            border: none !important;\r\n            outline: 2px solid rgba(45, 58, 140, 1) !important;\r\n        }\r\n    }\r\n\r\n\r\n    .srch_icon {\r\n        position: absolute;\r\n        top: 6px;\r\n        right: 4px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search_input_con": `w6ul76k3LS_WyG6LMsKh`,
	"srch_icon": `sY11kM6Ns0j4kCTELcdF`
};
export default ___CSS_LOADER_EXPORT___;
