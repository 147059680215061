import React, { useEffect, useState } from 'react'
import { TableContainer, TableHeader } from '../../components/Common/TableContainer'
import { SearchInput } from '../../components/Inputs/SearchInput'
import { useTranslation } from 'react-i18next'
import { CustomPagination } from '../../components/Common/CustomPagination'
import { BreadCrum } from '../../components/Common/BreadCrum'
import { assessmentStatusClass, changeDateFormat, currentUser, loader } from '../../utils'
import { getUserAssessments } from '../../api/Assessment'
import { searchDataByNestedKey } from '../../utils/search'
import style from "../../assets/css/views/assessment.module.css"
import { ViewButton } from '../../components/Buttons/ViewButton'
import { NoTableData } from '../../components/Common/NoTableData'
import { LinkButton } from '../../components/Buttons/LinkButton'
import { Link, useParams } from 'react-router-dom'
import { Tooltip } from '@mui/material'
import { useSelector } from 'react-redux'

export const UserAssessment = () => {
    const tokenStatus = useSelector((state) => state.tokenStatus);
    let [searchValue, setSearchValue] = useState("");

    const { assessmentType } = useParams("assessmentType")

    let { t } = useTranslation();
    let [allAssessments, setAllAssessments] = useState([]);
    let perPageData = 20
    let loaderP = useSelector(e => e.loader)


    let [page, setPage] = useState(1)
    let [allData, setAllData] = useState([])
    let [totalData, setTotalData] = useState(1);
    let [error, setError] = useState({
        error: false,
        errorMessage: t("Assessment.noAssesmentFound")
    })

    async function getUserAssessment() {
        try {
            loader.start()
            setAllAssessments([])
            setAllData([])
            setTotalData(0)
            const userId = currentUser().user.id
            let res = await getUserAssessments(userId, page, perPageData, assessmentType)
            setTotalData(res.data.totalCount)
            setAllAssessments(res.data?.response)
            setAllData(res.data?.response)
            setError({
                error: false,
                errorMessage: ""
            })
        }
        catch (err) {
            setError({
                error: true,
                errorMessage: err?.response?.data?.error
            })
        }
        finally {
            loader.stop()
        }
    }

    useEffect(() => {
        getUserAssessment()
    }, [page, assessmentType, tokenStatus])


    function searchAssessment(e) {
        setError({
            error: false,
            errorMessage: t("Assessment.noAssesmentFound")
        })
        setSearchValue(e.target.value)
        setAllAssessments(searchDataByNestedKey(allData, e.target.value, "assessmentData", "name"))
    }
    let breadCrumData = [
        {
            text: t("Assessment.User Assessment"),
        },
        {
            text: t(assessmentType),
        }


    ]
    return (
        <div className={`padding_22_44`}>

            <div>
                <BreadCrum dataArr={breadCrumData} />
            </div>
            <div>
                <TableHeader>
                    <SearchInput className="search_input" value={searchValue} onChange={searchAssessment} placeholder={t("Search")} />
                </TableHeader>

                <TableContainer>
                    <div className={style.userAssessment_table_scroll}>
                        <table>
                            <thead>
                                <tr>

                                    <th>{t("S.No.")}</th>
                                    <th>{t("Assessment.Assessment Name")}</th>
                                    <th>{t("Type")}</th>
                                    <th>{t("Deadline")}</th>
                                    <th>{t("Status")}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            {!error.error &&
                                <tbody>
                                    {allAssessments && allAssessments.map((res, key) => {
                                        return (
                                            <tr key={key}>
                                                <td><div >{key + 1}</div></td>
                                                <td><div >{res?.assessmentData[0]?.name || "--"}</div></td>
                                                <td><div >{res?.assessmentData[0]?.type || "--"}</div></td>
                                                <td><div >{res?.assessmentData[0]?.completionDate && changeDateFormat(res?.assessmentData[0]?.completionDate) || "--"}</div></td>


                                                <td><div className='flex align_center'>
                                                    <span className={`assessment_status ${assessmentStatusClass(res?.status)}`}></span>
                                                    &nbsp;&nbsp;
                                                    {res?.status}
                                                </div></td>

                                                <td><div className='flex align_center'>
                                                    <Tooltip title={res?.status !== "Completed" ? t("Go to survey") : ""} arrow>
                                                        <a style={{ marginTop: "6px" }} href={`/survey-form/${res?.assessmentData[0]?._id}`} target="_blank" rel="noopener noreferrer">
                                                            <LinkButton disabled={res?.status == "Completed"} />
                                                        </a>
                                                    </Tooltip>&nbsp;
                                                    {/* <Link to={`preview/${res?.assessmentData[0]._id}`}>
                                                        <ViewButton />
                                                    </Link> */}
                                                </div></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>}
                        </table>
                        {(error.error || allAssessments?.length === 0) && !loaderP && <NoTableData text={error.errorMessage} />}

                    </div>
                    <CustomPagination page={page - 1} pageChange={setPage} count={totalData} perPage={perPageData} />
                </TableContainer>

            </div>

        </div>
    )
}
