import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { TableHeader } from '../../components/Common/TableContainer'
import { SearchInput } from '../../components/Inputs/SearchInput'
import { NoTableData } from '../../components/Common/NoTableData'
import { CustomPagination } from '../../components/Common/CustomPagination'
import style from "../../assets/css/views/category.module.css"
import { useSelector } from 'react-redux'
import { CategoryTableDropdown } from '../../components/Common/Category/CategoryTableDropdown'
import { fetchGroups } from '../../api/StrategicImperatives'
import { currentUser, loader } from '../../utils'
import { searchDataByNestedKey } from '../../utils/search'

export const JobRole = () => {

    let loaderP = useSelector(e => e.loader)
    const { t } = useTranslation()
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState("")
    const [allData, setAllData] = useState([])
    const record_per_page = 9;
    const [jobRoleData, setJobRoleData] = useState([])

    const [error, setError] = useState({
        error: false,
        errorMessage: ""

    })
    const [totalGroup, setTotalGroup] = useState(5)


    useEffect(() => {
        getAllJobRolesByGroup()
    }, [])

    async function getAllJobRolesByGroup() {
        try {
            let data = {
                page: page,
                record_per_page: record_per_page,
                collectionType: "JOBROLE",
            }
            loader.start()
            let res = await fetchGroups(data)
            if (res?.data?.response.length > 0) {
                setJobRoleData(res.data.response)
                setTotalGroup(res.data.totalGroups)
                setAllData(res.data.response)
            }
            else {
                setError({
                    error: true,
                    errorMessage: t("jobRole.noJobrole")
                })
            }
        } catch (error) {
            setError({
                error: false,
                errorMessage: error?.response?.data?.error
            })
        }
        finally {
            loader.stop()
        }
    }
    let navigate = useNavigate()
    useEffect(() => {
        const checkUserRoleAndNavigate = () => {
            let user = currentUser()
            if (user.role.item_name === "groupAdmin") {
                navigate(`preview/${user.user.primary_group}`)
            }
        }
        checkUserRoleAndNavigate()
    }, [])


    useEffect(() => {
        // search categories on change of search input value 
        // setJobRoleData(searchDataByNestedKey(allData, search, "data", "jobRoleId"))
    }, [search])
    return (
        <div className='padding_22_44'>

            <div className={`${style.KPISI_home_table_con}`}>
                <div className="between">
                    <div className="h1">
                        {t("jobRole.jobRole")}
                    </div>

                    <Link to={"create"}>
                        <button className="btn-primary">{t("Create New")}</button>
                    </Link>
                </div>


                <TableHeader>
                    <div className={style.search_input}>
                        <SearchInput value={search} onChange={(e) => setSearch(e.target.value)} placeholder={`${t("jobRole.searchJobRole")}`} />

                    </div>
                </TableHeader>


                <div className={style.table_head}>
                    <div>{t("Group Name")}</div>
                    <div></div>
                </div>

                <div className={style.scroll_part}>
                    {(error.error || jobRoleData?.length === 0) && !loaderP ? <NoTableData text={error.errorMessage} /> :
                        <>
                            {jobRoleData.map((res, key) => {
                                return (
                                    <CategoryTableDropdown open={key === 0} data={res} key={key} />
                                )
                            })}
                        </>
                    }
                </div>

                <CustomPagination page={page - 1} pageChange={() => { }} count={totalGroup} perPage={record_per_page} />

            </div >




        </div>
    )
}
