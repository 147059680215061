import React from 'react'
import { TextField } from "@mui/material"



export const CustomInput = (props) => {


    return (
        <TextField {...props} />
    )
}