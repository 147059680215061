import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { loader, toast } from '../../utils'
import { editJobRole, fetchJobRoleById } from '../../api/jobRoles'
import { BreadCrum } from '../../components/Common/BreadCrum'
import { JobRoleForm } from '../../components/Common/JobRole/JobRoleForm'
import { useTranslation } from 'react-i18next'
import { useQueryParams } from '../../hooks/useQueryParams'

export const EditJobRole = () => {

    const { id } = useParams()
    const [jobRoleData, setJobRoleData] = useState({})
    const { t } = useTranslation();
    const navigate = useNavigate()

    useEffect(() => {
        fetchData()
    }, [])

    async function fetchData() {
        try {
            loader.start()
            let body = {
                id: id,

            }
            let res = await fetchJobRoleById(body)
            const apidata = res.data.response;

            let obj = {
                competencyIds: [],
                categoryIds: []
            }

            let data = {
                groupId: apidata.groupId,
                jobRoleObj: apidata.jobRoleObj,
                dpdId: apidata?.dpdDetails._id
            }

            apidata.categories.forEach((res) => {
                obj.categoryIds.push(res.categoryData._id)
                obj.competencyIds.push(...res.competenciesData.map(e => ({ ...e, categoryId: res.categoryData._id })).map(extractCompetencyData))
            })
            data = { ...data, ...obj }


            setJobRoleData(data)

        } catch (error) {
            toast.error(error?.response?.data?.error)
        }
        finally {
            loader.stop()
        }
    }
    function extractCompetencyData(data) {
        return ({
            name: data.name,
            _id: data._id,
            categoryId: data.categoryId
        })
    }

    const breadCrumData = [
        {
            text: t("jobRole.jobRole"),
            path: "/jobRole"
        },
        {
            text: t("Edit"),
        },
    ]
    async function handleEditJobRole(data) {
        try {
            let finalData = {
                groupId: data.groupId,
                jobRoleObj: data.jobRoleObj
            }

            finalData.categories = data.categoryIds.map((res) => {
                return ({
                    categoryId: res,
                    competencyIds: data.competencyIds.filter(e => e.categoryId == res).map(e => e._id)
                })
            })


            loader.start()
            let res = await editJobRole({ ...finalData, _id: id })
            toast.success(res?.data?.message)
            navigate("/jobRole")

        } catch (error) {
            toast.error(error?.response?.data?.error)
        }
        finally {
            loader.stop()

        }
    }


    return (
        <div className="padding_22_44">
            <BreadCrum dataArr={breadCrumData} />
            <br />
            {jobRoleData?.jobRoleObj?.roleId &&
                <JobRoleForm mode={"edit"} data={jobRoleData} onSubmit={handleEditJobRole} />}
        </div>
    )
}
