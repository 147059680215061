import React, { useEffect, useState } from 'react'
import { TableHeader } from '../TableContainer'
import style from "../../../assets/css/components/reports.module.css"
import { SearchInput } from '../../Inputs/SearchInput'
import { CustomProgressLine } from '../CustomProgressLine'
import { useTranslation } from 'react-i18next'
import { SearchAutoComplete } from '../../Inputs/SearchAutoComplete'
import { REPORTS_SORT_OPTIONS, REPORTS_FILTER_OPTIONS } from '../../../constants/constants'
import { searchDataWithMultipleKeys } from '../../../utils/search'

export const MultipleProgressLineTable = ({ searchAttributes, onClick, data }) => {
    let { t } = useTranslation()
    let [sortBy, setSortBy] = useState(null)
    let [filter, setFilter] = useState(null)
    let [filteredData, setFilteredData] = useState([])
    let [search, setSearch] = useState("")

    useEffect(() => {
        if (filter) {
            switch (filter) {
                case 'top5':
                    setFilteredData([...data.sort((a, b) => parseInt(b.value1) - parseInt(a.value1)).slice(0, 5)])
                    break;
                case 'top10':
                    setFilteredData([...data.sort((a, b) => parseInt(b.value1) - parseInt(a.value1)).slice(0, 10)])
                    break;
                case 'all':
                    setFilteredData([...data])
                    break;
            }
        }
        else {
            setFilteredData([...data])
        }
    }, [filter, data])

    useEffect(() => {
        if (sortBy) {
            switch (sortBy) {
                case 'lowToHigh':
                    setFilteredData([...[...data].sort((a, b) => parseInt(a.value1) - parseInt(b.value1))])
                    break;
                case 'highToLow':
                    setFilteredData([...[...data].sort((a, b) => parseInt(b.value1) - parseInt(a.value1))])
                    break;
            }
        }
        else {

            setFilteredData([...data])
        }
    }, [sortBy, data])

    useEffect(() => {
        setFilteredData(searchDataWithMultipleKeys(["name"], [...data], search))

    }, [search])

    return (
        <div className={style.multipleProgressLineTable_con}>

            <div className="flex align_center">
                <SearchInput placeholder={t("Search")} value={search} onChange={(e) => setSearch(e.target.value)} {...searchAttributes} />
                &nbsp;
                &nbsp;
                &nbsp;

                <SearchAutoComplete
                    className={style.sort_select}
                    label={t('sortBy')}
                    options={REPORTS_SORT_OPTIONS}
                    disableCloseOnSelect
                    getOptionLabel={(option) => {
                        return option?.label || option
                    }}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            {option.label}
                        </li>
                    )}
                    value={REPORTS_SORT_OPTIONS?.find(e => e.value == sortBy) || null}
                    onChange={(e, val) => setSortBy(val?.value)}
                    renderTags={(tagValue, getTagProps) => (
                        <span className={`${style.autocomplete_wrapper}`}>
                            {tagValue}
                        </span>
                    )}
                />
                &nbsp;
                &nbsp;
                &nbsp;
                <SearchAutoComplete
                    className={`${style.sort_select} bg-white`}
                    label={t('filter')}
                    options={REPORTS_FILTER_OPTIONS}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            {option.label}
                        </li>
                    )}
                    value={REPORTS_FILTER_OPTIONS?.find(e => e.value == filter) || null}
                    onChange={(e, val) => {
                        setFilter(val?.value)
                    }
                    }
                    renderTags={(tagValue, getTagProps) => (
                        <span className={`${style.autocomplete_wrapper}`}>
                            {tagValue}
                        </span>
                    )}
                />
            </div>
            <TableHeader>
                <div className={style.header_con}>
                    <div className={style.left_con}>

                    </div>
                    <div className={style.right_con}>
                        {(data?.[0]?.value1 || data?.[0]?.value1 == 0) &&
                            <>
                                <div className={style.item}>
                                    <div>0</div>
                                    <div>{data?.[0]?.heading1}</div>
                                    <div>100</div>
                                </div>
                            </>}
                        {(data?.[0]?.value2 || data?.[0]?.value2 == 0) &&
                            <>
                                |
                                <div className={style.item}>
                                    <div>0</div>
                                    <div>{data?.[0]?.heading2}</div>
                                    <div>100</div>
                                </div>
                            </>}

                        {(data?.[0]?.value3 || data?.[0]?.value3 == 0) &&
                            <>
                                |
                                <div className={style.item}>
                                    <div>0</div>
                                    <div>{data?.[0]?.heading3}</div>
                                    <div>100</div>
                                </div>
                            </>}
                    </div>

                </div>
            </TableHeader>
            {filteredData?.map((res, key) => {
                return (
                    <div className={`pointer ${style.table_row_con} ${res.active && style.table_active_row}`} key={key}
                        onClick={() => onClick && onClick(res, key, res?.active)}
                    >
                        <div className={style.left_con}>
                            {res?.name}
                        </div>
                        <div className={style.right_con}>
                            {(res?.value1 || res?.value1 === 0) &&
                                <div className={style.item}>
                                    <CustomProgressLine
                                        value={res?.value1}
                                        variant={"light_secondary"}
                                    />
                                </div>}
                            {(res?.value2 || res?.value2 === 0) &&
                                <div className={style.item}>
                                    <CustomProgressLine
                                        value={res?.value2 || 0}
                                        variant={"light_secondary"}
                                    />
                                </div>}
                            {(res?.value3 || res?.value3 === 0) &&
                                <div className={style.item}>
                                    <CustomProgressLine
                                        value={res?.value3 || 0}
                                        variant={"light_secondary"}
                                    />
                                </div>}
                        </div>
                    </div>

                )
            })}


        </div>
    )
}
