import React, { useEffect, useRef, useState } from "react";
import style from "../../assets/css/views/reports.module.css";
import { DownloadButton } from "../../components/Buttons/DownloadButton";
import { TableContainer } from "../../components/Common/TableContainer";
import { changeDateFormat, formatChartdata, loader, toast } from "../../utils";
import { fetchReport } from "../../api/Reports";
import { useParams } from "react-router";
import { FormClosed } from "../../components/Common/FormClosed";
import { ASSESSMENT_TYPES } from "../../constants/constants";
import { useTranslation } from "react-i18next";
import { MultipleCircularProgressCard } from "../../components/Common/Reports/MultipleCircularProgressCard";
import { MultipleProgressLineTable } from "../../components/Common/Reports/MultipleProgressLineTable";
import { LinearProgress } from "@mui/material";
import { DownloadPdf } from "../../components/Common/DownloadPdf";
import { BreadCrum } from "../../components/Common/BreadCrum";
import { Link } from "react-router-dom";

export const AssessmentReport = () => {

  const [assessmentType, setAssessmentType] = useState(null)

  let pdfRef = useRef()

  let { t } = useTranslation()
  let [resportLoader, setReportLoader] = useState(false)
  let params = useParams();
  let [dataLoad, setDataLoad] = useState(true);
  let [noData, setNoData] = useState({
    error: false,
    errorMessage: "",
  });

  let [chartData, setChartData] = useState({
    circularChartData: [],
    tableChartData: []
  })
  let [filteredChartData, setFilteredChartData] = useState({
    circularChartData: [],
    tableChartData: [],
    tableData: []
  })

  let [reportData, setReportData] = useState({});

  useEffect(() => {
    fetchAssessmentReportData()
  }, [])

  async function fetchAssessmentReportData() {
    try {
      loader.start();
      setDataLoad(true);
      setReportLoader(true)
      let res = await fetchReport(params.id)
      let response = res.data.response
      setAssessmentType(response?.assessmentType)
      setReportData(response);
      let data = formatChartdata(response, "SingleAssessment")
      setFilteredChartData({ ...data })
      setChartData({ ...data })
    } catch (err) {
      setNoData({
        error: true,
        errorMessage: err?.response?.data?.error,
      });
      toast.error(err?.response?.data?.error);
    }
    finally {
      setReportLoader(false)
      loader.stop();
      setDataLoad(false);
    }
  }



  function onCircularCardClick(data, chart1Index) {
    setFilteredChartData((pre) => {
      let tableChartData = pre.circularChartData[chart1Index].active ? chartData.tableChartData : chartData.tableChartData.filter(e => e.parentId == data.itemId)
      return {
        circularChartData: pre.circularChartData.map((item, index) => ({
          ...item,
          active: index === chart1Index ? !item.active : false
        })),
        tableChartData,
        tableData: chartData.tableData.filter(d => tableChartData.some(e => e.itemId == d.itemId))
      };
    });

  }

  let breadCrumData = [
    {
      text: t("Assessment.Assessment"),
      path: `/assessment/${assessmentType}`
    },
    {
      text: t(assessmentType)
    },
  ]

  function ProgressTableRowClick(d, rowIndex, isActive) {
    setFilteredChartData((pre) => {
      return {
        ...pre,
        tableChartData: pre.tableChartData.map((e, d) => ({ ...e, active: isActive ? false : rowIndex == d })),
        tableData: isActive ? chartData?.tableData : chartData.tableData.filter(e => e.itemId == d.itemId)
      };
    });
  }


  return (
    <>

      <div ref={pdfRef} className={`padding_22_44`}>
        <BreadCrum dataArr={breadCrumData} />
        <br />

        {!noData.error ? (
          <>
            {dataLoad ? (
              <></>
            ) : (
              <div className={`${style.assessmentReport_main_con}`}>

                <div className="flex between">
                  <div className={style.heading}>
                    {reportData?.assessmentData?.name}
                  </div>
                  <div>
                    <DownloadPdf
                      onSubmit={() => pdfRef.current}
                      fileName={`assessmentReport`}
                    >
                      <DownloadButton />
                    </DownloadPdf>
                  </div>
                </div>
                <div >
                  <div className={style.content_part}>
                    <div className="flex">
                      <div className="w-50">
                        <div className={style.label}>{t("Assessment.Type of Assessment")}:</div>
                        <div>{reportData?.assessmentData?.type || "----"}</div>
                      </div>
                      <div className="w-50">
                        <div className={style.label}>{t("Completion Date")}</div>
                        <div>
                          {(reportData?.assessmentData?.completionDate &&
                            changeDateFormat(reportData?.assessmentData.completionDate)) ||
                            "----"}
                        </div>
                      </div>
                    </div>

                    <br />
                    <div>
                      <div>
                        <div className={style.label}>{t("Description")}</div>
                        <div>{reportData?.assessmentData?.description || "----"}</div>
                      </div>
                      <br />
                      {assessmentType === ASSESSMENT_TYPES.organizational &&
                        <div>
                          <div className={style.label}>{t("Strategic Imperatives")}:</div>
                          <ul>
                            {reportData?.reports?.map((res) => {
                              return <li key={res?.siObj?._id}>{res?.siObj?.name}</li>;
                            })}
                          </ul>
                        </div>}
                      {assessmentType != ASSESSMENT_TYPES.organizational &&
                        <div>
                          <div className={style.label}>{t("category.categories")}:</div>
                          <ul>
                            {reportData?.reports?.map((res) => {
                              return <li key={res?.categoryObj?._id}>{res?.categoryObj?.name}</li>;
                            })}
                          </ul>
                        </div>}

                    </div>
                  </div>
                  <br />
                  {resportLoader ? <LinearProgress />
                    : <>
                      <div className={style.heading}>
                        {t(assessmentType == ASSESSMENT_TYPES.organizational ? "Strategic Imperatives" : "Categories")}
                      </div>
                      <br />
                      <div className="flex">
                        {filteredChartData?.circularChartData?.map((res, ind) => {
                          return (
                            <MultipleCircularProgressCard
                              key={res.itemId}
                              active={res.active}
                              title={res.name}
                              onClick={(e) => onCircularCardClick(res, ind)}
                              showChart={true}
                              values={[res?.value1, res?.value2, res?.value3].filter(e => e)}
                            />
                          )
                        })}
                      </div>
                      <br />
                      <div className={style.heading}>
                        {t(assessmentType == ASSESSMENT_TYPES.organizational ? "Key performance indicators (KPI)" : "competency.competency")}
                      </div>
                      <br />
                      <MultipleProgressLineTable
                        onClick={ProgressTableRowClick}
                        data={filteredChartData?.tableChartData || []}
                      />
                    </>
                  }
                  <br />
                  <div>
                    <TableContainer>
                      <table>
                        <thead>
                          <tr>
                            <th>{t("Multiple Choice Questions")}</th>
                            <th>&nbsp;&nbsp;</th>
                            <th>
                              {assessmentType === ASSESSMENT_TYPES.organizational ?
                                t("Key performance indicators (KPI)") :
                                t("competency.competencies")
                              }

                            </th>
                            <th>{t("Percentage")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredChartData?.tableData?.map((res, ind) => (
                            <tr key={ind}>
                              <td className="w-60">
                                <div>{res.question || "----"}</div>
                              </td>
                              <td>
                                <div>&nbsp;&nbsp;</div>
                              </td>
                              <td>
                                <div>{res?.name || "----"}</div>
                              </td>
                              <td>
                                <div>{res?.score}%</div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </TableContainer>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <FormClosed text={noData.errorMessage} />
        )}
        <br />
        <div className="end">
          <Link to={`/assessment/${assessmentType}`}>
            <button className="btn-secondary" type='button'>
              {t("Cancel")}
            </button>
          </Link>

        </div>
      </div>


    </>
  );
};
