import {
  FormControl,
  LinearProgress,
  TextField,
  Autocomplete,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box
} from "@mui/material";
import style from "../../../assets/css/components/assessment.module.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ASSESSMENT_STATUS_TYPES, ASSESSMENT_TYPES, CREATE_ASSESSMENT_TYPE } from "../../../constants/constants";
import { CustomDatePicker } from "../../Inputs/CustomDatePicker";
import { AddCircle } from "@mui/icons-material";
import { CustomCheckbox } from "../../Inputs/CustomCheckbox";
import { AssessmentDropDownMenu } from "./AssessmentDropDownMenu";
import { getallgroups } from "../../../store/Slices/GroupSlice";
import { getSubGroupByGroupId } from "../../../api/Group";
import { CustomSelect } from "../../Inputs/CustomSelect";
import { BreadCrum } from "../BreadCrum";
import { validateForm } from "../../../utils/formValidationCheck";
import { Link, useParams } from "react-router-dom";
import { loader, toast } from "../../../utils";
import { useTranslation } from "react-i18next";
import { getUsersByGroupId } from "../../../api/user";
import { fetchAssessmentById, fetchAssessments, validateJobRoleAssessment } from "../../../api/Assessment";
import { QuestionTable } from "./QuestionTable";
import { fetchJobRoleById, fetchJobRolesByGroupId, fetchTPJobRoles } from "../../../api/jobRoles";
import { ConfirmationDialog } from "../ConfirmationDialog";
import { CustomAutoComplete } from "../../Inputs/CustomAutoComplete";
import { getAllDPDByJobRole } from "../../../api/developmentPlan";

export const CreateJobRoleIndividualAssSurvey = ({
  assessmentData,
  onSubmit,
  setUpdatedData,
  mode,
  fullData,
  jobRole
}) => {
  let dispatch = useDispatch();
  const { assessmentType } = useParams()
  // disable all fields except completion date if assessment status is not equal to draft and inreview 
  let isEditDisabled = [ASSESSMENT_STATUS_TYPES.readyToDistribute, ASSESSMENT_STATUS_TYPES.live].includes(assessmentData?.status)

  const [jobRoleValidation, setJobRoleValidation] = useState({
    error: false,
    errorMsg: "",
    status: null
  })

  const [submitBtnDisabled, setSubmitDiasbled] = useState(false)
  let [formData, setFormData] = useState({ ...assessmentData });
  let [questionTableVisibility, setQuestionTableVisibility] = useState(false);
  let [fetchKpiLoader, setFetchKpiLoader] = useState(false);
  let [jobRoleId, setJobRoleId] = useState(null)
  let [previousSurveyData, setPreviousSurveyData] = useState([]);
  let [fetchTPJobRoleLoader, setFetchTPJobRoleLoader] = useState(false)
  let [developmentPlans, setDevelopmentPlans] = useState([])

  let [userJobroleData, setUserJobRoleData] = useState({
    jobRoleId: "",
    email: "",
  })

  let [openDropDownIndex, setOpenDropDownIndex] = useState(0);
  const [fetchSubgroupLoader, setFetchSubgroupLoader] = useState(false)

  let { t } = useTranslation();

  let [currentKPI, setCurrentKPI] = useState({
    kpiId: "",
    questionIds: [],
  });

  let [requiredField, setRequiredField] = useState({
    name: false,
    completionDate: false,
    groupId: false,
    jobRoleId: false,
    user: false,
    reviewers: false,
    category: false,
    dpdId: false

  });
  let allGroups = useSelector((e) => e.groupData.allGroups);
  let [allSubGroups, setAllSubGroups] = useState([]);
  let [allCategories, setAllCategories] = useState([])
  let [jobRoles, setJobRoles] = useState([]);
  let [fetchJobRoleLoader, setFetchJobRoleLoader] = useState(false)
  let [allTpJobRoles, setAllTpJobRoles] = useState([]);
  let [allCompetenciesOfJobrole, setAllCompetenciesOfJobrole] = useState([]); //
  let [allCompetencies, setAllCompetencies] = useState([]);
  let [allUsers, setAllUsers] = useState([]);
  let [fechUserloader, setFetchUserLoader] = useState(false);
  let [fetchCategoryLoader, setFetchCategoryLoader] = useState(false);
  let [pulseComptencies, setPulseComptencies] = useState([])
  let [pulseComptency, setPulseComptency] = useState([])
  let [selectedPreviousAssessment, setSelectedPreviousAssessment] = useState({})
  let [fetchDevelopmentPlanLoader, setFetchDevelopmentPlanLoader] = useState(false)

  useEffect(() => {
    if (mode === "Edit" && assessmentData?.type === "Pulse") {
      let allCompetencies = formData.kpiQuestions.map(e => e.kpiData)
      setAllCategories(fullData?.categoriesData || [])
      setPulseComptency(allCompetencies.map(e => e._id))
      setAllCompetencies(allCompetencies)
      setPulseComptencies(allCompetencies)
      setSelectedPreviousAssessment(fullData)
    }
    if (mode === "Edit" && assessmentType === ASSESSMENT_TYPES.jobRole) {
      setJobRoleId(jobRole)
    }
  }, [])
  useEffect(() => {
    if (allGroups.length === 0) {
      dispatch(getallgroups());
    }
  }, [dispatch, allGroups.length]);


  useEffect(() => {
    if (formData?.surveyAssignments?.jobRoleId) {
      // fetch job role data 
      // fetchJobRoleData(jobRoleId)
      fetchDevelopmentPlanData(formData?.surveyAssignments?.jobRoleId)
    }
  }, [formData?.surveyAssignments?.jobRoleId])

  async function fetchDevelopmentPlanData(jobId) {
    try {
      setFetchDevelopmentPlanLoader(true)
      let res = await getAllDPDByJobRole(jobId)
      setDevelopmentPlans(res?.data?.response?.[0]?.DPD || [])
    } catch (err) {
      toast.error(err?.response?.data?.error)
    }
    finally {
      setFetchDevelopmentPlanLoader(false)
    }

  }

  async function fetchTpJobRolesByGroupId() {
    try {
      setFetchTPJobRoleLoader(true)
      let res = await fetchTPJobRoles(formData.groupId)
      setAllTpJobRoles(res?.data?.response || [])
      setJobRoles(res?.data?.response || [])



    } catch (err) {
      toast.error(err?.response?.data?.error)
    }
    finally {
      setFetchTPJobRoleLoader(false)
    }
  }


  async function checkJobRoleValidation(jobRoleId) {
    try {
      // check validation of job role
      loader.start()
      setSubmitDiasbled(true)
      let res = await validateJobRoleAssessment(jobRoleId)
      if (res.status === 200) {
        setSubmitDiasbled(false)

      }

    } catch (error) {
      setJobRoleValidation({
        error: true,
        errorMsg: error?.response?.data?.error || "Error",
        status: error.response.status,
      })
    }
    finally {
      loader.stop()
    }
  }


  const fetchJobRoleData = async (jobroleId, dpdId) => {
    try {
      if (assessmentType === ASSESSMENT_TYPES.jobRole) {
        // show questions loader only in case of jobrole 
        setFetchKpiLoader(true)
      }
      setFetchCategoryLoader(true)

      let body = {
        id: jobroleId,
        dpdId: dpdId,

      }
      setFetchKpiLoader(true)
      let res = await fetchJobRoleById(body);
      let data = res?.data?.response

      let categoryData = data?.categories?.map((d) => d?.categoryData).flat(Infinity) || [];
      let allCompetencies = data?.categories?.map((d) => d?.competenciesData).flat(Infinity) || [];


      setAllCategories(categoryData)

      // if (assessmentType === ASSESSMENT_TYPES.jobRole) {
      // autofill all competencies and category in case of jobrole 
      setAllCompetencies(data?.categories?.map((d) => d?.competenciesData).flat(Infinity) || [])
      if (categoryData?.length > 0) {
        // Save all the categoryIds in formData only for job role
        setFormData({ ...formData, categoryIds: categoryData.map((e) => e._id) });
      }
      // }
      // else {
      //   // store all competencies in local state for later filtering by category
      setAllCompetenciesOfJobrole(allCompetencies)
      // }

    } catch (err) {
      toast.error(err?.response?.data?.error);
    }
    finally {
      setFetchSubgroupLoader(false)

      setFetchKpiLoader(false)
      setFetchCategoryLoader(false)
    }
  };


  // getSubGroups,SI,KPI based on group selected
  const getsubgroups = async () => {
    try {
      setFetchSubgroupLoader(true)
      let res = await getSubGroupByGroupId(formData.groupId);
      setAllSubGroups(res.data.response);
    } catch (err) {

      toast.error(err?.response?.data?.error);
    }
    finally {
      setFetchSubgroupLoader(false)
    }
  };




  // getjobRoles based on group selected
  const getjobRoles = async () => {
    try {
      setFetchJobRoleLoader(true)
      let res = await fetchJobRolesByGroupId(formData.groupId);
      // setJobRoles(res.data.response);`
    } catch (err) {
      toast.error(err?.response?.data?.error);

    }
    finally {
      setFetchJobRoleLoader(false)
    }
  };

  async function getAllUsers() {
    setFetchUserLoader(true);
    try {
      let res = await getUsersByGroupId(formData.groupId);
      if (res.data.success) {
        // let allUsers = res.data.users.filter((e) => e.role === "user");

        setAllUsers(res?.data?.users || []);

      } else {
        toast.error(res?.data?.message);
      }
      setFetchUserLoader(false);
    } catch (err) {

      setFetchUserLoader(false);
      toast.error(err?.response?.data?.error)
    }
  }

  useEffect(() => {
    if (formData.groupId) {
      getAllUsers();
      getjobRoles();
      fetchTpJobRolesByGroupId()
      getsubgroups();
    }
  }, [formData.groupId]);

  useEffect(() => {
    if (formData?.dpdId && formData?.surveyAssignments?.jobRoleId) {

      fetchJobRoleData(formData?.surveyAssignments?.jobRoleId, formData?.dpdId)


    }
    if (assessmentType === ASSESSMENT_TYPES.individual) {
      if (formData?.surveyAssignments?.user?.jobRoleId && formData?.dpdId) {

        fetchJobRoleData(formData?.surveyAssignments?.user?.jobRoleId, formData?.dpdId)
      }

    }
  }, [formData?.dpdId])


  // fetch competencies on change of category 
  useEffect(() => {

    if (formData.categoryIds && formData.categoryIds.length > 0 && formData.type !== "Pulse" && assessmentType !== ASSESSMENT_TYPES.jobRole) {

      setAllCompetencies(allCompetenciesOfJobrole.filter(e => formData.categoryIds.includes(e.categoryId)))

    }
    else if (assessmentType === ASSESSMENT_TYPES.jobRole && formData.categoryIds.length === 0) {
      // setAllCompetencies([])

    }
  }, [formData.categoryIds, allCompetenciesOfJobrole])


  function addQuestion(res) {
    let rr = formData.kpiQuestions.find((e) => e.kpiData?._id === res._id);
    if (!rr) {
      setCurrentKPI({ kpiData: res, questionData: [] });
    } else {
      setCurrentKPI({ ...rr });
    }
    setQuestionTableVisibility(true);
  }



  function addQuestionSubmit(data) {
    setFormData((pre) => {
      let ind = pre.kpiQuestions.findIndex(
        (e) => e.kpiData?._id === data.kpiData?._id
      );
      ind !== -1 ? (pre.kpiQuestions[ind] = data) : pre.kpiQuestions.push(data);
      if (mode === "Edit") {
        setUpdatedData((pre2) => {
          return { ...pre2, kpiQuestions: pre.kpiQuestions };
        });
      }

      return { ...pre };
    });

    setQuestionTableVisibility(false);
  }




  function formSubmit() {
    let isFormValid = formValidate();

    let isAllKpisHaveQuestions = validateQuestionsInCompetencies();

    if (isFormValid && isAllKpisHaveQuestions) {

      // if (formData?.type === "Pulse") {
      //   // delete formData.dpdId
      // }

      onSubmit(formData);
    } else {
      toast.error(
        !isFormValid
          ? "Please fill all the fields"
          : "All Competencies must have atleast one question !"
      );
    }
  }
  function validateQuestionsInCompetencies() {
    return (
      formData.kpiQuestions.length === allCompetencies.length &&
      formData.kpiQuestions.every((t) => t.questionData.length > 0) &&
      formData.kpiQuestions.length > 0
    );
  }
  function formValidate() {
    let requiredFields = ["name", "completionDate", "groupId", "reviewers"];

    if (assessmentType === ASSESSMENT_TYPES.jobRole) {
      requiredFields.push("jobRoleId")
    }
    if (assessmentType === ASSESSMENT_TYPES.individual) {
      requiredFields.push("user")
    }
    if (formData.type === "Baseline") {
      requiredFields.push("dpdId")

    }



    // check validation for nested fields also 
    let nestedFieldsData = {
      dpdId: !!formData?.dpdId,
      jobRoleId: formData?.surveyAssignments?.jobRoleId,
      user: !!formData?.surveyAssignments?.user?.userId,
      reviewers: [
        ...formData?.reviewers?.subGroupIds || [],
        ...formData?.reviewers?.individuals || [],
        ...formData?.reviewers?.jobRoleIds || []
      ]
    }

    let res = validateForm(requiredFields, { ...formData, ...nestedFieldsData });


    setRequiredField(res.data);

    return res.errorFields.length === 0;
  }

  function resetErrorFields() {
    setRequiredField({
      name: false,
      completionDate: false,
      siIds: false,
      groupId: false,
      previousSurveyId: false,
    });
  }

  let breadCrumData = [
    {
      text: t("Assessment.Assessment"),
      path: `/assessment/${assessmentType}`
    },
    {
      text: t(assessmentType)
    },
    {
      text: mode === "Edit" ? t("Edit") : t("Create New"),
    },

  ]

  const extractUserDetails = (user) => {
    return {
      userId: user?.id || null,
      email: user?.email || null,
      name: user?.username || null,
      jobRoleName: null,
      jobRoleId: user?.job_role || null
    };
  };


  // fetch previous survey data 
  const [fetchPreviousSurveyLoader, setFetchPreviousSurveyLoader] = useState(false)

  async function fetchPreviousSurveyData() {
    try {
      setFetchPreviousSurveyLoader(true)
      let query = {
        "$and": [
          { type: "Baseline" },
          { status: "Completed" },
          { assessmentType: ASSESSMENT_TYPES.individual },
          {
            "surveyAssignments.user.userId": formData?.surveyAssignments?.user?.userId
          },
          { groupId: formData.groupId }
        ]
      }

      let res = await fetchAssessments(query)
      setPreviousSurveyData(res?.data?.response || [])

    }
    catch (err) {
      toast.error(err?.response?.data?.error)
    }
    finally {
      setFetchPreviousSurveyLoader(false)
    }
  }
  // fetch previous survey data on assessment type or user changes 

  useEffect(() => {

    if (formData?.surveyAssignments?.user?.jobRoleId) {

      if (formData.type === "Baseline") {
        // fetchCompetencies(formData?.surveyAssignments?.user?.jobRoleId)
        // fetchJobRoleData(formData?.surveyAssignments?.user?.jobRoleId)

        fetchDevelopmentPlanData(formData?.surveyAssignments?.user?.jobRoleId)
      }
      else {
        fetchPreviousSurveyData()
      }
    }
  }, [formData?.surveyAssignments?.user])

  async function fetchPreviousAssessmentData(id) {
    try {
      setFetchJobRoleLoader(true)
      setFetchSubgroupLoader(true)
      setFetchKpiLoader(true)
      let res = await fetchAssessmentById(id)
      let {
        reviewers,
        categoriesData,
        questions,
        dpdData
      } = res.data.response

      setSelectedPreviousAssessment(res?.data?.response || {})
      let data = {
        dpdId: dpdData._id,
        categoryIds: categoriesData.map(e => e._id),
        reviewers: {
          subGroupIds: reviewers?.subGroupsData?.map(e => e._id),
          jobRoleIds: reviewers?.jobRoles?.map(e => e.id),
          individuals: reviewers?.individuals
        },
        kpiQuestions: questions.map(e => {
          return (
            {
              kpiData: e.competencyData,
              questionData: e.questionData
            }
          )
        }),
      }


      let allCompetencies = questions.map(e => e.competencyData)


      setAllCategories(categoriesData)
      setAllCompetencies(allCompetencies)
      setPulseComptencies(allCompetencies)
      setPulseComptency(allCompetencies.map(e => e._id))

      setAllSubGroups(reviewers?.subGroupsData)
      setJobRoles(reviewers?.jobRoles)
      setFormData(pre => ({ ...pre, ...data }))

    } catch (error) {
      toast.error(error?.response?.data?.error)
    }
    finally {
      setFetchJobRoleLoader(false)
      setFetchSubgroupLoader(false)
      setFetchKpiLoader(false)
    }
  }




  // empty field on change of some fields 
  function emptyFields(fieldName) {
    switch (fieldName) {
      case "Pulse":
        // dropdown states
        setAllCategories([])
        if (formData.type === "Pulse") {
          setAllSubGroups([])
          setJobRoles([])
        }

        setAllCompetencies([])
        setPulseComptency([])
        // formdata changes 
        setFormData(pre => {
          return ({
            ...pre,
            kpiQuestions: [],
            previousSurveyId: null,
            reviewers: {
              subGroupIds: [],
              jobRoleIds: [],
              individuals: []
            },
            surveyAssignments: {
              subGroupIds: [],
              user: {},
              jobRoleId: null
            }
          }
          )
        })
        break;

      default:
        break;
    }
  }

  function setCategoriesAndCompetecies(jobRoleData) {
    let categoryData = jobRoleData?.categories?.map((d) => d?.categoryData).flat(Infinity) || [];
    let allCompetencies = jobRoleData?.categories?.map((d) => d?.competenciesData).flat(Infinity) || [];

    setAllCategories(categoryData)

    if (assessmentType === ASSESSMENT_TYPES.jobRole) {
      // autofill all competencies and category in case of jobrole 
      setAllCompetencies(jobRoleData.categories?.map(d => d.competenciesData).flat(Infinity))
      if (categoryData?.length > 0) {
        // Save all the categoryIds in formData only for job role
        setFormData({ ...formData, categoryIds: categoryData.map((e) => e._id) });
      }
    }
    else {
      // store all competencies in local state for later filtering by category
      setAllCompetenciesOfJobrole(allCompetencies)
    }


  }

  return (
    <>
      {!questionTableVisibility ? (
        <>
          <BreadCrum dataArr={breadCrumData} />
          <br />
          <div className={style.createAssessment_main_con}>
            <div className={style.assessment_form}>
              <div className={style.groupSelect}>

                <CustomSelect
                  value={
                    formData.groupId
                      ? allGroups.length !== 0
                        ? allGroups.find((e) => e.id === formData.groupId)
                        : null
                      : null
                  }
                  options={allGroups}
                  disabled={mode === "Edit"}
                  optionLabel={"name"}
                  error={requiredField.groupId}
                  keyLabel={"id"}
                  onChange={(e, val) => {
                    setFormData((pre) => ({
                      ...pre,
                      groupId: val && val.id,
                      subGroupIds: [],
                      siIds: [],
                      kpiQuestions: [],
                      dpdId: null,
                      surveyAssignments: {
                        subGroupIds: [],
                        user: {},
                        jobRoleId: null
                      },
                      reviewers: {
                        subGroupIds: [],
                        jobRoleIds: [],
                        individuals: []
                      },
                    }));
                    setDevelopmentPlans([])
                    setAllCategories([])
                    setAllTpJobRoles([])
                    setAllCompetencies([])
                    resetErrorFields();
                    setAllSubGroups([]);
                    setJobRoles([])
                    setAllUsers([])
                  }}
                  label={t("Group Name")}
                  renderOption={"name"}
                />
              </div>
              <div className={style.assessment_name_section}>

                {assessmentType === ASSESSMENT_TYPES.individual &&
                  <div className={style.assessment_type_con_grid_con}>
                    <div>
                      <label>{t("Type")}:</label>
                      <FormControl sx={{ height: "40px" }}>
                        <RadioGroup

                          aria-labelledby="demo-radio-buttons-group-label"
                          row
                          value={formData.type}
                          name="Type"
                          onChange={(e) => {
                            setFormData({ ...formData, type: e.target.value });
                            if (e.target.value === "Pulse") {
                              emptyFields("Pulse")
                            }
                            else {
                              emptyFields("Pulse")
                              if (formData.groupId) {
                                getjobRoles()
                                fetchTpJobRolesByGroupId()
                                getsubgroups()
                              }
                            }

                          }}
                        >
                          {CREATE_ASSESSMENT_TYPE.map((res, key) => {
                            return (
                              <FormControlLabel
                                key={key}
                                value={res}
                                control={
                                  <Radio
                                    disabled={mode === "Edit"}
                                    sx={{
                                      "&, &.Mui-checked": {
                                        color: "rgba(82, 96, 109, 1)",
                                      },
                                    }}
                                  />
                                }
                                label={res}
                              />
                            );
                          })}
                        </RadioGroup>
                      </FormControl>
                    </div>
                    {assessmentType === ASSESSMENT_TYPES.individual &&
                      <div>
                        {(userJobroleData?.jobRole || userJobroleData?.email) && <div><label>{t("jobRole.userDetail")}:</label></div>}

                        <div>
                          {userJobroleData?.jobRole && <div>
                            <span>Name : </span>
                            <span>{userJobroleData?.name}</span>
                          </div>}
                          {userJobroleData?.jobRole && <div>
                            <span>Job Role : </span>
                            <span>{userJobroleData?.jobRole || "--"}</span>
                          </div>}
                          {userJobroleData?.email &&
                            <div>
                              <span>Email : </span>
                              <span>{userJobroleData?.email || "--"}</span>
                            </div>}


                        </div>

                      </div>}
                  </div>
                }

                <br />
                <div className={style.grid_con}>
                  <>
                    {assessmentType === ASSESSMENT_TYPES.jobRole && (
                      <div>
                        <Autocomplete
                          fullWidth
                          value={
                            formData?.surveyAssignments?.jobRoleId
                              ? allTpJobRoles.length !== 0
                                ? allTpJobRoles.find(
                                  (e) => e.id === formData?.surveyAssignments?.jobRoleId
                                )
                                : null
                              : null
                          }
                          disabled={isEditDisabled}
                          // options={jobRoles}
                          options={allTpJobRoles}
                          loading={fetchJobRoleLoader}
                          className='bg_white'

                          getOptionLabel={(option) => option?.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("jobRole.jobRole")}
                              variant="outlined"
                              error={requiredField.jobRoleId}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {fetchJobRoleLoader ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                ),
                              }}
                            />
                          )}
                          onChange={(e, val) => {

                            if (!val?.id) {
                              setAllCategories([])
                              setAllCompetencies([])
                              setDevelopmentPlans([])
                            }

                            // setJobRoleId(val && val?._id)
                            setJobRoleId(val?.id)

                            setFormData((pre) => ({
                              ...pre,
                              name: val?.name ? `${val?.name} Assessment` : "",
                              dpdId: null,
                              surveyAssignments: {
                                ...pre.surveyAssignments,
                                jobRoleId: val && val?.id,
                              }
                            }));
                            if (mode === "Edit") {
                              setUpdatedData((pre) => ({
                                ...pre,
                                surveyAssignments: {
                                  ...formData.surveyAssignments,
                                  jobRoleId: val && val?.id,
                                }
                              }));
                            }
                            resetErrorFields();
                          }}
                          renderOption={(props, option) => (
                            <li {...props} key={option?.id}>
                              {option?.name}
                            </li>
                          )}
                        />

                      </div>
                    )}
                  </>
                  <>
                    {assessmentType === ASSESSMENT_TYPES.individual && (
                      <div>
                        <Autocomplete
                          fullWidth
                          value={allUsers?.find(e => e.id == formData?.surveyAssignments?.user?.userId) || null}
                          loading={fechUserloader}
                          disabled={isEditDisabled}

                          // filter users by job role id
                          options={allUsers.filter(e => e.job_role_id && e.role === "user")}
                          className='bg_white'
                          getOptionLabel={(option) => option?.username}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("Users")}
                              variant="outlined"
                              error={requiredField.user}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {fechUserloader ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                ),
                              }}

                            />
                          )}
                          onChange={(e, val) => {
                            setAllCompetencies([])
                            emptyFields("Pulse")
                            setPreviousSurveyData([])
                            setUserJobRoleData({
                              jobRole: val?.job_role_label || null,
                              name: val?.userProfile?.firstname + " " + val?.userProfile?.lastname || null,
                              email: val?.email || null
                            })
                            let data = {
                              userId: val?.id || null,
                              email: val?.email || null,
                              first_name: val?.userProfile?.firstname || null,
                              last_name: val?.userProfile?.lastname || null,
                              job_role: val?.job_role_label || null,
                              jobRoleId: val?.job_role_id || null
                            }
                            setFormData((pre) => ({
                              ...pre,
                              kpiQuestions: [],
                              surveyAssignments: {
                                ...pre.surveyAssignments,
                                user: data
                              }
                            }));
                            if (mode === "Edit") {
                              setUpdatedData((pre) => ({
                                ...pre,
                                surveyAssignments: {
                                  ...formData.surveyAssignments,
                                  user: data
                                }
                              }));
                            }
                          }}
                          renderOption={(props, option) => (
                            <li {...props} key={option?.id}>
                              {option?.username}
                            </li>
                          )}
                        />
                      </div>
                    )}
                  </>
                  <div className={style.flex_grew_parent}>

                    {formData.type === "Pulse" ?
                      <div className={style.flex_grew_child}>
                        <CustomSelect
                          disabled={formData.type === "Baseline" || !formData?.surveyAssignments?.user?.userId || isEditDisabled}
                          value={previousSurveyData.find(e => e._id === formData?.previousSurveyId) || null}
                          className="bg-white"
                          loading={fetchPreviousSurveyLoader}
                          options={previousSurveyData}
                          optionLabel={"name"}
                          keyLabel={"_id"}
                          onChange={(e, val) => {
                            setFormData(pre => ({
                              ...pre, previousSurveyId: val && val._id
                            }))
                            if (val?._id) {
                              fetchPreviousAssessmentData(val._id)
                            }
                            else {
                              emptyFields("Pulse")
                            }
                          }}
                          label={t("Assessment.Previous survey")}
                          renderOption={"name"}
                        />
                      </div>
                      : null}

                    {formData?.type === "Baseline" && <div className={style.flex_grew_child}>
                      <CustomSelect
                        className="bg-white"
                        error={requiredField?.dpdId}
                        value={
                          formData.dpdId
                            ? developmentPlans.length !== 0
                              ? developmentPlans.find((e) => e._id === formData.dpdId)
                              : null
                            : null
                        }
                        loading={fetchDevelopmentPlanLoader}
                        options={developmentPlans}
                        // disabled={mode === "Edit"}
                        optionLabel={"name"}
                        // keyLabel={"_id"}
                        onChange={(e, val) => {
                          setFormData({
                            ...formData,
                            kpiQuestions: [],
                            dpdId: val?._id
                          })
                          setAllCompetencies([])
                          setAllCategories([])
                          if (mode === "Edit") {
                            setUpdatedData((pre) => ({
                              ...pre,
                              dpdId: val?._id
                            }));
                          }
                          resetErrorFields();
                        }}
                        label={t("developmentPlan.developmentPlans")}
                        renderOption={"name"}
                      />
                    </div>}

                    {assessmentType !== ASSESSMENT_TYPES.jobRole &&
                      <div className={style.flex_grew_child}>
                        <CustomDatePicker
                          value={formData.completionDate}
                          fullWidth
                          className="w-100"
                          error={requiredField?.completionDate}
                          disablePast
                          label={t("Completion Date")}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              completionDate: e
                                ? new Date(e.toString()).toISOString()
                                : null,
                            });
                            if (mode === "Edit") {
                              setUpdatedData((pre) => ({
                                ...pre,
                                completionDate: e
                                  ? new Date(e.toString()).toISOString()
                                  : null,
                              }));
                            }
                            resetErrorFields();
                          }}
                        />
                      </div>
                    }

                  </div>

                  <div>
                    <TextField
                      disabled={isEditDisabled || assessmentType === ASSESSMENT_TYPES.jobRole}
                      value={formData.name}
                      label={t("Assessment.Assessment Name")}
                      error={requiredField.name}
                      className="bg-white"
                      onChange={(e) => {
                        setFormData({ ...formData, name: e.target.value });
                        if (mode === "Edit") {
                          setUpdatedData((pre) => ({
                            ...pre,
                            name: e.target.value,
                          }));
                        }
                        resetErrorFields();
                      }}
                      fullWidth
                    />
                  </div>
                  <div>
                    {assessmentType !== ASSESSMENT_TYPES.jobRole &&
                      <TextField
                        value={formData.description}
                        disabled={isEditDisabled}
                        className="bg-white"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            description: e.target.value,
                          });
                          if (mode === "Edit") {
                            setUpdatedData((pre) => ({
                              ...pre,
                              description: e.target.value,
                            }));
                          }
                          resetErrorFields();
                        }}
                        label={t("Description")}
                        fullWidth
                      />}
                  </div>

                </div>
                <br />

                <div><label>{t("Reviewers")}:</label></div>
                <div className={style.reviewer_input_con}>
                  <div>

                    <CustomAutoComplete
                      disabled={isEditDisabled}
                      options={allSubGroups}
                      renderTagsClassName={style.autocomplete_wrapper}
                      optionLabelKey={"name"}
                      value={allSubGroups.filter(d => formData?.reviewers?.subGroupIds.includes(d._id))}
                      label={t("Sub Groups")}
                      error={requiredField?.reviewers}
                      onChange={(event, newValue) => {
                        setFormData({
                          ...formData,
                          reviewers: {
                            ...formData.reviewers,
                            subGroupIds: newValue.map(e => e._id),
                          }
                        })
                        if (mode === "Edit") {
                          setUpdatedData((pre) => ({
                            ...pre,
                            reviewers: {
                              ...formData.reviewers,
                              subGroupIds: newValue.map(e => e._id),
                            }
                          }));
                        }
                        resetErrorFields();
                      }}
                      loader={fetchSubgroupLoader}
                    />
                  </div>
                  <div>

                    <CustomAutoComplete
                      disabled={isEditDisabled}
                      options={allTpJobRoles}
                      renderTagsClassName={style.autocomplete_wrapper}
                      optionLabelKey={"name"}
                      value={allTpJobRoles?.filter(d => formData?.reviewers?.jobRoleIds.includes(d.id))}
                      onChange={(event, newValue) => {
                        setFormData((pre) => ({
                          ...pre,
                          reviewers: {
                            ...pre.reviewers,
                            jobRoleIds: newValue.map(e => e.id),
                          }
                        }))
                        if (mode === "Edit") {
                          setUpdatedData((pre) => ({
                            ...pre,
                            reviewers: {
                              ...formData.reviewers,
                              jobRoleIds: newValue.map(e => e.id),
                            }
                          }));
                        }
                        resetErrorFields();
                      }}
                      label={t("jobRole.Job Roles")}
                      error={requiredField?.reviewers}
                      loader={fetchTPJobRoleLoader}
                    />
                  </div>
                  <div>

                    <CustomAutoComplete
                      disabled={isEditDisabled}
                      optionLabelKey={"name"}
                      isOptionEqualToValue={(option, value) => option.userId == value.userId}
                      renderTagsClassName={style.autocomplete_wrapper}
                      options={allUsers.map(extractUserDetails)}
                      value={allUsers.map(extractUserDetails).filter(e => formData?.reviewers?.individuals.some(d => d.userId == e.userId))}
                      onChange={(event, newValue) => {

                        setFormData((pre) => ({
                          ...pre,
                          reviewers: {
                            ...pre.reviewers,
                            individuals: newValue,
                          }
                        }))
                        if (mode === "Edit") {
                          setUpdatedData((pre) => ({
                            ...pre,
                            reviewers: {
                              ...formData.reviewers,
                              individuals: newValue,
                            }
                          }));
                        }
                        resetErrorFields();
                      }}
                      renderOption={(props, option, { selected }) => {
                        return (
                          <li {...props}>
                            <CustomCheckbox
                              className="me-6"
                              checked={formData.reviewers?.individuals.some(e => e.userId == option.userId)}

                              style={{ marginRight: 8 }}
                            />
                            {option?.name}
                          </li>
                        )
                      }}
                      label={t("Users")}
                      error={requiredField?.reviewers}
                      loader={fechUserloader}
                    />
                  </div>
                </div>


              </div>
            </div>
            <br />
            <br />
            <div className={style.assessment_kpi_sec}>
              <div className={style.header}>
                {t("competency.Competencies for the Job Role")}*
              </div>


              {fetchKpiLoader ? (
                <>
                  <br />
                  <LinearProgress />
                </>
              ) : (
                <>
                  {/* {

                    (() => {
                      let ind = 0
                      return allCategories.map((category) => {
                        return (
                          <>

                            <div className={style.category_name}>{category?.name}</div>
                            {allCompetencies.filter(e => e.categoryId == category._id).map((res, index) => {

                              let allQuestions = formData?.kpiQuestions?.find(
                                (e) => e.kpiData?._id === res._id
                              );
                              return (
                                <div
                                  key={res._id}
                                  className={style.assessment_dropdown_con}
                                >
                                  <AssessmentDropDownMenu
                                    currentIndex={ind++ || 0}
                                    openIndex={openDropDownIndex || 0}
                                    questionData={allQuestions}
                                    data={res}
                                    setOpenIndex={(e) => {
                                      console.log(e, "okokok")
                                      setOpenDropDownIndex(e)
                                    }}
                                    addRemoveButtons={() => {
                                      return (
                                        <>
                                          {(formData.type === "Baseline" && !isEditDisabled) ? (
                                            <span
                                              onClick={() => {
                                                addQuestion(res);
                                                setOpenDropDownIndex(ind++);
                                              }}
                                              className="pointer flex align_center add_more"
                                            >
                                              <AddCircle />
                                              &nbsp;
                                              <span>
                                                {allQuestions?.questionData?.length > 0
                                                  ? t("Assessment.Add/Remove Questions")
                                                  : t("Assessment.Add Question")}
                                              </span>
                                            </span>
                                          ) : null}
                                        </>
                                      );
                                    }}
                                  />
                                  
                                </div>
                              );
                            })}
                          </>
                        )
                      })
                    })()

                  } */}



                  {
                    allCategories.map((category, categoryIndex) => {
                      let categoryStartIndex = allCategories
                        .slice(0, categoryIndex)
                        .reduce((acc, cat) => acc + allCompetencies.filter((e) => e.categoryId === cat._id).length, 0);

                      return (
                        <div key={category._id}>
                          <div className={style.category_name}>{category?.name}</div>
                          {allCompetencies
                            .filter((e) => e.categoryId === category._id)
                            .map((res, index) => {
                              let currentIndex = categoryStartIndex + index;
                              let allQuestions = formData?.kpiQuestions?.find(
                                (e) => e.kpiData?._id === res._id
                              );
                              return (
                                <div key={res._id} className={style.assessment_dropdown_con}>
                                  <AssessmentDropDownMenu
                                    currentIndex={currentIndex}
                                    openIndex={openDropDownIndex || 0}
                                    questionData={allQuestions}
                                    data={res}
                                    setOpenIndex={(e) => {
                                      setOpenDropDownIndex(e);
                                    }}
                                    addRemoveButtons={() => {
                                      return (
                                        <>
                                          {formData.type === "Baseline" && !isEditDisabled ? (
                                            <span
                                              onClick={() => {
                                                addQuestion(res);
                                                setOpenDropDownIndex(currentIndex);
                                              }}
                                              className="pointer flex align_center add_more"
                                            >
                                              <AddCircle />
                                              &nbsp;
                                              <span>
                                                {allQuestions?.questionData?.length > 0
                                                  ? t("Assessment.Add/Remove Questions")
                                                  : t("Assessment.Add Question")}
                                              </span>
                                            </span>
                                          ) : null}
                                        </>
                                      );
                                    }}
                                  />
                                </div>
                              );
                            })}
                        </div>
                      );
                    })
                  }



                </>
              )}
            </div>
          </div>
          <br />
          <div className="end">
            <button className="btn-primary" onClick={formSubmit}
              disabled={assessmentType === ASSESSMENT_TYPES.jobRole && submitBtnDisabled}
            >
              {mode === "Create" ? t("Create") : t("Update")}
            </button>
            &nbsp;&nbsp;
            <Link to={`/assessment/${assessmentType}`}>
              <button className="btn-secondary">{t("Cancel")}</button>
            </Link>
          </div>
          <ConfirmationDialog
            onSubmit={() => {
              if (jobRoleValidation.status === 409) {
                setSubmitDiasbled(false)
                setFormData(pre => ({ ...pre, name: pre.name + " 2" }))
              }
              setJobRoleValidation({
                error: false,
                errorMsg: "",
                status: null
              })
            }}
            noClosable
            heading={"Warning"}
            text={jobRoleValidation.errorMsg}
            open={jobRoleValidation.error}
          />
        </>
      ) : (
        <QuestionTable
          assessmentName={formData.name}
          onContinue={addQuestionSubmit}
          onCancel={() => setQuestionTableVisibility(false)}
          currentKPI={currentKPI}
          usedFor={"competency"}
        />
      )
      }
    </>
  );
};


function CompetencyDropdown({ allQuestions, currentIndex, res, addRemoveButtons }) {

  const [ind, setInd] = useState(0)

  return (
    <AssessmentDropDownMenu
      currentIndex={currentIndex}
      openIndex={ind}
      questionData={allQuestions}
      data={res}
      setOpenIndex={setInd}
      addRemoveButtons={addRemoveButtons}
    />
  );

}