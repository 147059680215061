import React, { useEffect, useState } from 'react'
import { BreadCrum } from '../../components/Common/BreadCrum'
import style from "../../assets/css/views/reports.module.css"
import { ASSESSMENT_TYPES, CREATE_ASSESSMENT_TYPE, REPORTS_TABS } from '../../constants/constants'
import { CustomPagination } from '../../components/Common/CustomPagination'
import { useTranslation } from 'react-i18next'
import { LinearProgress } from '@mui/material'
import { useParams } from 'react-router'
import { CustomSelect } from '../../components/Inputs/CustomSelect'
import { fetchAssessments } from '../../api/Assessment'
import { generateComparisonAssessmentReport, generateIndividualSurveyAssessmentReport } from '../../api/Reports'
import { toast } from '../../utils'
import { searchDataWithKey } from '../../utils/search'
import { getSubGroupByGroupId } from '../../api/Group'
import { DownloadButton } from '../../components/Buttons/DownloadButton'
import { DownloadPdf } from '../../components/Common/DownloadPdf'
import { NoTableData } from '../../components/Common/NoTableData'
import { useSelector } from 'react-redux'
import { MultiCircularProgressBar } from '../../components/Common/MultiCircularProgressBar'
import { MultipleCircularProgressCard } from '../../components/Common/Reports/MultipleCircularProgressCard'
import { MultipleProgressLineTable } from '../../components/Common/Reports/MultipleProgressLineTable'


export const ReportsDetailIndividual = () => {
  const tokenStatus = useSelector((state) => state.tokenStatus);
  const { assessmentType } = useParams()
  let [reportType, setReportType] = useState("Report")
  let [activeType, setActiveType] = useState("Baseline")
  let { t } = useTranslation()
  let [groupName, setGroupName] = useState("")
  let [page, setPage] = useState(1)

  let [reportAllData, setReportAllData] = useState({
    categoriesData: [],
    competencyData: []
  })
  let [allData, setAllData] = useState({
    categoriesData: [],
    competencyData: []
  })


  let [searchValue, setSearchValue] = useState("")

  let [dataLoader, setDataLoader] = useState(true)

  let [isError, setIsError] = useState({
    error: true,
    errorMessage: ""
  })

  let [allAssessmentCycle, setAllAssessmentCycle] = useState([])

  let [reportData, setReportData] = useState({ kpiData: [], siData: [] })


  let itemPerPage = 20
  let [filterInputValues, setFilterInputValues] = useState({
    assessmentCycle: null
  })

  let params = useParams()

  function pageChange(e) {
    setPage(e)
  }


  const downloadClickHandler = () => {
    const input = document.getElementById('pdf-content');
    return input;
  };



  let breadCrumData = [
    {
      text: t("Reports"),
      path: `/reports/${assessmentType}`
    },
    {
      text: t(assessmentType),
    },
    {
      text: t("Details"),
    },

  ]

  async function getAssessmentCycleData() {

    setIsError({
      error: false,
      errorMessage: ""
    })
    setReportAllData({
      categoriesData: [],
      competencyData: []
    })
    setAllData({
      categoriesData: [],
      competencyData: []
    })

    setAllAssessmentCycle([])
    let query = {
      "$and": [
        { type: activeType },
        { assessmentType: assessmentType },
        { status: "Completed" },
        { groupId: Number(params.id) }
      ]
    }
    try {
      let res = await fetchAssessments(query)
      setGroupName(res.data.groupName)
      if (res.data.response.length > 0) {
        let data = res.data.response
        setAllAssessmentCycle(data)
        setFilterInputValues(pre => ({ ...pre, assessmentCycle: data?.[0] || null }))
      }
      else {
        setIsError({
          error: true,
          errorMessage: "No Reports Available"
        })
      }
    }

    catch (err) {

      setIsError({
        error: true,
        errorMessage: err?.response?.data?.error
      })
    }
    finally {
      setDataLoader(false)
    }
  }

  useEffect(() => {
    setSearchValue("")
    getAssessmentCycleData()
    if (reportType === "Report") {
    }
    else {

      if (filterInputValues?.assessmentCycle?._id) {

        if (activeType == "Pulse") {
          // setActiveType("Baseline")
          // getAssessmentCycleData()
        }
        else {
          getComparisionReport(filterInputValues?.assessmentCycle?._id)
        }


      }
    }
  }, [activeType, reportType])


  useEffect(() => {
    setSearchValue("")
    if (filterInputValues?.assessmentCycle?._id) {
      if (reportType === "Report") {
        getReport(filterInputValues?.assessmentCycle?._id)
      }
      else {
        getComparisionReport(filterInputValues?.assessmentCycle?._id)
      }

    }

  }, [filterInputValues.assessmentCycle])

  async function getComparisionReport(asmntId) {
    try {
      setDataLoader(true)
      setIsError({
        error: false,
        errorMessage: ""
      })
      setReportAllData({
        categoriesData: [],
        competencyData: []
      })
      setAllData({
        categoriesData: [],
        competencyData: []
      })
      let body = {
        assessmentId: asmntId,
        comparisonType: reportType
      }
      let res = await generateComparisonAssessmentReport(body)
      let formattedData = formatReportData(res.data.response)
      setReportAllData(formattedData)
      setAllData(formattedData)
    } catch (err) {
      setIsError({ errorMessage: err?.response?.data?.error, error: true })
    }
    finally {

      setDataLoader(false)
    }
  }


  async function getReport(asmntId) {

    setIsError({
      error: false,
      errorMessage: ""
    })
    setReportAllData({
      categoriesData: [],
      competencyData: []
    })
    setAllData({
      categoriesData: [],
      competencyData: []
    })
    try {
      setDataLoader(true)
      setIsError(pre => ({ ...pre, error: false }))
      let res = await generateIndividualSurveyAssessmentReport(asmntId)
      setDataLoader(false)

      let formattedData = {}
      if (activeType === "Baseline") {
        formattedData = formatReportData(res?.data?.response)
      }
      else if (activeType === "Pulse") {
        // Merge baseline data with pulse data 

        let baselineFormattedData = formatReportData(res?.data?.response?.baseLineAssessment)
        let pulseFormattedData = formatReportData(res?.data?.response?.pulseAssessment)
        
        formattedData.categoriesData = pulseFormattedData?.categoriesData?.map(res => ({ ...res, value2: baselineFormattedData?.categoriesData?.find(r => r.id == res.id)?.value1 }))
        formattedData.competencyData = pulseFormattedData?.competencyData?.map(res => ({ ...res, value2: baselineFormattedData?.competencyData?.find(r => r.id == res.id)?.value1, heading1: "Pulse", heading2: "Baseline" }))
      }      

      setReportAllData(formattedData)
      setAllData(formattedData)

    }
    catch (err) {
      setReportData([])
      console.log(err)
      setDataLoader(false)
      setIsError({ errorMessage: err?.response?.data?.error, error: true })
    }
  }

  function formatReportData(data) {
    let obj = {
      categoriesData: [],
      competencyData: []
    }

    // format category data for category chart
    obj.categoriesData = data.categoriesData.map(e => {
      if (reportType === "Combined") {
        return ({
          name: e?.name,
          value1: e?.categoryScore?.toFixed(1),
          value2: e?.reviewersScore?.toFixed(1),
          value3: e?.jobRoleScore?.toFixed(1),
          active: false,
          id: e?._id
        })
      }
      else if (reportType === "JobRole") {
        return ({
          name: e?.name,
          value1: e?.categoryScore?.toFixed(1),
          value2: e?.jobRoleScore?.toFixed(1),
          active: false,
          id: e?._id
        })
      }
      else{
        return ({
          name: e?.name,
          value1: e?.categoryScore?.toFixed(1),
          value2: e?.reviewersScore?.toFixed(1),
          active: false,
          id: e?._id
        })
      }
    })

    // format competency data for competency table 
    obj.competencyData = data.questions.map((e) => {

      if (reportType === "Combined") {
        return ({
          name: e?.competencyData?.name,
          value1: e?.competencyData?.competencyScore,
          value2: e?.competencyData?.reviewersScore,
          value3: e?.competencyData?.jobRoleScore,
          heading1: t("Individual"),
          heading2: t("Reviewers"),
          heading3: t("jobRole.jobRole"),
          categoryId: e?.competencyData?.categoryId,
          id: e?.competencyData?._id

        })
      }
      else if (reportType === "JobRole") {

        return ({
          name: e?.competencyData?.name,
          value1: e?.competencyData?.competencyScore,
          value2: e?.competencyData?.jobRoleScore,
          categoryId: e?.competencyData?.categoryId,
          heading1: t("Individual"),
          heading2: t("jobRole.jobRole"),
          id: e?.competencyData?._id
        })

      }
      else{
        return ({
          name: e?.competencyData?.name,
          value1: e?.competencyData?.competencyScore,
          value2: e?.competencyData?.reviewersScore,
          categoryId: e?.competencyData?.categoryId,
          heading1: t("Individual"),
          heading2: t("Reviewers"),
          id: e?.competencyData?._id
        })
      }

    })

    return obj
  }

  function cardClick(categoryData, ind) {
    setSearchValue("")
    setReportAllData((prevState) => {
      if (prevState.categoriesData[ind].active) {
        return ({ ...allData })
      }
      // Update categoriesData by setting all active to false except for the selected index
      const updatedCategoriesData = prevState.categoriesData.map((category, index) => ({
        ...category,
        active: index === ind, // Set active to true only for the category at the given index
      }));

      // Filter the competencyData based on the selected categoryId
      const updatedCompetencyData = allData.competencyData.filter(e => e.categoryId === categoryData.id);

      return {
        ...prevState,
        categoriesData: updatedCategoriesData,
        competencyData: updatedCompetencyData,
      };
    });
  }

  useEffect(() => {

    setReportAllData((prevState) => {
      const updatedCompetencyData = searchDataWithKey("name", allData.competencyData, searchValue)

      return {
        ...prevState,
        competencyData: updatedCompetencyData,
      };
    });


  }, [searchValue])

  return (
    <div className={`padding_22_44 ${style.reports_detail_con}`}>
      <BreadCrum dataArr={breadCrumData} />
      <br />

      <div id="pdf-content">
        <section className={style.report_filter_con}>
          <div className={style.header}>
            <div className="between">
              <div className="header_4">{groupName}</div>
              <DownloadPdf
                onSubmit={downloadClickHandler}
                fileName={`${filterInputValues.assessmentCycle?.name}`}
              //   onBeforeGenerate={removePdfContentSection}
              >
                <DownloadButton />
              </DownloadPdf>
            </div>
          </div>
          <div className={style.report_filter} id="pdf-content-section">
            <div className={style.tab_con2}>
              {REPORTS_TABS.map((res, key) => {
                return (
                  <div
                    key={key}
                    onClick={() => {
                      if (activeType === "Pulse") {
                        setActiveType("Baseline")
                      }
                      setReportType(res.value)
                      setDataLoader(true)
                    }}
                    className={`${reportType === res.value ? style.active : ""} pointer`}
                  >
                    {res.label}
                  </div>
                );
              })}
            </div>


            {/* show tabs  if report type is Report */}
            {reportType === "Report" && (
              <div className={style.tab_con}>
                {CREATE_ASSESSMENT_TYPE.map((res, key) => {
                  return (
                    <div
                      key={key}
                      onClick={() => {
                        setActiveType(res);
                        setDataLoader(true);
                      }}
                      className={`${activeType === res ? style.active : ""
                        } pointer`}
                    >
                      {res} Survey
                    </div>
                  );
                })}
              </div>
            )}
            <div className={style.filter_input_con}>
              <div className="w-40">
                <CustomSelect
                  value={allAssessmentCycle?.find((e) => e?._id === filterInputValues?.assessmentCycle?._id) || null}
                  options={allAssessmentCycle}
                  optionLabel={"name"}
                  keyLabel={"_id"}
                  onChange={(e, val) => {
                    setFilterInputValues((pre) => ({
                      ...pre,
                      assessmentCycle: val ? val : null,
                    }));
                  }}
                  label={t("Assessment.Assessment Name")}
                  renderOption={"name"}
                />
              </div>







              {/* <div className='w-20'>
                                <FormControl fullWidth>
                                    <InputLabel>{t("Sub Groups")}</InputLabel>
                                    <Select

                                        multiple
                                        value={subGroups}
                                        onChange={(e) => {
                                            {
                                                setSubGroups(e.target.value)
                                            }
                                        }}
                                        input={<OutlinedInput label={t("Sub Groups")} />}
                                        renderValue={(selected) => allSubGroups.filter(e => selected.includes(e._id))?.map(e => e.name).join("/")}
                                    >
                                        {allSubGroups?.map((res) => (
                                            <MenuItem key={res._id} value={res._id}>
                                                <CustomCheckbox className="me-6" checked={subGroups.includes(res._id)} />
                                                <ListItemText primary={res.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div> */}
              {/* <div className='w-20'>

                                <FormControl fullWidth>
                                    <InputLabel>{t("Priority")}</InputLabel>
                                    <Select

                                        value={priority}
                                        label={t("Priority")}
                                        onChange={(e) => setPriority(e.target.value)}
                                    >
                                        {REPORTS_PRIORITY.map((res, key) => {
                                            return <MenuItem key={key} value={res}>{res}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </div> */}
              {/* <div className='w-20'>
                                <CustomDatePicker label={t("Period")} />

                            </div> */}
            </div>
          </div>
        </section>

        {
          // reportType != "Report" && (
          <h3>
            {REPORTS_TABS?.find(e => e.value == reportType)?.label || "Report"}
          </h3>
          // )
        }

        {dataLoader ? (
          <div>
            <br />
            <LinearProgress />
          </div>
        ) : (
          <>
            {isError?.error ? (
              <div className="body_1">
                <br />
                <NoTableData text={isError?.errorMessage} />
              </div>
            ) : (
              <section className={style.progress_bar_con}>

                <div className={style.heading}>

                  {t("category.categories")}

                </div>


                <div className={style.card_section_con}>

                  <div className={style.card_viewAll_container}>
                    {reportAllData?.categoriesData?.map((res, ind) => {
                      return (
                        <MultipleCircularProgressCard
                          key={ind}
                          active={res.active}
                          title={res.name}
                          onClick={() => cardClick(res, ind)}
                          showChart={true}
                          values={[res?.value1, res?.value2, res?.value3].filter(e => e)}
                        />
                      )
                    })}



                  </div>
                </div>



                <br />

                <div className={`subtitle_2`}>
                  {assessmentType === ASSESSMENT_TYPES.organizational
                    ? t("Key performance indicators (KPI)")
                    : t("competency.competencies")}
                </div>
                <div className={style.kpi_table_con}>
                  <MultipleProgressLineTable
                    searchAttributes={{
                      value: searchValue,
                      onChange: (e) => {
                        setSearchValue(e.target.value)
                      },
                      className: "search_inp",
                      placeholder: t("Search"),
                    }}
                    data={reportAllData?.competencyData || []}
                  />

                  <div className={style.pagination_con}>
                    <CustomPagination
                      page={page - 1}
                      pageChange={pageChange}
                      count={reportData?.kpiData?.length || 0}
                      perPage={itemPerPage}
                    />
                  </div>
                </div>
              </section>
            )}
          </>
        )}
      </div>
    </div>
  );
}
