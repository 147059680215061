import React from 'react'
import { Navigate, Outlet } from 'react-router'
import { currentUserRole } from '../utils';

export const ProtectedRoute = ({ authUsers, navigateUrl }) => {

    let isAuth = false;
    let currentRole = currentUserRole()
    if (authUsers.some(e => e === currentRole)) {
        isAuth = true
    }


    return isAuth ? (
        <Outlet />
    ) : (
        <Navigate to={navigateUrl} replace />
    );
}
