import React from 'react'
import { AddCircle } from "@mui/icons-material"
import style from "../../../assets/css/components/kpi&sicommon.module.css"
import { KPISIPreviewDropdown } from "./KPISIPreviewDropdown"
import { useLocation, useNavigate, useParams } from "react-router"
import { useTranslation } from 'react-i18next'
import { isUserCreateUpdateData } from '../../../utils'


export const KPISIPreview = ({ data, type, removeDataAfterDelete }) => {
    const addMore = new URLSearchParams(useLocation().search).get('addMore');
    const groupName = new URLSearchParams(useLocation().search).get('groupName');
    let navigate = useNavigate()
    let { id } = useParams()
    let { t } = useTranslation()

    function addMoreKPISI() {
        if (type === "KPI") {
            navigate(`/KPI/create?id=${id}`)
        }
        else {
            navigate(`/strategicImperatives/create?id=${id}`)
        }
    }


    return (

        <div className={style.KPISI_perview_con}>
            <div className={style.heading}>
                {data.groupName ?? "Not Found"}
            </div>
            {data.data.map((res, key) => {
                return (
                    <KPISIPreviewDropdown removeDataAfterDelete={removeDataAfterDelete} type={type} key={key} data={res} />
                )
            })}
            <br />

            {isUserCreateUpdateData() ?
                <div className="add_more" onClick={addMoreKPISI}>
                    <span className="pointer flex align_centers">
                        <AddCircle />&nbsp;
                        <span>
                            {type === "KPI" ? t("KPI.Add Key Performance Indicator") : t("SI.Add Strategic Imperatives")}
                        </span>
                    </span>
                </div> : null}

        </div>
    )
}