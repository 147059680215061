import React from 'react'
import style from "../../assets/css/components/common.module.css"
import { ArrowBackIos } from '@mui/icons-material'
import { useNavigate } from 'react-router'


export const BreadCrum = ({ dataArr }) => {
    let navigate = useNavigate();

    return (
        <div className={style.breadCrum_con}>
            {dataArr.map((res, key) => {
                return (
                    <div key={key} onClick={() => res.path && navigate(res.path)}>
                        <span className={`${key == dataArr.length - 1 ? style.activeTab : ""} ${res.path && style.breadCrum_link + " pointer"}`}>&nbsp;{res.text}</span>
                        {key == dataArr.length - 1 ? null : <span>{" > "}</span>}
                    </div>
                )
            })}
        </div>
    )
}