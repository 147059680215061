
export const searchDataWithString = (searchString, arrayOfObjects) => {

    const filterRecursive = (obj) => {
        return Object.values(obj).some((value) => {
            if (typeof value === 'object' && value !== null) {
                return filterRecursive(value);
            }
            if (Array.isArray(value)) {
                return value.some((item) => filterRecursive(item));
            }
            return typeof value === 'string' && value.toLowerCase().includes(searchString.toLowerCase());
        });
    };

    return arrayOfObjects.filter(filterRecursive);
}

export const searchDataWithKey = (key, allData, searchString) => {
    return allData.filter(e => e[key].trim().toLowerCase().includes(searchString.trim().toLowerCase()))
}

export const searchDataByNestedKey = (dataArr, searchString, outerArrayKey, nestedArrayObjectKey) => {
    return dataArr.filter((res) => res?.[outerArrayKey]?.some(r => r?.[nestedArrayObjectKey]?.toLowerCase()?.includes(searchString?.toLowerCase()?.trim())))

}
export const searchDataWithMultipleKeys = (keyArr, allData, searchString) => {
    return allData.filter((e) => keyArr?.some((d) => e[d]?.trim()?.toLowerCase().includes(searchString?.toLowerCase()?.trim()))
    );
};