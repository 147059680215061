import React, { useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { toast, loader } from "../../utils";

export const DownloadPdf = ({ children, fileName, onSubmit, onBeforeGenerate }) => {
  let [pdfBtnShow, setPdfBtnShow] = useState(true)

  const handleGeneratePDF = async () => {
    setPdfBtnShow(false)
    loader.start();
    // Execute onBeforeGenerate callback if provided
    if (typeof onBeforeGenerate === "function") {
      onBeforeGenerate();
    }
    const pdfContent = onSubmit();
    const dashboardHeight = pdfContent.clientHeight;
    const dashboardWidth = pdfContent.clientWidth;
    // Use html2canvas to capture the HTML content as an image
    html2canvas(pdfContent).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF(
        dashboardWidth > dashboardHeight ? "l" : "p",
        "mm",
        [dashboardWidth, dashboardHeight]
      );
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      // Add the image to the PDF
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

      // Save the PDF
      pdf.save(`${fileName}.pdf`);
      loader.stop();
      setPdfBtnShow(true)
      toast.success("PDF Downloaded Successfully");
    });
  };

  return <div onClick={handleGeneratePDF}>{pdfBtnShow && children}</div>;
};
