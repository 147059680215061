import { createSlice } from "@reduxjs/toolkit";

export const TokenExpireSlice = createSlice({
    name: 'tokenExpire',
    initialState: {
        validToken: true
    },
    reducers: {
        setTokenStatus: (state, action) => {
            return action.payload
        }
    }

})
export const { setTokenStatus } = TokenExpireSlice.actions