import { ExpandLess, ExpandMore } from "@mui/icons-material"
import React, { useState } from "react"
import { useLocation, useNavigate } from "react-router"
import { EditButton } from "../../Buttons/EditButton"
import { DeleteButon } from "../../Buttons/DeleteButton"
import style from "../../../assets/css/components/kpi&sicommon.module.css"
import { deleteKPI } from "../../../api/KPI"
import { deleteSI } from "../../../api/StrategicImperatives"
import { changeDateFormat, isUserCreateUpdateData, loader, toast } from "../../../utils"
import { useTranslation } from "react-i18next"
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"

export const KPISIPreviewDropdown = ({ data, type, removeDataAfterDelete }) => {

    let [expand, setExpand] = useState(false)
    let navigate = useNavigate()
    const toggleExpant = () => setExpand(!expand)
    const addMore = new URLSearchParams(useLocation().search).get('addMore');
    let { t } = useTranslation()

    function editData(e) {

        if (type === "SI") {
            navigate(`/strategicImperatives/edit/${data._id}?${addMore ? "&addMore=true" : ''}`)
        }
        else {
            navigate(`/KPI/edit/${data._id}?${addMore ? "&addMore=true" : ''}`)

        }

    }
    async function deleteData() {
        try {
            loader.start()
            let res;
            if (type === "KPI") {
                res = await deleteKPI(data._id)
            }
            else {
                res = await deleteSI(data._id)
            }
            loader.stop()
            removeDataAfterDelete(data._id)
            toast.success(res.data.message)

        }
        catch (err) {
            toast.error(err?.response?.data?.error)
            loader.stop()
        }
    }

    return (

        <div className={style.view_strat_dropDown_con}>
            <div className="between align_center">
                <div className="flex align_center">
                    <div className="expand_btn">
                        {expand ? <ExpandMore onClick={toggleExpant} /> : <ExpandLess onClick={toggleExpant} />}
                    </div>
                    <div className={style.sub_heading}>{data.name}</div>
                </div>
                {expand && isUserCreateUpdateData() ?
                    <div className="flex">
                        <EditButton
                            confirmation={type === "SI" ? data?.isMappedInKPI : data?.isMappedInAssessment}
                            heading={
                                type === "SI" ?
                                    t("Edit Strategic Imperatives") :
                                    t("Edit Key Performance Indicator")
                            }
                            text={
                                type === "SI" ?
                                    t("confirmationDialog.editSI")
                                    : t("confirmationDialog.editKPI")

                            }
                            data={data}
                            onClick={editData}
                        />&nbsp;&nbsp;
                        <DeleteButon
                            confirmation
                            heading={
                                type === "SI" ?
                                    t("Delete Strategic Imperatives") :
                                    t("Delete Key Performance Indicator")
                            }
                            text={
                                type === "SI" ?
                                    t("confirmationDialog.deleteSI") : t("confirmationDialog.deleteKPI")
                            }
                            id={data}
                            onDelete={deleteData}

                        />
                    </div> : null}
            </div>
            {expand ?

                <>

                    {data?.KPI && data?.KPI.length > 0 &&
                        <>

                            <div className={style.expand_data_si}>
                                <div>
                                    <div className={style.label}>{t("KPI.KPIsGoals")}:</div>
                                </div>
                                <div>
                                    <div className={style.label}>{t('SI.Owner')}: </div>
                                </div>
                                <div>
                                    <div className={style.label}>{t('SI.supervisor')}: </div>
                                </div>
                            </div>
                            {data.KPI.map((res, ind) => {
                                return (
                                    <div className={style.expand_data_si}>
                                        <div className={style.expand_content}>{res?.name}</div>
                                        <div className={style.expand_content}>{res?.owner?.email}</div>
                                        <div className={style.expand_content}>{res?.supervisor?.email}</div>
                                    </div>
                                )
                            })}
                        </>

                    }



                    {/* {type === "SI" ?
                        <> */}

                    {/* <div className={style.expand_data_si}>
                                <div>
                                    <div className={style.label}>{t("Priority")}:</div>
                                    <div className={style.expand_content}>{data.priority}</div>
                                </div>
                                <div>
                                    <div className={style.label}>{t("SI.Measurement Type")}: </div>
                                    <div className={style.expand_content}>{data.measurementType}</div>
                                </div>
                                <div className="w-100">
                                    <div className={style.label}>{t("Estimated Timeline")}: </div>
                                    <div className={`${style.start_date_end_date_con}`}>
                                        <div className={style.expand_content}>{t("Start Date")} : {data.startDate && changeDateFormat(data.startDate)}</div>
                                        <div className={style.expand_content}>{t("End Date")} : {data.endDate && changeDateFormat(data.endDate)}</div>
                                    </div>
                                </div>
                            </div> */}

                </>
                // :
                // <div className={style.expand_data}>
                //     <div>
                //         <div className={style.label}>{t("Strategic Imperatives")}:</div>
                //         <div className={style.expand_content}>{data?.strategicImperativesData?.[0]?.name}</div>
                //     </div>
                //     <div>
                //         <div className={style.label}>{t("KPI.Type of KPI")}: </div>
                //         <div className={style.expand_content}>{data.type}</div>
                //     </div>
                // </div>
                // }
                // </>
                : null}


        </div >
    )
}