import React, { useEffect } from 'react'
import { CustomPagination } from "../../components/Common/CustomPagination"
import { TableContainer, TableHeader } from "../../components/Common/TableContainer"
import { SearchInput } from "../../components/Inputs/SearchInput"
import { useState } from "react"
import style from "../../assets/css/views/reports.module.css"
import { useTranslation } from 'react-i18next'
import { currentUser, currentUserPrimaryGroupId, currentUserRole, loader, toast } from "../../utils"
import { fetchGroups } from "../../api/StrategicImperatives"
import { searchDataWithKey } from '../../utils/search'
import { useNavigate, useParams } from 'react-router-dom'
import { ViewButton } from '../../components/Buttons/ViewButton'
import { NoTableData } from '../../components/Common/NoTableData'
import { useSelector } from 'react-redux'
import { BreadCrum } from '../../components/Common/BreadCrum'
import { ASSESSMENT_TYPES } from '../../constants/constants'

export const Reports = () => {
    const tokenStatus = useSelector((state) => state.tokenStatus);
    let [page, setPage] = useState(1)
    const { assessmentType } = useParams()
    let [reportsData, setReportsData] = useState([])
    let record_per_page = 20
    let loaderP = useSelector(e => e.loader)
    let [totalData, setTotalData] = useState(0)
    let [searchValue, setSearchValue] = useState("")
    let navigate = useNavigate()
    let [allReportsData, setAllReportsData] = useState([])
    let { t } = useTranslation()
    let userRole = currentUserRole()
    let primary_group = currentUserPrimaryGroupId()

    // useEffect(() => {
    //     const checkUserRoleAndNavigate = () => {
    //         let user = currentUser()
    //         if (user.role.item_name === "groupAdmin") {
    //             navigate(`detail/${user.user.primary_group}`)
    //         }
    //     }
    //     checkUserRoleAndNavigate()
    // }, [])

    let [error, setError] = useState(
        {
            error: false,
            errorMessage: "No Reports Available"
        }
    )

    const ii = async () => {
        try {
            setSearchValue("")
            let data = {
                page,
                record_per_page,
                collectionType: "REPORTS",
                assessmentType: assessmentType,
                ...(userRole === 'groupAdmin' ? { primary_groupId: primary_group } : {})
            }
            loader.start()
            let res = await fetchGroups(data)
            setAllReportsData(res.data.response)
            setReportsData(res.data.response)
            setTotalData(res.data.totalGroups)
            loader.stop()
            setError({
                error: false,
                errorMessage: res.data.response.length !== 0 ? "" : "No Reports Available"
            })
        }
        catch (err) {
            setError({
                error: false,
                errorMessage: err?.response?.data?.error
            })
            // toast.error(err?.response?.data?.error)
        }
    }
    useEffect(() => {
        ii()
    }, [page, tokenStatus, assessmentType])

    function pageChange(e) {
        setPage(e)
    }
    function searchGroups(event) {
        setError({
            error: false,
            errorMessage: "No Reports Available"
        })
        setSearchValue(event.target.value)
        setReportsData(searchDataWithKey("groupName", allReportsData, event.target.value))
    }
    let breadCrumData = [
        {
            text: t("Reports"),
        },
        {
            text: t(assessmentType),
        },

    ]

    function onClickButton(data) {

        if (assessmentType === ASSESSMENT_TYPES.individual) {
            navigate(`detailIndividual/${data.groupId}`)

        }
        else {
            navigate(`detail/${data.groupId}`)
        }
    }

    return (
        <div className={`${style.reports_con} padding_22_44`}>

            <div className="between">
                <BreadCrum dataArr={breadCrumData} />


            </div>
            <TableHeader>
                <div className={style.search_inp}>
                    <SearchInput value={searchValue} onChange={searchGroups} placeholder={t("Search")} />
                </div>
            </TableHeader>

            <TableContainer>
                <div className={style.scroll_part}  >
                    <table>
                        <thead>
                            <tr>
                                <th>{t("Group Name")}</th>
                                <th>{t("Baseline Survey")}</th>
                                {assessmentType != ASSESSMENT_TYPES.jobRole && <th>{t("Pulse Survey")}</th>}
                                {/* <th>{t("Pulse Survey")}</th> */}
                                <th></th>
                            </tr>
                        </thead>
                        {!error.error &&
                            <tbody>
                                {reportsData && reportsData.map((res, key) => {
                                    if (res?.data?.baselineSurveyCount === 0) return ""
                                    return (
                                        <tr key={key}>
                                            <td><div >{res.groupName}</div></td>
                                            <td><div >{res?.data?.baselineSurveyCount}</div></td>
                                            {assessmentType != ASSESSMENT_TYPES.jobRole && <td><div>{res?.data?.pulseSurveyCount}</div></td>}
                                            <td><div><ViewButton onClick={() => onClickButton(res)} /></div></td>
                                        </tr>
                                    )
                                })}
                            </tbody>}
                    </table>
                    {(error.error || reportsData?.length === 0) && !loaderP && <NoTableData text={error.errorMessage} />}
                </div>
                <CustomPagination page={page - 1} pageChange={pageChange} count={totalData} perPage={record_per_page} />
            </TableContainer>
        </div>
    )
}
