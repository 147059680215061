import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { TableHeader } from '../../components/Common/TableContainer'
import { SearchInput } from '../../components/Inputs/SearchInput'
import { NoTableData } from '../../components/Common/NoTableData'
import { CustomPagination } from '../../components/Common/CustomPagination'
import style from "../../assets/css/views/category.module.css"
import { useSelector } from 'react-redux'
import { CategoryTableDropdown } from '../../components/Common/Category/CategoryTableDropdown'
import { loader } from '../../utils'
import { fetchGroups } from '../../api/StrategicImperatives'
import { searchDataByNestedKey } from '../../utils/search'

export const Competency = () => {
    let loaderP = useSelector(e => e.loader)
    const { t } = useTranslation()
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState("")
    const [allData, setAllData] = useState([])
    const record_per_page = 9;
    const [competencyData, setCompetencyData] = useState([])

    const [error, setError] = useState({
        error: false,
        errorMessage: ""

    })
    const [totalGroup, setTotalGroup] = useState(5)

    useEffect(() => {
        getAllCompetenciesByGroup()
    }, [])

    async function getAllCompetenciesByGroup() {
        try {
            let data = {
                page: page,
                record_per_page: record_per_page,
                collectionType: "COMPETENCY",
            }
            loader.start()
            let res = await fetchGroups(data)

            if (res?.data?.response?.length > 0) {
                setCompetencyData(res.data.response)
                setAllData(res.data.response)
                setTotalGroup(res.data.totalGroups)
            }
            else {
                setError({
                    error: false,
                    errorMessage: t("competency.noCompetency")
                })

            }
        } catch (error) {
            // toast.error(error?.response?.data?.error)
            setError({
                error: false,
                errorMessage: error?.response?.data?.error
            })
        }
        finally {
            loader.stop()
        }
    }
    useEffect(() => {
        // search competency on change of search input value 
        setCompetencyData(searchDataByNestedKey(allData, search, "data", "name"))
    }, [search])
    return (
        <div className='padding_22_44'>

            <div className={`${style.KPISI_home_table_con}`}>
                <div className="between">
                    <div className="h1">
                        {t("competency.competency")}
                    </div>

                    <Link to={"assign"}>
                        <button className="btn-primary">{t("Assign")}</button>
                    </Link>
                </div>


                <TableHeader>
                    <div className={style.search_input}>
                        <SearchInput value={search} onChange={(e) => setSearch(e.target.value)} placeholder={`${t("SearchCompetency")}`} />

                    </div>
                </TableHeader>


                <div className={style.table_head}>
                    <div>{t("Group Name")}</div>
                    {/* <div>{t("category.category")}</div> */}
                </div>

                <div className={style.scroll_part}>
                    {(error.error || competencyData?.length === 0) && !loaderP ? <NoTableData text={error.errorMessage} /> :
                        <>
                            {competencyData.map((res, key) => {
                                return (
                                    <CategoryTableDropdown usedFor={"competency"} open={key === 0} data={res} key={key} />
                                )
                            })}
                        </>
                    }
                </div>

                <CustomPagination page={page - 1} pageChange={() => { }} count={totalGroup} perPage={record_per_page} />

            </div >




        </div>
    )
}
