import React from 'react'
import { Box, IconButton, TablePagination } from "@mui/material"
import style from "../../assets/css/components/common.module.css"

export const CustomPagination = ({ page, count, pageChange, perPage }) => {

    const handleChangePage = (event, newPage) => {
        pageChange(newPage + 1)
    };

    function TablePaginationActions(props) {
        const { count, page, rowsPerPage, onPageChange } = props;

        const handleBackButtonClick = (event) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (event) => {
            onPageChange(event, page + 1);
        };

        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }} className={style.pagination_action_con}>
                <input style={{ width: "30px", textAlign: "center" }} type="number" disabled value={page + 1} onChange={(e) => {
                    if (e.target.value >= 0 && e.target.value * 1 <= Math.ceil(count / rowsPerPage) - 1) {

                        // pageChange(e.target.value * 1)

                    }
                }} />
                <IconButton
                    sx={{ ml: 2 }}
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    className={page === 0 ? style.disabled_arrow : ''}
                    aria-label="previous page"
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.7105 15.88L10.8305 12L14.7105 8.11998C15.1005 7.72998 15.1005 7.09998 14.7105 6.70998C14.3205 6.31998 13.6905 6.31998 13.3005 6.70998L8.71047 11.3C8.32047 11.69 8.32047 12.32 8.71047 12.71L13.3005 17.3C13.6905 17.69 14.3205 17.69 14.7105 17.3C15.0905 16.91 15.1005 16.27 14.7105 15.88Z" fill="#52606D" />
                    </svg>

                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}

                    className={page >= Math.ceil(count / rowsPerPage) - 1 ? style.disabled_arrow : ''}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                > <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.28953 15.88L13.1695 12L9.28953 8.11998C8.89953 7.72998 8.89953 7.09998 9.28953 6.70998C9.67953 6.31998 10.3095 6.31998 10.6995 6.70998L15.2895 11.3C15.6795 11.69 15.6795 12.32 15.2895 12.71L10.6995 17.3C10.3095 17.69 9.67953 17.69 9.28953 17.3C8.90953 16.91 8.89953 16.27 9.28953 15.88Z" fill="#52606D" />
                    </svg>

                </IconButton>

            </Box>
        );
    }


    return (
        <>

            <div className={style.pagination_con}>
                <TablePagination
                    component="div"
                    rowsPerPageOptions={[]}
                    count={count}
                    rowsPerPage={perPage ? perPage : 50}
                    page={page}
                    onPageChange={handleChangePage}
                    ActionsComponent={TablePaginationActions}
                    labelRowsPerPage={""}
                    style={{ border: 'none' }}
                />
            </div >
        </>
    )
}