import React, { useRef } from "react"
import { BreadCrum } from "../../components/Common/BreadCrum"
import { KPISIForm } from "../../components/Common/KPI&SICommonComp/KPISIForm"
import { createSI } from "../../api/StrategicImperatives"
import { loader, toast } from "../../utils"
import { useLocation, useNavigate } from "react-router"
import { useTranslation } from "react-i18next"

export const CreateStratImperative = () => {
    const id = new URLSearchParams(useLocation().search).get('id');
    const groupName = new URLSearchParams(useLocation().search).get('groupName');
    let { t } = useTranslation()

    let stratData = useRef({
        name: "",
        groupId: Number(id),
        keyPerformanceIndicators: [
            {
                name: "", owner: null, supervisor: null
            }
        ],
        groupName
    })
    let navigate = useNavigate()

    async function formSubmit(data) {

        let groupName = data.groupName
        delete data.groupName
        try {
            loader.start()
            let res = await createSI(data)
            loader.stop()
            toast.success(res.data.message)
            navigate(`/strategicImperatives/preview/${data.groupId}?groupName=${groupName}&addMore=true`)

        }
        catch (err) {
            loader.stop()
            toast.error(err?.response?.data?.error)
        }
    }

    let breadCrumData = [
        {
            text: t("Strategic Imperatives"),
            path: "/strategicImperatives"
        },
        {
            text: t("Create New"),
        },


    ]
    return (
        <div className="padding_22_44">
            <BreadCrum dataArr={breadCrumData} />
            <br />
            <KPISIForm mode={"Create"} groupDisable={id ? true : false} onClose={() => navigate("/strategicImperatives")} onSubmit={formSubmit} type={"SI"} data={stratData.current} />
        </div>
    )
}