import React, { useEffect, useState } from 'react'
import style from "../../assets/css/components/common.module.css"
import { ExpandLessButton } from '../Buttons/ExpandLessButton'
import { ExpandMoreButton } from '../Buttons/ExpandMoreButton'
import { Collapse } from '@mui/material'


export const Accordion = ({ title, primaryColor, children, actionTemplate, open, ...props }) => {
    let [expand, setExpand] = useState(false)

    const toggleClick = () => setExpand(!expand);
    useEffect(() => {
        setExpand(open)
    }, [open])
    return (
        <div {...props} className={`${style.accordion_con} ${primaryColor && style.primary_accordion_con}`}>
            <div className='between align_center'>
                <div className={`flex align_center`}>
                    <div className={style.expand_btn}>
                        {!expand ? <ExpandLessButton onClick={toggleClick} /> : <ExpandMoreButton onClick={toggleClick} />}
                    </div>
                    <div className={style.heading} >
                        {title}
                    </div>
                </div>
                {expand && <div>
                    {actionTemplate && actionTemplate}
                </div>}

            </div>
            {/* {expand ? children : null} */}

            <Collapse in={expand} timeout="auto" unmountOnExit>
                {children}
            </Collapse>




        </div >
    )
}
