import React from 'react'
import { AssignCompetencyForm } from '../../components/Common/Competency/AssignCompetencyForm'
import { BreadCrum } from '../../components/Common/BreadCrum'
import { useTranslation } from 'react-i18next'
import { assignCompetency } from '../../api/competency'
import { loader, toast } from '../../utils'
import { useLocation, useNavigate } from 'react-router'

export const AssignCompetency = () => {

    const { t } = useTranslation();
    const navigate = useNavigate()
    const formData = {
        competencyIds: [],
        unsetCompetencyIds: [],
        groupId: null,
        categoryId: null
    }
    const breadCrumData = [
        {
            text: t("competency.competency"),
            path: "/competency"
        },
        {
            text: t("Assign"),
        },
    ]
    async function onSubmit(data) {
        try {
            loader.start()
            let body = {
                competencyIds: data.competencyIds,
                unsetCompetencyIds: data.unsetCompetencyIds,
                categoryId: data.categoryId,
                groupId: data.groupId
            }
            let res = await assignCompetency(body)
            toast.success(res?.data?.message)
            navigate("/competency")

        } catch (error) {
            toast.error(error?.response?.data?.error)
        } finally {
            loader.stop()
        }
    }

    return (
        <div className="padding_22_44">
            <BreadCrum dataArr={breadCrumData} />
            <br />
            <AssignCompetencyForm mode={"create"} data={formData} onSubmit={onSubmit} />
        </div>
    )
}
