import React from 'react'
import { CircularProgressBar } from '../CircularProgressBar'
import style from "../../../assets/css/components/reports.module.css"
import { DoubleCircularProgressBar } from '../DoubleCircularProgressBar'
import { MultiCircularProgressBar } from '../MultiCircularProgressBar'

export const MultipleCircularProgressCard = ({ values, title, active, showChart = true, ...props }) => {
    return (
        <div {...props} className={`${active ? style.active_card : ""} ${!showChart ? style.show_only_name : style.progress_card_con} pointer`}>
            {showChart &&
                <div>
                    <MultiCircularProgressBar values={[...values]} />
                </div>}

            <div className={style.content}>{title}</div>
        </div>
    )
}
