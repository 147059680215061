import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { deleteDPD, getAllDPDByGroup } from '../../api/developmentPlan'
import style from "../../assets/css/views/developmentPlan.module.css"
import { BreadCrum } from '../../components/Common/BreadCrum'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Accordion } from '../../components/Common/Accordion'
import { DeleteButon } from '../../components/Buttons/DeleteButton'
import { EditButton } from '../../components/Buttons/EditButton'
import { Grid } from '@mui/material'
import { changeDateFormat, loader, toast } from '../../utils'
import { AddMoreItem } from '../../components/Common/AddMoreItem'

export const PreviewDevelopmentPlan = () => {

    const { t } = useTranslation()
    const { id } = useParams()
    const [developmentPlansData, setDevelopmentPlansData] = useState([])

    useEffect(() => {
        getDevelopmentByGroupId()
    }, [])

    async function getDevelopmentByGroupId() {
        try {
            loader.start()
            let res = await getAllDPDByGroup(id)
            setDevelopmentPlansData(res?.data?.response || [])
        } catch (error) {
            console.log(error)
        }
        finally {
            loader.stop()
        }
    }

    let breadCrumData = [
        {
            text: t("developmentPlan.developmentPlans"),
            path: "/developmentPlan"
        },
        {
            text: t("Preview"),
        },


    ]

    async function deleteDp(data) {
        try {
            loader.start()
            let res = await deleteDPD(data._id)
            toast.success(res?.data?.message)
            setDevelopmentPlansData(pre => pre.filter(re => re._id !== data._id))
        } catch (error) {
            toast.error(error?.response?.data?.error)
        }
        finally {
            loader.stop()
        }
    }
    function dpActionButtonsTemplate(rowData) {
        return (
            <div>
                <Link to={`/developmentPlan/update/${rowData?._id}`}>
                    <EditButton />
                </Link>
                &nbsp;&nbsp;
                <DeleteButon
                    confirmation
                    onDelete={() => deleteDp(rowData)}
                    heading={t("Delete")}
                    text={t("developmentPlan.deleteConfirmation")}

                />
            </div>
        )
    }

    return (
        <div className={`padding_22_44 ${style.preview_developmentPlan_con}`}>
            <BreadCrum dataArr={breadCrumData} />
            <br />
            <div className={`${style.preview_Dp_con}`}>
                <div className={style.heading}>
                    {"Group 1"}
                </div>
                {developmentPlansData.map((res) => {
                    return (
                        <Accordion
                            actionTemplate={dpActionButtonsTemplate(res)}
                            key={res?._id} title={res?.name}>
                            <Grid container className={style.accordion_con} mt={3} spacing={1}>
                                <Grid item md={6} xs={12}>
                                    <label>{t("developmentPlan.startDate")}: </label>
                                    <span className={style.value}>{res?.startDate && changeDateFormat(res?.startDate)}</span>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <label>{t("developmentPlan.endDate")}: </label>
                                    <span className={style.value}>{res?.endDate && changeDateFormat(res?.endDate)}</span>
                                </Grid>
                                <Grid item xs={12}>
                                    <label>{t("Description")}: </label>
                                    <span className={style.value}>{res?.description}</span>
                                </Grid>
                            </Grid>
                        </Accordion>
                    )
                })}
                <div className="add_more" >
                    <br />
                    <Link to={`/developmentPlan/create?groupId=${id}`} className='unstyled'>
                        <AddMoreItem text={t("developmentPlan.addDP")} />
                    </Link>
                </div>
            </div>
            <br />
            <div className="end">
                <Link to={"/developmentPlan"}>
                    <button type='button' className='btn-secondary'>{t("Cancel")}</button>
                </Link>
            </div>

        </div>
    )
}

