import React from "react";
import * as XLSX from "xlsx";
import { toast, loader } from "../../utils";

export const DownloadCsv = ({ children, fileName, onSubmit }) => {
  async function downloadCsv() {
    loader.start();
    let data = await onSubmit();
    if (data.length === 0) {
      toast.error("Csv Data Can't Be Empty !");
      return;
    }

    let ws = XLSX.utils.json_to_sheet(data);
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "sheet");
    XLSX.write(wb, { bookType: "xlsx", type: "buffer" });
    XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(wb, fileName || `QuestionBank.csv`);
    loader.stop();
    toast.success("CSV Downloaded Successfully !");
  }

  return (
    <>
      <div onClick={downloadCsv}>{children}</div>
    </>
  );
};
