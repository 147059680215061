import { configureStore } from '@reduxjs/toolkit'
import { questionCompetencySlice } from './Slices/QuestionCompetencySlice'
import { loaderSlice } from './Slices/LoaderSlice'
import { toastSlice } from './Slices/ToastSlice'
import { questionSlice } from './Slices/QuestionSlice'
import { groupSlice } from './Slices/GroupSlice'
import { TokenExpireSlice } from './Slices/TokenExpireSlice'

export const store = configureStore({
    reducer: {
        loader: loaderSlice.reducer,
        toast: toastSlice.reducer,
        questionCompetency: questionCompetencySlice.reducer,
        questionData: questionSlice.reducer,
        groupData: groupSlice.reducer,
        tokenStatus: TokenExpireSlice.reducer
    },
})