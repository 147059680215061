import * as React from 'react';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export const CustomDatePicker = ({ value, minDate, error, ...props }) => {


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                value={dayjs(value)}
                minDate={minDate ? dayjs(minDate) : null}
                format="MM-DD-YYYY"
                className={`${props.className} bg-white`}
                sx={{ backgroundColor: "white" }}
                {...props}
                slotProps={{
                    textField: { error: error || false, readOnly: true, },
                }}
            />
        </LocalizationProvider>
    );
}