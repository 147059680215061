import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AssessmentsCard } from '../../components/Common/Home/AssessmentsCard'
import style from "../../assets/css/views/home.module.css"
import { ProgressCard } from '../../components/Common/Reports/ProgressCard'
import { WhiteCardRow } from '../../components/Common/Home/WhiteCardRow'
import { createHomeDataEvent } from '../../api/home'
import { changeDateFormat, currentUser } from '../../utils'
import { useSelector } from 'react-redux'
import { CardLayout } from '../../components/Common/Home/CardLayout'
import { Skeleton } from '@mui/material'
import Slider from 'react-slick'


export const Home = () => {
    let { t } = useTranslation()
    const tokenStatus = useSelector((state) => state.tokenStatus);
    let [allData, setAllData] = useState({
        count: {
            isLoading: true,
            isError: false,
            data: {}
        },
        upcomingAssessments: {
            isLoading: true,
            isError: false,
            data: []
        },
        onGoingAssessments: {
            isLoading: true,
            isError: false,
            data: []
        },
        completedAssessments: {
            isLoading: true,
            isError: false,
            data: []
        },
        report: {
            isLoading: true,
            isError: false,
            data: ["p"]
        },
        latestAssessments: {
            isLoading: true,
            isError: false,
            data: []
        },
        keyPerformanceIndicators: {
            isLoading: true,
            isError: false,
            data: []
        },
        subGroups: {
            isLoading: true,
            isError: false,
            data: []
        },

    })
    const eventSource = useRef(null);

    let loader1 = useSelector(e => e.loader)
    let [error, setError] = useState({
        error: false,
        errorMessage: ""
    })

    let userData = currentUser()
    let userRole = userData.role.item_name
    let isGroupAdminRole = userData?.role?.item_name === "groupAdmin"

    let groupId = null;
    if (userRole === "groupAdmin") {
        groupId = userData.user.primary_group
    }

    // Handle incoming data from SSE
    const onDataAvailable = (event) => {
        try {
            const data = JSON.parse(event);
            const key = Object.keys(data)[0];


            setAllData(prevData => ({
                ...prevData,
                [key]: {
                    data: data[key],
                    isLoading: false,
                    isError: false
                }
            }));
        } catch (error) {
            console.error('Error parsing incoming data:', error);
        }
    };
    // Handle SSE errors
    const onError = (error) => {
        console.error('EventSource error occurred:', error);


        // Check if the error is due to a session invalid (e.g., 401 Unauthorized)

        // Handle other types of errors or attempt to reconnect
        if (eventSource.current) {
            eventSource.current.close(); // Close the current EventSource connection
            // Attempt to reconnect or perform other actions
            eventSource.current = createHomeDataEvent(groupId, onDataAvailable, onError);
        }

    };



    // Establish SSE connection on component mount
    useEffect(() => {
        eventSource.current = createHomeDataEvent(groupId, onDataAvailable, onError);

        // Cleanup function to close SSE connection on component unmount
        return () => {
            if (eventSource.current) {
                eventSource.current.close();
            }
        };
    }, [groupId]); // Re-run effect when groupId changes


    var settings = {
        dots: false,
        infinite: false,
        centered: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 3024,
                settings: {
                    slidesToShow: 8,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 2024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1250,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 740,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    };




    return (
        <>
            <div className={`padding_22_44 ${style.home_main_con}`}>

                <div className="h1">{t("Dashboard")}</div>
                <div className={style.assessment_card_con}>
                    <AssessmentsCard heading={t("Assessment.Upcoming Assesments")} value={allData?.count?.data?.upcomingAssessment} />
                    <AssessmentsCard heading={t("Assessment.In Progress Assesments")} value={allData?.count?.data?.inprogressAssessment} />
                    <AssessmentsCard heading={t("Assessment.Completed Assesments")} value={allData?.count?.data?.completedAssessment} />
                </div>

                <br />

                {!isGroupAdminRole &&
                    <div className={style.assessments_status_card_con}>

                        <CardLayout
                            // show no data message
                            noDataMsg={t("noDataAvailable")}
                            noData={!allData?.upcomingAssessments?.isLoading && allData?.upcomingAssessments?.data?.length == 0}
                            heading={t("Assessment.Upcoming Assessments")} viewRoute={"/assessment"}>
                            {allData?.upcomingAssessments?.isLoading ? <CardLoader /> : allData?.upcomingAssessments?.data.map((res, key) => {
                                return (
                                    <WhiteCardRow key={key} text1={res?.name} text2={`${res?.groupName} | ${res?.assessmentType} | ${res?.type}`} status={res?.status} viewRoute={"/assessment/" + res?.assessmentType + "/preview/" + res?._id} />
                                )
                            })}
                        </CardLayout>
                        <CardLayout
                            // show no data message 
                            noDataMsg={t("noDataAvailable")}
                            noData={!allData?.onGoingAssessments?.isLoading && allData?.onGoingAssessments?.data?.length == 0}
                            heading={t("Assessment.On Going Assessments")} viewRoute={"/assessment"}>
                            {allData?.onGoingAssessments?.isLoading ? <CardLoader /> : allData?.onGoingAssessments?.data.map((res, key) => {
                                return (
                                    <WhiteCardRow key={key} text1={res?.name} text2={`${res?.groupName} | ${res?.assessmentType} | ${res?.type} | ${changeDateFormat(res?.completionDate)}`} viewRoute={"/assessment/" + res?.assessmentType + "/preview/" + res?._id} />
                                )
                            })}
                        </CardLayout>
                        <CardLayout
                            // show no data message 
                            noDataMsg={t("noDataAvailable")}
                            noData={!allData?.completedAssessments?.isLoading && allData?.completedAssessments?.data?.length == 0}
                            heading={t("Assessment.Completed Assessments")} viewRoute={"/assessment"}>
                            {allData?.completedAssessments?.isLoading ? <CardLoader /> : allData?.completedAssessments?.data.map((res, key) => {
                                return (
                                    <WhiteCardRow key={key} text1={res?.name} text2={`${res?.groupName} | ${res?.assessmentType} | ${res?.type} | ${changeDateFormat(res?.completionDate)}`} viewRoute={"/assessment/" + res?.assessmentType + "/preview/" + res?._id} />
                                )
                            })}
                        </CardLayout>

                    </div>
                }

                <br />
                {/* <CardLayout heading={t("Strategic Imperatives")} viewRoute={"/strategicImperatives"}>

                    <div className={style.si_cards_con}>

                        {allData?.report?.isLoading ? <CardLoader /> : allData?.report?.data.map((res, ind) => {
                            return (
                                <Fragment key={res._id}>
                                    <div className='flex align_center'>
                                        <span className={style.si_cards_groupName}>{res?.groupName}:&nbsp;&nbsp;</span>
                                        <span className={style.si_cards_assesmentName}> {res?.name}</span>
                                    </div>
                                    <div className={style.si_cards_flex_box}>

                                        <Slider {...settings}>
                                            {res?.strategicImperativesData.map((res2) => {
                                                return (
                                                    <ProgressCard  showChart={true} key={res2._id + ind} name={res2?.name} onClick={() => { }} active={false} value1={Math.floor(res2?.SIScore)} />

                                                )
                                            })}

                                        </Slider>

                                        <br />
                                    </div>
                                </Fragment>
                            )
                        })
                        }


                    </div>
                </CardLayout > */}

                {isGroupAdminRole &&
                    <>
                        <br />
                        <div className={style.assessments_status_card_con}>

                            <CardLayout
                                noDataMsg={t("noDataAvailable")}
                                noData={!allData?.latestAssessments?.isLoading && allData?.latestAssessments?.data?.length == 0}
                                heading={t("Assessment.Assessments")} viewRoute={"/home"}>
                                {allData?.latestAssessments?.isLoading ? <CardLoader /> : allData?.latestAssessments?.data.map((res, key) => {
                                    return (
                                        <WhiteCardRow key={key} text1={res?.name} text2={`${res?.type}`} status={res?.status} />
                                    )
                                })}
                            </CardLayout>
                            <CardLayout
                                noDataMsg={t("noDataAvailable")}
                                noData={!allData?.keyPerformanceIndicators?.isLoading && allData?.keyPerformanceIndicators?.data?.length == 0}
                                heading={t("KPI.KPIs")} viewRoute={"/home"}>
                                {allData?.keyPerformanceIndicators?.isLoading ? <CardLoader /> : allData?.keyPerformanceIndicators?.data.map((res, key) => {
                                    return (
                                        <WhiteCardRow key={key} text1={res?.name} text2={`${res?.type}`} />
                                    )
                                })}
                            </CardLayout>
                            <CardLayout
                                noDataMsg={t("noDataAvailable")}
                                noData={!allData?.subGroups?.isLoading && allData?.subGroups?.data?.length == 0}
                                heading={t("groupManagement.Sub Group")} viewRoute={"/home"}>
                                {allData?.subGroups?.isLoading ? <CardLoader /> : allData?.subGroups?.data.map((res, key) => {
                                    return (
                                        <WhiteCardRow key={key} text1={res?.name} value={`${res?.members?.length} Members`} />
                                    )
                                })}
                            </CardLayout>

                        </div>
                    </>
                }
            </div >
        </>

    )
}

const CardLoader = () => (
    [...Array(4)].map((res, key) => (<Skeleton key={key} variant="rectangular" width={"100%"} height={20} />))
)
