import React, { useRef } from 'react'
import { Navigate, Outlet } from 'react-router'
import { useLocation } from 'react-router-dom';


export const UserAuth = ({ authenticatedRoles }) => {
    const location = useLocation();
    let isLoggedIn = useRef(false)
    let user = localStorage.getItem("userData")


    if (user !== null) {
        let role = JSON.parse(user).role.item_name
        if (authenticatedRoles.includes(role)) {
            isLoggedIn.current = true
        } else {


        }
    }

    if (!isLoggedIn.current) {
        localStorage.setItem("userAssessmentRedirectUrl", `${location.pathname}${location.search}`)
    }

    return (
        <>
            {
                isLoggedIn.current ?
                    <>
                        {/* {!location.pathname.startsWith("/survey-form/") &   & <SessionExpiredModal />} */}
                        <Outlet></Outlet></>
                    : <Navigate to={"/"} />
            }
        </>
    )
}
