import React, { useEffect, useState } from "react"
import { AddButton } from "../../Buttons/AddButton"
import { Box, Dialog, Divider, TextField } from "@mui/material"
import style from "../../../assets/css/components/questionBank.module.css"
import { CrossButton } from "../../Buttons/CrossButton"
import { toast } from "../../../utils"
import { useTranslation } from "react-i18next"
import { EditButton } from "../../Buttons/EditButton"
import { useParams } from "react-router"
import { ASSESSMENT_TYPES } from "../../../constants/constants"
import { DialpadOutlined } from "@mui/icons-material"

export const AddCompetencyOrTag = ({ disabled, onAddCompetency, setDialogOpen, onClose, dialogOpen, mode, data, allCompetencies }) => {
    let [dialog, setDialog] = useState(false)
    let { assessmentType } = useParams()
    let type = assessmentType === ASSESSMENT_TYPES.organizational ? "Tag" : "Competency"

    let isTag = type === "Tag"



    let [name, setName] = useState("")
    let [description, setDescription] = useState("")

    let { t } = useTranslation()

    // open dialog from edit component 
    useEffect(() => {
        if (dialogOpen) {
            setDialog(dialogOpen)
        }
    }, [dialogOpen])

    useEffect(() => {
        if (data?.name || data?.description) {
            setName(data?.name || "")
            setDescription(data?.description || "")
        }
    }, [data])




    async function competencySubmit(e) {
        e.preventDefault()

        if (mode === "add" && allCompetencies.some(e => e?.name?.trim()?.toLowerCase() == name?.trim()?.toLowerCase())) {
            toast.error(t(isTag ? "tag.tagAlreadyExist" : "competency.Competency already exists"))
            return
        }

        if (name.trim() !== "") {
            // loader.start()
            // setDialog(false)
            let formdata = {
                name,
                // status: true,
            }
            if (!isTag && description) {
                formdata.description = description
            }
            onAddCompetency({ ...formdata, ...(mode === "add" ? {} : { _id: data._id }) })
            // dispatch(addCategory(data))
            setName("")
            setDescription("")
            setDialog(false)
            onClose && onClose()

        }
        else {
            toast.error(`Please fill ${type}`)
        }
    }



    function closeDialog() {
        setDialog(false)
        setDialogOpen && setDialogOpen(false)

        onClose && onClose()
    }

    return (
        <>
            {mode === "add" && <AddButton disabled={disabled} type='button' onClick={() => setDialog(true)} />}
            <Dialog
                maxWidth={"sm"}
                fullWidth
                open={dialog}
                onClose={closeDialog}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box className={style.addCategory_con}>
                    <div className={`dialog_header between`}>
                        <div>
                            {t(isTag ?
                                (mode === "add" ? "tag.addNewTag" : "tag.editTag") :
                                (mode === "add" ? "competency.addNewCompetency" : "competency.editCompetency")
                            )
                            }
                        </div>
                        <div onClick={closeDialog} className={`pointer ${style.heading}`}>
                            <CrossButton />
                        </div>
                    </div>
                    <Divider color="#E0E8F9" sx={{ borderBottomWidth: 1 }} />

                    <Box p={4}>
                        <div className="flex align_center">
                            <TextField value={name} sx={{ mr: 3 }} onChange={(e) => setName(e.target.value)} required label={t(isTag ? "tag.tag" : "competency.competency")} fullWidth />
                        </div>
                        {!isTag &&
                            <>
                                <br />
                                <div className="flex align_center">
                                    <TextField value={description} sx={{ mr: 3 }} onChange={(e) => setDescription(e.target.value)} label={t("description")} fullWidth />
                                </div>
                            </>
                        }

                        <div className={`${style.dialog_actions} end`}>
                            <button type="button" onClick={competencySubmit} className=" btn-primary">{t(mode === "add" ? "Create" : "Update")}</button>&nbsp;&nbsp;&nbsp;
                            <button type="button" className="btn-secondary" onClick={() => {
                                setName("")
                                closeDialog()
                            }}>{t("Cancel")}</button>
                        </div>
                    </Box>

                </Box>
            </Dialog >
        </>
    )
}