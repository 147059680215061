import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAllGroups } from "../../api/Group";
import { startLoader, stopLoader } from "./LoaderSlice";
import { searchDataWithKey } from "../../utils/search";
import { setTokenStatus } from "./TokenExpireSlice";


let initialState = {
    allGroups: [],
    filteredGroups: [],
}

export const getallgroups = createAsyncThunk("getAllGroup", async (_, { rejectWithValue, ...thunkapi }) => {
    try {
        thunkapi.dispatch(startLoader())
        let response = await getAllGroups()
        thunkapi.dispatch(stopLoader())
        thunkapi.dispatch(setTokenStatus(true))
        return response.data.payload
    }
    catch (err) {
        if (err?.response?.data?.name === "Unauthorized") {
            thunkapi.dispatch(setTokenStatus(false))
        }

        thunkapi.dispatch(stopLoader())
        return rejectWithValue(err)
    }
})

export const groupSlice = createSlice({
    name: 'group',
    initialState,
    reducers: {
        filterGroup: (state, action) => {
            let data = [...current(state).allGroups]
            return ({ ...state, filteredGroups: searchDataWithKey("name", data, action.payload) })
        },

    },
    extraReducers: (builder) => {
        builder.addCase(getallgroups.fulfilled, (state, action) => {
            return {
                ...state, allGroups: action.payload, filteredGroups: action.payload
            }
        })
        builder.addCase(getallgroups.pending, (state, action) => {
            return state
        })
        builder.addCase(getallgroups.rejected, (state, action) => {
            return state
        })
    }

})

export let { filterGroup, fiterKPISI } = groupSlice.actions