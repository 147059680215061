import React, { useEffect, useMemo, useState } from "react";
import { CopyButton } from "../../components/Buttons/CopyButton";
import { DeleteButon } from "../../components/Buttons/DeleteButton";
import { EditButton } from "../../components/Buttons/EditButton";
import style from "../../assets/css/views/questionBank.module.css";
import { SearchInput } from "../../components/Inputs/SearchInput";
import { Link, useNavigate } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { BulkUploadQuestion } from "../../components/Common/QuestionBank/BulkUploadQuestion";
import {
  DeleteQuestion,
  GetAllQuestions,
  copyQuestions,
  fetchQuestionsByCategories,
} from "../../api/Question";
import { useDispatch, useSelector } from "react-redux";
import { getAllCompetencies } from "../../store/Slices/QuestionCompetencySlice";
import { isUserCreateUpdateData, loader, toast } from "../../utils";
import { CustomCheckbox } from "../../components/Inputs/CustomCheckbox";
import { CustomPagination } from "../../components/Common/CustomPagination";
import { DownloadCsv } from "../../components/Common/DownloadCsv";
import { searchDataWithKey } from "../../utils/search";
import _ from "underscore";
import { DownloadButton } from "../../components/Buttons/DownloadButton";
import { PreviewQuestion } from "../../components/Common/Assessment/PreviewQuestion";
import { TableContainer } from "../../components/Common/TableContainer";
import { useTranslation } from "react-i18next";
import { NoTableData } from "../../components/Common/NoTableData";
import { BreadCrum } from "../../components/Common/BreadCrum";
import { ASSESSMENT_TYPES } from "../../constants/constants";

export const IndividualQuestionBank = () => {
  const tokenStatus = useSelector((state) => state.tokenStatus);
  let [selectedQuestions, setSelectedQuestions] = useState([]);
  let [selectedCategories, setSelectedCategories] = useState([]);
  let [totalQuestion, setTotalQuestion] = useState(0);
  let [allQuestions, setAllQuestions] = useState([]);
  let [allData, setAllData] = useState([]);
  let dispatch = useDispatch();
  let [searchValue, setSearchValue] = useState("");
  let [page, setPage] = useState(1);
  let navigate = useNavigate();
  let per_page_data = 20;
  let { t } = useTranslation();
  let loaderP = useSelector(e => e.loader)
  let [error, setError] = useState({
    error: false,
    errorMessage: "No Questions Available"
  })

  function checkBoxclick(event, res) {
    if (event.target.checked) {
      setSelectedQuestions([...selectedQuestions, res]);
    } else {
      setSelectedQuestions((pre) => {
        pre.splice(
          pre.findIndex((e) => e._id === res._id),
          1
        );
        return [...pre];
      });

    }
  }

  const allCompetencies = useSelector((e) => e.questionCompetency.allCompetencies);

  const categories = useMemo(() => allCompetencies, [allCompetencies]);
  async function getAllQuestions(pg, perPage) {
    setSearchValue(""); // Optional: Reset search value if applicable
    loader.start(); // Start loading indicator

    try {
      const res = await GetAllQuestions(pg, perPage, ASSESSMENT_TYPES.individual); // Assuming GetAllQuestions is defined correctly

      // Handle successful response:
      setTotalQuestion(res.data.totalCount);
      setAllData(res.data.response);
      setAllQuestions(res.data.response); // Assuming these methods update component state
      // Clear error state (optional):
      setError({ error: false, errorMessage: res.data.response.length !== 0 ? "" : "No Questions Available" });
    } catch (error) {
      // Handle errors gracefully:
      console.error("Error fetching questions:", error); // Log for debugging
      setError({
        error: true,
        errorMessage: error?.response?.data?.error || "An error occurred while fetching questions." // Provide default error message
      });
    } finally {
      loader.stop(); // Ensure loading indicator stops regardless of success or failure
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (per_page_data && tokenStatus) { // Fetch only if both conditions are met
          await getAllQuestions(1, per_page_data);
        }
      } catch (error) {
        console.error(error); // Handle errors gracefully
      }
    };

    fetchData();
  }, [per_page_data, tokenStatus]);

  useEffect(() => {
    if (allCompetencies.length === 0 && tokenStatus) {
      dispatch(getAllCompetencies());
    }
  }, [allCompetencies.length, dispatch, tokenStatus]);

  async function deleteQuestion() {
    try {
      loader.start();
      let res = await DeleteQuestion({
        questionIds: _.map(selectedQuestions, (e) => e._id),
      });
      toast.success(res.data.message);
      setPage(1);
      setSelectedQuestions([]);
      await getAllQuestions(1, per_page_data);
      loader.stop();
    } catch (err) {
      loader.stop();
      toast.error(err?.response?.data?.error);
    }
  }

  const handleCategoryChange = async (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCategories(value);
    setPage(1);
    getQuestionByCategory(1, value);
  };
  async function getQuestionByCategory(page, value) {
    setSearchValue("");
    let data = {

      categoryIds: value.map((e) => e._id),
      page,
      records_per_page: per_page_data,
    };
    setError({
      error: false,
      errorMessage: "No Questions Available"
    })
    if (value.length === 0) {
      getAllQuestions(1, per_page_data);
      return;
    }
    try {
      loader.start();
      let res = await fetchQuestionsByCategories(data);
      loader.stop();
      setAllData(res.data.response);
      setAllQuestions(res.data.response);
      setTotalQuestion(res.data.totalCount);
    } catch (err) {
      loader.stop();
      // toast.error(err?.response?.data?.error);
      setError({
        error: true,
        errorMessage: err?.response?.data?.error
      })
    }
  }

  async function copyQuestion() {
    try {
      loader.start();
      let res = await copyQuestions({
        idsToCopy: _.map(selectedQuestions, (e) => e._id),
      });
      toast.success(res.data.message);
      setSelectedQuestions([]);

      getAllQuestions(page, per_page_data);

      loader.stop();
    } catch (err) {
      loader.stop();
      toast.error(err?.response?.data?.error);
    }
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  function pageChange(u) {
    setSearchValue("");
    if (selectedCategories.length === 0) {
      getAllQuestions(u, per_page_data);
    } else {
      getQuestionByCategory(u, selectedCategories);
    }
    setPage(u);
  }

  function bulkUploadDone() {
    dispatch(getAllCompetencies());
    getAllQuestions(page, per_page_data);
  }

  function movetoEditPage() {
    // let obj = allQuestions.find((e) => e._id === selectedQuestions[0]._id);
    // dispatch(
    //   setQuestionDetail({
    //     ...obj,
    //     categoryId: obj.categoryData.map((re) => re._id),
    //   })
    // );
    navigate(`/questionBank/edit/${selectedQuestions[0]._id}?assessmentType=${ASSESSMENT_TYPES.individual}`);
  }

  function downloadSubmit() {
    let formattedJsonData = [];
    selectedQuestions.forEach((res, index) => {
      let obj = {};
      // let ar = [...Array(5)]
      obj["Question"] = res.question;
      obj["Category"] = res.categoryData.map((e) => e.name).join(", ");
      // obj['Question_Type'] = res.questionType
      // ar.forEach((element, ind) => {
      //     obj[`Option_${ind + 1}`] = ""
      //     obj[`Weightage_${ind + 1}`] = ""
      // })
      res.options.forEach((opt, opIndex) => {
        obj[`Option_${opIndex + 1}`] = opt.name;
        // obj[`Weightage_${opIndex + 1}`] = opt.weightage
      });
      formattedJsonData.push(obj);
    });
    return formattedJsonData;
  }

  function searchQuestion(e) {
    setError({ error: false, errorMessage: "No questions available" });
    setSearchValue(e.target.value);
    let res = searchDataWithKey("question", allData, e.target.value);
    setAllQuestions(res);
  }
  let breadCrumData = [
    {
      text: t("questionBank.Question Bank"),
      path: "/questionBank"
    },
    {
      text: t("individual"),
    },

  ]
  return (
    <>
      <div className={style.question_bank_con}>
        <div className="between">

          <BreadCrum dataArr={breadCrumData} />

          {isUserCreateUpdateData() ? (
            <div className="flex">
              &nbsp; &nbsp;
              <Link to={`/questionBank/create?assessmentType=${ASSESSMENT_TYPES.individual}`}>
                <button className="btn-primary">
                  {t("Create New")}
                </button>
              </Link>
              &nbsp;&nbsp;
              <BulkUploadQuestion bulkUploadDone={bulkUploadDone} />
            </div>
          ) : null}
        </div>
        <div className={`${style.table_actions}`}>
          <div className={style.input_fields}>
            <div className={style.search_field}>
              <SearchInput
                value={searchValue}
                onChange={searchQuestion}
                placeholder={t("Search")}
              />{" "}
              &nbsp;&nbsp;&nbsp;
            </div>
            <div className={style.category_search}>
              <FormControl fullWidth>
                {selectedCategories.length === 0 ? (
                  <InputLabel shrink={false} sx={{ marginTop: "-8px" }}>
                    {t("competency.competency")}
                  </InputLabel>
                ) : null}
                <Select
                  sx={{ height: "38px" }}
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedCategories}
                  onChange={handleCategoryChange}
                  input={<OutlinedInput label="" />}
                  renderValue={(selected) =>
                    selected.map((e) => e.name).join(", ")
                  }
                  MenuProps={MenuProps}
                >
                  {categories?.length === 0 ? (
                    <MenuItem disabled>
                      <ListItemText primary={t("category.NoCategory")} />
                    </MenuItem>
                  ) : (
                    categories?.map((res) => (
                      <MenuItem key={res.name} value={res}>
                        <CustomCheckbox
                          checked={
                            selectedCategories.findIndex(
                              (e) => e.name === res.name
                            ) > -1
                          }
                          className="me-6"
                        />
                        <ListItemText primary={res.name} />
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="flex">
            {isUserCreateUpdateData() ? (
              <EditButton
                confirmation={selectedQuestions?.[0]?.isMappedInAssessment}
                text={t("confirmationDialog.editQuestion")}
                heading={t("Edit Question")}
                onClick={movetoEditPage}
                disabled={selectedQuestions.length !== 1}
              />
            ) : null}
            &nbsp; &nbsp;
            {isUserCreateUpdateData() ? (
              <CopyButton
                onClick={copyQuestion}
                confirmation
                heading={t("Copy Question")}
                text={t("confirmationDialog.copyQuestion")}
                disabled={selectedQuestions.length !== 1}
              />
            ) : null}
            &nbsp; &nbsp;
            {isUserCreateUpdateData() ? (
              <>
                <DeleteButon
                  heading={t("Delete Question")}
                  text={t("confirmationDialog.deleteQuestion")}
                  confirmation
                  disabled={selectedQuestions.length === 0}
                  onDelete={deleteQuestion}
                  deletedItem="Question"
                />
                &nbsp; &nbsp;
              </>
            ) : null}
            <DownloadCsv onSubmit={downloadSubmit} fileName={"_questionData"}>
              <DownloadButton disabled={selectedQuestions.length === 0} />
            </DownloadCsv>
          </div>
        </div>

        <div>
          <TableContainer>

            <div className={style.table_scroll}>
              <table>
                <thead>
                  <tr className={style.table_head}>
                    <th>
                      <CustomCheckbox
                        onChange={(e) =>
                          setSelectedQuestions(
                            e.target.checked ? allQuestions : []
                          )
                        }
                        checked={
                          selectedQuestions.length === allQuestions.length
                        }
                        type="checkbox"
                      />
                    </th>
                    <th>{t("questionBank.Questions")}</th>
                    <th></th>
                    <th>{t("questionBank.Competencies")}</th>
                    {/* <th className="noWrap">
                      {t("questionBank.Question Type")}
                    </th> */}
                    <th>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </th>
                  </tr>
                </thead>
                {!error?.error &&
                  <tbody>
                    {allQuestions &&
                      allQuestions.map((res, key) => {
                        return (
                          <tr
                            key={key}
                            className={`${style.table_item_row} ${selectedQuestions.includes(res.id)
                              ? style.selected
                              : ""
                              }`}
                          >
                            <td>
                              <div>
                                <CustomCheckbox
                                  checked={
                                    selectedQuestions.findIndex(
                                      (e) => e._id === res._id
                                    ) !== -1
                                  }
                                  onChange={(event) => checkBoxclick(event, res)}
                                  type="checkbox"
                                />
                              </div>
                            </td>
                            <td>
                              <div>{res.question}</div>
                            </td>
                            <td>
                              <div>&nbsp;&nbsp;&nbsp;</div>
                            </td>
                            <td>
                              <div>
                                <span className={style.category_column}>
                                  {res.competencyData.map((e) => e.name).join(" / ")}
                                </span>
                                &nbsp;&nbsp;&nbsp;
                              </div>
                            </td>
                            {/* <td>
                              <div>{res.questionType}</div>
                            </td> */}
                            <td className="text-end">
                              <PreviewQuestion
                                data={res}
                                parent={"questionBank"}
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>}
              </table>

              {(error.error || allQuestions?.length === 0) && !loaderP && <NoTableData text={error.errorMessage} />}

            </div>
          </TableContainer>

          <CustomPagination
            page={page - 1}
            pageChange={pageChange}
            count={totalQuestion}
            perPage={per_page_data}
          />
        </div>
      </div>
    </>
  );
};
