
import React, { useCallback, useEffect, useState } from "react"
import { useLocation, useParams } from "react-router"
import { fetchKPIByGroupId } from "../../api/KPI"
import { KPISIPreview } from "../../components/Common/KPI&SICommonComp/KPISIPreview"
import { Link } from "react-router-dom"
import { loader, toast, currentUser } from "../../utils"
import { BreadCrum } from "../../components/Common/BreadCrum"
import { useTranslation } from "react-i18next"
import { ADMIN_ROLE } from "../../constants/constants";
import { useSelector } from "react-redux"


export const PreviewKPI = () => {
    let { t } = useTranslation()
    let params = useParams()
    let [KPIData, setKPIData] = useState({})
    let [dataFetched, setDataFetched] = useState(false)

    const getKPIByGroupId = useCallback(async () => {
        try {
            loader.start();
            let res = await fetchKPIByGroupId(params.id);
            loader.stop();
            setDataFetched(true);

            setKPIData({ groupName: res.data.groupName, data: res.data.response });
        } catch (err) {
            loader.stop();
            toast.error(err?.response?.data?.error)
        }
    }, [params.id]);



    useEffect(() => {
        getKPIByGroupId()
    }, [getKPIByGroupId])
    function removeDataAfterDelete(deletedId) {
        setKPIData(pre => {
            return ({ ...pre, data: pre.data.filter(e => e._id !== deletedId) })
        })
    }
    let breadCrumData = [
        {
            text: t("KPI.Key Performance Indicators"),
            path: "/KPI"
        },
        {
            text: t("Preview"),
        },


    ]
    return (
        <div className="padding_22_44">
            <BreadCrum dataArr={breadCrumData} />
            <br />

            {
                dataFetched ?
                    <KPISIPreview removeDataAfterDelete={removeDataAfterDelete} type={"KPI"} data={KPIData} />
                    : null
            }
            {
                currentUser().role.item_name == ADMIN_ROLE ?
                    <div className="end">
                        <Link to={"/KPI"}>
                            <button className="btn-secondary">{t("Cancel")}</button>
                        </Link>
                    </div> : null
            }

        </div>
    )

}