import React from 'react'
import style from "../../assets/css/components/common.module.css"

export const FormClosed = ({ text }) => {
    return (
        <div className={style.formClose_main_con}>
            <div className={style.card_main_con}>
                <div className={style.textContent}>{text}</div>
                <div className={style.two_dot_con}>
                    <span></span>
                    <span></span>
                </div>
            </div>
            <div className={style.line_1_con}></div>
            <div className={style.line_2_con}></div>
        </div>
    )
}
