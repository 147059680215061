import React, { useEffect, useState } from "react";
import { BreadCrum } from "../../components/Common/BreadCrum";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { changeDateFormat, loader, toast } from "../../utils";
import { getAssessmnentUserStatus } from "../../api/Assessment";
import { TableContainer } from "../../components/Common/TableContainer";
import { Link } from "react-router-dom";
import style from "../../assets/css/views/assessment.module.css"
import { ASSESSMENT_TYPES } from "../../constants/constants";

export const PreviewUserAsessment = () => {


    const { assessmentType } = useParams()
    let params = useParams()
    let [assessmentData, setAssessmentData] = useState([])
    const [usersResponseData, setUserResponseData] = useState([])
    const [individualUserData, setIndividualUserData] = useState({})

    let { t } = useTranslation()

    useEffect(() => {
        getAssessmentData()
    }, [])

    async function getAssessmentData() {
        try {
            loader.start()

            let res = await getAssessmnentUserStatus(params.id)
            let response = res.data.response
            setUserResponseData(response?.usersResponses?.filter(e => e.type !== "Individual"))
            setIndividualUserData(response?.usersResponses?.find(e => e.type == "Individual"))

            setAssessmentData(response?.assessmentDetails)

            loader.stop()
        } catch (err) {
            loader.stop()
            console.log(err)
            toast.error(err?.response?.data?.error)
        }
    }

    let breadCrumData = [
        {
            text: t("Assessment.Assessment"),
            path: `/assessment/${assessmentType}`
        },
        {
            text: t(assessmentType),
            path: `/assessment/${assessmentType}`
        },
        {
            text: t("Preview")
        },

    ]

    return (
        <div className={`padding_22_44 ${style.previewUserAssessment_con}`}>
            <BreadCrum dataArr={breadCrumData} />
            <br />
            {assessmentType === ASSESSMENT_TYPES.individual &&
                <div className={style.reviewer_heading}>{t("Individual")}</div>}
            <div className={style.assessment_section}>
                <div className={style.asmnt_detail_grid}>
                    {/* show details if assessment is org or jobrole  */}

                    {assessmentType !== ASSESSMENT_TYPES.jobRole && <>
                        <div>
                            <label>{t("Type")}:</label>
                            <div>
                                {assessmentData?.type}
                            </div>
                        </div>
                        <div>
                            <label>{t("Completion Date")} :</label>
                            <div>
                                {assessmentData?.completionDate && changeDateFormat(assessmentData?.completionDate)}
                            </div>
                        </div>
                    </>

                    }
                    <div>
                        <label>{t("Assessment.Assessment Name")} :</label>
                        <div>{assessmentData?.name}</div>
                    </div>

                    {/* show details if assessment is individual  */}
                    {assessmentType === ASSESSMENT_TYPES.individual &&
                        <>

                            <div>
                                <label>{t("Email")} :</label>
                                <div>{assessmentData?.surveyAssignments?.user?.email}</div>
                            </div>

                            <div>
                                <label>{t("Name")} :</label>
                                <div>{assessmentData?.surveyAssignments?.user?.first_name}</div>
                            </div>
                            <div>
                                <label>{t("jobRole.jobRole")} :</label>
                                <div>{assessmentData?.surveyAssignments?.user?.job_role}</div>
                            </div>
                            <div>
                                <label>{t("Status")} :</label>
                                <div>{individualUserData?.status}</div>
                            </div>

                        </>
                    }

                    <div>
                        <label>{t("Description")} :</label>
                        <div>{assessmentData?.description}</div>
                    </div>

                </div>
            </div>

            <br />
            <div>
                {assessmentType !== ASSESSMENT_TYPES.organizational &&
                    <div className={style.reviewer_heading}>{t("Reviewers")}</div>}

                <TableContainer>
                    <table>
                        <thead>
                            <tr>
                                <th>{t("UserName")}</th>
                                <th>{t("Email ID")}</th>
                                <th>{t("Status")}</th>
                                <th>{t("Type")}</th>
                                <th>
                                    <div></div>
                                </th>

                            </tr>
                        </thead>

                        <tbody>
                            {usersResponseData &&
                                usersResponseData.map((res, key) => {
                                    return (
                                        <tr key={res._id}>

                                            <td>
                                                <div>{res?.userData?.username || "Not Found"}</div>
                                            </td>
                                            <td>
                                                <div>{res?.userData?.email || "Not Found"}</div>
                                            </td>
                                            <td>
                                                <div>{res.status}</div>
                                            </td>
                                            <td>
                                                <div>{res.type}</div>
                                            </td>
                                            <td>
                                                <div></div>
                                            </td>

                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </TableContainer>
                <br />
                <div className="end">
                    <Link to={`/assessment/${assessmentType}`}>
                        <button className="btn-secondary">{t("Cancel")}</button>
                    </Link>
                </div>


            </div>
        </div>
    )
}
