import React from 'react'
import { TableHeader } from '../TableContainer'

import style from "../../../assets/css/components/reports.module.css"
import { SearchInput } from '../../Inputs/SearchInput'
import { Divider } from '@mui/material'
import { CustomProgressLine } from '../CustomProgressLine'
import { useTranslation } from 'react-i18next'

export const MultipleProgressLineTable = ({ searchAttributes, data }) => {
    let { t } = useTranslation()
    return (
        <div className={style.multipleProgressLineTable_con}>


            <TableHeader>
                <div className={style.header_con}>

                    <div className={style.left_con}>
                        <SearchInput {...searchAttributes} />
                    </div>

                    <div className={style.right_con}>
                        {(data?.[0]?.value1 || data?.[0]?.value1 == 0) &&
                            <>
                                <div className={style.item}>
                                    <div>0</div>
                                    <div>{data?.[0]?.heading1}</div>
                                    <div>100</div>
                                </div>
                            </>}
                        {(data?.[0]?.value2 || data?.[0]?.value2 == 0) &&
                            <>
                                |
                                <div className={style.item}>
                                    <div>0</div>
                                    <div>{data?.[0]?.heading2}</div>
                                    <div>100</div>
                                </div>
                            </>}

                        {(data?.[0]?.value3 || data?.[0]?.value3 == 0) &&
                            <>
                                |
                                <div className={style.item}>
                                    <div>0</div>
                                    <div>{data?.[0]?.heading3}</div>
                                    <div>100</div>
                                </div>
                            </>}
                    </div>

                </div>
            </TableHeader>

            {data?.map((res, key) => {
                return (
                    <div className={style.table_row_con} key={key}>
                        <div className={style.left_con}>
                            {res?.name}
                        </div>
                        <div className={style.right_con}>
                            {(res?.value1 || res?.value1 === 0) &&
                                <div className={style.item}>
                                    <CustomProgressLine
                                        value={res?.value1}
                                        variant={"light_secondary"}
                                    />
                                </div>}
                            {(res?.value2 || res?.value2 === 0) &&
                                <div className={style.item}>
                                    <CustomProgressLine
                                        value={res?.value2 || 0}
                                        variant={"light_secondary"}
                                    />
                                </div>}
                            {(res?.value3 || res?.value3 === 0) &&
                                <div className={style.item}>
                                    <CustomProgressLine
                                        value={res?.value3 || 0}
                                        variant={"light_secondary"}
                                    />
                                </div>}
                        </div>
                    </div>

                )
            })}


        </div>
    )
}
