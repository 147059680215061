import React from 'react'
import style from "../../assets/css/components/common.module.css"

export const TableContainer = ({ children }) => {
    return (
        <div className={style.table_con}>
            {children}
        </div>

    )
}
export const TableHeader = ({ children }) => {
    return (
        <div className={style.table_header}>
            {children}
        </div>
    )
}