import {
  FormControl,
  LinearProgress,
  TextField,
  Autocomplete,
  CircularProgress,

  FormControlLabel,
  Radio,
  RadioGroup,
  Box
} from "@mui/material";
import style from "../../../assets/css/components/assessment.module.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ASSESSMENT_STATUS_TYPES, ASSESSMENT_TYPES, CREATE_ASSESSMENT_TYPE } from "../../../constants/constants";
import { CustomDatePicker } from "../../Inputs/CustomDatePicker";
import { AddCircle } from "@mui/icons-material";
import { CustomCheckbox } from "../../Inputs/CustomCheckbox";
import { AssessmentDropDownMenu } from "./AssessmentDropDownMenu";
import { getallgroups } from "../../../store/Slices/GroupSlice";
import { getSubGroupByGroupId } from "../../../api/Group";
import { CustomSelect } from "../../Inputs/CustomSelect";
import { BreadCrum } from "../BreadCrum";
import { validateForm } from "../../../utils/formValidationCheck";
import { Link, useParams } from "react-router-dom";
import { loader, toast } from "../../../utils";
import { useTranslation } from "react-i18next";
import { getUsersByGroupId } from "../../../api/user";
import { fetchCompetenciesByCategories, getCompetenciesByJobRole } from "../../../api/competency";
import { fetchCategoriesByGroupId } from "../../../api/category";
import { fetchAssessmentById, fetchAssessments, validateJobRoleAssessment } from "../../../api/Assessment";
import { QuestionTable } from "./QuestionTable";
import { fetchJobRoleById, fetchJobRolesByGroupId, fetchTPJobRoles } from "../../../api/jobRoles";
import { ConfirmationDialog } from "../ConfirmationDialog";

export const CreateJobRoleIndividualAssSurvey = ({
  assessmentData,
  onSubmit,
  setUpdatedData,
  mode,
  fullData,
  jobRole
}) => {
  let dispatch = useDispatch();
  const { assessmentType } = useParams()
  // disable all fields except completion date if assessment status is not equal to draft and inreview 
  let isEditDisabled = [ASSESSMENT_STATUS_TYPES.readyToDistribute, ASSESSMENT_STATUS_TYPES.live].includes(assessmentData?.status)

  const [jobRoleValidation, setJobRoleValidation] = useState({
    error: false,
    errorMsg: "",
    status: null
  })
  const [submitBtnDisabled, setSubmitDiasbled] = useState(false)

  let [formData, setFormData] = useState({ ...assessmentData });
  let [questionTableVisibility, setQuestionTableVisibility] = useState(false);
  let [fetchKpiLoader, setFetchKpiLoader] = useState(false);
  let [jobRoleId, setJobRoleId] = useState(null)
  let [previousSurveyData, setPreviousSurveyData] = useState([]);
  let [fetchTPJobRoleLoader, setFetchTPJobRoleLoader] = useState(false)

  let [userJobroleData, setUserJobRoleData] = useState({
    jobRoleId: "",
    email: "",
  })

  let [openDropDownIndex, setOpenDropDownIndex] = useState(0);
  const [fetchSubgroupLoader, setFetchSubgroupLoader] = useState(false)

  let { t } = useTranslation();

  let [currentKPI, setCurrentKPI] = useState({
    kpiId: "",
    questionIds: [],
  });

  let [requiredField, setRequiredField] = useState({
    name: false,
    completionDate: false,
    groupId: false,
    jobRoleId: false,
    user: false,
    reviewers: false,
    category: false

  });
  let allGroups = useSelector((e) => e.groupData.allGroups);
  let [allSubGroups, setAllSubGroups] = useState([]);
  let [allCategories, setAllCategories] = useState([])
  let [jobRoles, setJobRoles] = useState([]);
  let [fetchJobRoleLoader, setFetchJobRoleLoader] = useState(false)

  let [allTpJobRoles, setAllTpJobRoles] = useState([]);
  let [allCompetenciesOfJobrole, setAllCompetenciesOfJobrole] = useState([]); //

  let [allCompetencies, setAllCompetencies] = useState([]);

  let [allUsers, setAllUsers] = useState([]);
  let [fechUserloader, setFetchUserLoader] = useState(false);
  let [fetchCategoryLoader, setFetchCategoryLoader] = useState(false);
  let [pulseComptencies, setPulseComptencies] = useState([])
  let [pulseComptency, setPulseComptency] = useState([])
  let [selectedPreviousAssessment, setSelectedPreviousAssessment] = useState({})


  useEffect(() => {
    if (mode === "Edit" && assessmentData?.type === "Pulse") {
      let allCompetencies = formData.kpiQuestions.map(e => e.kpiData)

      setAllCategories(fullData?.categoriesData || [])
      setPulseComptency(allCompetencies.map(e => e._id))
      setAllCompetencies(allCompetencies)
      setPulseComptencies(allCompetencies)
      console.log(fullData)
      setSelectedPreviousAssessment(fullData)
    }
    if (mode === "Edit" && assessmentType === ASSESSMENT_TYPES.jobRole) {
      setJobRoleId(jobRole)
    }

  }, [])




  useEffect(() => {
    if (allGroups.length === 0) {
      dispatch(getallgroups());
    }
  }, [dispatch, allGroups.length]);


  useEffect(() => {
    if (jobRoleId) {
      // fetch job role data 
      fetchJobRoleData(jobRoleId)
    }


  }, [jobRoleId])

  async function fetchTpJobRolesByGroupId() {
    try {
      setFetchTPJobRoleLoader(true)
      let res = await fetchTPJobRoles(formData.groupId)
      setAllTpJobRoles(res?.data?.response || [])
    } catch (err) {
      toast.error(err?.response?.data?.error)
    }
    finally {
      setFetchTPJobRoleLoader(false)
    }
  }


  async function checkJobRoleValidation(jobRoleId) {
    try {
      // check validation of job role
      loader.start()
      setSubmitDiasbled(true)
      let res = await validateJobRoleAssessment(jobRoleId)
      if (res.status === 200) {
        setSubmitDiasbled(false)

      }

    } catch (error) {
      setJobRoleValidation({
        error: true,
        errorMsg: error?.response?.data?.error || "Error",
        status: error.response.status,
      })
    }
    finally {
      loader.stop()
    }
  }


  const fetchJobRoleData = async (jobroleId) => {
    try {
      if (assessmentType === ASSESSMENT_TYPES.jobRole) {
        // show questions loader only in case of jobrole 
        setFetchKpiLoader(true)
      }
      setFetchCategoryLoader(true)
      let res = await fetchJobRoleById(jobroleId);
      let data = res?.data?.response

      let categoryData = data?.categories?.map((d) => d?.categoryData).flat(Infinity) || [];
      let allCompetencies = data?.categories?.map((d) => d?.competenciesData).flat(Infinity) || [];


      setAllCategories(categoryData)

      if (assessmentType === ASSESSMENT_TYPES.jobRole) {
        // autofill all competencies and category in case of jobrole 
        setAllCompetencies(data.categories?.map(d => d.competenciesData).flat(Infinity))
        if (categoryData?.length > 0) {
          // Save all the categoryIds in formData only for job role
          setFormData({ ...formData, categoryIds: categoryData.map((e) => e._id) });
        }
      }
      else {
        // store all competencies in local state for later filtering by category
        setAllCompetenciesOfJobrole(allCompetencies)
      }




      // remove this condition when category data is fixed from bankend 
      // if (data?.categories?.[0]?.categoryData?.length > 0) {
      //   setAllCategories(data?.categories?.map(d => d?.categoryData).flat(Infinity) || [])
      // }
      // else {
      //   setAllCategories([])
      //   toast.error("No Competency available")
      // }

      // setAllSubGroups(res.data.response);

    } catch (err) {
      toast.error(err?.response?.data?.error);
    }
    finally {
      setFetchSubgroupLoader(false)
      setFetchKpiLoader(false)
      setFetchCategoryLoader(false)
    }
  };


  // getSubGroups,SI,KPI based on group selected
  const getsubgroups = async () => {
    try {
      setFetchSubgroupLoader(true)
      let res = await getSubGroupByGroupId(formData.groupId);
      setAllSubGroups(res.data.response);
    } catch (err) {

      toast.error(err?.response?.data?.error);
    }
    finally {
      setFetchSubgroupLoader(false)
    }
  };



  // const getAllCategories = async () => {
  //   try {
  //     setFetchCategoryLoader(true)

  //     let res = await fetchCategoriesByGroupId(formData.groupId)
  //     setAllCategories(res?.data?.response || [])
  //   }
  //   catch (err) {

  //     toast.error(err?.response?.data?.error);
  //   }
  //   finally {
  //     setFetchCategoryLoader(false)
  //   }
  // }


  // getjobRoles based on group selected
  const getjobRoles = async () => {
    try {
      setFetchJobRoleLoader(true)
      let res = await fetchJobRolesByGroupId(formData.groupId);
      setJobRoles(res.data.response);
    } catch (err) {
      toast.error(err?.response?.data?.error);

    }
    finally {
      setFetchJobRoleLoader(false)
    }
  };

  async function getAllUsers() {
    setFetchUserLoader(true);
    try {
      let res = await getUsersByGroupId(formData.groupId);
      if (res.data.success) {
        // let allUsers = res.data.users.filter((e) => e.role === "user");

        setAllUsers(res?.data?.users || []);

      } else {
        toast.error(res?.data?.message);
      }
      setFetchUserLoader(false);
    } catch (err) {

      setFetchUserLoader(false);
      toast.error(err?.response?.data?.error)
    }
  }

  useEffect(() => {
    if (formData.groupId) {
      getAllUsers();
      getjobRoles();
      fetchTpJobRolesByGroupId()

      getsubgroups();
    }
  }, [formData.groupId]);


  // fetch competencies on change of category 
  useEffect(() => {
    console.log("useEffect - init");

    if (formData.categoryIds && formData.categoryIds.length > 0 && formData.type !== "Pulse" && assessmentType !== ASSESSMENT_TYPES.jobRole) {

      setAllCompetencies(allCompetenciesOfJobrole.filter(e => formData.categoryIds.includes(e.categoryId)))

    }
    else if (assessmentType === ASSESSMENT_TYPES.jobRole && formData.categoryIds.length === 0) {
      console.log("user use sue sue")
      // setAllCompetencies([])

    }
  }, [formData.categoryIds, allCompetenciesOfJobrole])

  // fetch competencies on change of user in case of individual 
  // useEffect(() => {
  //   if (formData.surveyAssignments.user.jobRoleId) {
  //     fetchCompetencies(formData.surveyAssignments.user.jobRoleId)
  //   }
  // }, [formData.surveyAssignments.user])




  // async function fetchCompetenciesByCategory(categoryIds) {
  //   console.log("fetchCompetenciesByCategory");

  //   try {
  //     let body = {
  //       categoryIds: categoryIds
  //     }
  //     setFetchKpiLoader(true)
  //     let res = await fetchCompetenciesByCategories(body);
  //     setAllCompetencies(res.data.response);
  //   }
  //   catch (err) {
  //     toast.error(err?.response?.data?.error)
  //   }
  //   finally {
  //     setFetchKpiLoader(false)
  //   }
  // }
  // async function fetchCompetencies(jobRoleId) {
  //   try {
  //     setFetchKpiLoader(true)
  //     let res = await getCompetenciesByJobRole(jobRoleId)
  //     let data = res?.data?.response || []
  //     setAllCompetencies(data);
  //   }
  //   catch (err) {
  //     toast.error(err?.response?.data?.error)
  //     setAllCompetencies([])

  //   }
  //   finally {
  //     setFetchKpiLoader(false)
  //   }
  // }


  function addQuestion(res) {
    let rr = formData.kpiQuestions.find((e) => e.kpiData?._id === res._id);
    if (!rr) {
      setCurrentKPI({ kpiData: res, questionData: [] });
    } else {
      setCurrentKPI({ ...rr });
    }
    setQuestionTableVisibility(true);
  }

  function addQuestionSubmit(data) {
    setFormData((pre) => {
      let ind = pre.kpiQuestions.findIndex(
        (e) => e.kpiData?._id === data.kpiData?._id
      );
      ind !== -1 ? (pre.kpiQuestions[ind] = data) : pre.kpiQuestions.push(data);
      if (mode === "Edit") {
        setUpdatedData((pre2) => {
          return { ...pre2, kpiQuestions: pre.kpiQuestions };
        });
      }

      return { ...pre };
    });

    setQuestionTableVisibility(false);
  }
  function formSubmit() {
    let isFormValid = formValidate();
    console.log(isFormValid);

    let isAllKpisHaveQuestions = validateQuestionsInCompetencies();
    console.log(isAllKpisHaveQuestions);

    if (isFormValid && isAllKpisHaveQuestions) {

      onSubmit(formData);


    } else {
      toast.error(
        !isFormValid
          ? "Please fill all the fields"
          : "All Competencies must have atleast one question !"
      );
    }
  }
  function validateQuestionsInCompetencies() {
    return (
      formData.kpiQuestions.length === allCompetencies.length &&
      formData.kpiQuestions.every((t) => t.questionData.length > 0) &&
      formData.kpiQuestions.length > 0
    );
  }
  function formValidate() {
    let requiredFields = ["name", "completionDate", "groupId", "reviewers"];

    if (assessmentType === ASSESSMENT_TYPES.jobRole) {
      requiredFields.push("jobRoleId")
    }
    if (assessmentType === ASSESSMENT_TYPES.individual) {
      requiredFields.push("user")
    }

    console.log(formData?.reviewers);

    // check validation for nested fields also 
    let nestedFieldsData = {
      jobRoleId: formData?.surveyAssignments?.jobRoleId,
      user: formData?.surveyAssignments?.user,
      reviewers: [
        ...formData?.reviewers?.subGroupIds || [],
        ...formData?.reviewers?.individuals || [],
        ...formData?.reviewers?.jobRoleIds || []
      ]
    }
    let res = validateForm(requiredFields, { ...formData, ...nestedFieldsData });
    console.log(res.data);

    setRequiredField(res.data);

    return res.errorFields.length === 0;
  }



  function resetErrorFields() {
    setRequiredField({
      name: false,
      completionDate: false,
      siIds: false,
      groupId: false,
      previousSurveyId: false,
    });
  }

  let breadCrumData = [
    {
      text: t("Assessment.Assessment"),
      path: `/assessment/${assessmentType}`
    },
    {
      text: t(assessmentType)
    },
    {
      text: mode === "Edit" ? t("Edit") : t("Create New"),
    },

  ]

  const extractUserDetails = (user) => {
    return {
      userId: user?.id || null,
      email: user?.email || null,
      name: user?.username || null,
      jobRoleName: null,
      jobRoleId: user?.job_role || null
    };
  };


  // fetch previous survey data 

  async function fetchPreviousSurveyData() {
    try {

      let query = {
        "$and": [
          { type: "Baseline" },
          { status: "Completed" },
          { assessmentType: ASSESSMENT_TYPES.individual },
          {
            "surveyAssignments.user.userId": formData?.surveyAssignments?.user?.userId
          },
          { groupId: formData.groupId }
        ]
      }

      let res = await fetchAssessments(query)
      setPreviousSurveyData(res?.data?.response || [])

    }
    catch (err) {
      toast.error(err?.response?.data?.error)
    }
  }



  // fetch previous survey data on assessment type or user changes 

  useEffect(() => {

    if (formData?.surveyAssignments?.user?.userId) {

      if (formData.type === "Baseline") {
        // fetchCompetencies(formData?.surveyAssignments?.user?.jobRoleId)
        fetchJobRoleData(formData?.surveyAssignments?.user?.jobRoleId)

      }
      else {
        fetchPreviousSurveyData()
      }
    }
  }, [formData?.surveyAssignments?.user])

  async function fetchPreviousAssessmentData(id) {
    try {
      setFetchJobRoleLoader(true)
      setFetchSubgroupLoader(true)
      setFetchKpiLoader(true)
      let res = await fetchAssessmentById(id)
      let {
        reviewers,
        categoriesData,
        questions
      } = res.data.response
      setSelectedPreviousAssessment(res?.data?.response || {})
      let data = {
        categoryIds: categoriesData.map(e => e._id),
        reviewers: {
          subGroupIds: reviewers?.subGroupsData?.map(e => e._id),
          jobRoleIds: reviewers?.jobRoles?.map(e => e.jobRoleObj.roleId),
          individuals: reviewers?.individuals
        },
        kpiQuestions: questions.map(e => {
          return (
            {
              kpiData: e.competencyData,
              questionData: e.questionData
            }
          )
        }),
      }


      let allCompetencies = questions.map(e => e.competencyData)


      setAllCategories(categoriesData)
      setAllCompetencies(allCompetencies)
      setPulseComptencies(allCompetencies)
      setPulseComptency(allCompetencies.map(e => e._id))

      setAllSubGroups(reviewers?.subGroupsData)
      setJobRoles(reviewers?.jobRoles)
      setFormData(pre => ({ ...pre, ...data }))

    } catch (error) {
      toast.error(error?.response?.data?.error)
    }
    finally {
      setFetchJobRoleLoader(false)
      setFetchSubgroupLoader(false)
      setFetchKpiLoader(false)
    }
  }

  // empty field on change of some fields 
  function emptyFields(fieldName) {
    switch (fieldName) {
      case "Pulse":
        // dropdown states
        setAllCategories([])
        if (formData.type === "Pulse") {
          setAllSubGroups([])
          setJobRoles([])
        }

        setAllCompetencies([])
        setPulseComptency([])
        // formdata changes 
        setFormData(pre => {
          return ({
            ...pre,
            kpiQuestions: [],
            previousSurveyId: null,
            reviewers: {
              subGroupIds: [],
              jobRoleIds: [],
              individuals: []
            },
            surveyAssignments: {
              subGroupIds: [],
              user: {},
              jobRoleId: null
            }
          }
          )
        })
        break;

      default:
        break;
    }
  }

  return (
    <>
      {!questionTableVisibility ? (
        <>
          <BreadCrum dataArr={breadCrumData} />
          <br />
          <div className={style.createAssessment_main_con}>
            <div className={style.assessment_form}>
              <div className={style.groupSelect}>

                <CustomSelect
                  value={
                    formData.groupId
                      ? allGroups.length !== 0
                        ? allGroups.find((e) => e.id === formData.groupId)
                        : null
                      : null
                  }
                  options={allGroups}
                  disabled={mode === "Edit"}
                  optionLabel={"name"}
                  error={requiredField.groupId}
                  keyLabel={"id"}
                  onChange={(e, val) => {
                    setFormData((pre) => ({
                      ...pre,
                      groupId: val && val.id,
                      subGroupIds: [],
                      siIds: [],
                      kpiQuestions: [],
                      surveyAssignments: {
                        subGroupIds: [],
                        user: {},
                        jobRoleId: null
                      },
                      reviewers: {
                        subGroupIds: [],
                        jobRoleIds: [],
                        individuals: []
                      },
                    }));
                    setAllCompetencies([])
                    resetErrorFields();

                    setAllSubGroups([]);
                    setJobRoles([])
                    setAllUsers([])
                  }}
                  label={t("Group Name")}
                  renderOption={"name"}
                />
              </div>
              <div className={style.assessment_name_section}>

                {assessmentType === ASSESSMENT_TYPES.individual &&
                  <div className={style.assessment_type_con_grid_con}>
                    <div>
                      <label>{t("Type")}:</label>
                      <FormControl sx={{ height: "40px" }}>
                        <RadioGroup

                          aria-labelledby="demo-radio-buttons-group-label"
                          row
                          value={formData.type}
                          name="Type"
                          onChange={(e) => {
                            setFormData({ ...formData, type: e.target.value });
                            if (e.target.value === "Pulse") {
                              emptyFields("Pulse")
                            }
                            else {
                              emptyFields("Pulse")
                              if (formData.groupId) {
                                getjobRoles()
                                fetchTpJobRolesByGroupId()
                                getsubgroups()
                              }
                            }

                          }}
                        >
                          {CREATE_ASSESSMENT_TYPE.map((res, key) => {
                            return (
                              <FormControlLabel
                                key={key}
                                value={res}
                                control={
                                  <Radio
                                    disabled={mode === "Edit"}
                                    sx={{
                                      "&, &.Mui-checked": {
                                        color: "rgba(82, 96, 109, 1)",
                                      },
                                    }}
                                  />
                                }
                                label={res}
                              />
                            );
                          })}
                        </RadioGroup>
                      </FormControl>
                    </div>
                    {assessmentType === ASSESSMENT_TYPES.individual &&
                      <div>
                        {(userJobroleData?.jobRole || userJobroleData?.email) && <div><label>{t("jobRole.userDetail")}:</label></div>}

                        <div>
                          {userJobroleData?.jobRole && <div>
                            <span>Name : </span>
                            <span>{userJobroleData?.name}</span>
                          </div>}
                          {userJobroleData?.jobRole && <div>
                            <span>Job Role : </span>
                            <span>{userJobroleData?.jobRole || "--"}</span>
                          </div>}
                          {userJobroleData?.email &&
                            <div>
                              <span>Email : </span>
                              <span>{userJobroleData?.email || "--"}</span>
                            </div>}


                        </div>

                      </div>}
                  </div>
                }

                <br />
                <div className={style.grid_con}>
                  <>
                    {assessmentType === ASSESSMENT_TYPES.jobRole && (
                      <div>
                        <Autocomplete
                          fullWidth
                          value={
                            formData?.surveyAssignments?.jobRoleId
                              ? jobRoles.length !== 0
                                ? jobRoles.find(
                                  (e) => e.jobRoleObj.roleId === formData?.surveyAssignments?.jobRoleId
                                )
                                : null
                              : null
                          }
                          disabled={isEditDisabled}
                          options={jobRoles}
                          loading={fetchJobRoleLoader}
                          className='bg_white'
                          getOptionLabel={(option) => option?.jobRoleObj?.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("jobRole.jobRole")}
                              variant="outlined"
                              error={requiredField.jobRoleId}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {fetchJobRoleLoader ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                ),
                              }}
                            />
                          )}
                          onChange={(e, val) => {
                            if (val?.jobRoleObj?.roleId) {
                              checkJobRoleValidation(val?.jobRoleObj?.roleId, val)
                            }

                            if (!val?._id) {
                              // empty category and competency if we click on cross icon of dropdown 
                              setAllCategories([])
                              setAllCompetencies([])
                            }

                            setJobRoleId(val && val?._id)
                            setFormData((pre) => ({
                              ...pre,
                              name: val?.jobRoleObj?.name ? `${val?.jobRoleObj?.name} Assessment` : "",
                              surveyAssignments: {
                                ...pre.surveyAssignments,
                                jobRoleId: val && val?.jobRoleObj?.roleId,
                              }
                            }));
                            if (mode === "Edit") {
                              setUpdatedData((pre) => ({
                                ...pre,
                                surveyAssignments: {
                                  ...formData.surveyAssignments,
                                  jobRoleId: val && val?.jobRoleObj?.roleId,
                                }
                              }));
                            }
                            resetErrorFields();
                          }}
                          renderOption={(props, option) => (
                            <li {...props} key={option?.jobRoleObj?.roleId}>
                              {option?.jobRoleObj?.name}
                            </li>
                          )}
                        />


                      </div>
                    )}
                  </>
                  <>
                    {assessmentType === ASSESSMENT_TYPES.individual && (
                      <div>
                        <Autocomplete
                          fullWidth
                          value={allUsers?.find(e => e.id == formData?.surveyAssignments?.user?.userId) || null}
                          loading={fechUserloader}
                          disabled={isEditDisabled}
                          // filter users by job role id
                          options={allUsers.filter(e => e.job_role_id)}
                          className='bg_white'
                          getOptionLabel={(option) => option?.username}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("Users")}
                              variant="outlined"
                              error={requiredField.user}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {fechUserloader ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                ),
                              }}

                            />
                          )}
                          onChange={(e, val) => {
                            setAllCompetencies([])
                            emptyFields("Pulse")
                            setPreviousSurveyData([])
                            setUserJobRoleData({
                              jobRole: val?.job_role_label || null,
                              name: val?.userProfile?.firstname + " " + val?.userProfile?.lastname || null,
                              email: val?.email || null
                            })

                            let data = {
                              userId: val?.id || null,
                              email: val?.email || null,
                              first_name: val?.userProfile?.firstname || null,
                              last_name: val?.userProfile?.lastname || null,
                              job_role: val?.job_role_label || null,
                              jobRoleId: val?.job_role_id || null
                            }

                            setFormData((pre) => ({
                              ...pre,
                              kpiQuestions: [],
                              surveyAssignments: {
                                ...pre.surveyAssignments,
                                user: data
                              }
                            }));
                            if (mode === "Edit") {
                              setUpdatedData((pre) => ({
                                ...pre,
                                surveyAssignments: {
                                  ...formData.surveyAssignments,
                                  user: data
                                }
                              }));
                            }
                          }}
                          renderOption={(props, option) => (
                            <li {...props} key={option?.id}>
                              {option?.username}
                            </li>
                          )}
                        />



                      </div>
                    )}
                  </>

                  <div className="flex">
                    {formData.type === "Pulse" ?
                      <>
                        <div className="w-100">
                          <CustomSelect

                            disabled={formData.type === "Baseline" || !formData?.surveyAssignments?.user?.userId || isEditDisabled}
                            value={previousSurveyData.find(e => e._id === formData?.previousSurveyId) || null}
                            className="bg-white"
                            options={previousSurveyData}
                            optionLabel={"name"}
                            keyLabel={"_id"}
                            onChange={(e, val) => {

                              setFormData(pre => ({
                                ...pre, previousSurveyId: val && val._id
                              }))

                              if (val?._id) {
                                fetchPreviousAssessmentData(val._id)
                              }
                              else {
                                emptyFields("Pulse")
                              }
                            }}
                            label={t("Assessment.Previous survey")}
                            renderOption={"name"}
                          />
                        </div>
                        &nbsp;&nbsp; &nbsp;&nbsp;
                      </> : null}

                    <div className="w-100">
                      <CustomDatePicker

                        value={formData.completionDate}
                        fullWidth
                        className="w-100"
                        error={requiredField?.completionDate}
                        disablePast
                        label={t("Completion Date")}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            completionDate: e
                              ? new Date(e.toString()).toISOString()
                              : null,
                          });
                          if (mode === "Edit") {
                            setUpdatedData((pre) => ({
                              ...pre,
                              completionDate: e
                                ? new Date(e.toString()).toISOString()
                                : null,
                            }));
                          }
                          resetErrorFields();
                        }}
                      />
                    </div>
                  </div>

                  <div>
                    <TextField
                      disabled={isEditDisabled || assessmentType === ASSESSMENT_TYPES.jobRole}
                      value={formData.name}
                      label={t("Assessment.Assessment Name")}
                      error={requiredField.name}
                      className="bg-white"
                      onChange={(e) => {
                        setFormData({ ...formData, name: e.target.value });
                        if (mode === "Edit") {
                          setUpdatedData((pre) => ({
                            ...pre,
                            name: e.target.value,
                          }));
                        }
                        resetErrorFields();
                      }}
                      fullWidth
                    />
                  </div>
                  <div>
                    {assessmentType !== ASSESSMENT_TYPES.jobRole &&
                      <TextField
                        value={formData.description}
                        disabled={isEditDisabled}
                        className="bg-white"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            description: e.target.value,
                          });
                          if (mode === "Edit") {
                            setUpdatedData((pre) => ({
                              ...pre,
                              description: e.target.value,
                            }));
                          }
                          resetErrorFields();
                        }}
                        label={t("Description")}
                        fullWidth
                      />}
                  </div>

                </div>
                <br />
                <div style={{ display: "flex", gap: "30px" }}>
                  {assessmentType === ASSESSMENT_TYPES.individual ?
                    <div style={{ width: "100%" }}>
                      {/* <div><label>{t("Categories")}:</label></div> */}
                      <Autocomplete
                        multiple
                        disabled={isEditDisabled}
                        id="competency-select"
                        className="bg-white"
                        options={allCategories}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <CustomCheckbox
                              className="me-6"
                              checked={selected}
                              style={{ marginRight: 8 }}
                            />
                            {option.name}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={t("Categories")}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {fetchCategoryLoader ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                            error={requiredField?.category}
                          />
                        )}
                        value={allCategories.filter(d => formData?.categoryIds.includes(d._id))}
                        onChange={(event, newValue) => {

                          let arr = newValue.map(e => e._id)

                          console.log(arr);

                          console.log([...pulseComptencies]);


                          setPulseComptency([...pulseComptencies].filter(e => arr.includes(e.categoryId)).map(e => e._id))
                          setAllCompetencies([...pulseComptencies].filter(e => arr.includes(e.categoryId)))

                          let questions = []
                          if (formData?.type === "Pulse") {
                            questions = selectedPreviousAssessment?.questions?.filter(e => arr?.includes(e?.competencyData?.categoryId))?.map(ee => {
                              return ({
                                questionData: ee.questionData,
                                kpiData: ee.competencyData
                              }
                              )
                            }) || formData.kpiQuestions
                          }

                          setFormData({
                            ...formData,
                            categoryIds: newValue.map(e => e._id),
                            kpiQuestions: questions
                          })

                          if (mode === "Edit") {
                            setUpdatedData((pre) => ({
                              ...pre,
                              categoryIds: newValue.map(e => e._id),
                              kpiQuestions: questions
                            }));
                          }
                          resetErrorFields();
                        }}

                        renderTags={(tagValue, getTagProps) => (
                          tagValue.map((option) => option.name).join('/')
                        )}

                      />

                    </div> :

                    <div>
                      {allCategories.length > 0 && <div><label>{t("Categories")}:</label></div>}

                      <div className={`wrap flex`}>
                        {allCategories?.map((res) => <Box sx={{
                          mr: 1,
                          bgcolor: "white",
                          borderRadius: "6px",
                          padding: "5px 10px",
                          border: "1px solid var(--LIght-2, #E0E8F9)"
                        }} className={style.category_tab} key={res._id}>
                          {res.name}

                        </Box>)}
                      </div>
                    </div>
                  }

                  {/* competency for indivdual pulse autocomplete  */}
                  {(assessmentType === ASSESSMENT_TYPES.individual && formData.type === "Pulse") &&
                    <>
                      <div style={{ width: "100%" }}>

                        <Autocomplete
                          disabled={isEditDisabled}
                          multiple
                          id="competency-select"
                          className="bg-white"
                          options={pulseComptencies}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.name}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <CustomCheckbox
                                className="me-6"
                                checked={selected}
                                style={{ marginRight: 8 }}
                              />
                              {option.name}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={t("competency.competency")}
                            // error={requiredField?.category}
                            />
                          )}
                          value={pulseComptencies.filter(d => pulseComptency.includes(d._id))}

                          onChange={(event, newValue) => {
                            console.log(newValue)

                            let allCategories = new Set(newValue.map(e => e.categoryId))
                            console.log(allCategories)

                            setPulseComptency(newValue.map(e => e._id))
                            setAllCompetencies(pulseComptencies.filter(e => newValue.some(d => e._id == d._id)))

                            let data = selectedPreviousAssessment.questions?.filter(e => newValue.some(n => n._id == e.competencyData._id)).map(ee => {
                              return ({
                                questionData: ee.questionData,
                                kpiData: ee.competencyData
                              }
                              )
                            }) || formData.kpiQuestions

                            setFormData(pre => {
                              return ({
                                ...pre,
                                kpiQuestions: data,
                                categoryIds: [...allCategories]
                              })
                            })
                            if (mode === "Edit") {
                              setUpdatedData(pre => {
                                return ({
                                  ...pre,
                                  kpiQuestions: data,
                                  categoryIds: [...allCategories]
                                })
                              })
                            }

                          }}

                          renderTags={(tagValue, getTagProps) => (
                            tagValue.map((option) => option.name).join('/')
                          )}

                        />
                      </div>

                    </>

                  }
                </div>
                <br />
                <div><label>{t("Reviewers")}:</label></div>
                <div className={style.reviewer_input_con}>
                  <div>

                    <Autocomplete
                      disabled={isEditDisabled}
                      multiple
                      id="competency-select"
                      className="bg-white"
                      options={allSubGroups}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <CustomCheckbox
                            className="me-6"
                            checked={selected}
                            style={{ marginRight: 8 }}
                          />
                          {option.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"

                          label={t("Sub Groups")}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {fetchSubgroupLoader ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                          error={requiredField?.reviewers}
                        />
                      )}
                      value={allSubGroups.filter(d => formData?.reviewers?.subGroupIds.includes(d._id))}
                      onChange={(event, newValue) => {
                        setFormData({
                          ...formData,
                          reviewers: {
                            ...formData.reviewers,
                            subGroupIds: newValue.map(e => e._id),
                          }
                        })
                        if (mode === "Edit") {
                          setUpdatedData((pre) => ({
                            ...pre,
                            reviewers: {
                              ...formData.reviewers,
                              subGroupIds: newValue.map(e => e._id),
                            }
                          }));
                        }
                        resetErrorFields();
                      }}


                      renderTags={(tagValue, getTagProps) => (
                        <span className={`${style.autocomplete_wrapper} autocomplete-wrapper`}>
                          {tagValue.map((option) => option.name).join(' / ')}
                        </span>
                      )}

                    />
                  </div>
                  <div>


                    <Autocomplete
                      multiple
                      disabled={isEditDisabled}
                      id="competency-select"
                      className="bg-white"
                      options={allTpJobRoles}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option?.name}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <CustomCheckbox
                            className="me-6"
                            checked={selected}
                            style={{ marginRight: 8 }}
                          />
                          {option?.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"

                          label={t("jobRole.Job Roles")}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {fetchTPJobRoleLoader ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                          error={requiredField?.reviewers}
                        />
                      )}
                      value={allTpJobRoles?.filter(d => formData?.reviewers?.jobRoleIds.includes(d.id))}
                      onChange={(event, newValue) => {
                        setFormData((pre) => ({
                          ...pre,
                          reviewers: {
                            ...pre.reviewers,
                            jobRoleIds: newValue.map(e => e.id),
                          }
                        }))
                        if (mode === "Edit") {
                          setUpdatedData((pre) => ({
                            ...pre,
                            reviewers: {
                              ...formData.reviewers,
                              jobRoleIds: newValue.map(e => e.id),
                            }
                          }));
                        }
                        resetErrorFields();
                      }}



                      renderTags={(tagValue, getTagProps) => (
                        <span className={`${style.autocomplete_wrapper} autocomplete-wrapper`}>
                          {tagValue.map((option) => option.name).join('/')}
                        </span>
                      )}

                    />


                  </div>
                  <div>




                    <Autocomplete
                      multiple
                      disabled={isEditDisabled}
                      id="competency-select"
                      className="bg-white"
                      options={allUsers.map(extractUserDetails)}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option?.name}
                      isOptionEqualToValue={(option, value) => option.userId == value.userId}
                      renderOption={(props, option, { selected }) => {

                        return (
                          <li {...props}>
                            <CustomCheckbox
                              className="me-6"
                              checked={formData.reviewers?.individuals.some(e => e.userId == option.userId)}

                              style={{ marginRight: 8 }}
                            />
                            {option?.name}
                          </li>
                        )
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"

                          label={t("Users")}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {fechUserloader ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                          error={requiredField?.reviewers}
                        />
                      )}
                      value={allUsers.map(extractUserDetails).filter(e => formData?.reviewers?.individuals.some(d => d.userId == e.userId))}
                      onChange={(event, newValue) => {

                        setFormData((pre) => ({
                          ...pre,
                          reviewers: {
                            ...pre.reviewers,
                            individuals: newValue,
                          }
                        }))
                        if (mode === "Edit") {
                          setUpdatedData((pre) => ({
                            ...pre,
                            reviewers: {
                              ...formData.reviewers,
                              individuals: newValue,
                            }
                          }));
                        }
                        resetErrorFields();
                      }}

                      renderTags={(tagValue, getTagProps) => (
                        <span className={`${style.autocomplete_wrapper} autocomplete-wrapper`}>
                          {tagValue.map((option) => option.name).join(' / ')}
                        </span>
                      )}

                    />
                  </div>
                </div>


              </div>
            </div>
            <br />
            <br />
            <div className={style.assessment_kpi_sec}>
              <div className={style.header}>
                {t("competency.Competencies for the Job Role")}*
              </div>
              <br />
              {fetchKpiLoader ? (
                <LinearProgress />
              ) : (
                <>
                  {allCompetencies.map((res, index) => {
                    let allQuestions = formData?.kpiQuestions?.find(
                      (e) => e.kpiData?._id === res._id
                    );
                    return (
                      <div
                        key={res._id}
                        className={style.assessment_dropdown_con}
                      >
                        <AssessmentDropDownMenu
                          currentIndex={index}
                          openIndex={openDropDownIndex || 0}
                          questionData={allQuestions}
                          data={res}
                          setOpenIndex={setOpenDropDownIndex}
                          addRemoveButtons={() => {
                            return (
                              <>
                                {(formData.type === "Baseline" && !isEditDisabled) ? (
                                  <span
                                    onClick={() => {
                                      addQuestion(res);
                                      setOpenDropDownIndex(index);
                                    }}
                                    className="pointer flex align_center add_more"
                                  >
                                    <AddCircle />
                                    &nbsp;
                                    <span>
                                      {allQuestions?.questionData?.length > 0
                                        ? t("Assessment.Add/Remove Questions")
                                        : t("Assessment.Add Question")}
                                    </span>
                                  </span>
                                ) : null}
                              </>
                            );
                          }}
                        />
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </div>
          <br />
          <div className="end">
            <button className="btn-primary" onClick={formSubmit}
              disabled={assessmentType === ASSESSMENT_TYPES.jobRole && submitBtnDisabled}
            >
              {mode === "Create" ? t("Create") : t("Update")}
            </button>
            &nbsp;&nbsp;
            <Link to={`/assessment/${assessmentType}`}>
              <button className="btn-secondary">{t("Cancel")}</button>
            </Link>
          </div>
          <ConfirmationDialog
            onSubmit={() => {
              if (jobRoleValidation.status === 409) {
                setSubmitDiasbled(false)
                setFormData(pre => ({ ...pre, name: pre.name + " 2" }))

              }
              setJobRoleValidation({
                error: false,
                errorMsg: "",
                status: null
              })
            }}
            noClosable
            heading={"Warning"}
            text={jobRoleValidation.errorMsg}
            open={jobRoleValidation.error}
          />
        </>
      ) : (
        <QuestionTable
          assessmentName={formData.name}
          onContinue={addQuestionSubmit}
          onCancel={() => setQuestionTableVisibility(false)}
          currentKPI={currentKPI}
          usedFor={"competency"}
        />
      )
      }
    </>
  );
};
