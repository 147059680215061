import { Link } from "react-router-dom"
import style from "../../../assets/css/components/kpi&sicommon.module.css"
import { TableHeader } from "../TableContainer"
import { SearchInput } from "../../Inputs/SearchInput"
import { CustomPagination } from "../CustomPagination"
import React, { useEffect, useRef, useState } from "react"
import { KPISIHomeTableDropDown } from "./KPISIHomeTableDropDown"
import { searchDataByNestedKey } from "../../../utils/search"
import { isUserCreateUpdateData, loader, toast } from "../../../utils"
import { fetchGroups } from "../../../api/StrategicImperatives"
import { useTranslation } from "react-i18next"
import { NoTableData } from "../NoTableData"
import { useSelector } from "react-redux"


export const KPISIHomeTable = ({ type, primary_group, role }) => {
    const tokenStatus = useSelector((state) => state.tokenStatus);
    let [allData, setAllData] = useState([])
    let [page, setPage] = useState(1)
    let record_per_page = 20
    let [all, setAll] = useState([])
    let [searchValue, setSearchValue] = useState("")
    let loaderP = useSelector(e => e.loader)
    let [totalGroup, setTotalGroup] = useState(0)
    let { t } = useTranslation()
    let [error, setError] = useState({
        error: false,
        errorMessage: `No ${type} Available`
    })

    function pageChange(e) {
        setPage(e)
        setSearchValue("")
    }
    let getAllGroupsBySiOrKPIRef = useRef(getAllGroupsBySiOrKPI)
    async function getAllGroupsBySiOrKPI(pageNo) {
        try {
            let data = {
                page: pageNo,
                record_per_page: record_per_page,
                collectionType: type,
                ...(role === 'groupAdmin' ? { primary_groupId: primary_group } : {})
            }
            loader.start()
            let res = await fetchGroups(data)
            setTotalGroup(res.data.totalGroups)
            setAllData(res.data.response)
            setAll(res.data.response)
            loader.stop()
            setError({
                error: false,
                errorMessage: res.data.response.length !== 0 ? '' : `No ${type} Available`
            })
        }
        catch (err) {
            // toast.error(err?.response?.data?.error)
            loader.stop()
            setError({
                error: true,
                errorMessage: err?.response?.data?.error
            })
        }
    }

    useEffect(() => {
        setError({
            error: false,
            errorMessage: `No ${type} found`
        })
        setSearchValue("")
        getAllGroupsBySiOrKPIRef.current(page)
    }, [getAllGroupsBySiOrKPIRef, page, tokenStatus])

    function searchGroup(e) {
        setError({
            error: false,
            errorMessage: `No ${type} Available`
        })
        setSearchValue(e.target.value)
        setAllData(searchDataByNestedKey(all, e.target.value, "data", "name"))
    }

    return (
        <div className={`${style.KPISI_home_table_con} padding_22_44`}>
            <div className="between">
                <div className="h1">
                    {
                        type === "KPI" ?
                            t("KPI.Key Performance Indicators")
                            :
                            t("Strategic Imperatives")
                    }
                </div>
                {isUserCreateUpdateData() ?
                    <div>
                        <Link to={"create"}>
                            <button className="btn-primary">{t("Create New")}</button>
                        </Link>
                    </div> : null}
            </div>


            <TableHeader>
                <div className={style.search_input}>
                    <SearchInput value={searchValue} onChange={searchGroup} placeholder={`${t("Search")} ${t(type + "." + type)}`} />

                </div>
            </TableHeader>


            <div className={style.table_head}>
                <div>{t("Group Name")}</div>
                <div>{type}s</div>
            </div>

            <div className={style.scroll_part}>
                {(error.error || allData?.length === 0) && !loaderP ? <NoTableData text={error.errorMessage} /> :
                    <>
                        {allData.map((res, key) => {
                            return (
                                <KPISIHomeTableDropDown open={key === 0} type={type} data={res} key={key} />
                            )
                        })}
                    </>
                }
            </div>
            <CustomPagination page={page - 1} pageChange={pageChange} count={totalGroup} perPage={record_per_page} />

        </div >
    )
}