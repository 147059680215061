import React, { useEffect, useState } from 'react'
import style from "../../assets/css/components/common.module.css"
import { ExpandLessButton } from '../Buttons/ExpandLessButton'
import { ExpandMoreButton } from '../Buttons/ExpandMoreButton'


export const Accordion = ({ title, children, open }) => {
    let [expand, setExpand] = useState(false)

    const toggleClick = () => setExpand(!expand);
    useEffect(() => {
        setExpand(open)
    }, [open])
    return (
        <div className={`${style.accordion_con} `}>
            <div>
                <div className={`flex align_center`}>
                    <div className={style.expand_btn}>
                        {!expand ? <ExpandLessButton onClick={toggleClick} /> : <ExpandMoreButton onClick={toggleClick} />}
                    </div>
                    <div className={style.heading} >
                        {title}
                    </div>
                </div>

            </div>

            {expand ? children : null}
        </div >
    )
}
