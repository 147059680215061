import axios from "../config/axiosInterceptor";
import { API, BASE_URL } from "./api";

export const getAllTags = async () => {
    return await axios.get(BASE_URL + API.getAllTags)
}
export const editTag = async (body) => {
    return await axios.put(BASE_URL + API.editTag, body)
}
export const deleteTags = async (body) => {
    return await axios.post(BASE_URL + API.deleteTags, body)
}