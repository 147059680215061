import React from 'react';
import './App.css';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { Router } from './routes/routes';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { SessionExpiredModal } from './components/Common/SessionExpiredModal';
function App() {
  return (
    <Provider store={store}>

      <Router />
    </Provider>
  );
}

export default App;
