import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import React from 'react'
import { CustomCheckbox } from './CustomCheckbox';

export const CustomAutoComplete = ({ error, label, renderTagsClassName, keyNameForKey, optionLabelKey, loader, ...props }) => {
    return (
        <Autocomplete

            multiple
            id="competency-select"
            className="bg-white"
            disableCloseOnSelect
            getOptionLabel={(option) => option?.[optionLabelKey] || option}
            renderOption={(props, option, { selected }) => {
                let { key, ...rest } = props
                return (

                    <li key={option?.[keyNameForKey] || key} {...rest}>
                        <CustomCheckbox
                            className="me-6"
                            checked={selected}
                            style={{ marginRight: 8 }}
                        />
                        {option?.[optionLabelKey] || option}
                    </li>
                )
            }}

            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loader ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                    error={error}
                />
            )}
            renderTags={(tagValue, getTagProps) => (
                <span className={`${renderTagsClassName} autocomplete-wrapper`}>
                    {tagValue.map((option) => option?.[optionLabelKey])?.join(' / ')}
                </span>
            )}
            {...props}
        />
    )
}
