import React, { act, useRef, useState } from 'react'
import { BreadCrum } from '../../components/Common/BreadCrum'
import style from "../../assets/css/views/reports.module.css"
import { REPORTS_TABS } from '../../constants/constants'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { DownloadButton } from '../../components/Buttons/DownloadButton'
import { DownloadPdf } from '../../components/Common/DownloadPdf'
import { MultipleCircularProgressCard } from '../../components/Common/Reports/MultipleCircularProgressCard'
import { MultipleProgressLineTable } from '../../components/Common/Reports/MultipleProgressLineTable'
import { ButtonTabGroup } from '../../components/Common/ButtonTabGroup'
import { generateCombinedReport } from '../../api/Reports'
import { useEffect } from 'react'
import { formatChartdata, loader, toast } from '../../utils'
import { NoTableData } from '../../components/Common/NoTableData'
import { Grid, LinearProgress } from '@mui/material'
import { useQueryParams } from '../../hooks/useQueryParams'
import { getAllDPDByJobRole } from '../../api/developmentPlan'
import { TabButton } from '../../components/Common/TabButton'
import _ from "underscore";


export const ReportsDetailIndividual = () => {
  const query = useQueryParams()
  const jobRoleId = query.get("jobRoleId")
  const pdfRef = useRef()

  const { assessmentType } = useParams()
  const { t } = useTranslation()
  const [error, setError] = useState({
    error: false,
    errorMsg: ""
  })
  let [activeTab, setActiveTab] = useState(REPORTS_TABS[0].value)
  const [user, setUser] = useState({})
  const [group, setGroup] = useState(null)
  const [pageLoader, setPageLoader] = useState(false)
  const [unstructuredReportData, setUnstructuredReportData] = useState({})
  const [selectedDpd, setSelectedDpd] = useState(null)

  const [allDevelopmentPlans, setAllDevelopmentPlans] = useState([]);

  const [chartData, setChartData] = useState({
    circularChartData: [],
    tableChartData: []
  })
  const [filteredChartData, setFilteredChartData] = useState({
    circularChartData: [],
    tableChartData: [],
  })


  const [filterInputValues, setFilterInputValues] = useState({
    assessmentCycle: null
  })

  const params = useParams();


  useEffect(() => {
    if (params.jobRoleId) {
      fetchAllDevelopmentPlans(params.jobRoleId)
    }
  }, [])


  async function fetchAllDevelopmentPlans(jobRoleId) {
    try {
      loader.start()
      let res = await getAllDPDByJobRole(jobRoleId)

      let dpdData = res?.data?.response?.[0]?.DPD || []
      setAllDevelopmentPlans(dpdData)
      setSelectedDpd(dpdData?.[0]?._id)
    } catch (error) {
      setError({
        error: true,
        errorMsg: error?.response?.data?.error
      })
      toast.error(error?.response?.data?.error)
    }
    finally {
      loader.stop()
    }
  }

  useEffect(() => {
    if (selectedDpd) {
      getReportData()
    }
  }, [selectedDpd])

  const downloadClickHandler = () => {
    const input = document.getElementById('pdf-content-section');
    return input;
  };

  const breadCrumData = [
    {
      text: t("Reports"),
      path: `/reports/${assessmentType}`
    },
    {
      text: t(assessmentType),
    },
    {
      text: t("Details"),
    },

  ]



  async function getReportData() {
    try {
      let body = {
        assessmentType: assessmentType,
        userId: params.id,
        jobRoleId: params.jobRoleId,
        dpdId: selectedDpd,
      }
      setError({
        error: false,
        errorMsg: ""
      })
      loader.start()
      setPageLoader(true)
      let res = await generateCombinedReport(body)

      let response = res?.data?.response || []

      if (_.size(response.reports)) {
        setUser({
          email: response?.email,
          jobRole: response?.job_role,
          name: `${response?.first_name} ${response?.last_name}`,
        })
        setGroup(response?.groupName)
        let data = {
          assessmentType: assessmentType,
          reports: response.reports
        }
        setUnstructuredReportData(data)
        // this function is also used to format report date in single assesment report page
        // format response data as per chart compatiblity 
        let formattedData = formatChartdata(data, "multipleAssessmentReport", activeTab)
        setChartData(formattedData)
        setFilteredChartData(formattedData)

      }
      else {
        setError({
          error: true,
          errorMsg: "No Reports Found"
        })
      }
    }
    catch (err) {
      console.log(err)
    }
    finally {
      loader.stop()
      setPageLoader(false)
    }
  }



  function TabBtnClick(res) {
    setActiveTab(res.value)
    let formattedData = formatChartdata(unstructuredReportData, "multipleAssessmentReport", res.value)


    setChartData(formattedData)
    setFilteredChartData(formattedData)

  }


  function cicularCardClick(data, chart1Index) {

    setFilteredChartData((pre) => {
      let tableChartData = pre.circularChartData[chart1Index].active ? chartData.tableChartData : chartData.tableChartData.filter(e => e.parentId == data.itemId)
      return {
        circularChartData: pre.circularChartData?.map((item, index) => ({
          ...item,
          active: index === chart1Index ? !item.active : false
        })),
        tableChartData,
      };
    });
  }
  return (
    <div className={`padding_22_44 ${style.reports_detail_con}`}>
      <BreadCrum dataArr={breadCrumData} />
      <br />
      <section ref={pdfRef}>
        <section className={style.report_filter_con}>
          <div className={style.header}>
            <div className="between">
              <div className="header_4">{group}</div>
              <DownloadPdf
                onSubmit={() => pdfRef.current}
                fileName={`Report`}
              //   onBeforeGenerate={removePdfContentSection}
              >
                <DownloadButton />
              </DownloadPdf>
            </div>
          </div>




          <div className={style.report_filter}>

            <div className={style.individual_details_con}>
              <Grid container spacing={2}>
                <Grid item>
                  <span className={style.label}>{t("Email")}:</span>&nbsp;&nbsp;<span className={style.content}>{user.email}</span>
                </Grid>
                <Grid item>
                  <span className={style.label}>Name:</span>&nbsp;&nbsp;<span className={style.content}>{user.name}</span>
                </Grid>
                <Grid item>
                  <span className={style.label}>Job Role:</span>&nbsp;&nbsp;<span className={style.content}>{user.jobRole}</span>
                </Grid>
              </Grid>
            </div>
            <br />

            <div className={`${style.dp_heading}`}>{t("developmentPlan.developmentPlan")}</div>
            <div className={style.dp_btn_flex}>
              {allDevelopmentPlans.map((devData) => {
                return (
                  <TabButton
                    onClick={() => setSelectedDpd(devData?._id)}
                    active={selectedDpd === devData?._id}
                    label={devData?.name}
                  />
                )
              })}
            </div>


            <br />
            <br />
            <ButtonTabGroup
              onClick={TabBtnClick}
              activeIndex={REPORTS_TABS.findIndex(e => e.value === activeTab)}
              tabData={REPORTS_TABS} />
            <br />
            <br />

          </div>
        </section>


        {pageLoader ? <LinearProgress /> :
          <section className={style.progress_bar_con}>
            {error.error ?
              <NoTableData text={error.errorMsg} />
              :
              <>

                <div className={style.heading}>
                  {t("Categories")}
                </div>
                <div className={style.card_section_con}>
                  <div className="flex">
                    {filteredChartData?.circularChartData?.map((res, ind) => {
                      return (
                        <MultipleCircularProgressCard
                          key={res.itemId}
                          active={res.active}
                          title={res.name}
                          onClick={(e) => cicularCardClick(res, ind)}
                          showChart={true}
                          values={[res?.value1, res?.value2, res?.value3].filter(e => e !== null && e !== undefined)}
                        />
                      )
                    })}
                  </div>

                </div>
                <br />
                <div className={`subtitle_2`}>
                  {t("competency.competency")}
                </div>
                <br />
                <div className={style.kpi_table_con}>

                  <MultipleProgressLineTable
                    data={filteredChartData?.tableChartData || []}
                  />
                </div>
              </>}


          </section>

        }

      </section>
    </div >
  );
}
