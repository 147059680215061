import React, { useEffect, useState } from "react";
import styles from "../../assets/css/views/profile.module.css";
import { BreadCrum } from "../../components/Common/BreadCrum";
import { CustomSelect } from "../../components/Inputs/CustomSelect";
import { useTranslation } from "react-i18next";
import { SUPPORTED_LANGUAGES } from "../../constants/constants";
import { currentUser, currentUserRole, loader, toast } from "../../utils";
import { fetchTPGroup } from "../../api/user";
import { useSelector } from "react-redux";
export const Profile = () => {

    let data = currentUser();
    let userData = {};
    let userProfileData = {};
    let { t, i18n } = useTranslation();
    let [currentLanguage, setCurrentLanguage] = useState(
        localStorage.getItem("language") || "en"
    );
    let [userGroup, setUserGroup] = useState();
    if (data) {
        userData = data.user;
        userProfileData = data.userProfile;

    }

    function languageChange(e, val) {
        if (val) {
            i18n.changeLanguage(val.code);
            localStorage.setItem("language", val.code);
            setCurrentLanguage(val.code);
        } else {
            i18n.changeLanguage("en");
            localStorage.setItem("language", "en");
            setCurrentLanguage("en");
        }
    }
    useEffect(() => {
        if (userData && userData.primary_group && currentUser().role.item_name !== "user") {
            getGroupData();
        }
    }, []);

    async function getGroupData() {
        try {
            loader.start()
            let res = await fetchTPGroup(userData?.primary_group);
            setUserGroup(res.data.response)
            loader.stop()
        } catch (err) {
            console.error(err);
            toast.error(err.response.data.error)
            loader.stop()
        }
    }

    let breadCrumData = [
        {
            text: t("Profile"),
        },
    ]

    return (
        <div className={`${styles.profile_main_con} padding_22_44`}>
            <div className="between">
                <BreadCrum dataArr={breadCrumData} />
            </div>
            <br />
            <div className={styles.container}>
                <div className="flex">
                    <div className="w-20">
                        <b>{t("Name")} :</b>
                    </div>
                    <div className="w-50">{userProfileData?.firstname + " " + userProfileData?.lastname}</div>
                </div>
                <br />
                <div className="flex">
                    <div className="w-20">
                        <b>{t("Username")} :</b>
                    </div>
                    <div className="w-50">{userData.username}</div>
                </div>
                <br />
                <div className="flex">
                    <div className="w-20">
                        <b>{t("Email")} :</b>
                    </div>
                    <div className="w-50">{userData.email}</div>
                </div>

                {userGroup?.name &&
                    <>
                        <br />
                        <div className="flex">
                            <div className="w-20">
                                <b>{t("Primary Group")} :</b>
                            </div>
                            <div className="w-50">{userGroup?.name}</div>
                        </div>
                    </>
                }
                <br />
                <div className="flex">
                    <div className="w-20">
                        <b>{t("Role")} :</b>
                    </div>
                    <div className="w-50">{currentUserRole()}</div>
                </div>
                <br />
                <div className="flex align_center">
                    <div className="w-20">
                        <b>{t("Language")} :</b>
                    </div>
                    <div style={{ width: "250px" }}>
                        <CustomSelect
                            options={SUPPORTED_LANGUAGES}
                            onChange={languageChange}
                            optionLabel={"name"}
                            keyLabel={"key"}
                            label={t("Language")}
                            value={SUPPORTED_LANGUAGES.find((e) => e.code == currentLanguage)}
                            renderOption={"name"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
