import React from 'react';
import { Snackbar } from "@mui/material"
import { useSelector } from "react-redux"
import { currentUserRole, toast } from "../../utils"
import { Cancel, Close } from "@mui/icons-material"
import style from "../../assets/css/components/common.module.css"

export const CustomSnackbar = () => {
    let toaster = useSelector(e => e.toast)
    let currentUser = currentUserRole()

    return (
        <>
            <Snackbar open={toaster.open} sx={{ width: '80%', height: currentUser === "user" ? "300px" : "140px" }} autoHideDuration={3000}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                className={`${style.snackbar_con}`}
                onClose={() => toast.stop()}
            >

                <div className={`w-100 flex justify_between align_center ${style.toast} ${style[toaster.type]} `}>
                    <div className="toaster_msg">
                        <div style={{ height: '25px' }}>
                            {toaster.type === "success" ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                    <path d="M12.3663 18.0333L20.5913 9.80833L18.958 8.175L12.3663 14.7667L9.04134 11.4417L7.40801 13.075L12.3663 18.0333ZM13.9997 24.3333C12.3858 24.3333 10.8691 24.0271 9.44967 23.4146C8.03023 22.8021 6.79551 21.9708 5.74551 20.9208C4.69551 19.8708 3.86426 18.6361 3.25176 17.2167C2.63926 15.7972 2.33301 14.2806 2.33301 12.6667C2.33301 11.0528 2.63926 9.53611 3.25176 8.11667C3.86426 6.69722 4.69551 5.4625 5.74551 4.4125C6.79551 3.3625 8.03023 2.53125 9.44967 1.91875C10.8691 1.30625 12.3858 1 13.9997 1C15.6136 1 17.1302 1.30625 18.5497 1.91875C19.9691 2.53125 21.2038 3.3625 22.2538 4.4125C23.3038 5.4625 24.1351 6.69722 24.7476 8.11667C25.3601 9.53611 25.6663 11.0528 25.6663 12.6667C25.6663 14.2806 25.3601 15.7972 24.7476 17.2167C24.1351 18.6361 23.3038 19.8708 22.2538 20.9208C21.2038 21.9708 19.9691 22.8021 18.5497 23.4146C17.1302 24.0271 15.6136 24.3333 13.9997 24.3333Z" fill="#5BA400" />
                                </svg> :
                                <Cancel sx={{ color: 'red' }} />
                            }
                        </div>&nbsp;&nbsp;
                        <div>{toaster.msg}</div>
                    </div>
                    <div className="pointer" onClick={() => toast.stop()}><Close /></div>

                </div>
            </Snackbar>
        </>
    )
}