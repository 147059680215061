import { Box, Dialog, Divider } from "@mui/material";
import React, { useState } from "react";
import { loader, toast } from "../../../utils";
import * as XLSX from "xlsx";
import style from "../../../assets/css/components/questionBank.module.css";
import { Link } from "react-router-dom";
import { CrossButton } from "../../Buttons/CrossButton";
import { ProgressLine } from "../ProgressLine";
import { CreateBulkQuestionApi } from "../../../api/Question";
import ToastError from "../../../utils/toastError";
import { useTranslation } from "react-i18next";
import { ASSESSMENT_TYPES } from "../../../constants/constants";

export const BulkUploadQuestion = ({ bulkUploadDone }) => {
  let [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  let [btnDisable, setBtnDisable] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  let { t } = useTranslation();
  let [error, setError] = useState({
    error: false,
    errorMessage: "",
  });
  const handleCloseDialog = () => {
    // Add any additional actions you want to perform on dialog close
    setOpen(false);
    setFile(null);
  };
  const handleDownload = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href =
      "https://leadership-systems.s3.amazonaws.com/sample/_bulkQuestions-sample.csv"; // Adjust the path accordingly
    downloadLink.download = "bulkQuestions-copy.csv"; // Adjust the filename accordingly
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleFileChange = (e) => {
    setError({
      error: false,
      errorMessage: "",
    });
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setProgressValue(0);
    e.target.value = "";
  };

  const handleUpload = () => {
    if (file) {
      //   loader.start();
      setBtnDisable(true);
      setProgressValue(0); // Reset progress value
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });

          // Assuming there is only one sheet in the Excel file
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];

          // Convert the worksheet to an array of objects
          const rows = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
            blankrows: false,
          });
          // Ensure all columns are present in every row
          const columnNames = rows[0];
          // Define the expected order of headers
          const expectedHeaders = [
            "Question",
            "Assessment_Type",
            "Competency",
            "Tag",
            // "Question_Type",
            "Option_1",
            // "Weightage_1",
            "Option_2",
            // "Weightage_2",
            "Option_3",
            // "Weightage_3",
            "Option_4",
            // "Weightage_4",
            "Option_5",
            // "Weightage_5",
          ];
          // Validate the order of headers
          const headerValidation = expectedHeaders.every(
            (header, index) => columnNames[index] === header
          );
          if (!headerValidation) {
            setBtnDisable(false);
            setProgressValue(0);
            throw new setError({
              error: true,
              errorMessage:
                "Please check the order of the headers in your sample file.",
            });
          }
          const totalRows = rows.length;
          // Ensure all columns are present in every row, excluding the header row
          const completeRows = rows.slice(1).map((row, index) => {
            const currentProgress = ((index + 1) / totalRows) * 100;
            setProgressValue(currentProgress);

            const completeRow = {};
            columnNames.forEach((col, index) => {
              completeRow[col] = row[index] || null;
            });
            return completeRow;
          });
                  // Validate Assessment_Type values
        const validAssessmentTypes = [ASSESSMENT_TYPES.organizational, ASSESSMENT_TYPES.jobRole, ASSESSMENT_TYPES.individual];
        const invalidRow = completeRows.find(
          (row, index) => !validAssessmentTypes.includes(row["Assessment_Type"])
        );
        if (invalidRow) {
          const rowIndex = completeRows.indexOf(invalidRow);
          setBtnDisable(false);
          setProgressValue(0);
          throw new setError({
            error: true,
            errorMessage: `Invalid Assessment Type value in row ${rowIndex + 1}. Expected values are "Organizational", "JobRole", or "Individual".`,
          });
        }
        const rowWithMissingValues = completeRows.find((row, index) => {
          // Check for missing Question, and skip Competency check if Assessment_Type is "Organizational"
          if (!row["Question"]) {
            return true;
          }
          if (row["Assessment_Type"] !== ASSESSMENT_TYPES.organizational && !row["Competency"]) {
            delete row["Tag"];
            return true;
          }
          if (row["Assessment_Type"] === ASSESSMENT_TYPES.organizational && !row["Tag"]) {
            delete row["Competency"];
            return true;
          }
          return false;
        });
          console.log(rowWithMissingValues)
        if (rowWithMissingValues) {
          const columnName = Object.keys(rowWithMissingValues).find(
            (key) => !rowWithMissingValues[key]
          );
          const rowIndex = completeRows.indexOf(rowWithMissingValues);
          setBtnDisable(false);
          setProgressValue(0);
          throw new setError({
            error: true,
            errorMessage: `Row ${rowIndex + 1} has a missing value in the column "${columnName}".`,
          });
        } else {
            // ...

            // Check for null values in "Option" columns
            const optionCount = 5;
            const updatedRows = completeRows.map((row, index) => {
              let optionArray = [];
              let nameSet = new Set(); // Create a Set to store unique names
              let count = 0;
              let nullCount = 0;

              // Remove null values in "Option" columns
              for (let i = 1; i <= optionCount; i++) {
                const optionKey = `Option_${i}`;

                if (row[optionKey] === null) {
                  delete row[optionKey];
                  nullCount++;
                  if (nullCount > 3) {
                    throw new setError({
                      error: true,
                      errorMessage: `Please provide at least two options in Row ${index + 1
                        }.`,
                    });
                  }
                } else {
                  // Check if the name already exists in nameSet
                  if (nameSet.has(row[optionKey])) {
                    throw new setError({
                      error: true,
                      errorMessage: `There is a duplicate option found in row ${index + 1
                        }: ${row[optionKey]}`,
                    });
                  } else {
                    nameSet.add(row[optionKey]); // Add the name to nameSet
                    count++;
                    optionArray.push({
                      name: row[optionKey],
                      index: count,
                    });
                    delete row[optionKey];
                  }
                }
              }
              row["options"] = optionArray;
              return row;
            });
            addQuestion(updatedRows);

            // ...
          }
        } catch (e) {
          if (e instanceof ToastError) {
            toast.error(e.message);
          } else {
            console.error(e);
          }
          setBtnDisable(false);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const addQuestion = async (data) => {
    try {
      loader.start();
      let res = await CreateBulkQuestionApi(data);
      setProgressValue(0);
      setBtnDisable(false);
      toast.success(res?.data?.message);
      setOpen(false);
      setFile(null);
      bulkUploadDone();
    } catch (err) {
      toast.error(err?.response?.data?.error);
    }finally{
      setBtnDisable(false);
      setProgressValue(0);
      loader.stop();
    }
  };
  return (
    <>
      <button onClick={() => setOpen(true)} className="btn-secondary">
        {t("questionBank.Bulk Upload")}
      </button>
      <Dialog
        maxWidth={"sm"}
        fullWidth
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box >
          {/* <div className="between">
            <div className={style.heading}>
              {t("questionBank.Upload Question")}
            </div>
            <div
              onClick={handleCloseDialog}
              className={`pointer ${style.heading}`}
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.53341 25.3337L6.66675 23.467L14.1334 16.0003L6.66675 8.53366L8.53341 6.66699L16.0001 14.1337L23.4667 6.66699L25.3334 8.53366L17.8667 16.0003L25.3334 23.467L23.4667 25.3337L16.0001 17.867L8.53341 25.3337Z"
                  fill="black"
                />
              </svg>
            </div>
          </div> */}
          <div className={`between dialog_header`}>
            <div className="dialog_heading">{t("questionBank.Upload Question")}</div>
            <div onClick={handleCloseDialog} className={`pointer`}>
              <CrossButton />
            </div>
          </div>
          <Divider color="#E0E8F9" sx={{ borderBottomWidth: 1 }} />




          <Box className={style.bulkUpload_con} sx={{ p: 4 }}>



            <div className={style.upload_input}>
              <input
                type="file"
                id="bulkuploadquestion"
                hidden
                accept=".csv"
                onChange={handleFileChange}
              />
              <div className={style.file_area}>
                <div className="flex">
                  <label
                    htmlFor="bulkuploadquestion"
                    className={style.file_choose_area}
                  >
                    <div>
                      <svg
                        width="53"
                        height="53"
                        viewBox="0 0 53 53"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.8626 17.2745C13.0386 18.1068 13.0469 19.4483 13.8792 20.2723C14.7114 21.0942 16.0551 21.088 16.877 20.2537L24.3796 12.6641V34.5329C24.3796 35.7026 25.3279 36.6529 26.4996 36.6529C27.6714 36.6529 28.6196 35.7026 28.6196 34.5329V12.6641L36.1223 20.2537C36.9442 21.088 38.2879 21.0942 39.1201 20.2723C39.9524 19.4483 39.9606 18.1068 39.1367 17.2745L28.7625 6.77414C27.5161 5.51539 25.4831 5.51539 24.2367 6.77414L13.8626 17.2745Z"
                          fill="black"
                        />
                        <path
                          d="M11.1299 29.6796C11.1299 28.5078 10.1817 27.5596 9.00989 27.5596C7.83812 27.5596 6.88989 28.5078 6.88989 29.6796C6.88989 35.9818 7.57312 40.7767 10.9291 43.7246C12.5957 45.1883 14.7322 46.0289 17.2599 46.5174C19.7795 47.0039 22.8435 47.1696 26.4999 47.1696C30.1564 47.1696 33.2203 47.0039 35.74 46.5174C38.2678 46.0288 40.4044 45.1883 42.0708 43.7246C45.4268 40.7764 46.11 35.9818 46.11 29.6796C46.11 28.5078 45.1618 27.5596 43.99 27.5596C42.8182 27.5596 41.87 28.5078 41.87 29.6796C41.87 36.0936 41.0439 38.9837 39.2718 40.5403C38.3733 41.3291 37.0421 41.9481 34.9345 42.3539C32.8186 42.7639 30.0775 42.9295 26.5001 42.9295C22.9226 42.9295 20.1814 42.7638 18.0656 42.3539C15.958 41.9482 14.6268 41.3291 13.7283 40.5403C11.9561 38.9835 11.1299 36.0933 11.1299 29.6796Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                    &nbsp;&nbsp;&nbsp;
                    <div>
                      <div className={style.h1}>
                        {t("questionBank.Select a file to upload")}
                      </div>
                      <div className={style.h2}>
                        {t("questionBank.or drag and drop your files here")}
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              {file ? (
                <div className={style.uploaded_file}>
                  <div className={style.fileName}>
                    <svg
                      width="31"
                      height="31"
                      viewBox="0 0 31 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30.2262 1.65016C29.7527 1.21058 29.1327 0.980496 28.497 1.00353H9.54327C8.90027 0.980521 8.28027 1.2106 7.80677 1.65016C7.33328 2.08972 7.0584 2.68795 7.03298 3.34183V6.09671H1.57884C1.1768 6.08217 0.785674 6.22506 0.489004 6.49512C0.192334 6.76517 0.0191679 7.13449 0.00462889 7.54016L0.00341797 23.4664C0.0179492 23.8648 0.191114 24.2329 0.487793 24.5042C0.773576 24.7633 1.13808 24.9026 1.52192 24.9026H1.57399H7.03154V27.666C7.05818 28.3126 7.33186 28.9096 7.80533 29.3504C8.2558 29.7718 8.83946 30.0007 9.44858 30.0007H9.53456H28.4833C29.1263 30.0237 29.7463 29.7936 30.2198 29.3541C30.6933 28.9145 30.9682 28.3163 30.9936 27.6624L30.996 3.33358C30.9694 2.68695 30.6957 2.08996 30.2222 1.64917L30.2262 1.65016ZM5.36791 19.3394L4.03708 18.0085L6.54857 15.4971L4.03708 12.9856L5.36791 11.6547L7.8794 14.1662L10.3909 11.6547L11.7217 12.9856L9.21023 15.4971L11.7217 18.0085L10.3909 19.3394L7.8794 16.8279L5.36791 19.3394ZM28.4933 28.0752H9.5216C9.24308 28.1018 8.99485 27.9056 8.95368 27.6574V24.9025H14.1787C14.5808 24.917 14.9719 24.7742 15.2686 24.5041C15.5652 24.2341 15.7384 23.8647 15.7529 23.4591V20.8955H23.8988C24.4171 20.8955 24.8385 20.4741 24.8385 19.9558C24.8385 19.4376 24.4171 19.0162 23.8988 19.0162H15.7529V16.4902H23.8988C24.4171 16.4902 24.8385 16.0688 24.8385 15.5505C24.8385 15.0322 24.4171 14.6108 23.8988 14.6108H15.7529V12.0848H23.8988C24.4171 12.0848 24.8385 11.6634 24.8385 11.1451C24.8385 10.6269 24.4171 10.2055 23.8988 10.2055H15.7529V7.54385C15.7384 7.14544 15.5652 6.77731 15.2686 6.50606C14.9719 6.23602 14.5807 6.09314 14.1824 6.10765H8.94987V3.36731C8.9874 3.10817 9.21264 2.92048 9.4839 2.92048H9.53597H28.5077C28.7898 2.89384 29.038 3.09001 29.0756 3.33826V27.632C29.038 27.9069 28.7862 28.1019 28.4931 28.0789L28.4933 28.0752Z"
                        fill="#4C63B6"
                      />
                    </svg>
                    <span className={style.name}>{file?.name}</span>
                  </div>
                  <div className=" w-100 flex justify_between align_start">
                    <div className={`w-100 ${style.progress_line}`}>
                      <ProgressLine variant="determinate" value={progressValue} />
                    </div>
                    <div
                      className="pointer p-0 m-0"
                      onClick={() => setFile(null)}
                    >
                      <CrossButton />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className={style.msg}>
              {t(
                "questionBank.Ensure the uploaded CSV file matches the correct template. Download it"
              )}{" "}
              <Link onClick={handleDownload}>
                <span>{t("questionBank.here")}.</span>
              </Link>
            </div>
            <br />
            <div className="text-center body_1" style={{ color: "red" }}>
              {error ? error.errorMessage : ""}
            </div>
            <br />

            <div className="end">
              <button
                className="btn-primary"
                disabled={btnDisable || !file?.name}
                onClick={() => handleUpload()}
              >
                {t("Upload")}
              </button>
              &nbsp;&nbsp;&nbsp;
              <button className="btn-secondary" onClick={handleCloseDialog}>
                {t("Cancel")}
              </button>
            </div>


          </Box>



        </Box>
      </Dialog>
    </>
  );
};
