import React from 'react'
import { Link } from "react-router-dom"
import style from "../../assets/css/views/404.module.css"

export const Page404 = () => {
    return (
        <div className={style.notFoundContainer}>
            <div className={style.notFound}>404</div>
            <p className={style.notFoundText}>Oops! Page not found.</p>
            <Link to={"/questionBank"}>
                <button className="btn-primary">Go To Home Page</button>
            </Link>
        </div>
    )
}