
import { Link, useNavigate } from "react-router-dom"
import { CustomPagination } from "../../components/Common/CustomPagination"
import { TableContainer, TableHeader } from "../../components/Common/TableContainer"
import { SearchInput } from "../../components/Inputs/SearchInput"
import style from "../../assets/css/views/group.module.css"
import { ViewButton } from "../../components/Buttons/ViewButton"
import React, { useEffect, useRef, useState } from "react"
import { currentUser, currentUserPrimaryGroupId, currentUserRole, isUserCreateUpdateData, loader, toast } from "../../utils"
import { searchDataWithKey } from "../../utils/search"
import { fetchGroups } from "../../api/StrategicImperatives"
import { useTranslation } from "react-i18next"
import { NoTableData } from "../../components/Common/NoTableData"
import { useSelector } from "react-redux"


export const Groups = () => {
    let { t } = useTranslation()
    let navigate = useNavigate()

    let [allData, setAllData] = useState([])
    let [page, setPage] = useState(1)
    let record_per_page = 20
    let [all, setAll] = useState([])
    let loaderP = useSelector(e => e.loader)
    let [searchValue, setSearchValue] = useState("")
    let [totalGroup, setTotalGroup] = useState(0)
    let userRole = currentUserRole()
    let primary_group = currentUserPrimaryGroupId()
    let [error, setError] = useState({
        error: false,
        errorMessage: "No Subgroups Available"
    })


    useEffect(() => {
        const checkUserRoleAndNavigate = () => {
            let user = currentUser()
            if (user.role.item_name === "groupAdmin") {
                navigate(`viewSubgroup/${user.user.primary_group}`)
            }
        }
        checkUserRoleAndNavigate()
    }, [])


    function pageChange(e) {
        setPage(e)
        setSearchValue("")
    }
    let getAllGroupsBySubGroupRef = useRef(getAllGroupsBySubGroup)
    async function getAllGroupsBySubGroup(pageNo) {
        try {
            let data = {
                page: pageNo,
                record_per_page: record_per_page,
                collectionType: 'SUBGROUPS',
                ...(userRole === 'groupAdmin' ? { primary_groupId: primary_group } : {})
            }
            loader.start()
            let res = await fetchGroups(data)
            setTotalGroup(res.data.totalGroups)
            setAllData(res.data.response)
            setAll(res.data.response)
            loader.stop()
            setError({
                error: false,
                errorMessage: res.data.response.length !== 0 ? "" : "No Groups Available"
            })
        }
        catch (err) {
            // toast.error(err?.response?.data?.error)
            loader.stop()
            setError({
                error: false,
                errorMessage: err?.response?.data?.error
            })
        }
    }

    useEffect(() => {
        setSearchValue("")
        getAllGroupsBySubGroupRef.current(page)
    }, [getAllGroupsBySubGroupRef, page])

    function searchGroup(e) {
        setError({
            error: false,
            errorMessage: "No Groups Available"
        })
        setSearchValue(e.target.value);
        setAllData(searchDataWithKey("groupName", all, e.target.value));
    }
    return (
        <div className={`${style.group_con} padding_22_44`}>

            <div className="between">
                <div className='h1'> {t("groupManagement.Group Management")}</div>
                <div>
                    {isUserCreateUpdateData() ?
                        <Link to={"createSubGroup"}>
                            <button className="btn-primary">{t("Create New")}</button>
                        </Link> : null}
                </div>
            </div>
            <TableHeader>
                <div className={style.search_inp}>
                    <SearchInput value={searchValue} onChange={searchGroup} placeholder={t("Search")} />
                </div>
            </TableHeader>

            <TableContainer>
                <div className={style.scroll_part}  >
                    <table>
                        <thead>
                            <tr>
                                <th>{t("Group Name")}</th>
                                <th>{t("groupManagement.Group Code")}</th>
                                {/* <th>{t("Members")}</th> */}
                                <th></th>
                            </tr>
                        </thead>
                        {!error.error &&
                            <tbody>
                                {allData && allData.map((res, key) => {
                                    return (
                                        <tr key={key}>
                                            <td><div >{res.groupName}</div></td>
                                            <td><div >{res.groupCode}</div></td>
                                            {/* <td><div>0</div></td> */}
                                            <td>
                                                <div>
                                                    <Link to={`viewSubgroup/${res.groupId}`}>
                                                        <ViewButton />
                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>}
                    </table>
                    {(error.error || allData?.length === 0) && !loaderP && <NoTableData text={error.errorMessage} />}
                </div>
                <CustomPagination page={page - 1} pageChange={pageChange} count={totalGroup} perPage={record_per_page} />
            </TableContainer>


        </div>

    )
}