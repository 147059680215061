import { ExpandLess, ExpandMore } from '@mui/icons-material';
import React, { useState } from 'react'
import { EditButton } from '../../Buttons/EditButton';
import { DeleteButon } from '../../Buttons/DeleteButton';
import { useNavigate } from 'react-router';
import style from "../../../assets/css/components/jobRole.module.css";
import { deleteJobRole } from '../../../api/jobRoles';
import { changeDateFormat, loader, toast } from '../../../utils';
import { Box, Collapse, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Grid2 from '@mui/material/Unstable_Grid2';

export const JobRolePreviewCollapseTab = ({ data, onDeleteJobrole }) => {
    const [collapse, setCollapse] = useState(false)
    let navigate = useNavigate();
    const toggleExpand = () => setCollapse(!collapse);
    const { t } = useTranslation();

    function editData() {
        navigate(`/jobRole/edit/${data._id}`)
    };

    async function deleteData(data) {
        try {
            loader.start()
            let res = await deleteJobRole(data.jobRoleObj.roleId);
            toast.success(res?.data?.message);
            onDeleteJobrole(data._id);
        }
        catch (err) {
            toast.error(err?.response?.data?.error);
        }
        finally {
            loader.stop();
        }
    }

    return (
        <div className={style.jobrole_preview_collapse_row}>
            <div className={`${style.dropdown_header_grid_con}`}>

                <div className="flex align_center">
                    <div className="expand_btn">
                        {collapse ? <ExpandMore onClick={toggleExpand} /> : <ExpandLess onClick={toggleExpand} />}
                    </div>
                    <div>
                        {data?.dpdData?.name}
                    </div>
                </div>

                <div>{data?.jobRoleObj?.name}</div>


                {!collapse && <div></div>}
                {collapse &&
                    <div className='end'>
                        <EditButton
                            confirmation={"Edit"}
                            heading={"Edit"}
                            text={"Are you sure you want to Edit ?"}
                            data={data}
                            onClick={editData}
                        />&nbsp;&nbsp;
                        <DeleteButon
                            confirmation
                            heading={"Delete"}
                            text={"Are your sure your want to delete Job Role ?"}
                            id={data}
                            onDelete={deleteData}

                        />
                    </div>
                }
            </div>
            <Collapse in={collapse} timeout="auto" unmountOnExit>
                <Grid2 container>
                    <Grid2 md={12} xs={12}>
                        <span className={`${style.jobrole_label} text-secondary`}>{t("Status")}:</span>
                        <span>&nbsp;&nbsp;&nbsp;{data?.status}</span>
                    </Grid2>
                    {data?.categories.map((e, ind) => <Grid2 item lg={6} md={12} xs={12}>
                        <div className={`${style.jobrole_label} text-secondary`}>{e?.categoryData?.name}:</div>
                        <ul className={style.jobrole_list}>
                            {e?.competenciesData.map((comp, ind) => <li key={ind}>{comp?.name}</li>)}
                        </ul>
                    </Grid2>)}
                </Grid2>


                {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                    <div className={`${style.jobrole_label} text-secondary`}>{t("developmentPlan.name")}:</div>
                    <div className={style.jobrole_list}>{data?.dpdData?.name}</div>
                </Box> */}


            </Collapse>
        </div >
    )
}
