export const validateForm = (requiredFields, formData) => {
    let data = {}
    let errorFields = []
    requiredFields.forEach((r) => {
        if (!formData[r] || formData[r]?.toString()?.trim() === "" || formData[r]?.length == 0) {
            data[r] = true
            errorFields.push(r)
        }
        else {
            data[r] = false
        }
    })
    return ({ data, errorFields, isFormValid: errorFields.length === 0 ? true : false })
}