import axios from "../config/axiosInterceptor";
import { API, BASE_URL } from "./api"

export const fetchSIByGroupId = async (groupId) => {
    return await axios.get(`${BASE_URL}${API.fetchSIByGroupId}${groupId}`)
}
export const fetchSIById = async (id) => {
    return await axios.get(`${BASE_URL}${API.fetchSIById}${id}`)
}
export const deleteSI = async (id) => {
    return await axios.delete(`${BASE_URL}${API.deleteSI}${id}`)
}
export const createSI = async (data) => {
    return await axios.post(`${BASE_URL}${API.createSI}`, data)
}
export const editSI = async (data) => {
    return await axios.put(`${BASE_URL}${API.editSI}`, data)
}
export const fetchGroups = async (data) => {
    return await axios.post(`${BASE_URL}${API.fetchGroups}`, data)
}