import React, { useState } from "react";
import { ViewButton } from "../../Buttons/ViewButton";
import { Dialog, Divider } from "@mui/material";
import { CrossButton } from "../../Buttons/CrossButton";
import style from "../../../assets/css/components/assessment.module.css";
import { useNavigate } from "react-router";
import { ConfirmationDialog } from "../ConfirmationDialog";
import { useTranslation } from "react-i18next";
import { isUserCreateUpdateData } from "../../../utils";
import { ASSESSMENT_TYPES } from "../../../constants/constants";
export const PreviewQuestion = ({ data, parent }) => {
  let [dialog, setDialog] = useState(false);
  let [editQuestionDialog, setEditQuestionDialog] = useState(false);
  let navigate = useNavigate();
  let { t } = useTranslation()

  function naviateToEditQuestion(questionId) {
    navigate(`edit/${data._id}`);
  }

  return (
    <div>
      <ViewButton onClick={() => setDialog(true)} />

      <Dialog maxWidth={"md"} fullWidth open={dialog}>
        <div className={`between dialog_header`}>
          <div>{t("Preview Question")}</div>
          <div onClick={() => setDialog(false)} className={`pointer`}>
            <CrossButton />
          </div>
        </div>
        <Divider />

        <div className={style.preview_question_con}>
          <label>{t("questionBank.Question")}</label>
          <div>{data?.question} </div>

          <div className={`${style.cat_type_con}`}>
            {data?.assessmentType != ASSESSMENT_TYPES.organizational ? (
              <div>
                <label>{t("competency.competencies")}</label>
                <div>{data?.competencyData.map((y) => y.name).join(" / ")}</div>
              </div>
            ) : (
              <div>
                <label>{t("tag.tags")}</label>
                <div>{data?.tagData.map((y) => y.name).join(" / ")}</div>
              </div>
            )}

            <div>
              <label>{t("Question Type")}</label>
              <div>{data?.questionType}</div>
            </div>
          </div>
          <label>{t("questionBank.Options")}</label>
          <ol>
            {data?.options.map((res, key) => {
              return (
                <div key={key} className={`flex ${style.option_row_con}`}>
                  <div>
                    <li>{res.name}  </li>
                  </div>
                  {/* {data?.questionType !== "Rating" ? (
                    <div className="w-30">
                      <label>{t("Rating")}</label>
                      <div>1</div>
                    </div>
                  ) : null} */}
                </div>
              );
            })}
          </ol>

          <div className="end">
            {parent === "questionBank" ? (
              <>
                {/* {isUserCreateUpdateData() ?
                  <button
                    className="btn-primary"
                    onClick={() => setEditQuestionDialog(true)}
                  >
                    {t("Edit")}
                  </button> : null} */}

                {/* <ConfirmationDialog
                  onSubmit={() => {
                    setEditQuestionDialog(false);
                    naviateToEditQuestion();
                  }}
                  onCancel={() => setEditQuestionDialog(false)}
                  heading={"Edit Question"}
                  text={
                    data?.isMappedInAssessment
                      ? "This question is already part of an ongoing assessment. Do you want to continue to edit?"
                      : "Do you want to continue to edit?"
                  }
                  open={editQuestionDialog}
                /> */}

              </>
            ) : null}

            {/* <button className="btn-secondary" onClick={() => setDialog(false)}>
              {t("Cancel")}
            </button> */}
          </div>
        </div>
      </Dialog>
    </div>
  );
};
