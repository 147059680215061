import axios from "../config/axiosInterceptor";
import { API, BASE_URL } from "./api";



export const createAssessment = async (data) => {
    return await axios.post(BASE_URL + API.createAssessment, data)
}
export const editAssessment = async (data) => {
    return await axios.put(BASE_URL + API.editAssessment, data)
}

export const fetchAssessmentById = async (id) => {
    return await axios.get(BASE_URL + API.fetchAssessmentById + id)
}
export const deleteAssessment = async (id) => {
    return await axios.delete(BASE_URL + API.deleteAssessment + id)
}

export const fetchAssessments = async (data) => {
    return await axios.post(BASE_URL + API.fetchAssessments, data)
}
export const getSurveyFormById = async (data) => {
    return await axios.post(BASE_URL + API.getSurveyFormById, data)
}
export const saveSurveyResponse = async (data) => {
    return await axios.post(BASE_URL + API.saveSurveyResponse, data)
}

export const sendReminderEmails = async (id) => {
    return await axios.get(BASE_URL + API.sendReminderEmails + id)
}
export const getUserAssessments = async (userId, page, records_per_page, assessmentType) => {
    return await axios.get(`${BASE_URL}${API.fetchUserAssessments}?userId=${userId}&page=${page}&records_per_page=${records_per_page}&assessmentType=${assessmentType}`)
}
export const validateJobRoleAssessment = async (jobroleId) => {
    return await axios.get(BASE_URL + API.validateJobRoleAssessment + jobroleId)
}
export const getAssessmnentUserStatus = async (assessmentId) => {
    return await axios.get(`${BASE_URL}${API.getAssessmnentUserStatus}?assessmentId=${assessmentId}`)
}

