import { DeleteButon } from "../../Buttons/DeleteButton";
import { EditButton } from "../../Buttons/EditButton";
import style from ".././../../assets/css/components/assessment.module.css";
import React, { useEffect, useState } from "react";
import { AssessmentDropDownMenu } from "./AssessmentDropDownMenu";
import { Link, useNavigate, useParams } from "react-router-dom";
import { deleteAssessment, sendReminderEmails } from "../../../api/Assessment";
import { changeDateFormat, currentUserRole, toast, loader, isUserCreateUpdateData, assessmentStatusClass } from "../../../utils";
import { ChangeAssessmentStatus } from "./ChangeAssessmentStatus";
import { useTranslation } from "react-i18next";
import { ConfirmationButton } from "../../Buttons/ConfirmationButton";
import { ASSESSMENT_STATUS_TYPES, ASSESSMENT_TYPES } from "../../../constants/constants";

export const PreviewAssessmentSurvey = ({ previewData }) => {
  let [data, setData] = useState({});
  const { assessmentType } = useParams()
  let navigate = useNavigate();
  let userRole = currentUserRole();
  let [openDropDownIndex, setOpenDropDownIndex] = useState(0);
  let { t } = useTranslation();
  async function delAssessment() {
    try {
      let res = await deleteAssessment(data._id);
      toast.success(res.data.message);
      navigate(`/assessment/${assessmentType}`);
    } catch (err) {
      toast.error(err?.response?.data?.error);
    }
  }

  async function sendReminder() {
    try {
      loader.start()
      let res = await sendReminderEmails(data._id);
      toast.success(res.data.message);
      loader.stop()
    } catch (err) {
      toast.error(err?.response?.data?.error);
      loader.stop()
    }
  }

  useEffect(() => {
    setData({ ...previewData });
  }, [previewData]);

  function moveToEditAssessment() {
    navigate(`/assessment/${assessmentType}/edit/${data._id}`);
  }


  function checkVisibility(keysArray, currentStatus) {
    return keysArray.includes(currentStatus);
  }

  return (
    <div className={style.previewAssessment_main_con}>
      <section className={style.assessment_section}>
        <div className={style.top_bar_con}>

          <div>
            <div className={style.heading}>{data?.name}</div>
            <div className="subtitle_1">{data?.groupName}</div>
          </div>
          <div className={`${style.assessment_actions}`}>
            <div className="flex align_center">
              <span
                className={`${assessmentStatusClass(
                  data.status
                )} assessment_status`}
              ></span>
              &nbsp;&nbsp;{data.status}{" "}
            </div>
            {userRole !== "user" && isUserCreateUpdateData() ? (
              <div>
                {checkVisibility(
                  [ASSESSMENT_STATUS_TYPES.drafts, ASSESSMENT_STATUS_TYPES.inReview, ASSESSMENT_STATUS_TYPES.readyToDistribute],
                  data.status
                ) ? (
                  <>
                    <EditButton onClick={moveToEditAssessment} /> &nbsp;&nbsp;
                  </>
                ) : null}
                {checkVisibility([ASSESSMENT_STATUS_TYPES.drafts, ASSESSMENT_STATUS_TYPES.inReview], data.status) ? (
                  <DeleteButon
                    confirmation
                    text={("confirmationDialog.deleteAssessment")}
                    heading={t("Delete Assessment")}
                    onDelete={delAssessment}
                  />
                ) : null}
              </div>
            ) : null}
          </div>

        </div>
        <div className={style.dark_bg_section_con}>

          <div>


            <div className={style.details_grid_con}>

              {/* assessment type  */}
              {assessmentType !== ASSESSMENT_TYPES.jobRole &&
                <div>
                  <label>{t("Type")}:</label>
                  <div>
                    {data?.type}
                  </div>
                </div>}

              {/* previous survey name if type if pulse  */}
              {data?.type === "Pulse" &&
                <div>
                  <label>{t("Assessment.Previous survey")} :</label>
                  <div className={style.assessment_value}>{data?.previousSurveyData?.[0]?.name}</div>
                </div>
              }

              {/* completion date  */}
              <div>
                <label>{t("Completion Date")} :</label>
                <div>
                  {data?.completionDate && changeDateFormat(data?.completionDate)}
                </div>
              </div>

              {/* assessment name  */}
              <div>
                <label>{t("Assessment.Assessment Name")} :</label>
                <div>{data?.name}</div>
              </div>

              {/* description of assessment  */}
              <div>
                <label>{t("Description")} :</label>
                <div>{data?.description || "-----"}</div>
              </div>

              {/* sub group if type is organizaional */}
              {assessmentType === ASSESSMENT_TYPES.organizational &&
                <div>
                  <label>{t("Sub Groups")} :</label>
                  <ul>
                    {data?.surveyAssignments?.subGroupsData &&
                      data?.surveyAssignments?.subGroupsData.map((res) => {
                        return <li key={res._id}>{res.name}</li>;
                      })}
                  </ul>
                </div>
              }

              {/* si if type is organizatinal  */}
              {assessmentType == ASSESSMENT_TYPES.organizational &&
                <div>
                  <label>{t("Strategic Imperatives")}:</label>
                  <ul>
                    {data?.strategicImperativesData &&
                      data?.strategicImperativesData.map((res) => {
                        return <li key={res._id}>{res.name}</li>;
                      })}
                  </ul>
                </div>
              }
              {/* job role name if type is job role  */}
              {assessmentType === ASSESSMENT_TYPES.jobRole &&
                <div>
                  <label>{t("Job Role Name")}:</label>
                  <div>
                    {data?.surveyAssignments &&
                      data?.surveyAssignments?.jobRole?.jobRoleObj?.name}
                  </div>
                </div>}

              {/* categories if type is job role  */}
              {assessmentType === ASSESSMENT_TYPES.jobRole &&
                <div>
                  <label>{t("category.categories")}:</label>
                  <ul>
                    {data?.categoriesData &&
                      data?.categoriesData.map((res) => {
                        return <li key={res._id}>{res.name}</li>;
                      })}
                  </ul>
                </div>}


            </div>

            <div className={style.reviewer_label}>{t("Reviewers")}:</div>
            <div className={style.details_grid_con}>

              <div>
                <label>{t("Sub Groups")}:</label>
                <ul>
                  {data?.reviewers &&
                    data?.reviewers?.subGroupsData.map((res) => {
                      return <li key={res._id}>{res.name}</li>;
                    })}
                </ul>
              </div>
              <div>
                <label>{t("jobRole.Job Roles")}:</label>
                <ul>
                  {data?.reviewers &&
                    data?.reviewers?.jobRoles.map((res) => {
                      return <li key={res._id}>{res?.jobRoleObj?.name}</li>;
                    })}
                </ul>
              </div>
              <div>
                <label>{t("Users")}:</label>
                <ul>
                  {data?.reviewers &&
                    data?.reviewers?.individuals.map((res) => {
                      return <li key={res._id}>{res.email}</li>;
                    })}
                </ul>
              </div>

            </div>

          </div>
          <div></div>
        </div>

      </section>

      <section className={style.question_section}>
        <div className={style.header}>
          {assessmentType === ASSESSMENT_TYPES.organizational ?
            t("Assessment.Key Performance Indicators & Questions") :
            t("Assessment.Competency & Questions")
          }
        </div>
        <div className={style.assessment_question_row}>
          {data?.questions &&
            data?.questions.map((res, key) => {
              return (
                <div
                  key={key}
                  className={`${style.assessment_question_details_row} ${style.assessment_dropdown_con}`}
                >
                  <AssessmentDropDownMenu
                    currentIndex={key}
                    openIndex={openDropDownIndex || 0}
                    setOpenIndex={setOpenDropDownIndex}
                    data={res?.kpiData || res?.competencyData}
                    questionData={res}
                  />
                </div>
              );
            })}
        </div>
      </section>
      <br />
      <div className="end">
        {isUserCreateUpdateData() && <>
          {data?.status === "Live" &&
            (<ConfirmationButton
              confirmation
              heading={t("Resend Assessment")}
              text={t("confirmationDialog.resendAssessment")}
              id={data}
              buttonText={t("Send Reminder")}
              onClick={sendReminder}
            />)}&nbsp;&nbsp;&nbsp;&nbsp;
          {data.status === "Completed" ? null : (
            <>
              {userRole !== "user" ? (
                <ChangeAssessmentStatus
                  isPreviewScreen={true}
                  assessmentData={data}
                  currentStatus={data.status}
                  onSubmit={(status) => setData({ ...data, status })}
                />
              ) : null}
              &nbsp;&nbsp;
            </>
          )}
          &nbsp;&nbsp;
        </>
        }

        <Link to={currentUserRole() === "user" ? `/userAssessment/${assessmentType}` : `/assessment/${assessmentType}`}>
          <button className="btn-secondary">{t("Close")}</button>
        </Link>
      </div>
    </div>
  );
};
