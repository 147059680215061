import React from 'react'
import img from "../../assets/Images/noData.png"
import style from "../../assets/css/components/common.module.css"
import {CircularProgress} from "@mui/material";
import {useSelector} from "react-redux";

export const NoTableData = ({text}) => {
    return (
        <div className={style.noTableData_con}>
            <div className={style.content_con}>

                <div>
                    <img src={img}/>
                </div>
                <div className={`body_1`}>{text}</div>
                {text === 'Oops! Session Expired. Refreshing your session now. Please wait a moment'?
                    <CircularProgress/> : null
                }
            </div>
        </div>
    )
}
