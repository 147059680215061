import axios from "../config/axiosInterceptor";
import { API, BASE_URL } from "./api";

export const fetchCompetenciesByGroupId = async (id) => {
    return await axios.get(BASE_URL + API.fetchCompetenciesByGroupId + id)
}
export const getAllCompetencies = async () => {
    return await axios.get(BASE_URL + API.getAllCompetencies)
}
export const createCompetency = async (body) => {
    return await axios.get(BASE_URL + API.createCompetency, body)
}
export const editCompetency = async (body) => {
    return await axios.put(BASE_URL + API.editCompetency, body)
}
export const assignCompetency = async (body) => {
    return await axios.post(BASE_URL + API.assignCompetency, body)
}
export const fetchCompetencyById = async (id, groupId) => {
    // return await axios.get(BASE_URL + API.fetchCompetencyById + id)
    return await axios.get(`${BASE_URL}${API.fetchCompetencyById}?id=${id}&groupId=${groupId}`)
}
export const deleteCompetency = async (id) => {
    return await axios.delete(BASE_URL + API.deleteCompetency + id)
}
export const deleteCompetencies = async (data) => {
    return await axios.post(BASE_URL + API.deleteCompetencies, data)
}
export const getCompetenciesByJobRole = async (id) => {
    return await axios.get(BASE_URL + API.getCompetenciesByJobRole + id)
}
export const fetchCompetenciesByCategories = async (body) => {
    return await axios.post(BASE_URL + API.fetchCompetenciesByCategories, body)
}
export const getCompetenciesByCategory = async (body) => {
    return await axios.post(BASE_URL + API.getCompetenciesByCategory, body)
}