import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import {
    editJobRole,
    fetchJobRoleById,
    getSortedCompetenciesPercentage,
    saveSortedCompetenciesByUser
} from '../../api/jobRoles'
import { Accordion } from '../../components/Common/Accordion'
import { DraggableTable } from '../../components/Common/DraggableTable'
import { changeDateFormat, loader, toast } from '../../utils'
import { useTranslation } from 'react-i18next'
import style from "../../assets/css/components/jobRole.module.css"
import { BreadCrum } from '../../components/Common/BreadCrum'
import { ADMIN_ROLE, STATUS } from '../../constants/constants'
import { Link } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router';
import { FormClosed } from '../../components/Common/FormClosed'
import { ConfirmationDialog } from "../../components/Common/ConfirmationDialog";


export const SortCompetencies = ({ role }) => {
    const isAdmin = role === ADMIN_ROLE
    let { id } = useParams()
    const { t } = useTranslation()
    const navigate = useNavigate();
    const [currentStatus, setCurrentStatus] = useState(null);
    const [statusChangeDialog, setStatusChangeDialog] = useState(null)

    const [categoryData, setCategoryData] = useState([])
    const [jobroleData, setJobroleData] = useState({})
    const [groupName, setGroupName] = useState(null)
    const [status, setStatus] = useState(null)
    const [dpdData, setDpdData] = useState({});
    const [changedStatus, setChangedStatus] = useState(null)

    const [oldCategoriesData, setOldCategoriesData] = useState([]);
    const [completed, setCompleted] = useState({
        isCompleted: false,
        completedMsg: ""
    })

    useEffect(() => {
        getJobRoleData()
    }, [])


    async function getJobRoleData() {
        try {
            loader.start()
            let res;
            if (isAdmin) {
                let admRes = await getSortedCompetenciesPercentage(id)

                let comp = admRes.data.response.sortedCompetencies;

                setGroupName(admRes.data.response?.groupName)
                setStatus(admRes.data.response.status)
                setJobroleData(admRes.data.response?.jobRoleDetails)
                setDpdData(admRes.data.response?.dpdDetails)
                setOldCategoriesData(comp)

                let sortedData = comp.map(e => ({
                    ...e,
                    competencies: e.competencies.sort((a, b) => b.percentage - a.percentage)
                }))
                let ff = sortedData.map((res) => {
                    return ({
                        name: res?.categoryDetails?.name,
                        _id: res?.categoryDetails?._id,
                        data: res?.competencies.map(e => ({
                            name: e?.competencyDetails ? e?.competencyDetails.name : e?.name,
                            id: e?.competencyDetails ? e?.competencyDetails?._id : e?._id,
                        }))
                    })
                })
                setCategoryData(ff)
            } else {
                let body = {
                    id: id,
                    isAuthenticated: false
                }
                res = await fetchJobRoleById(body)
                let response = res.data.response?.categories
                setStatus(res.data.response.status)
                setJobroleData(res?.data?.response)
                setDpdData(res?.data?.response.dpdDetails)
                let dd = fixData(response)
                setCategoryData(dd)
            }
        } catch (err) {
            console.log(err)
        } finally {
            loader.stop()
        }
    }


    function fixData(data) {
        return data.map((res) => {
            return ({
                name: res?.categoryData?.name,
                _id: res?.categoryData?._id,
                data: res?.competenciesData.map(e => ({ name: e.name, id: e._id }))
            })
        })

    }

    function handleRowsUpdate(d, ind) {

        setCategoryData(pre => {
            pre[ind].data = d;
            return ([...pre])
        })

    }

    const columns = [{ key: "name" }, { key: "" }]

    let breadCrumData = [
        {
            text: t("Job Role"),
            path: `/jobRole`
        },
        {
            text: t("jobRole.updateCompetencyRanking"),
        },
    ];


    async function sortCategories() {
        try {
            loader.start()
            if (isAdmin) {

                let categories = oldCategoriesData.map(e => {
                    return (
                        {
                            categoryId: e.categoryDetails._id,
                            competencyIds: e.competencies.map(comp => comp.competencyDetails ? comp.competencyDetails._id : comp._id),
                            sortedCompetencyIds: categoryData.find(cat => cat._id === e.categoryDetails._id)?.data?.map(d => d.id)
                        })
                })


                let body = {
                    _id: id,
                    categories,
                    status: STATUS.COMPLETED
                }

                let res = await editJobRole(body)

                toast.success(res?.data?.message)
                navigate("/jobRole")


            } else {
                let sortedCompetencies = categoryData.map(category => {
                    return ({
                        categoryId: category._id,
                        competencies: category.data.map((comp, ind) => ({
                            competencyId: comp.id,
                            priority: ind + 1
                        }))
                    })
                });

                let body = {
                    email: "",
                    groupId: dpdData?.groupId,
                    jobRoleId: jobroleData?._id,
                    dpdId: dpdData._id,
                    ...(status === STATUS.DRAFTS ? { status: STATUS.IN_PROGRESS } : {}),

                    sortedCompetencies
                }
                let res = await saveSortedCompetenciesByUser(body)

                setCompleted({
                    isCompleted: true,
                    completedMsg: res?.data?.message || "Response saved successfully"
                })
            }

        } catch (err) {
            console.log(err)
        } finally {
            loader.stop()
        }
    }

    async function changeStatus(status) {
        try {
            loader.start()
            setStatusChangeDialog(false);
            let body = {
                status: changedStatus,
                _id: id
            }
            if (changedStatus === STATUS.COMPLETED) {
                let categories = oldCategoriesData.map(e => {
                    return (
                        {
                            categoryId: e.categoryDetails._id,
                            competencyIds: e.competencies.map(comp => comp.competencyDetails ? comp.competencyDetails._id : comp._id),
                            sortedCompetencyIds: categoryData.find(cat => cat._id === e.categoryDetails._id)?.data?.map(d => d.id)
                        })
                })
                body.categories = categories
            }
            let res = await editJobRole(body)
            toast.success(res.data.message)
            getJobRoleData()
        }
        catch (err) {
            console.log(err)
        }
        finally {
            loader.stop()
        }
    }

    // show full page msg if user submit form
    if (completed.isCompleted) {
        return <FormClosed text={completed.completedMsg} />
    }

    return (
        <>
            <ConfirmationDialog
                open={statusChangeDialog}
                onSubmit={changeStatus}
                heading={"Action"}
                onCancel={() => setStatusChangeDialog(false)}
                text={"Are you sure you want to change the status?"} />

            <div
                className={`padding_22_44 ${style.sortCompetencies_main_con} ${!isAdmin && style.sortCompetency_user_con}`}>
                {isAdmin && <>
                    <BreadCrum dataArr={breadCrumData} />
                    <br />
                </>}
                <div className={`${style.jobRole_details_con}`}>

                    <div className={style.header}>
                        <div>
                            {isAdmin ? groupName : jobroleData?.jobRoleObj?.name}
                        </div>
                    </div>

                    <div className={style.jobrole_detail_text}>
                        <div className="flex between">
                            <div>
                                <label>{t("developmentPlan.name")} : </label>
                                <span className={style.jobrole_value_text}>{dpdData?.name}</span>
                            </div>
                            <div>
                                <label>Development Plan Duration : </label>
                                <span className={style.jobrole_value_text}>
                                    {dpdData?.startDate && changeDateFormat(dpdData?.startDate)}
                                    {" "}to{" "}
                                    {dpdData?.startDate && changeDateFormat(dpdData?.endDate)}
                                </span>
                            </div>

                        </div>
                        <br />
                        <div>
                            <span className={style.jobrole_value_text}>{dpdData?.description}</span>
                        </div>
                    </div>
                </div>

                <div className={style.category_text}>{t("Categories")}</div>
                <div className={style.categories_table_con}>
                    {categoryData.map((res, ind) => {
                        return (
                            <Accordion key={`p${ind}`} title={res.name} open={open == ind || ind === 0}>
                                <br />
                                <DraggableTable
                                    rows={res.data} columns={columns} onRowsUpdate={(e) => handleRowsUpdate(e, ind)}
                                />
                            </Accordion>
                        )
                    })}
                </div>
                <br />
                <div className="end">

                    {isAdmin && <><Link to={"/jobRole"}>
                        <button className='btn-secondary'>{t("Cancel")}</button>
                    </Link>

                        {(status === STATUS.IN_PROGRESS) && (
                            <>
                                &nbsp;&nbsp;
                                <button onClick={() => {
                                    setChangedStatus(STATUS.COMPLETED)
                                    setStatusChangeDialog(true)
                                }} className={"btn-primary"}>
                                    {STATUS.COMPLETED}
                                </button>
                            </>
                        )}


                        {(status === STATUS.DRAFTS || status === STATUS.COMPLETED) && (
                            <>
                                &nbsp;&nbsp;
                                <button onClick={() => {
                                    setChangedStatus(STATUS.IN_PROGRESS)
                                    setStatusChangeDialog(true)
                                }} className={"btn-primary"}>
                                    {STATUS.IN_PROGRESS}
                                </button>
                            </>
                        )}
                    </>}
                    {/* button used for user to submit form  */}
                    {!isAdmin &&
                        <>
                            &nbsp;&nbsp;
                            <button className='btn-primary'
                                onClick={sortCategories}
                            >Submit</button>
                        </>

                    }
                </div>
            </div>
        </>

    )
}
