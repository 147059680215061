import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { editAssessment, fetchAssessmentById } from '../../api/Assessment'
import { CreateAssessmentSurvey } from '../../components/Common/Assessment/CreateAssessmentSurvey'
import { loader, toast } from '../../utils'
import { useSelector } from 'react-redux'
import { ASSESSMENT_TYPES } from '../../constants/constants'
import { CreateJobRoleIndividualAssSurvey } from '../../components/Common/Assessment/CreateJobRoleIndividualAssSurvey'

export const EditAssessment = () => {
    const tokenStatus = useSelector((state) => state.tokenStatus);
    const { assessmentType } = useParams()
    let params = useParams()
    let [assessmentData, setAssessmentData] = useState({})
    let [pulseTypeDropDownData, setPulseTypeDropDownData] = useState({})
    let [updatedData, setUpdatedData] = useState({})
    let [fullData, setFullData] = useState({})
    let navigate = useNavigate()
    const [jobRoleId, setJobRoleId] = useState(null)


    async function getAssessmentById() {
        loader.start()
        try {
            let res = await fetchAssessmentById(params.id)
            let response = res.data.response
            setFullData(response)

            let ddd = {
                strategicImperativesData: response?.strategicImperativesData,
                surveyAssignments: {
                    subGroupsData: response?.surveyAssignments?.subGroupsData,

                },
                reviewers: {
                    subGroupsData: response?.reviewers?.subGroupsData
                }
            }


            setPulseTypeDropDownData(ddd)
            if (assessmentType !== ASSESSMENT_TYPES.organizational) {
                setJobRoleId(response?.surveyAssignments?.jobRole?._id)
            }

            let data2 = {
                name: response.name,
                status: response.status,
                groupId: response.groupId,
                type: response.type,
                previousSurveyId: response?.previousSurveyData?.[0]?._id || response?.previousSurveyId || null,
                description: response.description,
                categoryIds: response?.categoriesData?.map(e => e._id),
                surveyAssignments: {
                    subGroupIds: response?.surveyAssignments?.subGroupsData?.map(e => e._id) || [],
                    user: response?.surveyAssignments?.user || {},
                    jobRoleId: response?.surveyAssignments?.jobRole?.jobRoleObj?.roleId || null
                },
                reviewers: {
                    subGroupIds: response?.reviewers?.subGroupsData?.map(e => e._id) || [],
                    jobRoleIds: response?.reviewers?.jobRoles?.map(e => e?.jobRoleObj?.roleId) || [],
                    individuals: response?.reviewers?.individuals || []
                },
                completionDate: response.completionDate,
                siIds: response?.strategicImperativesData?.map(e => e._id) || [],
                kpiQuestions: response?.questions?.map(e => {
                    return (
                        {
                            questionData: e.questionData,
                            kpiData: e?.kpiData?.name ? e.kpiData : e.competencyData
                            // kpiData: e.competencyData
                        }
                    )
                }),
                // status: "Drafts"
            }

            setUpdatedData({ _id: response._id })
            loader.stop()
            setAssessmentData(data2)
        }
        catch (err) {
            loader.stop()
            toast.error(err?.response?.data?.error)
        }
    }


    useEffect(() => {
        getAssessmentById()
    }, [tokenStatus])



    async function formSubmit(dd) {
        try {
            loader.start()
            let data = { ...updatedData }

            if (data.kpiQuestions) {

                data.questions = [...data.kpiQuestions].map((res) => {
                    return (
                        {
                            [assessmentType === ASSESSMENT_TYPES.organizational ? "kpiId" : "competencyId"]: res.kpiData._id,
                            questionIds: res.questionData.map(s => s._id)
                        }
                    )
                })
            }

            delete data.kpiQuestions

            let res = await editAssessment(data)
            toast.success(res.data.message)
            navigate(`/assessment/${assessmentType}/preview/${updatedData._id}`)
            loader.stop()
        }
        catch (err) {
            loader.stop()
            toast.error(err?.response?.data?.error)
        }

    }

    return (
        <div className='padding_22_44'>
            {assessmentData.name &&
                <>
                    {assessmentType == ASSESSMENT_TYPES.organizational ? (

                        <CreateAssessmentSurvey
                            pulseTypeDropDownData={pulseTypeDropDownData}
                            setUpdatedData={setUpdatedData} onSubmit={formSubmit} mode="Edit" assessmentData={assessmentData} />
                    ) : (
                        <CreateJobRoleIndividualAssSurvey
                            fullData={fullData}
                            jobRole={jobRoleId}
                            onSubmit={formSubmit} mode="Edit" assessmentData={assessmentData} setUpdatedData={setUpdatedData} />
                    )
                    }
                </>
            }

        </div>
    )
}
