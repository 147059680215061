
import React, { useEffect, useState } from "react";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { Autocomplete, Box, CircularProgress, Dialog, TextField } from "@mui/material";
import { DialogHeader } from "../DialogHeader";
import { CustomDatePicker } from "../../Inputs/CustomDatePicker";
import { useTranslation } from "react-i18next";
import { CustomCheckbox } from "../../Inputs/CustomCheckbox";
import { Controller, useForm } from "react-hook-form";
import { fetchTPUsersByJobRole } from "../../../api/user";
import { loader, toast } from "../../../utils";
import { createAssessment } from "../../../api/Assessment";
import { Tooltip } from '@mui/material'


export const CopyAssessment = ({ assessmentData, onCopyAssessment }) => {
    let [dialog, setDialog] = useState(false)
    let { t } = useTranslation()
    let [fetchUsersLoader, setFetchUsersLoader] = useState(false)
    let [allUsers, setAllUsers] = useState([])
    let [formData, setFormData] = useState({ completionDate: null })

    const { control, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm({
        defaultValues: {
            name: "",
            completionDate: null,
            user: null
        }
    })

    useEffect(() => {
        if (dialog) {
            if (assessmentData.surveyAssignments?.user?.jobRoleId) {
                fetchUsersByJobrole()
            }
        }
    }, [dialog])

    async function fetchUsersByJobrole() {
        try {
            setFetchUsersLoader(true)
            let res = await fetchTPUsersByJobRole(assessmentData.surveyAssignments?.user?.jobRoleId)
            setAllUsers(res?.data?.users?.map(formatUserData) || []);

        } catch (error) {
            console.log(err)
        }
        finally {
            setFetchUsersLoader(false)
        }
    }

    function onClose() {
        setDialog(false)
    }

    async function onSubmit(data) {
        try {
            loader.start()
            let { _id, __v, createdAt, updatedAt, ...preData } = assessmentData
            let body = {
                ...preData,
                status: "Drafts",
                name: data?.name,
                completionDate: data?.completionDate,
                surveyAssignments: { ...preData?.surveyAssignments, user: data?.user }
            }


            let res = await createAssessment(body)
            setDialog(false)
            reset()
            toast.success(res.data.message)
            onCopyAssessment()
        }
        catch (err) {
            console.log(err)

        }
        finally {
            loader.stop()
        }

    }

    function formatUserData(iterData) {
        return ({
            userId: iterData?.userProfile?.user_id,
            email: iterData?.email,
            job_role: assessmentData?.surveyAssignments?.user?.job_role,
            jobRoleId: iterData?.job_role,
            first_name: iterData?.userProfile?.firstname || null,
            last_name: iterData?.userProfile?.lastname || null,
        })
    }


    return (

        <div>
            <Tooltip title={t("Duplicate")} arrow>
                <ContentCopyOutlinedIcon className="pointer" onClick={() => setDialog(true)} />
            </Tooltip>
            <Dialog
                maxWidth={"sm"}
                fullWidth
                open={dialog}
                onClose={onClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >

                <Box>
                    <DialogHeader heading={"Copy Assessment"} onClose={onClose} />
                    <br />
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <Box p={2}>
                            <Controller
                                name="user"
                                control={control}
                                defaultValue={null}
                                rules={{ required: true }}
                                render={({ field: { value, onChange } }) => {
                                    return (
                                        <Autocomplete
                                            fullWidth
                                            value={value || null}
                                            options={allUsers}
                                            className="bg_white"
                                            getOptionLabel={(option) => option.email}
                                            isOptionEqualToValue={(option) => option.email}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t("Individual")}
                                                    variant="outlined"
                                                    error={errors.user}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {fetchUsersLoader ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </>
                                                        ),
                                                    }}
                                                // helperText={errors.groupId ? t("Competency is required") : null}
                                                />
                                            )}
                                            onChange={(e, val) => {
                                                onChange(val);
                                            }}
                                            renderOption={(props, option) => (
                                                <li {...props} key={option.userId}>
                                                    {option.email}
                                                </li>
                                            )}
                                        />
                                    );
                                }}
                            />

                            <br />
                            <Controller
                                name="name"
                                control={control}
                                defaultValue={null}
                                rules={{ required: true }}
                                render={({ field: { value, onChange } }) => {
                                    return (
                                        <TextField
                                            error={errors.name}
                                            value={value}
                                            label={t("Assessment.Assessment Name")}
                                            className="bg-white"
                                            onChange={onChange}
                                            fullWidth
                                        />
                                    )
                                }} />
                            <br />
                            <br />

                            <Controller
                                name="completionDate"
                                control={control}
                                defaultValue={null}
                                rules={{ required: true }}
                                render={({ field: { value, onChange } }) => {
                                    return (
                                        <CustomDatePicker
                                            value={value}
                                            fullWidth
                                            error={errors.completionDate}
                                            className="w-100"
                                            disablePast
                                            label={t("Completion Date")}
                                            onChange={(e) => {
                                                onChange(e ? new Date(e.toString()).toISOString() : null)
                                            }}
                                        />
                                    )
                                }} />

                            <br />
                            <br />
                            <br />
                            <div className="end">
                                <button className="btn-secondary" type="button" onClick={onClose}>{t("Cancel")}</button>&nbsp;&nbsp;
                                <button className="btn-primary" type="submit">{t("Submit")}</button>
                            </div>


                        </Box>
                    </form>


                </Box>


            </Dialog>



        </div>
    )
}
