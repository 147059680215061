// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* NotFoundAnimation.module.css */

.WdPlD24dEOAOW50Dwx3F {
    text-align: center;
    padding: 50px;
}

.uwvQkXQiGXZ0Q4ZFBUHP {
    font-size: 8em;
    color: #e74c3c;
    animation: T_RJzAuySgeemegUUbNG 2s infinite;
}

.RPPk2MkxTQVHiP4bTOY5 {
    font-size: 1.5em;
    color: #333;
    margin-top: 20px;
}

@keyframes T_RJzAuySgeemegUUbNG {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-20px);
    }

    60% {
        transform: translateY(-10px);
    }
}












/* UserProfile.module.css */

.jVjj96OX4FpqF7W5k6yN {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
}

.WnQ_qlipoDxuGJO1EzuL {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.B6ZqAlMT6nRWSCOSCPZv {
    border-radius: 50%;
    margin-right: 20px;
}

.mSiWjD1EnKtwAOOib39e {
    flex-grow: 1;
}

._YwXTRHgPWhGu_OQk2KD {
    margin: 0;
    font-size: 1.5em;
}

.OiB6szZXWyJ_0qnNviGQ {
    margin: 0;
    color: #666;
}

._qfX7RyFiYmuOlV05cQz {
    margin-bottom: 20px;
}

/* Add more styles for additional sections or elements */`, "",{"version":3,"sources":["webpack://./src/assets/css/views/404.module.css"],"names":[],"mappings":"AAAA,iCAAiC;;AAEjC;IACI,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,cAAc;IACd,2CAA6B;AACjC;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,gBAAgB;AACpB;;AAEA;;IAEI;;;;;QAKI,wBAAwB;IAC5B;;IAEA;QACI,4BAA4B;IAChC;;IAEA;QACI,4BAA4B;IAChC;AACJ;;;;;;;;;;;;;AAaA,2BAA2B;;AAE3B;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,SAAS;IACT,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB;;AAEA,wDAAwD","sourcesContent":["/* NotFoundAnimation.module.css */\r\n\r\n.notFoundContainer {\r\n    text-align: center;\r\n    padding: 50px;\r\n}\r\n\r\n.notFound {\r\n    font-size: 8em;\r\n    color: #e74c3c;\r\n    animation: bounce 2s infinite;\r\n}\r\n\r\n.notFoundText {\r\n    font-size: 1.5em;\r\n    color: #333;\r\n    margin-top: 20px;\r\n}\r\n\r\n@keyframes bounce {\r\n\r\n    0%,\r\n    20%,\r\n    50%,\r\n    80%,\r\n    100% {\r\n        transform: translateY(0);\r\n    }\r\n\r\n    40% {\r\n        transform: translateY(-20px);\r\n    }\r\n\r\n    60% {\r\n        transform: translateY(-10px);\r\n    }\r\n}\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n/* UserProfile.module.css */\r\n\r\n.profile-container {\r\n    max-width: 600px;\r\n    margin: 0 auto;\r\n    padding: 20px;\r\n}\r\n\r\n.profile-header {\r\n    display: flex;\r\n    align-items: center;\r\n    margin-bottom: 20px;\r\n}\r\n\r\n.profile-picture {\r\n    border-radius: 50%;\r\n    margin-right: 20px;\r\n}\r\n\r\n.profile-details {\r\n    flex-grow: 1;\r\n}\r\n\r\n.user-name {\r\n    margin: 0;\r\n    font-size: 1.5em;\r\n}\r\n\r\n.user-email {\r\n    margin: 0;\r\n    color: #666;\r\n}\r\n\r\n.profile-info {\r\n    margin-bottom: 20px;\r\n}\r\n\r\n/* Add more styles for additional sections or elements */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notFoundContainer": `WdPlD24dEOAOW50Dwx3F`,
	"notFound": `uwvQkXQiGXZ0Q4ZFBUHP`,
	"bounce": `T_RJzAuySgeemegUUbNG`,
	"notFoundText": `RPPk2MkxTQVHiP4bTOY5`,
	"profile-container": `jVjj96OX4FpqF7W5k6yN`,
	"profile-header": `WnQ_qlipoDxuGJO1EzuL`,
	"profile-picture": `B6ZqAlMT6nRWSCOSCPZv`,
	"profile-details": `mSiWjD1EnKtwAOOib39e`,
	"user-name": `_YwXTRHgPWhGu_OQk2KD`,
	"user-email": `OiB6szZXWyJ_0qnNviGQ`,
	"profile-info": `_qfX7RyFiYmuOlV05cQz`
};
export default ___CSS_LOADER_EXPORT___;
