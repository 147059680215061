/* eslint-disable react-hooks/exhaustive-deps */
import { TableHeader } from "../../components/Common/TableContainer"
import { SearchInput } from "../../components/Inputs/SearchInput"
import style from "../../assets/css/views/assessment.module.css"
import { ViewButton } from "../../components/Buttons/ViewButton"
import { CustomPagination } from "../../components/Common/CustomPagination"
import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { fetchGroups } from "../../api/StrategicImperatives"
import { assessmentStatusClass, changeDateFormat, currentUserPrimaryGroupId, currentUserRole, isUserCreateUpdateData, loader } from "../../utils"
import { searchDataByNestedKey } from "../../utils/search"
import { ChangeAssessmentStatus } from "../../components/Common/Assessment/ChangeAssessmentStatus"
import { ReportsButton } from "../../components/Buttons/ReportsButton"
import { DATA_CREATE_UPDATE_ACCESS_ROLES, ASSESSMENT_STATUS } from "../../constants/constants"
import { useTranslation } from "react-i18next"
import { Accordion } from "../../components/Common/Accordion"
import { NoTableData } from "../../components/Common/NoTableData"
import { useSelector } from "react-redux"
import { BreadCrum } from "../../components/Common/BreadCrum"
import { ASSESSMENT_TYPES } from '../../constants/constants'


export const Assessment = () => {
    const tokenStatus = useSelector((state) => state.tokenStatus);

    const { assessmentType } = useParams()

    let [page, setPage] = useState(1)
    let record_per_page = 20
    let loaderP = useSelector(e => e.loader)
    let { t } = useTranslation()
    let [totalData, setTotalData] = useState(0)
    let [allAssessments, setAllAssessments] = useState([])
    let [searchValue, setSearchValue] = useState("")
    let [allData, setAllData] = useState([])
    let userRole = currentUserRole()
    let primary_group = currentUserPrimaryGroupId()
    let navigate = useNavigate();
    let [error, setError] = useState({
        error: false,
        errorMessage: "No Assessments Available"
    });
    function pageChange(e) {
        setPage(e)
    }


    const fetchData = async () => {
        if (userRole === "user") return
        setError({
            error: false,
            errorMessage: "No Assessments Available"
        })
        try {
            let data = {
                page,
                record_per_page,
                collectionType: "ASSESSMENTS",
                assessmentType: assessmentType,
                ...(userRole === 'groupAdmin' ? { primary_groupId: primary_group } : {})
            }
            loader.start()
            let res = await fetchGroups(data)
            setAllAssessments(res.data.response)
            setAllData(res.data.response)
            setTotalData(res.data.totalGroups)
            loader.stop()
        }
        catch (err) {
            setError({
                error: false,
                errorMessage: err?.response?.data?.error
            })
            // toast.error(err?.response?.data?.error)
        }
    }

    useEffect(() => {
        fetchData()
    }, [page, tokenStatus, assessmentType])



    function searchAssessment(e) {
        setSearchValue(e.target.value)
        setAllAssessments(searchDataByNestedKey(allData, e.target.value, "data", "name"))
    }



    function statusChanged(nextStatus) {
        fetchData()
    }

    let breadCrumData = [
        {
            text: t("Assessment.Assessment"),
            path: "/assessment"
        },
        {
            text: t(assessmentType),
        },

    ]

    return (
        <div className={`padding_22_44 ${style.assessment_main_con}`}>

            {userRole === "user" ? <h1>
                Assessments Page
            </h1> : <>
                <div className="between">
                    {/* <div className="h1">{t("Assessment.Assessment")}</div> */}
                    <BreadCrum dataArr={breadCrumData} />
                    <div>
                        {DATA_CREATE_UPDATE_ACCESS_ROLES.some(e => e === userRole) ?
                            <Link to={"create"}>
                                <button className="btn-primary">{t("Create New")}</button>
                            </Link> : null}
                    </div>
                </div>

                <TableHeader>
                    <SearchInput className="search_input" value={searchValue} onChange={searchAssessment} placeholder={t("Search")} />
                </TableHeader>


                <div className={style.table_scroll}>


                    <div className={style.table_head}>

                        <div className={style.header_grid_con}>
                            <div>{t("Assessment.Assessment Name")}</div>
                            {assessmentType != ASSESSMENT_TYPES.jobRole && <div>{t("Type")}</div>}
                            {assessmentType === ASSESSMENT_TYPES.jobRole && <div>{t("jobRole.jobRole")}</div>}
                            {/* <div>{t("Type")}</div> */}
                            <div>{t("Completion Date")}</div>
                            <div>{t("Current Status")}</div>
                        </div>

                    </div>

                    {(error.error || allAssessments?.length === 0) && !loaderP ? <NoTableData text={error.errorMessage} /> : <>

                        {allAssessments.map((res, key) => {
                            return (
                                <div key={key} className={style.table_row}>

                                    <Accordion open={key === 0} title={res.groupName}>
                                        <div style={{ marginTop: "20px" }}>

                                            {res.data.map((assessment) => {
                                                return (
                                                    <div key={assessment._id} className={`${style.tableRow_grid}`}>
                                                        <div>{assessment.name}</div>
                                                        {/* <div>{assessment.type}</div> */}
                                                        {assessmentType != ASSESSMENT_TYPES.jobRole && <div>{assessment.type}</div>}
                                                        {assessmentType === ASSESSMENT_TYPES.jobRole && <div>{assessment?.jobRoleData?.jobRoleObj?.name}</div>}

                                                        <div>{assessment.completionDate && changeDateFormat(assessment.completionDate)}</div>
                                                        <div>
                                                            <div className="flex align_center ">
                                                                <div
                                                                    className={`${assessment.status} ${assessmentStatusClass(assessment.status)} assessment_status`}></div>
                                                                &nbsp;&nbsp;
                                                                <div>
                                                                    {assessment.status}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex end align_center">
                                                            <Link to={`/assessment/${assessmentType}/preview/${assessment._id}`}>
                                                                <ViewButton />
                                                            </Link>
                                                            &nbsp;
                                                            {userRole !== "user" && isUserCreateUpdateData() ? <>
                                                                <ChangeAssessmentStatus
                                                                    onSubmit={statusChanged}
                                                                    assessmentData={assessment}
                                                                    currentStatus={assessment.status}
                                                                    disabled={assessment.status === "Completed"}
                                                                />
                                                                &nbsp;&nbsp;
                                                            </> : null}
                                                            <ReportsButton onClick={() => navigate(`/assessment/report/${assessment._id}`)}
                                                                disabled={assessment.status !== ASSESSMENT_STATUS[ASSESSMENT_STATUS.length - 1]} />

                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>

                                    </Accordion>

                                </div>
                            )
                        })}

                    </>}

                </div>

                <CustomPagination page={page - 1} pageChange={pageChange} count={totalData} perPage={record_per_page} />
            </>
            }

        </div >
    )
}