import { createSlice } from "@reduxjs/toolkit";

let initialState = {
    question: {}
}

export const questionSlice = createSlice({
    name: 'questionSlice',
    initialState,
    reducers: {
        setQuestionDetail: (state, action) => {
            return ({ ...state, question: action.payload })
        }
    }
})

export let { setQuestionDetail } = questionSlice.actions