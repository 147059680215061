import React from 'react'
import styled from "@emotion/styled";
import { LinearProgress, linearProgressClasses } from "@mui/material";

export const ProgressLine = ({ value }) => {
    return (
        <>
            <ProgressLine2 variant="determinate" value={value} />
            <div className="flex justify_between">
                <div>{Math.round(value)}% Done</div>
                <div>945 KB/Sec</div>
            </div>
        </>)
}
const ProgressLine2 = styled(LinearProgress)(({ theme }) => ({

    height: '14px',
    borderRadius: '8px',
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: 'rgba(190, 208, 247, 1)',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: '8px',
        backgroundColor: 'rgba(76, 99, 182, 1)',
    },
}));

