import React from "react";
import { CrossButton } from "../Buttons/CrossButton";

export const DialogHeader = ({ onClose, noClosable, heading }) => {
    return (
        <div className={`between dialog_header`}>
            <div className='dialog_heading'>{heading}</div>
            {!noClosable ?
                <div onClick={onClose} className={`pointer`}>
                    <CrossButton />
                </div> : null}
        </div>
    )
}
