import React from 'react'
import { Navigate, Outlet, useParams } from 'react-router';

export const ValidateRouteParams = ({ allowedParams, redirectTo, paramsKey }) => {
    const params = useParams();
    if (allowedParams.includes(params[paramsKey])) {
        return <Outlet />;
    }
    return <Navigate to={redirectTo} />
};

