import React from 'react';
import { Navigate, Outlet } from "react-router"
import Sidebar from "../components/Sidebar/Sidebar";
import { useRef } from "react";

export const CheckAuth = ({ authenticatedRoles }) => {
    let isLoggedIn = useRef(false)
    let user = localStorage.getItem("userData")

    if (user !== null) {
        let role = JSON.parse(user).role.item_name
        if (authenticatedRoles.includes(role)) {
            isLoggedIn.current = true
        }
    }

    return (
        <>
            {isLoggedIn.current ?
                <Sidebar>
                    <Outlet></Outlet>
                </Sidebar>
                : <Navigate to={"/"} />}

        </>
    )
}












