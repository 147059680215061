import { setTokenStatus } from "../store/Slices/TokenExpireSlice";
import { store } from "../store/store";

export const eventSource = (url, onDataAvailable, onError) => {

  const source = new EventSource(url, { withCredentials: true });

  source.onmessage = function (event) {
    let data = JSON.parse(event.data)
    if (data.status && data.status === 401) {
      source.close();
      store.dispatch(setTokenStatus(false))
      return
    }
    onDataAvailable(event.data);
  };

  source.onerror = function (event) {
    console.error("EventSource error:", event);
    source.close();

    onError(event);
  };
}