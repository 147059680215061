import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { BreadCrum } from '../../components/Common/BreadCrum';
import { QuestionTable } from '../../components/Common/Assessment/QuestionTable';
import { CustomSelect } from '../../components/Inputs/CustomSelect';
import { LinearProgress, patch, TextField } from '@mui/material';
import { CustomAutoComplete } from '../../components/Inputs/CustomAutoComplete';
import style from "../../assets/css/views/assessment.module.css"
import { Controller, useForm } from 'react-hook-form';
import { loader, makeHashMapWithKey, toast } from '../../utils';
import { getUsersByGroupId } from '../../api/user';
import { fetchJobRoleById, fetchTPJobRoles } from '../../api/jobRoles';
import { getSubGroupByGroupId } from '../../api/Group';
import { getAllDPDByJobRole } from '../../api/developmentPlan';
import { Accordion } from '../../components/Common/Accordion';
import { AddMoreItem } from '../../components/Common/AddMoreItem';
import { Circle } from '@mui/icons-material';
import { createAssessment, editAssessment, fetchAssessmentById } from '../../api/Assessment';
import { Link } from 'react-router-dom';
import useGroupData from '../../hooks/useGroupData';
import _ from "underscore"

export const CreateUpdateJobRoleAssessment = () => {
    const navigate = useNavigate()
    const { assessmentType } = useParams();
    let { id } = useParams()
    const mode = id ? "Edit" : "Create";
    const [categoryData, setCategoryData] = useState([]);
    const [questionTableVisibility, setQuestionTableVisibility] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const [allTpJobRoles, setAllTpJobRoles] = useState([]);
    const [allSubGroups, setAllSubGroups] = useState([]);
    const [allDevelopmentPlans, setAllDevelopmentPlans] = useState([]);
    const [openCollapseId, setOpenCollapseId] = useState(null);

    const [assessmentName, setAssessmentName] = useState({
        jobRoleName: "",
        dpdName: ""
    })

    const [fetchLoader, setFetchLoader] = useState({
        jobRole: false,
        subGroup: false,
        users: false,
        developmentPlan: false,
        categoryData: false
    })


    let [currentKPI, setCurrentKPI] = useState({
        kpiId: "",
        questionIds: [],
    });

    const { t } = useTranslation();

    const { control, reset, setValue, getValues, handleSubmit, formState: { errors }, watch } = useForm({
        defaultValues: {
            groupId: null,
            assessmentType: "JobRole",
            description: "",
            categoryIds: [],
            dpdId: null,
            surveyAssignments: {
                subGroupIds: [],
                user: {},
                jobRoleId: null
            },
            reviewers: {
                subGroupIds: [],
                jobRoleIds: [], //tp ids
                individuals: []
            },
            status: "Drafts",
            questions: [],
            validationCheck: true
        }
    })

    const groupId = watch("groupId");
    const selectedJobroleId = watch("surveyAssignments.jobRoleId")
    const dpdId = watch("dpdId")
    const jobRoleId = watch("surveyAssignments.jobRoleId")
    const name = watch("name")

    // fetch all groups from store  
    const allGroups = useGroupData()

    useEffect(() => {
        if (mode === "Edit") {
            getAssessmentDataById()
        }
    }, [])

    async function getAssessmentDataById() {
        try {
            let res = await fetchAssessmentById(id)
            const response = res.data.response;
            let { groupId, description, dpdData, categoriesData, reviewers, questions, surveyAssignments } = response
            let data = {
                categoryIds: categoriesData.map(e => e._id),
                dpdId: dpdData?._id,
                surveyAssignments: {
                    subGroupIds: null,
                    user: null,
                    jobRoleId: surveyAssignments.jobRole.jobRoleObj.roleId
                },
                reviewers: {
                    subGroupIds: reviewers.subGroupsData.map(e => e._id),
                    jobRoleIds: reviewers.jobRoles.map(e => e.jobRoleObj.roleId),
                    individuals: reviewers.individuals
                },
                questions: combileCategoryAndCompetencyQuestionData(categoriesData, questions)
            }
            setAssessmentName({
                jobRoleName: surveyAssignments.jobRole.jobRoleObj.name,
                dpdName: ""
            })
            let questionMap = new Map(questions.map(option => [option.competencyData._id, option.questionData]))

            fetchJobRoleData(surveyAssignments.jobRole.jobRoleObj.roleId, surveyAssignments.jobRole.dpdId, questionMap)





            reset({ groupId, description, ...data })
        } catch (error) {
            console.log(error)
        }
    }

    function combileCategoryAndCompetencyQuestionData(catData) {
        const categoryMap = makeHashMapWithKey(catData, "_id")

    }

    useEffect(() => {
        if (groupId) {
            getAllUsers();
            fetchTpJobRolesByGroupId()
            getsubgroups();
        }
    }, [groupId])

    // fetch development plans on job role change c
    useEffect(() => {
        if (selectedJobroleId) {
            fetchDevelopmentPlans(selectedJobroleId)
        }
    }, [selectedJobroleId])


    async function fetchJobRoleData(id, dpdId, questionCompMap) {
        try {
            let body = {
                id: id,
                dpdId: dpdId,
            }
            setFetchLoader(pre => ({ ...pre, categoryData: true }))
            let res = await fetchJobRoleById(body);
            let data = res?.data?.response

            let formattedData = data.categories.map(catData => {
                let arr = _.size(catData?.sortedCompetenciesData) ? catData?.sortedCompetenciesData : catData?.competenciesData
                return ({
                    name: catData.categoryData.name,
                    _id: catData?.categoryData?._id,
                    competencyData: arr.map(compData => {
                        return ({
                            name: compData.name,
                            _id: compData._id,
                            questionData: questionCompMap ? questionCompMap?.get(compData._id) || [] : []
                        })
                    })
                }
                )
            })


            setOpenCollapseId(formattedData?.[0]?.competencyData?.[0]?._id)
            setCategoryData(formattedData)

        } catch (error) {
            console.log(error)
        }
        finally {
            setFetchLoader(pre => ({ ...pre, categoryData: false }))
        }
    }

    // fetch all development plans on jobrole change 
    async function fetchDevelopmentPlans(jobId) {
        try {
            setFetchLoader(pre => ({ ...pre, developmentPlan: true }))
            let res = await getAllDPDByJobRole(jobId)
            setAllDevelopmentPlans(res?.data?.response?.[0]?.DPD || [])
        } catch (error) {
            console.log(error)
        }
        finally {
            setFetchLoader(pre => ({ ...pre, developmentPlan: false }))
        }
    }

    // fetch all users of group selected 
    async function getAllUsers() {
        try {
            setFetchLoader(pre => ({ ...pre, users: true }))
            let res = await getUsersByGroupId(groupId);
            if (res.data.success) {
                let userData = res?.data?.users?.map(extractUserDetails) || []
                setAllUsers(userData);
            } else {
                toast.error(res?.data?.message);
            }
        } catch (err) {
            toast.error(err?.response?.data?.error)
        }
        finally {
            setFetchLoader(pre => ({ ...pre, users: false }))
        }
    }

    // fetch all tp job roles of group selected 
    async function fetchTpJobRolesByGroupId() {
        try {
            setFetchLoader(pre => ({ ...pre, jobRole: true }))
            let res = await fetchTPJobRoles(groupId)
            setAllTpJobRoles(res?.data?.response || [])
        } catch (err) {
            toast.error(err?.response?.data?.error)
        }
        finally {
            setFetchLoader(pre => ({ ...pre, jobRole: false }))
        }
    }

    // fetch all sub group of group selected 
    async function getsubgroups() {
        try {
            setFetchLoader(pre => ({ ...pre, subGroup: true }))
            let res = await getSubGroupByGroupId(groupId);
            setAllSubGroups(res.data.response);
        } catch (err) {
            toast.error(err?.response?.data?.error);
        }
        finally {
            setFetchLoader(pre => ({ ...pre, subGroup: false }))
        }
    }


    let breadCrumData = [
        {
            text: t(assessmentType),
            path: `/assessment/${assessmentType}`
        },
        {
            text: t("Assessment.Assessment"),
        },
        {
            text: mode === "Edit" ? t("Edit") : t("Create New"),
        },

    ];

    const extractUserDetails = (user) => {
        return {
            userId: user?.id || null,
            email: user?.email || null,
            name: user?.username || null,
            jobRoleName: null,
            jobRoleId: user?.job_role || null
        };
    }

    function resetForm(groupId) {
        // reset all fields except description of form   
        // reset({ groupId, description: getValues().description })

        setValue("categoryIds", [])
        setValue("dpdId", null)
        setValue("surveyAssignments", {
            subGroupIds: [],
            user: {},
            jobRoleId: null
        })
        setValue("reviewers", {
            subGroupIds: [],
            jobRoleIds: [], //tp ids
            individuals: []
        })
        setValue("questions", [])
        setAllTpJobRoles([])
        setAllUsers([])
        setAllDevelopmentPlans([])
        setAllSubGroups([])
        setCategoryData([])
    }


    function addQuestion(res, categoryIndex, competencyIndex) {
        // set current clicked competency data to state and send data to question table component 

        let { questionData, ...kpiData } = categoryData[categoryIndex].competencyData[competencyIndex]
        setOpenCollapseId(kpiData?._id)
        setCurrentKPI({ kpiData, questionData, categoryIndex, competencyIndex })
        setQuestionTableVisibility(true);
    }

    function addQuestionToCompetency(data) {
        // push selected question to category data state 
        setCategoryData(pre => {
            let tempVar = [...pre]
            tempVar[data.categoryIndex].competencyData[data?.competencyIndex].questionData = data.questionData
            return ([...tempVar])
        })
        setQuestionTableVisibility(false);
    }


    async function submitForm(data) {

        try {
            let noQuestionCompLength = 0
            // extract competency and selected question ids from category data 
            // also check all competency have atleast one questions or not 
            data.questions = categoryData.flatMap(e => {
                data.categoryIds.push(e._id);
                return e.competencyData.map(comp => {
                    if (!comp?.questionData?.length) noQuestionCompLength++;
                    return {
                        competencyId: comp._id,
                        questionIds: comp.questionData.map(q => q._id)
                    };
                });
            });

            if (noQuestionCompLength !== 0) {
                toast.error("All Competencies must have atleast one question !")
                return
            }
            data.name = `${assessmentName?.jobRoleName}-${assessmentName?.dpdName}`
            loader.start()
            if (mode === "Create") {
                let res = await createAssessment(data)
                toast.success("Assessment Created Successfully")
            }
            else {
                let res = await editAssessment({ ...data, _id: id })
                toast.success("Assessment Updated Successfully")
            }
            navigate("/assessment/JobRole")
        }
        catch (err) {
            console.log(err)
        }
        finally {
            loader.stop()
        }

    }
    function errorform() {
        toast.error("All Fiels are required")
    }
    const validateAtLeastOneReviewerField = () => {
        const { reviewers } = getValues();
        return (
            (reviewers.subGroupIds && reviewers.subGroupIds.length > 0) ||
            (reviewers.jobRoleIds && reviewers.jobRoleIds.length > 0) ||
            (reviewers.individuals && reviewers.individuals.length > 0) ||
            "At least one field is required."
        );
    };

    return (
        <div className='padding_22_44'>

            <form onSubmit={handleSubmit(submitForm, errorform)}>

                <>
                    {!questionTableVisibility ? (
                        <>
                            <BreadCrum dataArr={breadCrumData} />
                            <br />
                            <div className={style.createJobRoleAssessment_main_con}>
                                <div className={style.assessment_form}>
                                    <div className={style.groupSelect}>
                                        {/* group select  */}
                                        <Controller
                                            name="groupId"
                                            control={control}
                                            defaultValue={null}
                                            rules={{ required: true }}
                                            render={({ field: { value, onChange } }) => {
                                                return (
                                                    <CustomSelect
                                                        value={allGroups?.find(e => e.id == value) || null}
                                                        options={allGroups}
                                                        optionLabel={"name"}
                                                        error={!!errors?.groupId}
                                                        keyLabel={"id"}
                                                        onChange={(e, val) => {
                                                            onChange(val?.id)
                                                            resetForm(val?.id)
                                                        }}
                                                        label={t("Group Name")}
                                                        renderOption={"name"}
                                                    />
                                                )
                                            }} />
                                    </div>
                                    <div className={style.assessment_name_section}>
                                        <br />
                                        <div className={style.grid_con}>
                                            <Controller
                                                name="surveyAssignments.jobRoleId"
                                                control={control}
                                                defaultValue={null}
                                                rules={{ required: true }}
                                                render={({ field: { value, onChange } }) => {
                                                    return (
                                                        <CustomSelect
                                                            value={allTpJobRoles?.find(e => e.id === value) || null}
                                                            options={allTpJobRoles}
                                                            loading={fetchLoader.jobRole}
                                                            optionLabel={"name"}
                                                            error={!!errors?.surveyAssignments?.jobRoleId}
                                                            keyLabel={"id"}
                                                            onChange={(e, val) => {
                                                                onChange(val?.id)
                                                                setAssessmentName({
                                                                    jobRoleName: val?.name,
                                                                    dpdName: ""
                                                                })
                                                                // make development plan null and remove all the values from dropdown of DP
                                                                setValue("dpdId", null)
                                                                setAllDevelopmentPlans([])
                                                                setCategoryData([])
                                                            }}
                                                            label={t("jobRole.jobRole")}
                                                            renderOption={"name"}
                                                        />
                                                    )
                                                }} />
                                            <div>
                                                <Controller
                                                    name="dpdId"
                                                    control={control}
                                                    defaultValue={null}
                                                    rules={{ required: true }}
                                                    render={({ field: { value, onChange } }) => {
                                                        return (
                                                            <CustomSelect
                                                                value={allDevelopmentPlans?.find(e => e._id == value) || null}
                                                                options={allDevelopmentPlans}
                                                                optionLabel={"name"}
                                                                error={!!errors?.dpdId}
                                                                keyLabel={"_id"}
                                                                loading={fetchLoader.developmentPlan}
                                                                onChange={(e, val) => {
                                                                    setAssessmentName(pre => ({ ...pre, dpdName: val?.name }))
                                                                    onChange(val?._id)
                                                                    setCategoryData([])
                                                                    if (val?._id && jobRoleId) {

                                                                        fetchJobRoleData(jobRoleId, val?._id)
                                                                    }
                                                                }}
                                                                label={t("developmentPlan.developmentPlans")}
                                                                renderOption={"name"}
                                                            />
                                                        )
                                                    }} />
                                            </div>

                                            <div>
                                                <Controller
                                                    name="description"
                                                    control={control}
                                                    defaultValue={null}

                                                    render={({ field: { value, onChange } }) => {
                                                        return (
                                                            <TextField
                                                                value={value}
                                                                label={t("Description")}
                                                                className="bg-white"
                                                                onChange={onChange}
                                                                fullWidth
                                                            />
                                                        )
                                                    }} />
                                            </div>


                                        </div>
                                        <br />
                                        <div><label>{t("Reviewers")}:</label></div>
                                        <div className={style.reviewer_input_con}>
                                            <div>
                                                <Controller
                                                    name="reviewers.subGroupIds"
                                                    control={control}
                                                    defaultValue={null}
                                                    rules={{ validate: validateAtLeastOneReviewerField }}
                                                    render={({ field: { value, onChange } }) => {
                                                        return (
                                                            <CustomAutoComplete
                                                                error={errors?.reviewers?.subGroupIds}

                                                                loader={fetchLoader.subGroup}
                                                                options={allSubGroups}
                                                                renderTagsClassName={style.autocomplete_wrapper}
                                                                optionLabelKey={"name"}
                                                                value={allSubGroups.filter(e => value.includes(e._id))}
                                                                label={t("Sub Groups")}
                                                                onChange={(event, val) => { onChange(val.map(e => e._id)) }}
                                                            />
                                                        )
                                                    }} />
                                            </div>
                                            <div>
                                                <Controller
                                                    name="reviewers.jobRoleIds"
                                                    control={control}
                                                    defaultValue={null}
                                                    rules={{ validate: validateAtLeastOneReviewerField }}
                                                    render={({ field: { value, onChange } }) => {
                                                        return (
                                                            <CustomAutoComplete
                                                                loader={fetchLoader.jobRole}
                                                                error={errors?.reviewers?.jobRoleIds}
                                                                options={allTpJobRoles}
                                                                renderTagsClassName={style.autocomplete_wrapper}
                                                                optionLabelKey={"name"}
                                                                value={allTpJobRoles.filter(e => value.includes(e.id))}
                                                                label={t("jobRole.Job Roles")}
                                                                onChange={(event, val) => { onChange(val.map(e => e.id)) }}
                                                            />
                                                        )
                                                    }} />
                                            </div>
                                            <div>
                                                <Controller
                                                    name="reviewers.individuals"
                                                    control={control}
                                                    defaultValue={null}
                                                    rules={{ validate: validateAtLeastOneReviewerField }}
                                                    render={({ field: { value, onChange } }) => {
                                                        return (
                                                            <CustomAutoComplete
                                                                options={allUsers}
                                                                error={errors?.reviewers?.individuals}
                                                                renderTagsClassName={style.autocomplete_wrapper}
                                                                optionLabelKey={"name"}
                                                                loader={fetchLoader?.users}
                                                                value={allUsers.filter(e => value.some(d => d.userId == e.userId))}
                                                                label={t("Users")}
                                                                onChange={(event, val) => {
                                                                    onChange(val)


                                                                }}
                                                            />
                                                        )
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <br />
                                <div className={style.assessment_kpi_sec}>
                                    <div className={style.header}>
                                        {t("competency.Competencies for the Job Role")}*
                                    </div>
                                    <br />
                                    {fetchLoader?.categoryData ? <LinearProgress /> :
                                        <div>
                                            {categoryData?.map((cat, catInd) => {
                                                return (
                                                    <Fragment key={cat._id}>
                                                        <div className={style.category_name}>{cat?.name}</div>

                                                        {cat?.competencyData?.map((comp, compIndex) => {
                                                            let isQuestionAvailable = comp?.questionData?.length > 0
                                                            return (
                                                                <Accordion open={openCollapseId == comp._id} key={`${cat._id}${comp._id}`} primaryColor title={comp?.name}>
                                                                    <div className={style.comptency_accordion_inner_con}>
                                                                        {/* show no question msg if no question selected  */}
                                                                        {!isQuestionAvailable && <div className='body_1'>{t("No Question Selected")}...</div>}
                                                                        {/* map selected question  */}
                                                                        {comp?.questionData?.map((question) => {
                                                                            return (
                                                                                <div key={`${cat._id}${comp._id}${question._id}`} className={`flex align_center ${style.content}`} style={{ gap: "30px" }} >
                                                                                    <div className='w-70 flex'>
                                                                                        <div><Circle sx={{ fontSize: "6px", pb: 0.2, mr: 1 }} /></div>
                                                                                        <div>{question.question}</div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                        <AddMoreItem
                                                                            onClick={() => addQuestion(comp, catInd, compIndex)}
                                                                            className={style.add_item_btn}
                                                                            text={isQuestionAvailable
                                                                                ? t("Assessment.Add/Remove Questions")
                                                                                : t("Assessment.Add Question")} />
                                                                    </div>
                                                                </Accordion>
                                                            )
                                                        })}
                                                        <br />
                                                    </Fragment>
                                                )
                                            })}
                                        </div>}
                                </div>
                            </div>
                            <br />
                        </>
                    ) : (
                        <QuestionTable
                            assessmentName={name}
                            onContinue={addQuestionToCompetency}
                            onCancel={() => setQuestionTableVisibility(false)}
                            currentKPI={currentKPI}
                            usedFor={"competency"}
                        />
                    )
                    }
                </>

                {!questionTableVisibility && <div className="end">
                    <Link to={"/assessment/JobRole"}>
                        <button type='button' className='btn-secondary'>Cancel</button>
                    </Link>
                    &nbsp;&nbsp;
                    <button type='submit' className='btn-primary'>Submit</button>
                </div>
                }
            </form>


        </div>
    )
}
