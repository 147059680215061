// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .footer_main_con {
    height: 40px;
    color: #4A4A4A;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0px 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & span {
        margin-left: 40px;
    }
}

*/
.TRO1pqXlcV4IDU2BLrds {
    padding: 10px 44px;
    font-size: 14px;
    color: #4A4A4A;
    font-weight: 400;
    display: flex;
    align-items: center;
    background-color: #F9F9FD;

    .kQXmilObjzxov__CUqDK {
        display: flex;
        gap: 20px;
        text-wrap: nowrap;
    }

}

@media (max-width:850px) {
    .TRO1pqXlcV4IDU2BLrds {
        font-size: 10px;

        .kQXmilObjzxov__CUqDK {
            gap: 10px;
        }
    }
}

/* @media (max-width:677px) {
    .footer_main_con {
        font-size: 5px;


    }
} */`, "",{"version":3,"sources":["webpack://./src/assets/css/components/footer.module.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;CAiBC;AACD;IACI,kBAAkB;IAClB,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,yBAAyB;;IAEzB;QACI,aAAa;QACb,SAAS;QACT,iBAAiB;IACrB;;AAEJ;;AAEA;IACI;QACI,eAAe;;QAEf;YACI,SAAS;QACb;IACJ;AACJ;;AAEA;;;;;;GAMG","sourcesContent":["/* .footer_main_con {\r\n    height: 40px;\r\n    color: #4A4A4A;\r\n    font-size: 14px;\r\n    font-style: normal;\r\n    font-weight: 400;\r\n    line-height: normal;\r\n    padding: 0px 44px;\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n\r\n    & span {\r\n        margin-left: 40px;\r\n    }\r\n}\r\n\r\n*/\r\n.footer_main_con {\r\n    padding: 10px 44px;\r\n    font-size: 14px;\r\n    color: #4A4A4A;\r\n    font-weight: 400;\r\n    display: flex;\r\n    align-items: center;\r\n    background-color: #F9F9FD;\r\n\r\n    .container_2 {\r\n        display: flex;\r\n        gap: 20px;\r\n        text-wrap: nowrap;\r\n    }\r\n\r\n}\r\n\r\n@media (max-width:850px) {\r\n    .footer_main_con {\r\n        font-size: 10px;\r\n\r\n        .container_2 {\r\n            gap: 10px;\r\n        }\r\n    }\r\n}\r\n\r\n/* @media (max-width:677px) {\r\n    .footer_main_con {\r\n        font-size: 5px;\r\n\r\n\r\n    }\r\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer_main_con": `TRO1pqXlcV4IDU2BLrds`,
	"container_2": `kQXmilObjzxov__CUqDK`
};
export default ___CSS_LOADER_EXPORT___;
