import React from 'react'
import { JobRoleForm } from '../../components/Common/JobRole/JobRoleForm'
import { BreadCrum } from '../../components/Common/BreadCrum';
import { useTranslation } from 'react-i18next';
import { createJobRole } from '../../api/jobRoles';
import { loader, toast } from '../../utils';
import { useLocation, useNavigate } from 'react-router';
import { STATUS } from '../../constants/constants';

export const CreateJobRole = () => {
    const groupId = new URLSearchParams(useLocation().search).get("groupId");
    const { t } = useTranslation();
    const navigate = useNavigate();

    const jobRoleData = {
        groupId: parseInt(groupId),
        jobRoleObj: {
            name: "",
            roleId: null
        },
        competencyIds: [],
        categoryIds: [],
        dpdId: null,
        status: ""
    };

    const breadCrumData = [
        {
            text: t("jobRole.jobRole"),
            path: "/jobRole"
        },
        {
            text: t("Create"),
        },
    ]


    async function handleCreateJobRole(data) {
        try {

            let finalData = {
                groupId: data.groupId,
                jobRoleObj: data.jobRoleObj,
                dpdId: data?.dpdId,
                status: STATUS.DRAFTS
            }

            finalData.categories = data.categoryIds.map((res) => {
                return ({
                    categoryId: res,
                    competencyIds: data.competencyIds.filter(e => e.categoryId == res).map(e => e._id)
                })
            })

            loader.start()
            let res = await createJobRole(finalData)
            toast.success(res?.data?.message)
            navigate("/jobRole")
        } catch (error) {
            toast.error(error?.response?.data?.error)
        }
        finally {
            loader.stop()
        }

    }

    return (
        <div className="padding_22_44">
            <BreadCrum dataArr={breadCrumData} />
            <br />
            <JobRoleForm mode={"create"} data={jobRoleData} onSubmit={handleCreateJobRole} />
        </div>
    )
}
