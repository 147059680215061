import React, { Fragment, useEffect, useState } from "react"
import style from "../../../assets/css/components/kpi&sicommon.module.css"
import { Autocomplete, CircularProgress, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { CustomInput } from "../../Inputs/CustomInput"
import { STRAT_MEASUREMENT_TYPE, STRAT_PRIORITY, TYPE_OF_KPI } from "../../../constants/constants"
import { CustomRadio } from "../../Inputs/CustomRadio"
import { CustomDatePicker } from "../../Inputs/CustomDatePicker"
import { getallgroups } from "../../../store/Slices/GroupSlice"
import { StrategicImperativesDropDown } from "./StrategicImperativesDropDown"
import { useTranslation } from "react-i18next"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import { DeleteButon } from "../../Buttons/DeleteButton"
import { AddMoreItem } from "../AddMoreItem"
import { getUsersByGroupId } from "../../../api/user"
import { KPIFields } from "./KpiFields"
import { useLocation, useParams } from "react-router"
import { toast } from "../../../utils"

export const KPISIForm = ({ data, type, mode, onSubmit, onClose, groupDisable }) => {


    // type can be "KPI" or "SI"
    const queryGroupId = new URLSearchParams(useLocation().search).get('id');
    let { t } = useTranslation()
    let { id } = useParams()
    let [allUsers, setAllUsers] = useState([])
    let [fetchUsersLoader, setFetchUsersLoader] = useState(false)
    let allGroups = useSelector(e => e.groupData.allGroups)
    let dispatch = useDispatch()

    const { control, handleSubmit, setValue, watch, formState: { errors } } = useForm({
        defaultValues: { ...data }
    })

    const { fields: kpiFields, append: appendKPI, remove: removeKPI } = useFieldArray({
        control,
        name: "keyPerformanceIndicators"
    });

    const { fields: newKpiFields, append: appendNewKPI, remove: removeNewKPI } = useFieldArray({
        control,
        name: "newKPIs"
    });
    const { append: deletedKpisAppend } = useFieldArray({
        control,
        name: "deletedKPIs"
    });


    let groupId = watch("groupId")

    useEffect(() => {
        if (groupId) {
            getTpUsers()
        }
    }, [groupId])

    async function getTpUsers() {
        try {

            setFetchUsersLoader(true)
            let res = await getUsersByGroupId(groupId)
            let croppedData = res.data.users?.map(pullRequireFieldsOnly)
            setAllUsers(croppedData)
        } catch (error) {
            console.error(error)
        }
        finally {
            setFetchUsersLoader(false)
        }
    }
    function pullRequireFieldsOnly(val) {
        return ({
            userId: val?.id ? Number(val?.id) : null,
            email: val?.email || null,
            first_name: val?.userProfile?.firstname || null,
            last_name: val?.userProfile?.lastname || null,
            job_role: val?.job_role_label || null,
            jobRoleId: val?.job_role_id || null
        })
    }

    useEffect(() => {
        if (allGroups.length === 0) {
            dispatch(getallgroups())

        }
    }, [dispatch, allGroups.length])

    function formSubmit(data) {
        if (data?.deletedKPIs?.length === 0) {
            delete data?.deletedKPIs
        }
        if (data?.newKPIs?.length === 0) {
            delete data?.newKPIs
        }
        onSubmit(data);
    }

    function onDeleteKpi(kpiData) {
        // store deleted kpi ids 
        deletedKpisAppend(kpiData._id)

    }

    return (
        <div className={style.KPISI_create_form_con}>
            <form onSubmit={handleSubmit(formSubmit)}>

                <div>
                    {/* group field  */}
                    <Controller
                        name="groupId"
                        control={control}
                        defaultValue={null}
                        rules={{ required: true }}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <Autocomplete
                                    value={allGroups.find(d => d.id === value) || null}
                                    options={allGroups}
                                    sx={{ width: "300px" }}
                                    disabled={mode === "Update" || queryGroupId}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t("Group Name")}
                                            variant="outlined"
                                            error={!!errors.groupId}
                                        />
                                    )}
                                    onChange={(e, val) => {
                                        kpiFields.forEach((_, index) => {
                                            setValue(`keyPerformanceIndicators.${index}.owner`, null);
                                            setValue(`keyPerformanceIndicators.${index}.supervisor`, null);
                                        });
                                        onChange(val ? val.id : null);
                                    }}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.id}>
                                            {option.name}
                                        </li>
                                    )}
                                />
                            );
                        }}
                    />
                </div>

                <div className={style.strat_dark_area}>
                    {/* si name field  */}
                    <div>
                        <Controller
                            name='name'
                            control={control}
                            className="w-100"
                            rules={{ required: true }}
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <TextField
                                        fullWidth
                                        error={!!errors.name}
                                        className='bg_white'
                                        onChange={onChange}
                                        label={t("Strategic Imperatives")}
                                        value={value}
                                    />
                                )
                            }}
                        />
                    </div>
                    <div className={style.kpi_label}><label>{t("KPI.KPIsGoals")}:</label></div>
                    <div className={style.kpi_form_grid_con}>


                        {/* {kpiFields.map((field, index) => {

                            return (

                                <Fragment key={field.id}>
                                    <Controller
                                        control={control}
                                        rules={{ required: true }}
                                        name={`keyPerformanceIndicators.${index}.name`}
                                        defaultValue={field.name}
                                        render={({ field }) => {
                                            return (
                                                <TextField
                                                    className="bg_white"
                                                    {...field}
                                                    label="KPI"
                                                    error={!!errors?.keyPerformanceIndicators?.[index]?.name}
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )
                                        }}
                                    />

                                    <Controller
                                        control={control}
                                        name={`keyPerformanceIndicators.${index}.owner`}
                                        defaultValue={field.owner}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange } }) => {
                                            return (
                                                <Autocomplete
                                                    value={allUsers.find(d => d?.userId == value?.userId) || null}
                                                    options={allUsers}
                                                    className="bg_white"
                                                    getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <>
                                                                        {fetchUsersLoader ? <CircularProgress color="inherit" size={20} /> : null}
                                                                        {params.InputProps.endAdornment}
                                                                    </>
                                                                ),
                                                            }}
                                                            label={t("SI.Owner")}
                                                            variant="outlined"
                                                            error={!!errors?.keyPerformanceIndicators?.[index]?.owner}
                                                        />
                                                    )}
                                                    onChange={(e, val) => {
                                                      
                                                        onChange(val);
                                                    }}
                                                    renderOption={(props, option) => (
                                                        <li {...props} key={option.userId}>
                                                            {`${option.first_name} ${option.last_name}`}
                                                        </li>
                                                    )}
                                                />
                                            )
                                        }
                                        }
                                    />

                                    <Controller
                                        control={control}
                                        name={`keyPerformanceIndicators.${index}.supervisor`}
                                        defaultValue={field.supervisor}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange } }) => (
                                            <Autocomplete
                                                value={allUsers.find(d => d?.userId === value?.userId) || null}
                                                options={allUsers}
                                                className="bg_white"
                                                getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {fetchUsersLoader ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                        label={t("SI.supervisor")}
                                                        variant="outlined"
                                                        error={!!errors?.keyPerformanceIndicators?.[index]?.owner}
                                                    />
                                                )}
                                                onChange={(e, val) => {
                                                    onChange(val);
                                                }}
                                                renderOption={(props, option) => (
                                                    <li {...props} key={option.userId}>
                                                        {`${option.first_name} ${option.last_name}`}
                                                    </li>
                                                )}
                                            />
                                        )}
                                    />

                                    <DeleteButon type="button" onClick={() => removeKPI(index)}>
                                        Remove
                                    </DeleteButon>
                                </Fragment>
                            )
                        })}
                        {newKpiFields.map((field, index) => {

                            return (

                                <Fragment key={field.id}>
                                    <Controller
                                        control={control}
                                        rules={{ required: true }}
                                        name={`keyPerformanceIndicators.${index}.name`}
                                        defaultValue={field.name}
                                        render={({ field }) => {
                                            return (
                                                <TextField
                                                    className="bg_white"
                                                    {...field}
                                                    label="KPI"
                                                    error={!!errors?.keyPerformanceIndicators?.[index]?.name}
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )
                                        }}
                                    />

                                    <Controller
                                        control={control}
                                        name={`keyPerformanceIndicators.${index}.owner`}
                                        defaultValue={field.owner}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange } }) => {
                                            return (
                                                <Autocomplete
                                                    value={allUsers.find(d => d?.userId == value?.userId) || null}
                                                    options={allUsers}
                                                    className="bg_white"
                                                    getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <>
                                                                        {fetchUsersLoader ? <CircularProgress color="inherit" size={20} /> : null}
                                                                        {params.InputProps.endAdornment}
                                                                    </>
                                                                ),
                                                            }}
                                                            label={t("SI.Owner")}
                                                            variant="outlined"
                                                            error={!!errors?.keyPerformanceIndicators?.[index]?.owner}
                                                        />
                                                    )}
                                                    onChange={(e, val) => {

                                                        onChange(val);
                                                    }}
                                                    renderOption={(props, option) => (
                                                        <li {...props} key={option.userId}>
                                                            {`${option.first_name} ${option.last_name}`}
                                                        </li>
                                                    )}
                                                />
                                            )
                                        }
                                        }
                                    />

                                    <Controller
                                        control={control}
                                        name={`keyPerformanceIndicators.${index}.supervisor`}
                                        defaultValue={field.supervisor}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange } }) => (
                                            <Autocomplete
                                                value={allUsers.find(d => d?.userId === value?.userId) || null}
                                                options={allUsers}
                                                className="bg_white"
                                                getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {fetchUsersLoader ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                        label={t("SI.supervisor")}
                                                        variant="outlined"
                                                        error={!!errors?.keyPerformanceIndicators?.[index]?.owner}
                                                    />
                                                )}
                                                onChange={(e, val) => {
                                                    onChange(val);
                                                }}
                                                renderOption={(props, option) => (
                                                    <li {...props} key={option.userId}>
                                                        {`${option.first_name} ${option.last_name}`}
                                                    </li>
                                                )}
                                            />
                                        )}
                                    />
                                    <div className="border">
                                        <DeleteButon type="button" onClick={() => removeNewKPI(index)} />
                                    </div>
                                </Fragment>
                            )
                        })} */}

                        <KPIFields
                            control={control}
                            fields={kpiFields}
                            remove={removeKPI}
                            errors={errors}
                            prefix="keyPerformanceIndicators"
                            allUsers={allUsers}
                            fetchUsersLoader={fetchUsersLoader}
                            setValue={setValue}
                            onDeleteKpi={onDeleteKpi}
                        />
                        <KPIFields
                            control={control}
                            fields={newKpiFields}
                            remove={removeNewKPI}
                            errors={errors}
                            prefix="newKPIs"
                            allUsers={allUsers}
                            fetchUsersLoader={fetchUsersLoader}
                            setValue={setValue}
                            onDeleteKpi={() => { }}
                        />


                    </div>
                    <br />

                    <AddMoreItem text={"Add More"} onClick={() => {
                        mode === "Create" ?
                            appendKPI({ name: "", owner: null, supervisor: null }) :
                            appendNewKPI({ name: "", siId: id, groupId, owner: null, supervisor: null })
                    }} />

                    {/* {type === "KPI" ? 
                <div className={style.radio_inputs_con}>
                    <div>
                        <StrategicImperativesDropDown
                            error={validation.siId}
                            groupId={formData.groupId}
                            value={formData?.siId}
                            onChange={(e) => {
                                setFormData({ ...formData, siId: e })
                                resetErrorFields()
                            }}
                        />
                    </div>

                </div>
             :
                    <>
                        <div className={style.radio_inputs_con}>
                            <div>
                                <div className={style.label}>{t("Priority")}:</div>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        value={formData?.priority}
                                        row
                                        className={style.radio_text}
                                        name="priority"
                                        onChange={(e) => {
                                            setFormData({ ...formData, priority: e.target.value })
                                            resetErrorFields()
                                        }}
                                    >
                                        {STRAT_PRIORITY.map((res, key) => {
                                            return (
                                                <CustomRadio label={res} key={key} value={res} />
                                            )
                                        })}
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <div>
                                <div className={style.label}>{t("SI.Measurement Type")}:</div>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        value={formData?.measurementType}
                                        className={style.radio_text}
                                        row
                                        name="measurementType"
                                        onChange={(e) => {
                                            setFormData({ ...formData, measurementType: e.target.value })
                                            resetErrorFields()
                                        }}
                                    >
                                        {STRAT_MEASUREMENT_TYPE.map((res, key) => {
                                            return (
                                                <FormControlLabel key={key} value={res} control={<Radio sx={{
                                                    '&, &.Mui-checked': {
                                                        color: 'rgba(82, 96, 109, 1)',
                                                    }
                                                }} />} label={res} />
                                            )
                                        })}
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <div>

                            </div>
                        </div>
                        <div className={style.calender_con}>
                            <div>
                                <CustomDatePicker
                                    disablePast error={validation.startDate} label={t("Start Date")} value={formData?.startDate}
                                    onChange={(e) => {
                                        setFormData({ ...formData, startDate: e ? new Date(e.toString()).toISOString() : null })
                                        resetErrorFields()
                                    }} />

                            </div>
                            <div>
                                <CustomDatePicker
                                    minDate={formData.startDate}
                                    disablePast error={validation.endDate} label={t("End Date")} value={formData?.endDate}
                                    onChange={(e) => {
                                        setFormData({ ...formData, endDate: e ? new Date(e).toISOString() : null })
                                        resetErrorFields()
                                    }
                                    } />

                            </div>
                            <div></div>
                        </div>
                    </>
                } */}
                </div>
                <br />
                <div className="end">
                    <button className="btn-primary">{t(mode)}</button>&nbsp;&nbsp;&nbsp;
                    <button type="button" className="btn-secondary" onClick={onClose}>{t("Cancel")}</button>
                </div>
            </form >


        </div >
    )
}