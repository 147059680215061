import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import React from 'react'

export const CustomSelect = ({ error, renderOption, label, keyLabel, loading, optionLabel, ...props }) => {
    return (
        <Autocomplete
            fullWidth
            getOptionLabel={(option) => option[optionLabel]}
            {...props}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    error={error}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            renderOption={(props, option) => {
                return (
                    <li {...props} key={option[keyLabel]}>
                        {option[renderOption]}
                    </li>
                );
            }}

        />
    )
}
