import axios from "../config/axiosInterceptor";
import { BASE_URL, API } from "./api"


export const getAssessmentReport = async (id) => {
    return await axios.get(BASE_URL + API.generateAssessmentReport + id)
}
export const generateOrganizationalSurveyAssessmentReport = async (id) => {
    return await axios.get(BASE_URL + API.generateOrganizationalSurveyAssessmentReport + id)
}

export const generateJobroleSurveyAssessmentReport = async (id) => {
    return await axios.get(BASE_URL + API.generateJobroleSurveyAssessmentReport + id)
}

export const generateIndividualSurveyAssessmentReport = async (id) => {
    return await axios.get(BASE_URL + API.generateIndividualSurveyAssessmentReport + id)
}

export const generateComparisonAssessmentReport = async (body) => {
    return await axios.post(BASE_URL + API.generateComparisonAssessmentReport, body)
}

export const fetchReport = async (assessmentId) => {
    return await axios.get(BASE_URL + API.fetchReport + assessmentId)
}

export const generateCombinedReport = async (body) => {
    return await axios.post(BASE_URL + API.generateCombinedReport, body)
}
