import React from 'react'
import style from ".././../../assets/css/components/home.module.css"
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'

export const CardLayout = ({ children, noData, heading, noDataMsg, viewRoute }) => {
    let { t } = useTranslation()
    let navigate = useNavigate()
    return (
        <div className={style.home_card_layout}>
            <div className={style.card_header}>
                <div className={style.card_heading}>{heading}</div>
                {/* <div className={style.view_all_btn} onClick={() => navigate(viewRoute)}>{t("View All")}</div> */}
            </div>

            <div className={style.children_container}>

                {noData ? <div className='body_1 text-center'>{noDataMsg || t("noDataAvailable")}</div> : children}

            </div>
        </div>
    )
}
