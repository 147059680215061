import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import style from "../../assets/css/views/category.module.css"
import { BreadCrum } from '../../components/Common/BreadCrum'
import { useTranslation } from 'react-i18next'
import { CategoryPreviewCollapseTab } from '../../components/Common/Category/CategoryPreviewCollapseTab'
import { loader, toast } from '../../utils'
import { Link } from 'react-router-dom'
import { fetchCategoriesByGroupId } from "../../api/category";
import { AddCircle } from '@mui/icons-material'
import { isUserCreateUpdateData } from '../../utils'


export const PreviewCategory = () => {

    let { id } = useParams()
    const { t } = useTranslation()
    const [categoryData, setCategoryData] = useState([])
    const [groupName, setGroupName] = useState("")
    useEffect(() => {
        getCategoryByGroupId()
    }, [])

    async function getCategoryByGroupId() {
        try {
            loader.start()
            let res = await fetchCategoriesByGroupId(id)
            setCategoryData(res.data.response)
            setGroupName(res.data.groupName)
        } catch (error) {
            toast.error(error?.response?.data?.error)
        }
        finally {
            loader.stop()
        }
    }
    let breadCrumData = [
        {
            text: t("category.category"),
            path: "/category"
        },
        {
            text: t("Preview"),
        },


    ]
    function onDeleteCategory(id) {
        setCategoryData(pre => pre.filter(c => c._id !== id))
    }

    return (
        <div className={` padding_22_44`}>

            <BreadCrum dataArr={breadCrumData} />
            <br />
            <div className={`${style.preview_category_con}`}>
                <div className={style.heading}>
                    {groupName}
                </div>
                {categoryData?.map((res, key) => {
                    return (
                        <CategoryPreviewCollapseTab onDeleteCategory={onDeleteCategory} data={res} key={key} />
                    )
                })}

                {isUserCreateUpdateData() ?
                    <div className="add_more" >
                        <br />
                        <Link className='unstyled' to={`/category/create?groupId=${id}`}>
                            <span className="pointer flex align_centers">
                                <AddCircle />&nbsp;
                                <span>
                                    {t("category.Add Category")}
                                </span>
                            </span>
                        </Link>
                    </div>
                    : null}
            </div>
            {isUserCreateUpdateData() ?
                <div className="end">
                    <Link to={"/category"}>
                        <button className="btn-secondary">{t("Cancel")}</button>
                    </Link>
                </div>
                : null}
        </div>
    )
}
