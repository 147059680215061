import { ASSESSMENT_STATUS, ASSESSMENT_TYPES, DATA_CREATE_UPDATE_ACCESS_ROLES, REPORTS_TABS } from "../constants/constants"
import { startLoader, stopLoader } from "../store/Slices/LoaderSlice"
import { stopToast, toastaction } from "../store/Slices/ToastSlice"
import { store } from "../store/store"
import { useNavigate } from "react-router";
import { setTokenStatus } from "../store/Slices/TokenExpireSlice";
import moment from "moment";

export const loader = {
    start: () => store.dispatch(startLoader()),
    stop: () => store.dispatch(stopLoader())
}

export const toast = {
    success: (msg) => store.dispatch(toastaction({ type: "success", msg })),
    error: (msg) => store.dispatch(toastaction({ type: "error", msg })),
    warning: (msg) => store.dispatch(toastaction({ type: "warning", msg })),
    info: (msg) => store.dispatch(toastaction({ type: "info", msg })),
    stop: () => store.dispatch(stopToast())
}

export const findNextAssessmentStatus = (currentStatus) => {
    let currentStatusIndex = ASSESSMENT_STATUS.indexOf(currentStatus)
    return currentStatusIndex === ASSESSMENT_STATUS.length - 1 || currentStatusIndex < 0 ? currentStatus : ASSESSMENT_STATUS[currentStatusIndex + 1]
}

export const currentUserRole = () => {
    let role = null
    let userData = localStorage.getItem("userData");
    if (userData) {
        role = JSON.parse(userData)?.role?.item_name;
    }
    return role;
}

export const currentUserPrimaryGroupId = () => {
    let primaryGroupId = null
    let userData = localStorage.getItem("userData");
    if (userData) {
        primaryGroupId = JSON.parse(userData)?.user?.primary_group;
    }
    return primaryGroupId;
}

export const currentUser = () => {
    const user = JSON.parse(localStorage.getItem("userData"));
    return user;
}

export const isUserCreateUpdateData = () => {
    return DATA_CREATE_UPDATE_ACCESS_ROLES.some(e => e === currentUserRole())
}

export const logout = (navigate) => {
    store.dispatch(setTokenStatus(true))
    localStorage.clear()
    navigate("/")
}


export const PaginateData = (page, itemsPerPage, allData) => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedData = allData.slice(startIndex, endIndex);
    return paginatedData;
}


export const changeDateFormat = (date) => {

    return moment.parseZone(date).format("DD-MM-YYYY");

    const dateObject = new Date(date);
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();
    return `${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}-${year}`
}




export let selectInputStyle = {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(45, 58, 140, 1)',
    },
}
export function assessmentStatusClass(key) {
    switch (key) {
        case "Drafts":
            return "drafts"
        case "In Review":
            return "in_review"
        case "Ready To Distribute":
            return "ready_to_distribute"
        case "Live":
            return "live"
        case "Completed":
            return "completed"
        case "In Progress":
            return "in_progress"
        case "New":
            return "new_status"
        default:
            return key
    }
}
export function changeAssessmentName(name) {
    switch (name) {
        case "Ready To Distribute":
            return "Distribute"
        default:
            return name
    }
}

export const trimFileName = (name, startChars, endChars) => {
    const extIndex = name.lastIndexOf('.');
    const ext = name.slice(extIndex);
    const base = name.slice(0, extIndex);
    return base.length > startChars + endChars
        ? `${base.slice(0, startChars)}...${base.slice(-endChars)}${ext}`
        : name;
};


export function formatChartdata(data, reportType, comparisonType) {
    // comparisonType is used form individual report
    // comparision type can be (Reviewers, JobRole, Combined)
    let reportFormattedData = {
        circularChartData: [],
        tableChartData: []
    }

    if (data?.reports?.length > 0) {



        let [_, { value: Reviewers }, { value: JobRole }, { value: Combined }] = REPORTS_TABS
        let isReportForReviewerComparison = (comparisonType === Reviewers)
        let isReportForJobroleComparison = comparisonType === JobRole
        let isReportForCombinedComparison = comparisonType === Combined

        console.log(isReportForJobroleComparison, "plplplplplplplplplpl")

        // set circular chart data 
        reportFormattedData.circularChartData = data.reports.map(res => {
            return ({
                name: res?.siObj?.name || res?.categoryObj?.name,
                value1: res?.score,
                value2: res?.baselineScore ?? (
                    (isReportForReviewerComparison ? (res?.reviewersScore ?? 0) : null) ??
                    (isReportForJobroleComparison ? res?.jobRoleScore ?? 0 : null) ??
                    (isReportForCombinedComparison ? (res?.reviewersScore ?? 0) : null)
                ),
                value3: isReportForCombinedComparison ? res?.jobRoleScore ?? 0 : null,
                itemId: res?.siObj?._id || res?.categoryObj?._id,
                active: false
            });
        });

        switch (data.assessmentType) {
            // format chart data for organizational type assessment 
            case ASSESSMENT_TYPES.organizational:
                reportFormattedData.tableChartData = data.reports.flatMap(e => e.KPIs.map(d => {
                    return ({
                        name: d?.kpiObj?.name,
                        // if comparisonType is valid it means we are using this function in  individual report
                        heading1: comparisonType ? "Individual" : "Baseline",
                        heading2: d?.baselineScore ? "Pulse" : "",
                        value1: d?.score,
                        value2: d?.baselineScore,
                        itemId: d?.kpiObj?._id,
                        parentId: d?.kpiObj?.siId,
                    })
                }
                ));

                if (reportType == "SingleAssessment") {
                    reportFormattedData.tableData = data.reports
                        .flatMap(k => k.KPIs.flatMap(d => d.questions.flatMap(q => {
                            return ({
                                name: d.kpiObj.name,
                                itemId: d.kpiObj._id,
                                question: q.questionObj?.question,
                                score: d.score
                            })
                        }
                        )))
                }

                return reportFormattedData;
                break;

            // format chart data for jobrole type assessment 
            case ASSESSMENT_TYPES.jobRole:
            case ASSESSMENT_TYPES.individual:
                // reportFormattedData.tableChartData = data.reports.flatMap(e => e.competencies.map(d => {
                //     return ({
                //         name: d.competencyObj.name,
                //         value1: d.score,
                //         // if comparisonType is valid it means we are using this function in  individual report
                //         heading1: comparisonType ? "Individual" : "Baseline",
                //         heading2: comparisonType ?
                //             comparisonType == "Combined" ? "Reviewers" : comparisonType : "Pulse",

                //         heading3: isReportForCombinedComparison && "Job Role",
                //         value3: isReportForCombinedComparison ? d?.jobRoleScore : null,
                //         value2: d?.baselineScore ||
                //             (isReportForReviewerComparison ? (d?.reviewersScore || 0) : null) ||
                //             (isReportForJobroleComparison ? d?.jobRoleScore : 0) ||
                //             (isReportForCombinedComparison ? (d?.reviewersScore || 0) : null),

                //         itemId: d.competencyObj._id,
                //         parentId: d.competencyObj.categoryId
                //     })
                // }
                // ))
                reportFormattedData.tableChartData = data.reports.flatMap(e =>
                    e.competencies.map(d => {
                        return {
                            name: d.competencyObj.name,
                            value1: d.score,
                            // if comparisonType is valid it means we are using this function in an individual report
                            heading1: comparisonType ? "Individual" : "Baseline",
                            heading2: comparisonType
                                ? (comparisonType === "Combined" ? "Reviewers" : comparisonType)
                                : "Pulse",
                            heading3: isReportForCombinedComparison && "Job Role",
                            value3: isReportForCombinedComparison ? d?.jobRoleScore ?? null : null,
                            value2: d?.baselineScore ??
                                (isReportForReviewerComparison ? (d?.reviewersScore ?? 0) : null) ??
                                (isReportForJobroleComparison ? d?.jobRoleScore ?? 0 : null) ??
                                (isReportForCombinedComparison ? (d?.reviewersScore ?? 0) : null),
                            itemId: d.competencyObj._id,
                            parentId: d.competencyObj.categoryId
                        };
                    })
                );

                if (reportType == "SingleAssessment") {
                    reportFormattedData.tableData = data.reports
                        .flatMap(comp => comp.competencies.flatMap(d => d.questions.flatMap(question => {
                            return ({
                                name: d.competencyObj.name,
                                itemId: d.competencyObj._id,
                                question: question.questionObj.question,
                                score: question.score
                            }
                            )
                        }
                        )))
                }

        }
    }
    else {
        // throw new Error("No Reports found")
    }
    return reportFormattedData
}



export const makeHashMapWithKey = (options, key = '_id') => {
    return new Map(options.map(option => [option[key], option]))
};