import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { TableContainer, TableHeader } from '../../components/Common/TableContainer'
import style from "../../assets/css/views/developmentPlan.module.css"
import { SearchInput } from '../../components/Inputs/SearchInput'
import { CustomPagination } from '../../components/Common/CustomPagination'
import { fetchGroups } from '../../api/StrategicImperatives'
import { ViewButton } from '../../components/Buttons/ViewButton'
import { loader } from '../../utils'
import { Accordion } from '../../components/Common/Accordion'
import { searchDataByNestedKey } from '../../utils/search'

export const DevelopmentPlan = () => {

    const { t } = useTranslation()
    const [searchValue, setSearchValue] = useState("")
    const [allData, setAllData] = useState([]);
    const [filteredData, setFilteredData] = useState([])

    const [error, setError] = useState({
        error: false,
        errorMsg: "No data available"
    })

    const [page, setPage] = useState(1)
    const record_per_page = 10;
    const [totalDataCount, setTotalDataCount] = useState(0)

    useEffect(() => {
        getAllDP()
    }, [])

    async function getAllDP() {
        try {
            loader.start()
            let body = {
                collectionType: "DevelopmentPlans",
                page,
                record_per_page
            }
            let res = await fetchGroups(body)

            setAllData(res?.data?.response)
        } catch (error) {
            console.log(error)
        }
        finally {
            loader.stop()
        }
    }

    useEffect(() => {
        setFilteredData(searchDataByNestedKey(allData, searchValue, "data", "name"))
    }, [allData, searchValue])

    return (


        <div className={`${style.development_landing_page_con} padding_22_44`}>

            <div className="between">
                <div className="h1">{t("developmentPlan.developmentPlans")}</div>
                <div>
                    <Link to={"create"}>
                        <button className="btn-primary">{t("Create New")}</button>
                    </Link>
                </div>
            </div>

            <TableHeader>
                <div className={style.search_inp}>
                    <SearchInput value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder={t("Search")} />
                </div>
            </TableHeader>

            <TableContainer>
                <div className={style.scroll_part}  >
                    <table>
                        <thead>
                            <tr>
                                <th>{t("Group Name")}</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>

                    </table>

                    {filteredData.map((res, ind) => {
                        return (
                            <Accordion title={res?.groupName}
                                open={ind === 0}
                                actionTemplate={
                                    <Link to={`preview/${res.groupId}`}>
                                        <ViewButton />
                                    </Link>
                                }>
                                <br />

                                {res?.data?.map((dp) => {
                                    return (
                                        <div className={style.table_dark_bg_row}>{dp.name}</div>
                                    )
                                })}

                            </Accordion>
                        )
                    })}
                </div>
                <CustomPagination page={page - 1} pageChange={setPage} count={totalDataCount} perPage={record_per_page} />
            </TableContainer>
        </div>
    )
}
