import React from 'react'
import { BreadCrum } from '../../components/Common/BreadCrum'
import { useTranslation } from 'react-i18next'
import { CategoryForm } from '../../components/Common/Category/CategoryForm';
import { createCategory } from '../../api/category';
import { loader, toast } from '../../utils';
import { useLocation, useNavigate } from 'react-router';

export const CreateCategory = () => {
    const { t } = useTranslation();
    const groupId = new URLSearchParams(useLocation().search).get("groupId");
    let navigate = useNavigate()
    const categoryData = {
        name: "",
        description: "",
        groupId: parseInt(groupId),
        competencyIds: [],
        unsetCompetencyIds: [],
    }
    const breadCrumData = [
        {
            text: t("category.category"),
            path: "/category"
        },
        {
            text: t("Create"),
        },
    ]
    async function handleCreateCategory(data) {
        try {
            loader.start()
            let res = await createCategory(data)
            toast.success(res?.data?.message)
            navigate("/category")
        } catch (error) {
            toast.error(error?.response?.data?.error)
        }
        finally {
            loader.stop()
        }
    }

    return (
        <div className="padding_22_44">
            <BreadCrum dataArr={breadCrumData} />
            <br />
            <CategoryForm mode={"create"} selectedCompetency={[]} data={categoryData} onSubmit={handleCreateCategory} />
        </div>
    )
}
