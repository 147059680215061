import React, { useState } from "react";
import { ConfirmationDialog } from "../Common/ConfirmationDialog";

export const ConfirmationButton = ({
  onClick,
  id,
  buttonText,
  confirmation,
  heading,
  text,
  ...props
}) => {
  let [dialog, setDialog] = useState(false);

  return (
    <>
      <button
        onClick={() => {
          confirmation ? setDialog(true) : onClick(id ? id : null);
        }}
        {...props}
        className="btn-primary"
      >
        {buttonText}
      </button>
      <ConfirmationDialog
        onSubmit={() => {
          onClick(id ? id : null);
          setDialog(false);
        }}
        onCancel={() => setDialog(false)}
        heading={heading}
        text={text}
        open={dialog}
      />
    </>
  );
};
