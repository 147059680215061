import React, { Fragment } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { DeleteButon } from "../../Buttons/DeleteButton";
import { EditButton } from "../../Buttons/EditButton";
import style from "../../../assets/css/components/group.module.css";
import { AddCircle } from "@mui/icons-material";
import { deleteSubGroup } from "../../../api/Group";
import { isUserCreateUpdateData, loader, toast, currentUser } from "../../../utils";
import { useTranslation } from "react-i18next";
import { PreviewCollapse } from "./PreviewCollapse";
import { ADMIN_ROLE } from "../../../constants/constants";
import { ViewButton } from "../../Buttons/ViewButton";
import { ViewPrimaryButton } from "../../Buttons/ViewPrimaryButton";

export const ViewSubGroupComp = ({ data, setData, groupName }) => {
  let params = useParams();
  let { t } = useTranslation()
  let navigate = useNavigate();

  function addSubGroup() {
    navigate(`/group/createSubGroup?type=recreate&groupId=${params.id}`);
  }
  async function deletesubgroup(e) {
    try {
      loader.start();
      let res = await deleteSubGroup(e);
      toast.success(res?.data?.message);

      setData((pre) => pre.filter((r) => r._id !== e));
      loader.stop();
    } catch (err) {
      loader.stop();
      toast.error(err?.response?.data?.error);
    }
  }
  function editBtnClick(id) {
    navigate(`/group/editSubgroup/${id}`);
  }

  return (
    <div>
      <div className={style.main_white_con}>
        <div className="h1">{groupName || ""}</div>
        <br />
        {data.map((res, key) => {
          return (
            <Fragment key={key}>
              <div className={style.content_con}>
                <div className={style.flex_con}>

                  <div className={style.sub_group_content_con}>
                    <div className={`${style.c1}`}>
                      <div className={`text-secondary ${style.head} `}>
                        {t("Sub Group")}-{key + 1}
                      </div>
                      <div className={style.content}>{res.name}</div>
                    </div>
                    <div className={`${style.c1}`}>
                      <div className={`text-secondary ${style.head}`}>{t("Members")}</div>
                      <div className={style.content}>{res.members.length}</div>
                    </div>
                    <div className={`${style.description_sec}`}>
                      <div className={`text-secondary ${style.head}`}>
                        {t("Description")}
                      </div>
                      <div className={style.content}>{res.description}</div>
                    </div>
                  </div>
                  {isUserCreateUpdateData() ?
                    <div className={style.button_con}>
                      <ViewPrimaryButton
                        onClick={() => navigate(`/group/editSubgroup/${res._id}?type=preview`)}
                      />
                      &nbsp;&nbsp;
                      <EditButton
                        confirmation={res?.isMappedInAssessment}
                        text={t("confirmationDialog.editSubGroup")}
                        heading={t("groupManagement.Edit Sub Group")}
                        onClick={() => editBtnClick(res._id)}
                      />
                      &nbsp;&nbsp;
                      <DeleteButon
                        heading={t("Delete Sub Group")}
                        text={t("confirmationDialog.deleteSubGroup")}
                        confirmation={true}
                        onDelete={deletesubgroup}
                        id={res._id}
                      />
                    </div> : null}
                </div>

                {/* <div className={style.preview_collapse_con}>
                  <PreviewCollapse index={key} data={res} />
                </div> */}

              </div>
            </Fragment>
          );
        })}
        {isUserCreateUpdateData() ?
          <div className={"add_more"}>
            <span className="pointer flex align_centers" onClick={addSubGroup}>
              <AddCircle />
              &nbsp;
              <span>{t("groupManagement.Add Sub Group")}</span>
            </span>
          </div> : null}

      </div>
      <br />
      {
        currentUser().role.item_name == ADMIN_ROLE ?
          <div className="end">
            <Link to={"/group"}>
              <button className="btn-secondary">{t("Close")}</button>
            </Link>
          </div> : null
      }

    </div >
  );
};
