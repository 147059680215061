import React from 'react'
import style from "./../../../assets/css/components/home.module.css"
import { assessmentStatusClass, changeAssessmentName } from '../../../utils'
import { CustomProgressLine } from '../CustomProgressLine'
import { useNavigate } from 'react-router'

export const WhiteCardRow = ({ status, text1, text2, progress, value, viewRoute }) => {
    let navigate = useNavigate()
    return (
        <div className={`${style.white_card_Row_con}`}>
            <div className={style.view_btn} onClick={() => navigate(viewRoute)}>
                <div className={style.heading1}>{text1}</div>
                <div className={style.heading2} dangerouslySetInnerHTML={{ __html: text2 }}></div>
            </div>
            {status &&
                <div className={`${style.status_con}`}>
                    <div className={`${assessmentStatusClass(status)} assessment_status`}></div>&nbsp;&nbsp;
                    <div className={style.status_text}>{changeAssessmentName(status)}</div>
                </div>
            }
            {value &&
                <div className={`${style.value_con}`}>
                    <div className={style.value_text}>{value}</div>
                </div>
            }
            {/* {progress &&
                <div className={`${style.progress_bar_con}`}>

                    <div className='w-100'>
                        <CustomProgressLine value={progress} variant={"primary"} />
                    </div>
                </div>
            } */}

        </div>
    )
}
