import { useLocation, useNavigate, useParams } from "react-router"
import React, { useEffect, useRef, useState } from "react"
import { editKPI, fetchKPI, fetchKPIById } from "../../api/KPI"
import { KPISIForm } from "../../components/Common/KPI&SICommonComp/KPISIForm"
import { loader, toast } from "../../utils"
import { BreadCrum } from "../../components/Common/BreadCrum"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
export const EditKPI = () => {
    const tokenStatus = useSelector((state) => state.tokenStatus);
    let params = useParams()
    let { t } = useTranslation()
    let [KPIData, setKPIData] = useState({})
    let [dataFetched, setDataFetched] = useState(false)
    const groupName = new URLSearchParams(useLocation().search).get('groupName');
    const addMore = new URLSearchParams(useLocation().search).get('addMore');

    let navigate = useNavigate()

    let getkpibyid = useRef(getKPIById)

    useEffect(() => {
        getkpibyid.current()
    }, [getkpibyid,tokenStatus])

    async function getKPIById() {
        try {
            loader.start()
            let res = await fetchKPI({ _id: params.id })
            loader.stop()
            let { name, groupId, siId, type, _id } = res.data.response
            setKPIData({ name, groupId, siId, type, _id, groupName })
            setDataFetched(true)
        }
        catch (err) {
            loader.stop()
            toast.error(err?.response?.data?.error)
        }
    }

    async function formSubmit(data) {
        delete data.groupName
        try {
            loader.start()
            let res = await editKPI(data)
            loader.stop()
            toast.success(res.data.message)
            navigate(`/KPI/preview/${data.groupId}?${addMore ? "&addMore=true" : ""}`)
        }
        catch (err) {
            loader.stop()
            toast.error(err?.response?.data?.error)
        }
    }

    function closeForm() {
        navigate(`/KPI/preview/${KPIData.groupId}`)
    }
    let breadCrumData = [
        {
            text: t("KPI.Key Performance Indicators"),
            path: "/KPI"
        },
        {
            text: t("Edit"),
        },
    ]

    return (
        <div className="padding_22_44">
            <BreadCrum dataArr={breadCrumData} />
            <br />
            {
                dataFetched ?
                    <KPISIForm mode={"Update"} groupDisable={true} onClose={closeForm} onSubmit={formSubmit} type={"KPI"} data={KPIData} />
                    : null}
        </div>
    )
}