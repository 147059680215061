import { ASSESSMENT_STATUS, DATA_CREATE_UPDATE_ACCESS_ROLES } from "../constants/constants"
import { startLoader, stopLoader } from "../store/Slices/LoaderSlice"
import { stopToast, toastaction } from "../store/Slices/ToastSlice"
import { store } from "../store/store"
import { useNavigate } from "react-router";
import { setTokenStatus } from "../store/Slices/TokenExpireSlice";

export const loader = {
    start: () => store.dispatch(startLoader()),
    stop: () => store.dispatch(stopLoader())
}

export const toast = {
    success: (msg) => store.dispatch(toastaction({ type: "success", msg })),
    error: (msg) => store.dispatch(toastaction({ type: "error", msg })),
    warning: (msg) => store.dispatch(toastaction({ type: "warning", msg })),
    info: (msg) => store.dispatch(toastaction({ type: "info", msg })),
    stop: () => store.dispatch(stopToast())
}

export const findNextAssessmentStatus = (currentStatus) => {
    let currentStatusIndex = ASSESSMENT_STATUS.indexOf(currentStatus)
    return currentStatusIndex === ASSESSMENT_STATUS.length - 1 || currentStatusIndex < 0 ? currentStatus : ASSESSMENT_STATUS[currentStatusIndex + 1]
}

export const currentUserRole = () => {
    let role = null
    let userData = localStorage.getItem("userData");
    if (userData) {
        role = JSON.parse(userData)?.role?.item_name;
    }
    return role;
}

export const currentUserPrimaryGroupId = () => {
    let primaryGroupId = null
    let userData = localStorage.getItem("userData");
    if (userData) {
        primaryGroupId = JSON.parse(userData)?.user?.primary_group;
    }
    return primaryGroupId;
}

export const currentUser = () => {
    const user = JSON.parse(localStorage.getItem("userData"));
    return user;
}

export const isUserCreateUpdateData = () => {
    return DATA_CREATE_UPDATE_ACCESS_ROLES.some(e => e === currentUserRole())
}

export const logout = (navigate) => {
    store.dispatch(setTokenStatus(true))
    localStorage.clear()
    navigate("/")
}


export const PaginateData = (page, itemsPerPage, allData) => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedData = allData.slice(startIndex, endIndex);
    return paginatedData;
}


export const changeDateFormat = (date) => {
    const dateObject = new Date(date);
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();
    return `${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}-${year}`
}
export let selectInputStyle = {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(45, 58, 140, 1)',
    },
}
export function assessmentStatusClass(key) {
    switch (key) {
        case "Drafts":
            return "drafts"
        case "In Review":
            return "in_review"
        case "Ready To Distribute":
            return "ready_to_distribute"
        case "Live":
            return "live"
        case "Completed":
            return "completed"
        case "In Progress":
            return "in_progress"
        case "New":
            return "new_status"
        default:
            return key
    }
}
export function changeAssessmentName(name) {
    switch (name) {
        case "Ready To Distribute":
            return "Distribute"
        default:
            return name
    }
}