import { CustomPagination } from "../CustomPagination";
import { TableContainer, TableHeader } from "../TableContainer";
import { CustomCheckbox } from "../../Inputs/CustomCheckbox";
import style from "../../../assets/css/components/group.module.css";
import { SearchInput } from "../../Inputs/SearchInput";
import React, { useEffect, useState } from "react";
import _ from "underscore";
import { searchDataWithKey, searchDataWithMultipleKeys } from "../../../utils/search";
import { getUsersByGroupId } from "../../../api/user";
import { LinearProgress } from "@mui/material";
import { PaginateData } from "../../../utils";
import { toast } from "../../../utils";
import { useTranslation } from "react-i18next";
import { NoTableData } from "../NoTableData";

export const UserListing = ({ value, onChange, groupId, type }) => {
  let [all, setAll] = useState([]);
  let { t } = useTranslation();
  let [searchValue, setSearchValue] = useState("");
  let [fechUserloader, setFetchUserLoader] = useState(false);
  let [filteredData, setFilteredData] = useState([]);
  let [page, setPage] = useState(1);
  let [allData, setAllData] = useState([]);
  let per_page_data = 20;

  let [error, setError] = useState({
    error: true,
    errorMessage: "No users",
  });
  let [allUsers, setAllUsers] = useState([]);

  async function getAllUsers(id) {
    setFetchUserLoader(true);
    setError({
      error: false,
      errorMessage: "",
    });
    try {
      let res = await getUsersByGroupId(id);
      if (res.data.success) {

        let allUsers = res.data.users.filter((e) => e.role === "user").sort((a, b) => value.some(ee => ee.userId == b.id) - value.some(ee => ee.userId == a.id)).map(e => ({ ...e, fullName: e.userProfile.firstname + " " + e.userProfile.lastname }))
        console.log(allUsers, "llllllll")
        // if (type) {
        //   // allUsers.filter()
        //   allUsers = allUsers.filter((e) =>
        //     value.some((d) => d.userId === e.id),
        //   );
        // }
        setAllData(allUsers);

        let paginateData = PaginateData(page, per_page_data, allUsers);
        setAllUsers(
          paginateData.sort(
            (a, b) =>
              value.some((e) => e.userId == b.id) -
              value.some((e) => e.userId == a.id),
          ),
        );
        setFilteredData(
          allUsers.map((e) => ({ ...e, id: Number(e.id) })),
        );
        setAll(allUsers.map((e) => ({ ...e, id: Number(e.id) })));
      } else {
        setError({
          error: true,
          errorMessage: res?.data?.message,
        });
        toast.error(res?.data?.message);
      }
      setFetchUserLoader(false);
    } catch (err) {
      setFetchUserLoader(false);
      setError({
        error: true,
        errorMessage: err?.response?.data?.error,
      });
      // toast.error(err?.response?.data?.error)
    }
  }

  useEffect(() => {
    if (groupId) {
      getAllUsers(groupId);
      setPage(1);
      setSearchValue("");
      setAllUsers([]);
    }
  }, [groupId]);

  useEffect(() => {
    let paginateData = PaginateData(page, per_page_data, filteredData);
    setAllUsers(
      paginateData.sort(
        (a, b) =>
          value.some((e) => e.userId == b.id) -
          value.some((e) => e.userId == a.id),
      ),
    );
  }, [page, filteredData, per_page_data]);

  function checkBoxChange(e, user) {
    if (type !== "preview") {
      if (e.target.checked) {
        onChange([
          ...value,
          {
            userId: user.id,
            email: user.email,
            first_name: user?.userProfile?.firstname || null,
            last_name: user?.userProfile?.lastname || null,
            job_role: user?.job_role_label || null,
            jobRoleId: user?.job_role_id || null
          },
        ]);
      } else {
        onChange(value.filter((e) => e.userId !== user.id));
      }
    }
  }

  function searchUser(e) {
    setSearchValue(e.target.value);
    // let rr = searchDataWithKey("email", all, e.target.value);
    let rr = searchDataWithMultipleKeys(["email", "fullName"], all, e.target.value);



    let paginateData = PaginateData(page, per_page_data, rr);
    setAllUsers(
      paginateData.sort(
        (a, b) =>
          value.some((e) => e.userId == b.id) -
          value.some((e) => e.userId == a.id),
      ),
    );
    setFilteredData(rr.map((e) => ({ ...e, id: Number(e.id) })));
  }

  return (
    <div className={style.userlisting_main_con}>
      {/* <TableHeader>
                <div className="between">
                    <SearchInput placeholder={t("Search")} onChange={searchUsers} className={style.search_inp} />

                </div>
            </TableHeader> */}
      {fechUserloader ? (
        <div>
          <LinearProgress />
        </div>
      ) : (
        <>
          <TableHeader>
            <div className={style.search_input}>
              <SearchInput
                value={searchValue}
                onChange={searchUser}
                placeholder={`${t("Search")}`}
              />
            </div>
          </TableHeader>

          <TableContainer>
            <div className={style.table_con}>
              <table>
                <thead>
                  <tr>
                    <th>
                      <CustomCheckbox
                        checked={
                          value.length !== 0 &&
                          allUsers.every((v) =>
                            value.some((e) => v.id === e.userId),
                          )
                        }
                        onChange={(e) => {
                          e.target.checked
                            ? type !== "preview" &&
                            onChange(
                              allUsers.map((user) => ({
                                userId: user.id,
                                email: user.email,
                                name: user.username,
                              })),
                            )
                            : type !== "preview" && onChange([]);
                        }}
                      />
                    </th>
                    <th>{t("groupManagement.Members Name")}</th>
                    <th>{t("Email ID")}</th>
                    <th></th>
                  </tr>
                </thead>
                {!error.error && (
                  <tbody>
                    {allUsers &&
                      allUsers.map((res, key) => {
                        return (
                          <tr
                            key={key}
                            className={`${value.some((e) => e.userId === res.id) ? "table_selected_row" : ""}`}
                          >
                            <td>
                              <div>
                                <CustomCheckbox
                                  checked={value.some(
                                    (e) => e.userId === res.id,
                                  )}
                                  onChange={(e) => checkBoxChange(e, res)}
                                />
                              </div>
                            </td>
                            <td>
                              <div>{res?.fullName}</div>
                            </td>
                            <td>
                              <div>{res.email}</div>
                            </td>
                            <td>
                              <div></div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                )}
              </table>
              {error.error && <NoTableData text={error.errorMessage} />}
            </div>
            <CustomPagination
              page={page - 1}
              pageChange={(e) => setPage(e)}
              count={filteredData.length}
              perPage={per_page_data}
            />
          </TableContainer>
        </>
      )}
    </div>
  );
};
