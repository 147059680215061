// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tCCgPMdidvNCs8SHTkXD {


    .dYB5QoOfSZ1k0n3kr10v {
        padding: 40px;
        margin-top: 20px;
        background-color: rgba(239, 243, 252, 1);

        .SaeWkDKBFEHzo3WaiWtA {
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin: 0px;
            gap: 30px;

        }
    }
}

.bi1A5WgFKQZvkJhoeMd6 {
    background-color: white;
    margin-bottom: 8px;
    padding: 20px;

    .kJSAoQiaxf0GgK7sA2gL {
        color: rgba(62, 76, 89, 1);
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        margin-bottom: 20px;
    }
}

.pXt0kZYzWxwWxJRmMt93 {
    border-radius: 4px;
    background: var(--Light-1, #EFF3FC);
    padding: 15px 20px;
    margin-bottom: 8px;

    .OZHU94cJooEmLDPrQ4Mb {
        margin-top: 10px;
        margin-left: 34px;
    }

    .YmUB4nbNObDp9xmRJgo4 {
        margin-left: 34px;
        color: var(--Grey-1, #3E4C59);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-top: 5px;
        letter-spacing: 0.5px;
    }
}`, "",{"version":3,"sources":["webpack://./src/assets/css/components/jobRole.module.css"],"names":[],"mappings":"AAAA;;;IAGI;QACI,aAAa;QACb,gBAAgB;QAChB,wCAAwC;;QAExC;YACI,aAAa;YACb,8BAA8B;YAC9B,WAAW;YACX,SAAS;;QAEb;IACJ;AACJ;;AAEA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,aAAa;;IAEb;QACI,0BAA0B;QAC1B,eAAe;QACf,kBAAkB;QAClB,gBAAgB;QAChB,iBAAiB;QACjB,mBAAmB;IACvB;AACJ;;AAEA;IACI,kBAAkB;IAClB,mCAAmC;IACnC,kBAAkB;IAClB,kBAAkB;;IAElB;QACI,gBAAgB;QAChB,iBAAiB;IACrB;;IAEA;QACI,iBAAiB;QACjB,6BAA6B;QAC7B,eAAe;QACf,kBAAkB;QAClB,gBAAgB;QAChB,iBAAiB;QACjB,eAAe;QACf,qBAAqB;IACzB;AACJ","sourcesContent":[".jobRole_form_con {\r\n\r\n\r\n    .jobrole_dard_bg {\r\n        padding: 40px;\r\n        margin-top: 20px;\r\n        background-color: rgba(239, 243, 252, 1);\r\n\r\n        .jobrole_dard_bg_grid_con {\r\n            display: grid;\r\n            grid-template-columns: 1fr 1fr;\r\n            margin: 0px;\r\n            gap: 30px;\r\n\r\n        }\r\n    }\r\n}\r\n\r\n.preview_jobrole_con {\r\n    background-color: white;\r\n    margin-bottom: 8px;\r\n    padding: 20px;\r\n\r\n    .heading {\r\n        color: rgba(62, 76, 89, 1);\r\n        font-size: 20px;\r\n        font-style: normal;\r\n        font-weight: 500;\r\n        line-height: 28px;\r\n        margin-bottom: 20px;\r\n    }\r\n}\r\n\r\n.jobrole_preview_collapse_row {\r\n    border-radius: 4px;\r\n    background: var(--Light-1, #EFF3FC);\r\n    padding: 15px 20px;\r\n    margin-bottom: 8px;\r\n\r\n    .jobrole_label {\r\n        margin-top: 10px;\r\n        margin-left: 34px;\r\n    }\r\n\r\n    .jobrole_list {\r\n        margin-left: 34px;\r\n        color: var(--Grey-1, #3E4C59);\r\n        font-size: 16px;\r\n        font-style: normal;\r\n        font-weight: 400;\r\n        line-height: 24px;\r\n        margin-top: 5px;\r\n        letter-spacing: 0.5px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"jobRole_form_con": `tCCgPMdidvNCs8SHTkXD`,
	"jobrole_dard_bg": `dYB5QoOfSZ1k0n3kr10v`,
	"jobrole_dard_bg_grid_con": `SaeWkDKBFEHzo3WaiWtA`,
	"preview_jobrole_con": `bi1A5WgFKQZvkJhoeMd6`,
	"heading": `kJSAoQiaxf0GgK7sA2gL`,
	"jobrole_preview_collapse_row": `pXt0kZYzWxwWxJRmMt93`,
	"jobrole_label": `OZHU94cJooEmLDPrQ4Mb`,
	"jobrole_list": `YmUB4nbNObDp9xmRJgo4`
};
export default ___CSS_LOADER_EXPORT___;
